import { useSubscriptionContext } from '~/providers/SubscriptionProvider';

export const useShouldShowStorageUsage = () => {
  const { data: subscription } = useSubscriptionContext();
  const shouldShowStorageUsage = !!subscription?.maxStorage;

  return {
    shouldShowStorageUsage,
  };
};
