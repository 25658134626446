import pluralize from 'pluralize';

import DragType from '~/components/Shared/Drag/dragTypes';

interface ReactDndDragItemsCountProps {
  type: DragType;
  mixedTypes: boolean;
  count: number;
}

export const ReactDndDragItemsCount = ({ count, mixedTypes, type }: ReactDndDragItemsCountProps) => {
  const countText = () => {
    let itemsText = '';

    if (mixedTypes) {
      itemsText = 'item';
    } else {
      switch (type) {
        case DragType.boardNavFavorites:
        case DragType.boardNavWorkspace:
        case DragType.boardNavLibrary:
        case DragType.boardCard:
          itemsText = 'board';
          break;
        case DragType.asset:
          itemsText = 'asset';
          break;
        case DragType.file:
          itemsText = 'file';
          break;
      }
    }

    return pluralize(itemsText.toUpperCase(), count, true);
  };

  return (
    <div className="rounded-3xl bg-black/80 px-[7px] py-2">
      <div className="text-12 font-bold text-grey-1">{countText()}</div>
    </div>
  );
};
