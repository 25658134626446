import { Workspaces } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const WORKSPACE_TOKENS = 'WORKSPACE_TOKENS';

export const getWorkspaceTokensKey = (workspaceId?: string) =>
  workspaceId ? [WORKSPACE_TOKENS, { workspaceId }] : [WORKSPACE_TOKENS];

export const useInviteTokens = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  return useQuery({
    queryKey: getWorkspaceTokensKey(workspaceId),

    queryFn: () => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return Workspaces.getInviteTokens({ workspaceId });
    },
    enabled: !!workspaceId,
  });
};
