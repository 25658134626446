import { WorkspaceInfo } from '@air/api/types';
import { Tooltip } from '@air/primitive-tooltip';
import { tailwindMerge } from '@air/tailwind-variants';
import { useCopyToClipboard } from 'react-use';

import DraggableWorkspaceItem from '~/components/MainNavigation/WorkspacesNav/DraggableWorkspaceItem';
import { WorkspaceNavImage } from '~/components/MainNavigation/WorkspacesNav/WorkspacesNavImage';
import { WORKSPACE_NAV_WORKSPACE_BUTTON } from '~/constants/testIDs';

import { WorkspaceNavButton } from './WorkspaceNavButton';

export interface WorkspaceNavButtonProps {
  index: number;
  workspace: WorkspaceInfo;
  onClick: () => void;
  isActive: boolean;
}

export const WorkspaceButton = ({ workspace, onClick, isActive, index }: WorkspaceNavButtonProps) => {
  const [_, copyToClipboard] = useCopyToClipboard();

  return (
    <DraggableWorkspaceItem index={index} workspace={workspace}>
      <Tooltip label={workspace.name} side="right">
        <WorkspaceNavButton
          className="relative mx-auto flex justify-center"
          onClick={onClick}
          onContextMenu={(event) => {
            event.preventDefault();
            copyToClipboard(workspace.id);
          }}
          data-testid={WORKSPACE_NAV_WORKSPACE_BUTTON}
          data-workspace-id={workspace.id}
          data-workspace-name={workspace.name}
          state={isActive ? 'active' : 'default'}
        >
          <WorkspaceNavImage
            className={tailwindMerge(
              isActive ? 'border border-grey-1 opacity-100' : 'opacity-40',
              'transition-opacity hover:opacity-100',
            )}
            image={workspace.image}
            data-testid={workspace.name}
          />
        </WorkspaceNavButton>
      </Tooltip>
    </DraggableWorkspaceItem>
  );
};
