import { useTrackToggledAnonymousAccountRequirementOff } from '@air/analytics/src/events/useTrackToggledAnonymousAccountRequirementOff';
import { useTrackToggledAnonymousAccountRequirementOn } from '@air/analytics/src/events/useTrackToggledAnonymousAccountRequirementOn';
import { Form } from '@air/api/types';
import { ShortUrlResponse } from '@air/types';
import { memo, useCallback } from 'react';

import { useUpdateContentCollectionLink } from '~/components/Modals/ContentCollectionModal/hooks/useUpdateContentCollectionLink';
import { PermissionToggleInfo } from '~/components/SharePrivateContentModal/shared/PermissionToggleInfo';
import { SharePrivateContentPermissionToggle } from '~/components/SharePrivateContentModal/shared/SharePrivateContentPermissionToggle';

interface ContentCollectionRequireAccountToggleProps {
  formId: Form['id'];
  shortIdInfo: ShortUrlResponse;
  boardId: string;
}

export const ContentCollectionRequireAccountToggle = memo(
  ({ shortIdInfo, formId, boardId }: ContentCollectionRequireAccountToggleProps) => {
    const { onShortUrlUpdate, isLoading } = useUpdateContentCollectionLink({ formId, shortIdInfo });
    const { trackToggledAnonymousAccountRequirementOn } = useTrackToggledAnonymousAccountRequirementOn();
    const { trackToggledAnonymousAccountRequirementOff } = useTrackToggledAnonymousAccountRequirementOff();

    const onCheckedChange = useCallback(
      (checked: boolean) => {
        if (checked) {
          trackToggledAnonymousAccountRequirementOn({
            share_url: shortIdInfo.id,
            object_id: boardId,
            object_type: 'board',
          });
        } else {
          trackToggledAnonymousAccountRequirementOff({
            share_url: shortIdInfo.id,
            object_id: boardId,
            object_type: 'board',
          });
        }
        return onShortUrlUpdate({
          requireAccount: checked,
        });
      },
      [
        boardId,
        onShortUrlUpdate,
        shortIdInfo.id,
        trackToggledAnonymousAccountRequirementOff,
        trackToggledAnonymousAccountRequirementOn,
      ],
    );

    return (
      <SharePrivateContentPermissionToggle
        label="Require name and email to upload"
        id="requireAccount"
        isLoading={isLoading}
        checked={!!shortIdInfo.requireAccount}
        onCheckedChange={onCheckedChange}
      >
        <PermissionToggleInfo text="Link visitors must enter name and email to upload" />
      </SharePrivateContentPermissionToggle>
    );
  },
);

ContentCollectionRequireAccountToggle.displayName = 'ContentCollectionRequireAccountToggle';
