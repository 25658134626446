import { Library } from '@air/api/types';

import { LibraryAdvancedSettingsAssignOwners } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsAssignOwners';
import { LibraryAdvancedSettingsCustomFieldsContainer } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsCustomFieldsContainer';
import { LibraryAdvancedSettingsDefaultRoleContainer } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsDefaultRoleContainer';
import { LibraryAdvancedSettingsDeleteLibraryContainer } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsDeleteLibraryContainer';
import { LibraryAdvancedSettingsLeaveLibraryContainer } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsLeaveLibraryContainer';

export type LibraryAdvancedSettingsProps = {
  libraryId: Library['id'];
  showAssignOwners?: boolean;
  showChangeDefaultRoles?: boolean;
  showCustomFields?: boolean;
  showDeleteLibrary?: boolean;
  showLeaveLibrary?: boolean;
  onAssignOwners?: () => void;
  onChangeDefaultRole?: () => void;
  onDeleteLibrary?: () => void;
  onLeaveLibrary?: () => void;
};

export const LibraryAdvancedSettings = ({
  libraryId,
  onAssignOwners,
  onChangeDefaultRole,
  onDeleteLibrary,
  onLeaveLibrary,
  showAssignOwners = false,
  showChangeDefaultRoles = false,
  showCustomFields = false,
  showLeaveLibrary = true,
  showDeleteLibrary = true,
}: LibraryAdvancedSettingsProps) => {
  return (
    <div className="flex flex-col gap-4">
      {showCustomFields && (
        <>
          <LibraryAdvancedSettingsCustomFieldsContainer libraryId={libraryId} />
          <div className="h-px bg-grey-5" />
        </>
      )}

      {showChangeDefaultRoles && (
        <>
          <LibraryAdvancedSettingsDefaultRoleContainer libraryId={libraryId} onChangeRole={onChangeDefaultRole} />
          <div className="h-px bg-grey-5" />
        </>
      )}

      {showAssignOwners && (
        <>
          <LibraryAdvancedSettingsAssignOwners onAssignOwners={onAssignOwners} />
          <div className="h-px bg-grey-5" />
        </>
      )}

      {showLeaveLibrary && (
        <>
          <LibraryAdvancedSettingsLeaveLibraryContainer libraryId={libraryId} onLeaveLibrary={onLeaveLibrary} />
          <div className="h-px bg-grey-5" />
        </>
      )}

      {showDeleteLibrary && (
        <LibraryAdvancedSettingsDeleteLibraryContainer libraryId={libraryId} onDelete={onDeleteLibrary} />
      )}
    </div>
  );
};
