import { Close } from '@air/next-icons';
import { memo } from 'react';

import { CompletedAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { GetCompletedMetadata, LibraryDeletionTask } from '~/store/tasks/types';

export type DuplicateBoardCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<LibraryDeletionTask>;
  onClear: () => void;
};

export const DeleteLibraryCompletedPanelItem = memo(({ onClear }: DuplicateBoardCompletedPanelItemProps) => {
  return (
    <PaneItemTW
      truncate={false}
      avatar={<CompletedAvatar />}
      title={<StatusTrackingTitle>Library deleted successfully</StatusTrackingTitle>}
      buttons={<PaneButton label="Clear" Icon={Close} onClick={onClear} />}
    />
  );
});

DeleteLibraryCompletedPanelItem.displayName = 'DeleteLibraryCompletedPanelItem';
