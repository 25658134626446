import { Button } from '@air/primitive-button';

interface BoardsNavSectionTextButtonProps {
  text: string;
  onClick?: () => void;
}

export const BoardsNavSectionTextButton = ({ onClick, text }: BoardsNavSectionTextButtonProps) => {
  return (
    <Button appearance="ghost" className="ml-6 justify-start" color="grey" onClick={onClick} size="small">
      {text}
    </Button>
  );
};
