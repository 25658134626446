import { useTrackSavedFilterUpdated } from '@air/analytics';
import { SavedFilter } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { useAirModal } from '@air/provider-modal';
import { useCallback, useMemo } from 'react';

import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { canDeleteSavedFilters } from '~/utils/permissions/workspacePermissions';

import { ModalFormData, SavedFilterModal } from './components/SavedFilterModal';
import { DeleteSavedFilterModal } from './DeleteSavedFilterModal';
import { useUpdateSavedFilter } from './shared/hooks/useUpdateSavedFilter';

export interface EditSavedFiltersModalProps extends AirModalProps {
  savedFilter: SavedFilter;
}

export const EditSavedFilterModal = ({ onClose, savedFilter }: EditSavedFiltersModalProps) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { updateSavedFilter } = useUpdateSavedFilter();
  const [showDeleteSavedFilterModal] = useAirModal(DeleteSavedFilterModal);
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canDeleteSavedFilter = canDeleteSavedFilters(permissions);
  const { trackSavedFilterUpdated } = useTrackSavedFilterUpdated();

  const onApplyClick = useCallback(
    async (modalFormData: ModalFormData) => {
      updateSavedFilter.mutate(
        {
          id: savedFilter.id,
          ...modalFormData,
          criteria: {
            ...savedFilter.criteria,
            workspaceId: currentWorkspace?.id as string,
          },
        },
        {
          onSuccess: () => {
            if (!currentWorkspaceMember) {
              return;
            }

            trackSavedFilterUpdated({
              currentUser: currentWorkspaceMember.accountId || '',
              role: currentWorkspaceMember?.role?.type || '',
              createdBy: savedFilter.createdBy,
            });

            onClose();
          },
        },
      );
    },
    [
      updateSavedFilter,
      savedFilter.id,
      savedFilter.criteria,
      savedFilter.createdBy,
      currentWorkspace?.id,
      currentWorkspaceMember,
      trackSavedFilterUpdated,
      onClose,
    ],
  );

  const onDelete = useCallback(() => {
    showDeleteSavedFilterModal({ savedFilter, onDelete: () => onClose() });
  }, [onClose, savedFilter, showDeleteSavedFilterModal]);

  const tertiaryCTA = useMemo(() => {
    return canDeleteSavedFilter ? (
      <Button appearance="ghost" color="red" size="large" onClick={onDelete}>
        Delete
      </Button>
    ) : undefined;
  }, [canDeleteSavedFilter, onDelete]);

  return (
    <SavedFilterModal
      isEditing
      modalLabel="Update saved filter"
      onClose={onClose}
      onSubmit={onApplyClick}
      primaryCTACopy="Update"
      savedFilter={savedFilter}
      tertiaryCTA={tertiaryCTA}
    />
  );
};
