import { type ResponseCustomFieldValue } from '@air/api/types';
import { HoverCard } from '@air/primitive-hover-card';
import { memo, useMemo } from 'react';

import {
  NotificationCustomFieldSingleSelectValue,
  NotificationCustomFieldValueProps,
} from '~/components/AirNotifications/components/NotificationCustomFieldSingleSelectValue';

export const MAX_CUSTOM_FIELD_VALUES = 2;

type ValueWithSetType = ResponseCustomFieldValue & Pick<Required<NotificationCustomFieldValueProps>, 'state'>;

export type NotificationCustomFieldsMultiselectValuesProps = {
  newValues: ResponseCustomFieldValue[];
  removedValues: ResponseCustomFieldValue[];
};

export const NotificationCustomFieldsMultiselectValues = memo(
  ({ newValues, removedValues }: NotificationCustomFieldsMultiselectValuesProps) => {
    const values = useMemo(() => {
      const values: ValueWithSetType[] = [];

      newValues.forEach((value) => {
        values.push({
          ...value,
          state: 'set',
        });
      });

      removedValues.forEach((value) => {
        values.push({
          ...value,
          state: 'unset',
        });
      });

      return values;
    }, [newValues, removedValues]);

    const shouldTruncate = values.length > MAX_CUSTOM_FIELD_VALUES;
    const formattedValues = useMemo(() => {
      if (shouldTruncate) {
        return values.slice(0, MAX_CUSTOM_FIELD_VALUES);
      }

      return values;
    }, [shouldTruncate, values]);

    const moreValues = useMemo(() => {
      if (shouldTruncate) {
        return values.slice(MAX_CUSTOM_FIELD_VALUES);
      }

      return [];
    }, [shouldTruncate, values]);

    return (
      <div className="flex items-center gap-1">
        {formattedValues.map((value) => {
          return <NotificationCustomFieldSingleSelectValue key={value.id} state={value.state} value={value} />;
        })}
        {shouldTruncate ? (
          <HoverCard
            className="flex w-full max-w-[240px] flex-row flex-wrap gap-2"
            trigger={
              <div className="relative z-10 cursor-help text-12 text-pigeon-400">
                +{values.length - MAX_CUSTOM_FIELD_VALUES} more
              </div>
            }
          >
            {moreValues.map((value) => {
              return <NotificationCustomFieldSingleSelectValue key={value.id} state={value.state} value={value} />;
            })}
          </HoverCard>
        ) : null}
      </div>
    );
  },
);

NotificationCustomFieldsMultiselectValues.displayName = 'NotificationCustomFieldsMultiselectValues';
