import { Subnav } from '@air/api';
import { SortDirection } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { canChangeWorkspaceDetails } from '~/utils/permissions/workspacePermissions';

import { getWorkspaceSubnavSortKey } from './useSubnavSort';

export const useUpdateSubnavSort = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const queryClient = useQueryClient();

  const canUpdateSubnavSort = canChangeWorkspaceDetails(permissions);

  const updateSubnavSort = useCallback(
    async (name: string, direction: SortDirection) => {
      try {
        if (!currentWorkspace?.id) {
          return;
        }
        const key = getWorkspaceSubnavSortKey(currentWorkspace.id);
        queryClient.setQueryData(key, { workspaceId: currentWorkspace.id, boardSort: { name, direction } });
        await Subnav.updateSubnav({ workspaceId: currentWorkspace.id, update: { boardSort: { name, direction } } });
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to update subnav board sort',
          metadata: { data: { boardSort: { name, direction } } },
        });
      }
    },
    [currentWorkspace?.id, queryClient],
  );

  return { canUpdateSubnavSort, updateSubnavSort };
};
