import Router from 'next/router';
import { useCallback } from 'react';

import { AccountNotificationsType } from '~/components/AirNotifications/utils/types';
import { createLibraryRoute, Routes } from '~/constants/routes';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';

import { GoToAssetPageProps, useGoToAssetPage } from './useGoToAssetPage';
import { useGoToBoardAssetPage } from './useGoToBoardAssetPage';
import { useGoToBoardPage } from './useGoToBoardPage';

export function useHandleNotification() {
  const { goToAssetPage } = useGoToAssetPage();
  const { goToBoardAssetPage } = useGoToBoardAssetPage();
  const { goToBoardPage } = useGoToBoardPage();

  const handleNotification = useCallback(
    (notification: AccountNotificationsType) => {
      switch (notification.type) {
        case 'commentCreated_userIsMentioned':
        case 'commentCreated_userIsBoardFollower':
        case 'commentCreated_userIsShareLinkCreator':
        case 'commentCreated_userIsDiscussionParticipant': {
          const {
            data: { clip, board },
          } = notification;
          if (clip) {
            if (board) {
              goToBoardAssetPage({
                board,
                asset: clip as GoToAssetPageProps['asset'],
                tab: AssetModalPanel.COMMENTS,
              });
            } else {
              goToAssetPage({ asset: clip, trackLocation: 'comment-notification', base: Routes.media.all });
            }
          }
          return;
        }
        case 'memberInvited_userIsMember':
        case 'clipAppended_userIsBoardFollower':
        case 'clipAppended_userIsShareLinkCreator': {
          goToBoardPage({
            board: notification.data.board,
            trackLocation: 'notification',
          });
          return;
        }
        case 'boardAppended_userIsBoardFollower': {
          if (notification.data.child) {
            goToBoardPage({
              board: notification.data.child,
              trackLocation: 'notification',
            });
          }
          return;
        }
        case 'assetCustomFieldUpdated_userIsBoardFollower':
        case 'assetCustomFieldUpdated_userIsShareLinkCreator': {
          if (notification.data.assetIds.length > 1) {
            if (notification.data.boards.length > 0) {
              goToBoardPage({
                board: notification.data.boards[0],
                trackLocation: 'notification',
              });
            }
          } else {
            if (notification.data.clips.length === 1) {
              if (notification.data.boards.length === 0) {
                goToAssetPage({
                  base: Routes.media.all,
                  asset: notification.data.clips[0],
                  trackLocation: 'share-link-notification',
                });
              } else if (notification.data.boards.length === 1) {
                goToBoardAssetPage({
                  board: notification.data.boards[0],
                  asset: notification.data.clips[0] as GoToAssetPageProps['asset'],
                  tab: AssetModalPanel.FIELDS,
                });
              } else {
                goToBoardPage({
                  board: notification.data.boards[0],
                  trackLocation: 'notification',
                });
              }
            } else if (notification.data.clips.length > 1) {
              if (notification.data.boards.length > 0) {
                goToBoardAssetPage({
                  board: notification.data.boards[0],
                  asset: notification.data.clips[0] as GoToAssetPageProps['asset'],
                  tab: AssetModalPanel.FIELDS,
                });
              }
            }
          }
          return;
        }
        case 'boardCustomFieldUpdated_userIsBoardFollower':
        case 'boardCustomFieldUpdated_userIsShareLinkCreator': {
          if (notification.data.boards.length > 0) {
            goToBoardPage({
              board: notification.data.boards[0],
              trackLocation: 'notification',
            });
          }
          return;
        }
        case 'clipDownloaded_userIsShareLinkCreator': {
          const { clips, shortUrl } = notification.data;
          if (clips.length === 1) {
            if (shortUrl.boardContext) {
              goToBoardAssetPage({
                board: shortUrl.boardContext,
                asset: clips[0],
              });
            } else {
              goToAssetPage({
                base: Routes.media.all,
                asset: clips[0],
                trackLocation: 'share-link-notification',
              });
            }
          } else if (shortUrl.objectType === 'board') {
            goToBoardPage({
              board: { id: shortUrl.objectId, title: shortUrl.objectName },
              trackLocation: 'notification',
            });
          } else {
            goToAssetPage({
              base: Routes.media.all,
              asset: { id: shortUrl.objectId },
              trackLocation: 'share-link-notification',
            });
          }
          break;
        }
        case 'itemsDownloaded_userIsShareLinkCreator': {
          const { clips, shortUrl, boards } = notification.data;
          if (clips.length === 1 && boards.length === 0) {
            if (shortUrl.boardContext) {
              goToBoardAssetPage({
                board: shortUrl.boardContext,
                asset: clips[0],
              });
            } else {
              goToAssetPage({
                base: Routes.media.all,
                asset: clips[0],
                trackLocation: 'share-link-notification',
              });
            }
          } else if (boards.length === 1 && clips.length === 0) {
            goToBoardPage({
              board: boards[0],
              trackLocation: 'notification',
            });
          } else {
            goToBoardPage({
              board: { id: shortUrl.objectId, title: shortUrl.objectName },
              trackLocation: 'notification',
            });
          }
          break;
        }

        case 'libraryMemberInvited_userIsMember':
        case 'libraryMemberInvited_userIsAdmin':
        case 'libraryMemberUpdated_userIsMember':
          Router.push(createLibraryRoute(notification.data.library.id));
          return;
        case 'shareLinkExpired_userIsShareLinkCreator':
        case 'shareLinkExpiring_userIsShareLinkCreator':
        case 'shareLinkNotDownloaded_userIsShareLinkCreator':
        case 'shareLinkNotViewed_userIsShareLinkCreator':
        case 'shareLinkViewed_userIsShareLinkCreator': {
          {
            const { shortUrl } = notification.data;
            if (shortUrl.objectType === 'clip') {
              const board = shortUrl.boardContext;
              if (board) {
                goToBoardAssetPage({
                  board,
                  asset: { id: shortUrl.objectId },
                });
              } else {
                goToAssetPage({
                  base: Routes.media.all,
                  asset: { id: shortUrl.objectId },
                  trackLocation: 'share-link-notification',
                });
              }
            } else if (shortUrl.objectType === 'board') {
              goToBoardPage({
                board: { id: shortUrl.objectId, title: shortUrl.objectName },
                trackLocation: 'notification',
              });
            }
          }
          break;
        }
        default:
          console.error('Unhandled notification type', notification.type);
          break;
      }
    },
    [goToAssetPage, goToBoardAssetPage, goToBoardPage],
  );
  return {
    handleNotification,
  };
}
