import { Palette } from '@air/icons';
import { darken } from 'polished';
import { ComponentPropsWithoutRef, forwardRef, useState } from 'react';

import { CUSTOM_FIELD_MODAL_OPTION_COLOR_MENU_TRIGGER } from '~/constants/testIDs';

interface CustomFieldModalColorMenuTriggerProps extends ComponentPropsWithoutRef<'div'> {
  selectedColor: string;
  fontHex: string;
}

export const CustomFieldModalColorMenuTrigger = forwardRef<HTMLDivElement, CustomFieldModalColorMenuTriggerProps>(
  ({ selectedColor, fontHex, ...props }: CustomFieldModalColorMenuTriggerProps, ref) => {
    const [isHovered, setIsHovered] = useState(false);

    const darkenBgColor = darken(0.1, selectedColor);

    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={ref}
        className="absolute left-2 top-1/2 flex h-6 w-6 -translate-y-1/2 cursor-pointer items-center justify-center rounded p-0"
        data-testid={CUSTOM_FIELD_MODAL_OPTION_COLOR_MENU_TRIGGER}
        style={{
          backgroundColor: isHovered ? darkenBgColor : selectedColor,
          color: fontHex,
        }}
        {...props}
      >
        <Palette className="h-4 w-4" />
      </div>
    );
  },
);

CustomFieldModalColorMenuTrigger.displayName = 'CustomFieldModalColorMenuTrigger';
