import { SidebarNavigation, SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { BoardMove, EllipsisVertical, Lifeline, Star, Tag } from '@air/next-icons';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Router from 'next/router';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SidebarNavigationItemAll } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemAll';
import { SidebarNavigationItemBrowseLibraries } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemBrowseLibraries';
import { SidebarNavigationItemFavorites } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemFavorites';
import { SidebarNavigationItemMyUploads } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemMyUploads';
import { SidebarNavigationItemPeople } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemPeople';
import { SidebarNavigationItemRecentlyAdded } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemRecentlyAdded';
import { SidebarNavigationItemRecentlyDeleted } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemRecentlyDeleted';
import { SidebarNavigationItemRecentlyModified } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemRecentlyModified';
import { SidebarNavigationItemUntaggedItems } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemUntaggedItems';
import { SidebarNavigationItemUnusedItems } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemUnusedItems';
import { SidebarNavigationItemWorkspaceSettings } from '~/components/CurrentWorkspaceNav/components/SidebarNavigationItemWorkspaceSettings';
import { SavedFiltersNavigationItem } from '~/components/CurrentWorkspaceNav/SavedFiltersNavigationItem';
import { Routes } from '~/constants/routes';
import { SIDEBAR_NAVIGATION_ITEM_MORE } from '~/constants/testIDs';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import {
  viewingFavoritesSelector,
  viewingRecentlyModifiedSelector,
  viewingUntaggedItemsSelector,
  viewingUnusedItemsSelector,
} from '~/store/router/selectors';
import { useCurrentWorkspaceAddOns } from '~/swr-hooks/workspaces/addOns/useCurrentWorkspaceAddOns';
import { isAddOnEnabled } from '~/utils/addOns';
import { canSeeWorkspaceMembers, canViewAddOns, canViewPeople } from '~/utils/permissions/workspacePermissions';

export const WorkspaceNavigation = () => {
  const { deletionRecovery: deletionRecoveryFlag, libraries: librariesFlag } = useFlags();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const canViewMembers = canSeeWorkspaceMembers(workspacePermissions);
  const isViewingFavorites = useSelector(viewingFavoritesSelector);
  const isViewingRecentlyModified = useSelector(viewingRecentlyModifiedSelector);
  const isViewingUnusedItems = useSelector(viewingUnusedItemsSelector);
  const isViewingUntaggedItems = useSelector(viewingUntaggedItemsSelector);

  const canSeeAddOns = canViewAddOns(workspacePermissions);

  const { data: addOns } = useCurrentWorkspaceAddOns({ enabled: canSeeAddOns });
  const isFacesAddOnEnabled = useMemo(
    () => addOns?.data?.some((addOn) => addOn.key === 'facialRecognition' && isAddOnEnabled(addOn)),
    [addOns?.data],
  );

  const canSeePeople = canViewPeople(workspacePermissions) && isFacesAddOnEnabled;

  const moreItems = useMemo(() => {
    const moreItemsArr: DropdownMenuOption[] = [];

    if (!isViewingFavorites) {
      moreItemsArr.push({
        id: 'my-favorites',
        label: 'My favorites',
        onSelect: () => Router.push(Routes.media.favoriteItems),
        prefix: <Star className="h-4 w-4" />,
        type: 'item',
      });
    }

    if (!isViewingRecentlyModified) {
      moreItemsArr.push({
        id: 'recently-modified',
        label: 'Recently modified',
        onSelect: () => Router.push(Routes.media.recentlyModified),
        prefix: <Lifeline className="h-4 w-4" />,
        type: 'item',
      });
    }

    if (!isViewingUnusedItems) {
      moreItemsArr.push({
        id: 'unused-items',
        label: 'Unused items',
        onSelect: () => Router.push(Routes.media.unusedItems),
        prefix: <BoardMove className="h-4 w-4" />,
        type: 'item',
      });
    }

    if (!isViewingUntaggedItems) {
      moreItemsArr.push({
        id: 'untagged-items',
        label: 'Untagged items',
        onSelect: () => Router.push(Routes.media.untaggedItems),
        prefix: <Tag className="h-4 w-4" />,
        type: 'item',
      });
    }

    return moreItemsArr;
  }, [isViewingFavorites, isViewingRecentlyModified, isViewingUnusedItems, isViewingUntaggedItems]);

  return (
    <SidebarNavigation>
      <SidebarNavigationItemAll />
      {librariesFlag && <SidebarNavigationItemBrowseLibraries />}
      <SidebarNavigationItemRecentlyAdded />
      <SavedFiltersNavigationItem />
      <SidebarNavigationItemMyUploads />
      {deletionRecoveryFlag && <SidebarNavigationItemRecentlyDeleted />}

      {canViewMembers && <SidebarNavigationItemWorkspaceSettings />}

      {canSeePeople && <SidebarNavigationItemPeople />}

      {isViewingFavorites && <SidebarNavigationItemFavorites />}
      {isViewingRecentlyModified && <SidebarNavigationItemRecentlyModified />}
      {isViewingUnusedItems && <SidebarNavigationItemUnusedItems />}
      {isViewingUntaggedItems && <SidebarNavigationItemUntaggedItems />}

      <DropdownMenu
        alignOffset={32}
        align="start"
        className="min-w-[240px]"
        trigger={
          <SidebarNavigationItem
            data-testid={SIDEBAR_NAVIGATION_ITEM_MORE}
            prefix={<EllipsisVertical className="h-4 w-4" />}
          >
            More
          </SidebarNavigationItem>
        }
      >
        {renderDropdownItems({ options: moreItems })}
      </DropdownMenu>
    </SidebarNavigation>
  );
};
