import { useTrackCompletedDragging } from '@air/analytics';
import { Clip } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import { resetSelectedItemsAction, selectedItemsCountSelector } from '@air/redux-selected-items';
import { compact } from 'lodash';
import pluralize from 'pluralize';
import { useDispatch } from 'react-redux';

import { MergeAssetsModal } from '~/components/Modals/MergeAssetsModal';
import { SelectableGalleryAssetItem, SelectableGalleryFileItem } from '~/store/selectedItems/types';
import { useMergeVersions } from '~/swr-hooks/versions/useMergeVersions';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

import { DropLocation } from '../../dragTypes';

export interface HandleDragAssetsToMergeParams {
  asset: Clip;
  assets: (SelectableGalleryAssetItem | SelectableGalleryFileItem)[];
  dropType: DropLocation;
}

export const useHandleDragAssetsToMerge = () => {
  const store = useAirStore();
  const dispatch = useDispatch();
  const { mergeAssets } = useMergeVersions();
  const [showMergeAssetsModal, hideMergeAssetsModal] = useAirModal(MergeAssetsModal);
  const { showToast } = useToasts();
  const { trackCompletedDragging } = useTrackCompletedDragging();

  const handleDragAssetsToMerge = ({ assets, asset, dropType }: HandleDragAssetsToMergeParams) => {
    const assetIdsToMerge = compact(assets.map((asset) => asset.item.assetId));

    if (!assetIdsToMerge.includes(asset.assetId)) {
      const currentBoardId = getBoardIdFromPath(window.location.pathname);
      const selectedCount = selectedItemsCountSelector(store.getState());

      showMergeAssetsModal({
        mergeAssets: () => {
          mergeAssets({
            targetAsset: asset,
            assetsToMerge: assetIdsToMerge,
            draggedClips: assets,
            boardId: currentBoardId,
          });
          showToast(
            `${pluralize('asset', assetIdsToMerge.length, true)} moved to ${asset?.title ?? asset?.importedName}`,
          );
          hideMergeAssetsModal();

          trackCompletedDragging({
            numberOfAssets: assets.length,
            clipIds: assetIdsToMerge,
            dropLocation: {
              type: dropType,
              id: asset.id,
            },
            usedSelecting: !!selectedCount,
          });

          dispatch(resetSelectedItemsAction());
        },
      });
    }
  };

  return {
    handleDragAssetsToMerge,
  };
};
