import { Subscriptions } from '@air/api';
import { PlanPricing } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { getSubscriptionPlanKey } from '~/swr-hooks/subscriptions/useSubscriptionPlan';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export type EnhancedSubscriptionPlan = {
  plan: PlanPricing;
};

export type SubscriptionPlanProviderContextValue = {
  data: EnhancedSubscriptionPlan | undefined;
  error?: unknown;
};

const DEFAULT_VALUE: SubscriptionPlanProviderContextValue = {
  data: undefined,
};

export const SubscriptionPlanContext = createContext(DEFAULT_VALUE);

export type SubscriptionPlanProviderProps = PropsWithChildren<object>;

/**
 * @deprecate Use `<PlanProvider` in favor of this provider.
 * @todo Remove this while removing feature flag.
 */
export const SubscriptionPlanProvider = ({ children }: SubscriptionPlanProviderProps) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: subscription } = useSubscriptionContext();
  const subscriptionPlanKey = getSubscriptionPlanKey(currentWorkspace?.id);

  const { data, error } = useQuery({
    queryKey: subscriptionPlanKey,

    queryFn: () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Subscriptions.getSubscriptionPlan({ workspaceId });
    },
    enabled: !!currentWorkspace?.id && !!subscription?.planId,
  });

  const value = useMemo(() => ({ data, error }), [data, error]);

  return <SubscriptionPlanContext.Provider value={value}>{children}</SubscriptionPlanContext.Provider>;
};

export const useSubscriptionPlanContext = () => {
  const context = useContext(SubscriptionPlanContext);

  if (context === DEFAULT_VALUE) {
    const error = 'SubscriptionProviderContext used outside of SubscriptionProvider';

    if (isDevOrTestStage()) {
      throw new Error(error);
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
