import { useTrackCompletedDragging, useTrackStartedDragging } from '@air/analytics';
import { Board, Clip, Library } from '@air/api/types';
import {
  itemIsSelectedSelector,
  resetSelectedItemsAction,
  selectedItemsCountSelector,
} from '@air/redux-selected-items';
import classnames from 'classnames';
import { memo, PropsWithChildren, useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { useMountedState } from 'react-use';

import { GalleryItemType } from '~/components/Gallery/types';
import { useHandleDragItemsToRootBoard } from '~/components/Shared/Drag/DNDMovableToBoardItem/hooks/useHandleDragItemsToRootBoard';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import {
  hasSelectedSameTypeItemsSelector,
  selectedBoardPrivateItemsSelector,
  selectedClipItemsSelector,
} from '~/store/selectedItems/selectors';
import { useGetSubnavSortValue } from '~/swr-hooks/subnav/useSubnavSort';
import { useRemoveBoardsFromSideNav } from '~/utils/mutateUtils/SideNavBoards';
import { useAirSelector, useAirStore } from '~/utils/ReduxUtils';

import DragType, { DropData, isBoardDragType, RearrangableItemType } from '../dragTypes';
import { useHandleDragAssetsToMerge } from './hooks/useHandleDragAssetsToMerge';
import { useHandleDragBoardsToLibrary } from './hooks/useHandleDragBoardsToLibrary';
import { useHandleDragItemsToBoard } from './hooks/useHandleDragItemsToBoard';
import { droppedOnAsset, droppedOnBoard, droppedOnRoot } from './hooks/utils';

export const isBoardDragItem = (item: AssetDragItem | BoardDragItem): item is BoardDragItem =>
  isBoardDragType(item.type);

export interface DragItem {
  type: DragType;
  index?: number;
}

export interface BoardDragItem extends DragItem {
  /** This is to dictate the item being dragged is a board  */
  type: DragType.boardCard | DragType.boardNavFavorites | DragType.boardNavWorkspace | DragType.boardNavLibrary;
  /** The board to be moved */
  board: Board;
}

export interface AssetDragItem extends DragItem {
  /** This is to dictate the item being dragged is an asset  */
  type: DragType.asset | DragType.file;
  /** The asset to be moved */
  asset: Clip;
  assetId?: Clip['assetId'];
}

export interface DNDMovableToBoardItemProps {
  /** Item has the details about the item being dragged */
  item: BoardDragItem | AssetDragItem;
  /** A fn to call when dragging starts */
  onDragStart?: (id: string | number) => void;
  /** Dictates whether or not the item can be dragged, ie. public/private mode */
  enabled?: boolean;
  /** This is the id the asset or board */
  id: Clip['id'] | Board['id'];

  index?: number;

  'data-testid'?: string;
}

/**
 * DNDMovableToBoardItem is an HOC that adds functionality for dragging to a board, and it currently accepts a board or an asset.
 */
export const DNDMovableToBoardItem = memo(
  ({
    item,
    id,
    children,
    onDragStart,
    enabled = true,
    index,
    'data-testid': testId,
  }: PropsWithChildren<DNDMovableToBoardItemProps>) => {
    const dispatch = useDispatch();
    const store = useAirStore();
    const [isMoving, setIsMoving] = useState(false);
    const isMounted = useMountedState();

    const { trackStartedDragging } = useTrackStartedDragging();
    const { trackCompletedDragging } = useTrackCompletedDragging();
    const { handleDragAssetsToMerge } = useHandleDragAssetsToMerge();
    const { handleDragItemsToBoard } = useHandleDragItemsToBoard();
    const { handleDragItemsToRootBoard } = useHandleDragItemsToRootBoard();
    const { handleDragBoardsToLibrary } = useHandleDragBoardsToLibrary();
    const { removeBoardsFromSideNav } = useRemoveBoardsFromSideNav();
    const { getSubNavSortValue } = useGetSubnavSortValue();
    const { currentWorkspace } = useCurrentWorkspace();

    const selectedCount = selectedItemsCountSelector(store.getState());
    const selectedClips = selectedClipItemsSelector(store.getState());
    const selectedBoards = selectedBoardPrivateItemsSelector(store.getState());
    const hasSelectedOneItemType = hasSelectedSameTypeItemsSelector(store.getState());

    const clips = [...selectedClips];
    const boards = [...selectedBoards];

    const isSelected = useAirSelector((st) => itemIsSelectedSelector(st, id));

    const getItem = (): RearrangableItemType => {
      if (isBoardDragItem(item)) {
        return {
          id,
          type: item.type,
          count: !isSelected ? 1 : selectedCount,
          index: index || 0,
          mixedTypes: !hasSelectedOneItemType,
          firstItem: item.board,
          parentId: item.board.parentId,
        };
      } else {
        return {
          id,
          type: item.type,
          count: !isSelected ? 1 : selectedCount,
          index: index || 0,
          mixedTypes: !hasSelectedOneItemType,
          firstItem: item.asset,
          assetId: item.asset.assetId,
        };
      }
    };

    const getAssetsBoardsIds = (): { clipIds: Clip['id'][]; boardIds: Board['id'][] } => {
      const clipIds = isSelected ? clips.map(({ id }) => id) : [];
      const boardIds = isSelected ? boards.map(({ id }) => id) : [];

      if (!isSelected) {
        switch (item.type) {
          case DragType.boardNavWorkspace:
          case DragType.boardNavFavorites:
          case DragType.boardNavLibrary:
          case DragType.boardCard:
            boardIds.push(id);
            break;
          case DragType.asset:
          case DragType.file:
            clipIds.push(id);
            break;
          default:
            break;
        }
      }

      return { clipIds, boardIds };
    };

    // if we drag an items, its not selected - add it to boards or assets array
    const addNotSelectedItemToItemsArray = () => {
      if (!isSelected) {
        switch (item.type) {
          case DragType.boardNavFavorites:
          case DragType.boardNavWorkspace:
          case DragType.boardCard:
          case DragType.boardNavLibrary:
            boards.push({
              type: GalleryItemType.board,
              id,
              item: item.board,
            });
            break;

          case DragType.asset:
          case DragType.file:
            clips.push({
              type: GalleryItemType.asset,
              id,
              item: item.asset,
            });
            break;

          default:
            break;
        }
      }
    };

    const [{ isDragging }, drag, preview] = useDrag({
      item: getItem(),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      begin: () => {
        const { boardIds, clipIds } = getAssetsBoardsIds();

        if (!isSelected) {
          dispatch(resetSelectedItemsAction());
        }

        trackStartedDragging({
          numberOfBoards: boardIds.length,
          boardIds,
          numberOfAssets: clipIds.length,
          clipIds,
        });

        if (onDragStart) {
          onDragStart(id);
        }
      },
      end: async (_dragPayload, monitor) => {
        // this board comes from the `drop` of DNDParentBoard
        const dropResult: DropData<Board | Clip | Library> | null = monitor.getDropResult();
        const dropType = dropResult?.type;

        if (!dropResult || dropType === 'rejected') {
          return;
        }

        // TODO: Why is this scenario handle here? This component is for items that can be moved to a board.
        // This is for rearranging. Why isn't the drop for the rearrange handling this scenario and this component
        // only cares about dropType === move?
        if (dropType === 'rearrange') {
          trackCompletedDragging({
            numberOfBoards: boards.length,
            boardIds: boards.map(({ id }) => id),
            numberOfAssets: clips.length,
            clipIds: clips.map(({ id }) => id),
            dropLocation: {
              type: dropResult.location,
            },
            usedSelecting: !!selectedCount,
          });

          dispatch(resetSelectedItemsAction());
        } else if (dropType === 'move') {
          // TODO: should this also be above for rearrange?
          addNotSelectedItemToItemsArray();

          setIsMoving(true);

          if (droppedOnRoot(dropResult.data)) {
            handleDragItemsToRootBoard({
              boards,
            });
          } else if (droppedOnBoard(dropResult.data)) {
            const board = dropResult.data;

            handleDragItemsToBoard({
              assets: clips,
              boards,
              board,
              dropLocation: dropResult.location,
            });
          } else if (droppedOnAsset(dropResult.data)) {
            const asset = dropResult.data;

            handleDragAssetsToMerge({
              asset,
              assets: clips,
              dropType: dropResult.location,
            });
          }

          if (!isMounted()) {
            return;
          }

          setIsMoving(false);
        } else if (dropType === 'move-to-library') {
          addNotSelectedItemToItemsArray();
          const { library: oldLibrary, parentId } = boards[0].item;

          const library = dropResult.data as Library;
          if (library && boards.length > 0 && currentWorkspace) {
            handleDragBoardsToLibrary({ boards, library });
            removeBoardsFromSideNav({
              workspaceId: currentWorkspace?.id,
              boardIds: boards.map((b) => b.id),
              parentId: parentId,
              navSortParams: getSubNavSortValue()?.boardSort,
              libraryId: oldLibrary?.id || undefined,
            });
          }
        }
      },
      canDrag: enabled,
    });

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    return (
      <div
        data-testid={testId}
        data-draggable="true"
        className={classnames('h-full', {
          dragging: isDragging,
          'moving-item': isMoving,
        })}
        ref={drag}
      >
        {children}
      </div>
    );
  },
);

DNDMovableToBoardItem.displayName = 'DNDMovableToBoardItem';
