import { permissionsSelector } from '~/store/permissions/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface UseFormPermissionsParams {
  formId: string | undefined;
}

export const useFormPermissions = ({ formId }: UseFormPermissionsParams) => {
  const formPermissions = useAirSelector((st) => permissionsSelector(st, 'formIds', formId));

  return {
    formPermissions,
  };
};
