import { TreeItemAction } from '@air/component-tree';
import { EllipsisVertical as EllipsisVerticalIcon, Plus as PlusIcon } from '@air/next-icons';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';

import { useGeneralLibraryMenuOptions } from '~/components/LibraryBeta/GeneralLibraryPage/hooks/useGeneralLibraryMenuOptions';
import { CreateBoardModal } from '~/components/Modals/CreateBoardModal';
import { BOARDS_SELECT_EMPTY_OPTION } from '~/constants/BoardSearch';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';

export const GeneralLibraryItemActions = memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canCreateRootBoards = canCreateBoard(permissions);
  const [showCreateBoardModal] = useAirModal(CreateBoardModal);

  const createNewRootBoard = useCallback(() => {
    showCreateBoardModal({
      selectedParentBoard: BOARDS_SELECT_EMPTY_OPTION,
      trackLocation: 'side-nav-extension',
    });
  }, [showCreateBoardModal]);

  const { menuOptions } = useGeneralLibraryMenuOptions();

  if (!canCreateRootBoards && !menuOptions.length) {
    return null;
  }

  return (
    <div
      className={classNames(
        'items-center group-focus-within/treeItem:flex group-hover/treeItem:flex group-active/treeItem:flex',
        isOpen ? 'flex' : 'hidden',
      )}
    >
      {!!menuOptions.length && (
        <DropdownMenu
          align="start"
          onOpenChange={setIsOpen}
          open={isOpen}
          trigger={
            <TreeItemAction>
              <EllipsisVerticalIcon className="h-4 w-4" />
            </TreeItemAction>
          }
        >
          {renderDropdownItems({ options: menuOptions })}
        </DropdownMenu>
      )}

      {canCreateRootBoards && (
        <TreeItemAction data-testid="GENERAL_LIBRARY_NAV_ITEM_ADD_BOARD_BUTTON" onClick={createNewRootBoard}>
          <PlusIcon className="h-4 w-4" />
        </TreeItemAction>
      )}
    </div>
  );
});

GeneralLibraryItemActions.displayName = 'GeneralLibraryItemMenu';
