import { Upload } from '@air/redux-uploader';
import { useUploadSmall } from '@air/upload-utils';
import { useCallback } from 'react';

import { PrivateUploadTaskParams } from '~/components/Upload/hooks/types';
import { usePrepareForPrivateUploading } from '~/components/Upload/privateUpload/usePrepareForPrivateUploading';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { metadataForPrivateUploadSelector } from '~/store/privateUploadMetadata/selectors';
import { useAirStore } from '~/utils/ReduxUtils';
import { fetchSmallPrivateUploadUrl } from '~/utils/uploadApi/privateUploadApi/privateSmallUploadApi';

export const useUploadSmallPrivateFile = () => {
  const store = useAirStore();
  const { uploadSmall } = useUploadSmall();
  const { prepareForUploading } = usePrepareForPrivateUploading();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const uploadSmallPrivateFile = useCallback(
    async ({ uploadId, onUploadFinished, onBoardCreated, onUploadStarted, onError }: PrivateUploadTaskParams) => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      await uploadSmall({
        uploadId,
        onUploadStarted,
        onUploadFinished,
        onError,
        getUploadUrls: async (upload: Upload) => {
          const parentBoardId = await prepareForUploading({ upload, onBoardCreated });

          const uploadMetadata = metadataForPrivateUploadSelector(store.getState(), uploadId);
          return fetchSmallPrivateUploadUrl({
            parentBoardId,
            assetId: uploadMetadata?.assetId,
            apiUploadData: upload.apiUploadData,
            workspaceId,
          });
        },
      });
    },
    [prepareForUploading, store, uploadSmall, workspaceId],
  );

  return {
    uploadSmallPrivateFile,
  };
};
