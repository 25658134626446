import { type Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';
import pluralize from 'pluralize';
import { useMemo } from 'react';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { NotificationBoard } from '~/components/AirNotifications/components/NotificationBoard';
import { NotificationCustomFields } from '~/components/AirNotifications/components/NotificationCustomFields';

export type NotificationBoardCustomFieldUpdatedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification: Notification<
    'boardCustomFieldUpdated_userIsBoardFollower' | 'boardCustomFieldUpdated_userIsShareLinkCreator',
    'account'
  >;
};

export const NotificationBoardCustomFieldUpdatedType = ({
  notification,
  onClick,
}: NotificationBoardCustomFieldUpdatedTypeProps) => {
  const {
    data: { actor, boards, customFields, shortUrl },
  } = notification;

  const meta = useMemo(() => {
    if (shortUrl) {
      return 'via share link';
    } else if (boards.length === 0) {
      return null;
    } else if (boards.length === 1) {
      return `in ${boards[0].title}`;
    } else {
      return `in ${pluralize('board', boards.length, true)}`;
    }
  }, [boards, shortUrl]);

  const boardsTitle = useMemo(() => {
    if (boards.length === 0) {
      return '[deleted board(s)]';
    } else if (boards.length === 1) {
      return boards[0].title;
    } else {
      return `${pluralize('boards', boards.length, true)}`;
    }
  }, [boards]);

  return (
    <NotificationBaseItem
      content={<NotificationCustomFields customFields={customFields} />}
      meta={meta}
      notification={notification}
      onClick={boards.length > 0 ? onClick : undefined}
      suffix={boards.length > 1 ? <NotificationBoard board={boards[0]} /> : undefined}
      title={
        <>
          <b>{formatActorNameInitialsOrDefault(actor)}</b>
          {` edited custom fields on `}
          <b>{boardsTitle}</b>
        </>
      }
    />
  );
};
