import { ShortUrl } from '@air/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getShortIdInformationKey } from '~/swr-hooks/shortUrl/useGetExistingShortIdInformation';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export const useGetShortIdForForm = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();

  const getShortIdForForm = useMutation({
    mutationFn: ({ id, boardContext }: Omit<Parameters<typeof ShortUrl.getShortIdForForm>[0], 'workspaceId'>) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      /**
       * We do not do permission checks here because
       * if the user created a form, they have permission to create a short url link for it.
       * @see https://air-labs-team.slack.com/archives/C052RDYCGAG/p1691021989976859
       */

      return ShortUrl.getShortIdForForm({
        workspaceId,
        id,
        boardContext,
      });
    },
    onSuccess: (data, { id, boardContext }) => {
      queryClient.setQueryData(getShortIdInformationKey({ objectId: id, boardContext }), data);

      return data;
    },
    onError: (error, params) => {
      throw reportErrorToBugsnag({
        error,
        context: `Failed to create a new content collection share link`,
        metadata: {
          params,
        },
      });
    },
  });

  return {
    getShortIdForForm,
  };
};
