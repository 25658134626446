import { ReactElement } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { useStripe } from '~/hooks/useStripe';

interface AirStripeProps {
  children: ({ stripeIsLoaded }: { stripeIsLoaded: boolean }) => ReactElement | null;
}

/**
 * Use this component when you want to load Stripe to use any of it's elements.
 * It'll pass a boolean as a render prop to children so you can know for sure once
 * Stripe has finished loading
 * @param {ReactElement | null} children - The components you'd like to render that depend on Stripe being loaded
 */
const AirStripe = ({ children }: AirStripeProps) => {
  const { canStripeProviderRender, apiKey } = useStripe();

  return canStripeProviderRender ? (
    // TODO: react-stripe-elements has been deprecated, we should be using:
    // https://github.com/stripe/react-stripe-js
    // These component do no have a `children` prop defined, and don't play well with react 18.
    // We're not able to patch them, so we have to ignore the type error.
    // @ts-ignore
    <StripeProvider apiKey={apiKey}>
      {/* @ts-ignore */}
      <Elements>{children({ stripeIsLoaded: true })}</Elements>
    </StripeProvider>
  ) : (
    children({ stripeIsLoaded: false })
  );
};

export default AirStripe;
