import { Close } from '@air/next-icons';
import { Avatar } from '@air/primitive-avatar';
import { IconButton } from '@air/primitive-icon-button';

import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

export type LibraryInviteMemberChipProps = {
  avatar?: string;
  colorSeed?: string;
  label: string;
  onRemoveClick?: () => void;
};

export const LibraryInviteMemberChip = ({ avatar, colorSeed, label, onRemoveClick }: LibraryInviteMemberChipProps) => {
  const initials = label
    .split(' ')
    .map((word) => word[0])
    .join('');

  return (
    <div className="my-0.5 mr-2 flex h-7 items-center gap-1.5 rounded-full bg-pigeon-800/15 pl-0.5 pr-2">
      <Avatar
        alt={`${label}'s avatar`}
        appearance="circle"
        colorSeed={colorSeed}
        size={24}
        src={getAvatarImageSrc(avatar, 24)}
        text={initials}
      />

      <div className="flex max-w-[240px] grow flex-col overflow-hidden">
        <div className="truncate text-14 font-medium text-pigeon-600">{label}</div>
      </div>

      {!!onRemoveClick && (
        <IconButton
          label="Remove member"
          icon={Close}
          size="extra-small"
          appearance="ghost"
          color="grey"
          onClick={onRemoveClick}
        />
      )}
    </div>
  );
};
