import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentPropsWithoutRef, forwardRef, memo } from 'react';

export type SidebarContentProps = ComponentPropsWithoutRef<'div'>;

export const SidebarContent = memo(
  forwardRef<HTMLDivElement, SidebarContentProps>(({ className, ...restOfProps }, forwardedRef) => {
    return (
      <div
        className={tailwindMerge('flex-1 overflow-y-auto p-2', className)}
        data-testid="SIDEBAR_CONTENT"
        ref={forwardedRef}
        {...restOfProps}
      />
    );
  }),
);

SidebarContent.displayName = 'SidebarContent';
