import { useTrackOpenIntercomChat } from '@air/analytics';
import { AlertBanner } from '@air/primitive-alert-banner';
import { useAirModal } from '@air/provider-modal';
import { memo, useMemo } from 'react';

import { Routes } from '~/constants/routes';
import { OUT_OF_STORAGE_BANNER } from '~/constants/testIDs';
import { NEW_TAB } from '~/constants/urls';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';
import { useStorage } from '~/swr-hooks/subscriptions/useStorage';
import { canManageSubscription } from '~/utils/permissions/workspacePermissions';

import { EnterprisePlanModal } from '../Modals/EnterprisePlanModal';
import { PlansModal } from '../Modals/PlansModal/PlansModal';

export const WorkspaceStorageStatusBanner = memo(() => {
  const { data: subscription } = useSubscriptionContext();
  const { members } = useWorkspaceMembers();
  const { currentWorkspace } = useCurrentWorkspace();
  const [showPlansModal] = useAirModal(PlansModal);
  const [showEnterprisePlanModal] = useAirModal(EnterprisePlanModal);
  const { data: storage } = useStorage();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const { trackOpenIntercomChat } = useTrackOpenIntercomChat();

  const message = useMemo(() => {
    const prefix = 'Uh oh!';
    if (storage && storage.isOutOfStorage) {
      return `${prefix} Your workspace is out of storage. Note that your storage total may take up to 30 minutes to update.`;
    }

    return `${prefix} Your workspace is almost out of storage. Note that your storage total may take up to 30 minutes to update.`;
  }, [storage]);

  if (!currentWorkspace || !storage || !members || (!storage?.isOutOfStorage && !storage?.isAlmostOutOfStorage)) {
    return null;
  }

  const showPlanUpgradeModal = () => {
    if (subscription?.isEnterprise) {
      return showEnterprisePlanModal();
    }
    showPlansModal();
  };

  const canEditPlan = canManageSubscription(workspacePermissions);

  return (
    <AlertBanner
      color={storage.isOutOfStorage && !subscription?.isInFreePlan ? 'red' : 'yellow'}
      data-testid={OUT_OF_STORAGE_BANNER}
    >
      {message}{' '}
      <a
        className="font-semibold text-inherit"
        href={canEditPlan ? Routes.workspaceAdmin.subscription : `#`}
        onClick={
          canEditPlan
            ? (e) => {
                e.preventDefault();

                if (subscription?.isEnterprise) {
                  trackOpenIntercomChat();
                } else {
                  showPlanUpgradeModal();
                }
              }
            : undefined
        }
        rel={!canEditPlan ? 'noopener noreferrer' : ''}
        target={!canEditPlan ? NEW_TAB : ''}
      >
        {canEditPlan
          ? subscription?.isOnProPlan
            ? subscription?.isEnterprise
              ? 'Talk to support →'
              : 'Upgrade to Enterprise →'
            : 'Upgrade now →'
          : 'Notify your workspace admin'}
      </a>
    </AlertBanner>
  );
});

WorkspaceStorageStatusBanner.displayName = 'WorkspaceStorageStatusBanner';
