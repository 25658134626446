import type { ScopedPermissions } from '@air/api';
import { WorkspaceUserRole } from '@air/api/types';

/**
 * This type can go away and be replaced by ScopedPermissions once all the permissions are cleaned up
 */
type _ScopedPermissions = Omit<ScopedPermissions, 'shortUrl'> & {
  shortUrl: Omit<ScopedPermissions['shortUrl'], 'canDownloadClips' | 'canViewAssetVersions'>;
};

export const isPermissionsGTE = (high: _ScopedPermissions, low: _ScopedPermissions) =>
  Object.entries(low).every(([scope, properties]) =>
    Object.entries(properties).every(([property, required]) => !required || (high as any)?.[scope]?.[property]),
  );

export const getLeastPermissiveRole = (roles: WorkspaceUserRole[]): WorkspaceUserRole =>
  roles.reduce(
    (selectedRole, role) => (isPermissionsGTE(selectedRole.permissions, role.permissions) ? role : selectedRole),
    roles[0],
  );
