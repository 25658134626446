import {
  ActionSheet,
  ActionSheetHeader,
  ActionSheetOption,
  ActionSheetProps,
  renderActionSheetItems,
} from '@air/primitive-action-sheet';

export type ActionSheetModalProps = Pick<AirModalProps, 'onClose'> &
  Omit<ActionSheetProps, 'onClose'> & {
    options: ActionSheetOption[];
    title?: string;
  };

/**
 * This component is used so you can easily pass in a blank actionsheet into AirModal
 */
export const ActionSheetModal = ({ onClose, options, title, ...restOfProps }: ActionSheetModalProps) => {
  return (
    <ActionSheet {...restOfProps} onClose={onClose}>
      {!!title && <ActionSheetHeader onClose={onClose}>{title}</ActionSheetHeader>}
      {renderActionSheetItems({ options: options })}
    </ActionSheet>
  );
};
