import { Task } from '@air/classes-task-scheduler';
import { useCallback } from 'react';

import { PrivateUploadTaskParams } from '~/components/Upload/hooks/types';
import { useIsBlockedByParentBoard } from '~/components/Upload/privateUpload/useIsBlockedByParentBoard';
import { useUploadSmallPrivateFile } from '~/components/Upload/privateUpload/useUploadSmallPrivateFile';

export const useGetSmallPrivateUploadTask = () => {
  const { isBlockedByParentBoard } = useIsBlockedByParentBoard();
  const { uploadSmallPrivateFile } = useUploadSmallPrivateFile();

  const getSmallUploadTask = useCallback(
    (params: PrivateUploadTaskParams): Task => {
      return {
        id: params.uploadId,
        getIsBlocked: () => isBlockedByParentBoard(params.uploadId),
        run: () => uploadSmallPrivateFile(params),
      };
    },
    [isBlockedByParentBoard, uploadSmallPrivateFile],
  );

  return {
    getSmallUploadTask,
  };
};
