import { Library } from '@air/api/types';
import { useMemo } from 'react';

import { useAccountContext } from '~/providers/AccountProvider';
import { useWorkspaceRolesContext } from '~/providers/WorkspaceRolesProvider';

import { useLibraryMembers } from './useLibraryMembers';

export type UseCurrentWorkspaceLibraryMemberParams = {
  libraryId: Library['id'] | undefined;
};

export const useCurrentWorkspaceLibraryMember = ({ libraryId }: UseCurrentWorkspaceLibraryMemberParams) => {
  const { data: libraryMembers } = useLibraryMembers({
    libraryId,
    options: {
      hideSysadmins: false,
    },
  });
  const { data: account } = useAccountContext();
  const { data: roles } = useWorkspaceRolesContext();

  const currentWorkspaceLibraryMember = useMemo(() => {
    const currentMember = libraryMembers?.find((member) => member.accountId === account?.id);
    const memberRole = roles?.find((role) => role.id === currentMember?.roleId);

    return { ...currentMember, role: memberRole };
  }, [account?.id, libraryMembers, roles]);

  return {
    currentWorkspaceLibraryMember,
  };
};
