import { useTrackLoggedOutInactiveUser } from '@air/analytics';
import { Importer } from '@air/api';
import { ClipSource, WorkspaceListResponse } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { hasCurrentlyUploadingUploadsSelector } from '@air/redux-uploader';
import { useEffect } from 'react';

import { InactivityAlertModal } from '~/components/Modals/InactivityAlertModal';
import { useAccountContext } from '~/providers/AccountProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useInactivity } from '~/providers/InactivityProvider';
import { zippingTasksSelector } from '~/store/tasks/selectors';
import { useLogout } from '~/swr-hooks/auth/useLogout';
import { getImportsInProgress } from '~/swr-hooks/useImports';
import { useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';
import { useAirStore } from '~/utils/ReduxUtils';

export const PROMPT_BEFORE_IDLE_SECONDS = 60;

/**
 * We want the most restrictive (as far as time) to be the value that gets used for that user's session
 */
const getShortestTimeoutInWorkspaces = (workspaces: WorkspaceListResponse) =>
  workspaces.reduce<null | number>((curr, { inactivityTimeout }) => {
    if (!curr) {
      return inactivityTimeout;
    }

    return inactivityTimeout && inactivityTimeout < curr ? inactivityTimeout : curr;
  }, null);

export const useHandleInactivity = () => {
  const store = useAirStore();
  const { setProps, start, pause, activate } = useInactivity();
  const [showInactivityAlertModal, hideInactivityModal] = useAirModal(InactivityAlertModal);
  const { data: workspaces } = useWorkspaces();
  const { logout } = useLogout();
  const { trackLoggedOutInactiveUser } = useTrackLoggedOutInactiveUser();
  const { data: account } = useAccountContext();
  const timeout = workspaces ? getShortestTimeoutInWorkspaces(workspaces) : null;
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  useEffect(() => {
    /**
     * We don't want to start the timer if the currenet user is the sysadmin
     * @see https://air-labs-team.slack.com/archives/CKE8SK3M1/p1679418630172709?thread_ts=1679418558.491369&cid=CKE8SK3M1
     */
    if (!account || account.email === 'admin@aircamera.com') {
      return;
    }

    if (timeout) {
      setProps({
        onIdle: () => {
          pause();
          hideInactivityModal();
          trackLoggedOutInactiveUser();
          logout({
            query: {
              expiredSession: true,
            },
          });
        },
        onPrompt: async () => {
          if (!workspaceId) {
            throw new Error('No workspace id');
          }

          const isUploading = hasCurrentlyUploadingUploadsSelector(store.getState());
          const zippingTasks = zippingTasksSelector(store.getState());
          const isZipping = !!zippingTasks.length;
          let hasImportsInProgress: boolean | null = null;

          try {
            const list = await Importer.list({
              workspaceId,
              params: {
                limit: 1000,
                sources: [ClipSource.dropbox, ClipSource.googleDrive, ClipSource.box],
              },
            });

            /**
             * Before we show the prompt to a user letting them know they might be logged out,
             * we first check to see if they have any active imports going on. If they do,
             * or if they have any active uploads or are zipping files for downloads,
             * we keep the timer active
             */
            hasImportsInProgress = getImportsInProgress(list.data).length > 0;
          } catch (_error) {
            /**
             * In the case that the server error for some reason trying to fetch the list,
             * we'll just assume they don't have any imports going on and we'll log them out.
             */
            hasImportsInProgress = false;
          }

          if (isUploading || isZipping || hasImportsInProgress) {
            activate();
          } else {
            showInactivityAlertModal();
          }
        },
        timeout: timeout * 1000,
        promptBeforeIdle: PROMPT_BEFORE_IDLE_SECONDS * 1000,
      });

      start();
    } else {
      /**
       * We have a pause here because if the inactivity timeout changes during a session (like a user turns it off),
       * the value could change and thus we would need to stop the existing timer
       */
      pause();
    }
  }, [
    hideInactivityModal,
    logout,
    pause,
    setProps,
    showInactivityAlertModal,
    start,
    workspaces,
    trackLoggedOutInactiveUser,
    store,
    activate,
    timeout,
    account,
    workspaceId,
  ]);
};
