import { AlertBanner } from '@air/primitive-alert-banner';
import { useAirModal } from '@air/provider-modal';
import Router from 'next/router';
import { useCallback, useMemo } from 'react';
import { useMount } from 'react-use';

import { ALERT_BANNER_SUBSCRIPTION } from '~/constants/localStorageKeys';
import { Routes } from '~/constants/routes';
import {
  SUBSCRIPTION_EXPIRATION_BANNER_CANCELLED,
  SUBSCRIPTION_EXPIRATION_BANNER_EXPIRED,
  SUBSCRIPTION_EXPIRATION_BANNER_INVALID,
  SUBSCRIPTION_EXPIRATION_BANNER_PAYMENT_FAILED,
} from '~/constants/testIDs';
import { useFixPlanAction } from '~/hooks/useFixPlanAction';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { canManageSubscription } from '~/utils/permissions/workspacePermissions';

import UpdatePaymentMethodWrapper from '../Modals/Billing/UpdatePaymentMethod';
import { PlansModal } from '../Modals/PlansModal/PlansModal';

export const StripeSubscriptionStatusBanner = () => {
  const { data: subscription } = useSubscriptionContext();
  const [showPlansModal] = useAirModal(PlansModal);
  const [showUpdatePaymentMethodModal] = useAirModal(UpdatePaymentMethodWrapper);
  const { href } = useFixPlanAction({ action: 'renew' });
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const canEditPlan = canManageSubscription(workspacePermissions);

  useMount(() => {
    Router.prefetch(Routes.workspaceAdmin.subscription);
  });

  const {
    message,
    label = 'Upgrade now →',
    testId,
  } = useMemo(() => {
    if (!subscription) return {};
    switch (subscription.expirationReason) {
      case 'payment_failed':
        return {
          message: `Your payment has failed.`,
          label: `Update now →`,
          testId: SUBSCRIPTION_EXPIRATION_BANNER_PAYMENT_FAILED,
        };
      case 'subscription_cancelled':
        return {
          message: `Your subscription was cancelled.`,
          label: 'Resubscribe now →',
          testId: SUBSCRIPTION_EXPIRATION_BANNER_CANCELLED,
        };
      case 'product_not_available':
      case 'price_increase':
      case 'unknown_error':
        return {
          message: `Your subscription is no longer valid.`,
          testId: SUBSCRIPTION_EXPIRATION_BANNER_INVALID,
        };

      default:
        return {
          message: `Your subscription has expired.`,
          label: `Reactivate`,
          testId: SUBSCRIPTION_EXPIRATION_BANNER_EXPIRED,
        };
    }
  }, [subscription]);

  const handleClick = useCallback(() => {
    if (canEditPlan) {
      if (subscription?.expirationReason === 'payment_failed') {
        Router.push(Routes.workspaceAdmin.subscription);
        showUpdatePaymentMethodModal();
      } else {
        showPlansModal();
      }
    } else {
      Router.push(href);
    }
  }, [canEditPlan, href, showPlansModal, showUpdatePaymentMethodModal, subscription?.expirationReason]);

  if (
    !subscription ||
    subscription.plan === 'demo' ||
    (!subscription.expirationReason && subscription.hasActiveSubscription)
  ) {
    return null;
  }

  return (
    <AlertBanner data-testid={testId} dismissDays={7} id={ALERT_BANNER_SUBSCRIPTION}>
      Uh oh! {message}{' '}
      <button className="font-semibold" onClick={handleClick}>
        {canEditPlan ? label : 'Notify your workspace admin'}
      </button>
    </AlertBanner>
  );
};
