import { useTrackClickedLibrariesCTA } from '@air/analytics/src/events/useTrackClickedLibrariesCTA';
import { Button } from '@air/primitive-button';
import { useAirModal } from '@air/provider-modal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, useCallback } from 'react';

import { UpgradeForLibrariesModal } from '~/components/CurrentWorkspaceNav/TryLibrariesCTA/UpgradeForLibrariesModal/UpgradeForLibrariesModal';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { canChangeWorkspaceDetails } from '~/utils/permissions/workspacePermissions';

export const TryLibrariesCTA = memo(() => {
  const { data } = useSubscriptionContext();
  const { libraries: librariesFlag, librariesCta } = useFlags();
  const { trackClickedLibrariesCTA } = useTrackClickedLibrariesCTA();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const [showUpgradeForLibrariesModal] = useAirModal(UpgradeForLibrariesModal);

  const onClick = useCallback(() => {
    trackClickedLibrariesCTA();
    showUpgradeForLibrariesModal();
  }, [showUpgradeForLibrariesModal, trackClickedLibrariesCTA]);

  // TODO: this flag should be removed after GA release https://airlabs.atlassian.net/browse/ORCO-1368
  if (!librariesCta) {
    return null;
  }

  /**
   * Using canChangeWorkspaceDetails as permission check for this
   * @see https://air-labs-team.slack.com/archives/C052RDYCGAG/p1698804806437019
   */
  if (!data || data?.isEnterprise || !!librariesFlag || !canChangeWorkspaceDetails(permissions)) {
    return null;
  }

  return (
    <Button appearance="outline" color="grey" className="m-4" onClick={onClick}>
      Try libraries
    </Button>
  );
});

TryLibrariesCTA.displayName = 'TryLibrariesCTA';
