import { memo } from 'react';

import { CurrentWorkspaceStorageMeter } from '~/components/Workspace/CurrentWorkspaceStorageMeter';
import { useShouldShowStorageUsage } from '~/swr-hooks/subscriptions/useShouldShowStorageUsage';

export const CurrentWorkspaceHeaderStorageMeter = memo(() => {
  const { shouldShowStorageUsage } = useShouldShowStorageUsage();

  return shouldShowStorageUsage ? (
    <div className="mt-3">
      <CurrentWorkspaceStorageMeter />
    </div>
  ) : null;
});

CurrentWorkspaceHeaderStorageMeter.displayName = 'CurrentWorkspaceHeaderStorageMeter';
