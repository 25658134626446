import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps } from 'react';

export type LibraryIconProps = Pick<ComponentProps<'div'>, 'children' | 'className'> & {
  background: string;
  color: string;
};

export const LibraryIcon = ({ background, children, className, color }: LibraryIconProps) => {
  return (
    <div
      className={tailwindMerge('flex h-5 w-5 items-center justify-center rounded', className)}
      style={{
        backgroundColor: background,
        color,
      }}
    >
      {children}
    </div>
  );
};
