import { ChevronUp } from '@air/next-icons';
import { Badge } from '@air/primitive-badge';
import { useAirModal } from '@air/provider-modal';
import * as RadixCollapsible from '@radix-ui/react-collapsible';
import { useFormikContext } from 'formik';

import { CustomFieldFormAPIInformationCopyValue } from '~/components/CustomFields/CustomFieldModal/CustomFieldModalForm/CustomFieldModalFormAPIInformationCopyValue';
import { CustomFieldModalFormData } from '~/components/CustomFields/CustomFieldModal/CustomFieldModalForm/types';
import { ChilipiperModal } from '~/components/Modals/ChilipiperModal';
import { useIsAdminEnterpriseMember } from '~/hooks/useAdminEnterpriseMember';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canManageSubscription } from '~/utils/permissions/workspacePermissions';

export const CustomFieldModalFormAPIInformation = () => {
  const { isAdminEnterpriseMember } = useIsAdminEnterpriseMember();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const [showContactSalesModal] = useAirModal(ChilipiperModal);
  const { values } = useFormikContext<CustomFieldModalFormData>();

  const canEditPlan = canManageSubscription(workspacePermissions);

  if (!values.id) return null;

  return (
    <div className="mt-6 flex flex-col gap-6 border-t border-t-grey-5 pt-6">
      <div className="flex flex-col gap-2">
        <div className="text-12 font-semibold text-grey-11">Field ID</div>

        <div className="flex gap-1">
          {isAdminEnterpriseMember ? (
            <CustomFieldFormAPIInformationCopyValue id={values.id} />
          ) : (
            <div className="text-12 text-grey-9">
              Upgrade to Enterprise to access API info.{' '}
              {canEditPlan ? (
                <button
                  className="mr-1 inline text-grey-12 hover:underline"
                  onClick={() =>
                    showContactSalesModal({
                      uiLocation: 'asset_modal_api_cta',
                    })
                  }
                  type="button"
                >
                  Contact sales →
                </button>
              ) : (
                'Contact a workspace admin to upgrade.'
              )}
            </div>
          )}
        </div>
      </div>

      {isAdminEnterpriseMember && !!values?.values?.length && (
        <RadixCollapsible.Root className="flex flex-col gap-2" defaultOpen={false}>
          <RadixCollapsible.Trigger className="group">
            <div className="flex items-center gap-1 text-12 font-semibold text-grey-11">
              Option IDs
              <ChevronUp className="h-4 w-4 group-radix-state-open:rotate-180" />
            </div>
          </RadixCollapsible.Trigger>

          <RadixCollapsible.Content className="flex flex-col gap-1">
            {values.values.map((value) => (
              <div className="flex gap-1" key={value.id}>
                <div className="flex gap-2 text-12 text-grey-9">
                  <Badge
                    style={{
                      backgroundColor: value.color?.backgroundHex,
                      color: value.color?.fontHex,
                    }}
                  >
                    {value.value}
                  </Badge>
                  <CustomFieldFormAPIInformationCopyValue id={value.id} />
                </div>
              </div>
            ))}
          </RadixCollapsible.Content>
        </RadixCollapsible.Root>
      )}
    </div>
  );
};
