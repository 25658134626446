/* eslint-disable jsx-a11y/alt-text */
import { Close, Image, Stack } from '@air/next-icons';
import { RadioGroup, RadioGroupItem, RadioGroupProps } from '@air/primitive-radio-group';

export enum DuplicateItemChoice {
  Stack = 'stack',
  Separate = 'separate',
  Cancel = 'cancel',
}

export type DuplicateItemOptionsProps = Pick<RadioGroupProps, 'name' | 'onValueChange' | 'value'> & {
  isApplyAll?: boolean;
};

export const DuplicateItemOptions = ({ isApplyAll, name, onValueChange, value }: DuplicateItemOptionsProps) => {
  return (
    <RadioGroup data-testid="DUPLICATE_ITEMS_OPTIONS" name={name} onValueChange={onValueChange} value={value}>
      <RadioGroupItem
        className="rounded border border-grey-5 p-3"
        data-testid="DUPLICATE_ITEMS_OPTIONS_OPTION"
        data-id={DuplicateItemChoice.Stack}
        id={`${name}-${DuplicateItemChoice.Stack}`}
        value="stack"
      >
        <Stack className="h-4 w-4 text-grey-8" />
        Stack as new version
      </RadioGroupItem>

      <RadioGroupItem
        className="rounded border border-grey-5 p-3"
        data-testid="DUPLICATE_ITEMS_OPTIONS_OPTION"
        data-id={DuplicateItemChoice.Separate}
        id={`${name}-${DuplicateItemChoice.Separate}}`}
        value="separate"
      >
        <Image className="h-4 w-4 text-grey-8" />
        Upload as separate {isApplyAll ? 'items' : 'item'}
      </RadioGroupItem>

      <RadioGroupItem
        className="rounded border border-grey-5 p-3"
        data-testid="DUPLICATE_ITEMS_OPTIONS_OPTION"
        data-id={DuplicateItemChoice.Cancel}
        id={`${name}-${DuplicateItemChoice.Cancel}`}
        value="cancel"
      >
        <Close className="h-4 w-4 text-grey-8" />
        Cancel upload for&nbsp;
        {isApplyAll ? 'all items' : 'this item'}
      </RadioGroupItem>
    </RadioGroup>
  );
};
