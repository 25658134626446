import { Tasks } from '@air/api';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { GetBaseMetadata, LibraryDeletionTask } from '~/store/tasks/types';
import { useCreateTask } from '~/utils/taskUtils/useCreateTask';

export type CreateDeleteLibraryTaskParams = GetBaseMetadata<LibraryDeletionTask>;

export const useCreateDeleteLibraryTask = () => {
  const { createTask } = useCreateTask();
  const { currentWorkspace } = useCurrentWorkspace();

  const createDeleteLibraryTask = useCallback(
    async ({ libraryId, removeClips, removeBoards }: CreateDeleteLibraryTaskParams) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return createTask({
        task: {
          metadata: {
            removeBoards,
            removeClips,
            libraryId,
          },
          type: 'LibraryDeletion',
          workspaceId,
        },
        createTask: () =>
          Tasks.createTask({
            workspaceId,
            task: {
              type: 'ContentRemover',
              args: {
                removeClips,
                libraryId,
              },
            },
          }),
      });
    },
    [createTask, currentWorkspace?.id],
  );

  return {
    createDeleteLibraryTask,
  };
};
