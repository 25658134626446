import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentPropsWithoutRef, memo, useCallback, useEffect, useState } from 'react';

export const SIDEBAR_LOCAL_STORAGE_KEY = 'SIDEBAR_SIZE';

export type SidebarResizerProps = ComponentPropsWithoutRef<'div'> & {
  localStorageKey?: string;
  maxSize?: number;
  minSize?: number;
  size: number;
};

export const SidebarResizer = memo(
  ({
    className,
    localStorageKey = SIDEBAR_LOCAL_STORAGE_KEY,
    maxSize = 720,
    minSize = 200,
    size,
    ...restOfProps
  }: SidebarResizerProps) => {
    const [sidebarWidth, setSidebarWidth] = useState<number>(size);
    const [canResize, setCanResize] = useState(false);
    const [initialPosition, setInitialPosition] = useState(0);

    const handleSizeChange = useCallback(
      (width: number) => {
        window.localStorage.setItem(localStorageKey, width.toString());

        setSidebarWidth(width);
        document.documentElement.style.setProperty('--sidebar-width', width + 'px');
      },
      [localStorageKey],
    );

    const handleReset = useCallback(() => {
      handleSizeChange(size);
    }, [handleSizeChange, size]);

    useEffect(() => {
      const handleMouseUp = () => setCanResize(false);

      window.addEventListener('mouseup', handleMouseUp);

      return () => window.removeEventListener('mouseup', handleMouseUp);
    }, []);

    useEffect(() => {
      const width = window.localStorage.getItem(localStorageKey);

      if (width) {
        setSidebarWidth(parseInt(width, 10));
      }
    }, [localStorageKey]);

    return (
      <div
        className={tailwindMerge(
          'group absolute -right-2 bottom-0 top-0 z-10 flex h-full w-3 cursor-col-resize select-none flex-col pl-1',
          className,
        )}
        data-testid="SIDEBAR_RESIZER"
        data-draggable={false}
        data-disableselect={true}
        onMouseDown={(event) => {
          /**
           * If the user double clicks on the resizer,
           * we reset the size to the default
           */
          if (event.detail === 2) {
            setCanResize(false);
            handleReset();
          } else {
            setInitialPosition(event.clientX - sidebarWidth);
            setCanResize(true);
          }
        }}
        onMouseMove={(event) => {
          if (canResize) {
            const delta = event.clientX - initialPosition;

            if (maxSize && delta > maxSize) {
              handleSizeChange(maxSize);
            } else if (minSize && delta < minSize) {
              handleSizeChange(minSize);
            } else {
              handleSizeChange(delta);
            }
          }
        }}
        {...restOfProps}
      >
        <div
          className={
            'pointer-events-none -ml-px h-full w-1 bg-macaw-200 opacity-0 transition-opacity duration-300 group-hover:opacity-100'
          }
        />
        {canResize ? <div className="fixed inset-0 z-10 h-full w-full" /> : null}
      </div>
    );
  },
);

SidebarResizer.displayName = 'SidebarResizer';
