import { Check, Close, EllipsisVertical, Gear } from '@air/next-icons';
import { ActionSheet, ActionSheetHeader, renderActionSheetItems } from '@air/primitive-action-sheet';
import { IconButton } from '@air/primitive-icon-button';
import { useState } from 'react';

export type MobileNotificationHeaderProps = {
  onClose?: () => void;
  onMarkAllAsRead?: () => void;
  onNotificationSettings?: () => void;
};

export const MobileNotificationsHeader = ({
  onClose,
  onMarkAllAsRead,
  onNotificationSettings,
}: MobileNotificationHeaderProps) => {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  return (
    <header className="relative flex h-14 shrink-0 items-center justify-between border-b border-b-grey-5 px-4">
      <div className="text-16 font-semibold text-grey-12">Notifications</div>

      <div className="flex gap-2">
        <IconButton
          appearance="ghost"
          color="grey"
          label="Notification settings"
          icon={EllipsisVertical}
          onClick={() => setIsActionSheetOpen(true)}
        />
        <IconButton appearance="ghost" color="grey" label="Close" icon={Close} onClick={onClose} />
        {isActionSheetOpen && (
          <ActionSheet onClose={() => setIsActionSheetOpen(false)}>
            <ActionSheetHeader onClose={() => setIsActionSheetOpen(false)}>Notification settings</ActionSheetHeader>
            {renderActionSheetItems({
              options: [
                {
                  id: 'notification-settings',
                  label: 'Notification settings',
                  prefix: <Gear className="h-4 w-4" />,
                  onSelect: onNotificationSettings,
                  type: 'item',
                },
                {
                  id: 'mark-all-as-read',
                  label: 'Mark all as read',
                  prefix: <Check className="h-4 w-4" />,
                  onSelect: onMarkAllAsRead,
                  type: 'item',
                },
              ],
            })}
          </ActionSheet>
        )}
      </div>
    </header>
  );
};
