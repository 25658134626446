import { LibraryMemberWithInfo } from '@air/api/types';
import { Avatar } from '@air/primitive-avatar';
import { formatFullName, formatInitials } from '@air/utilities';
import { memo, ReactNode } from 'react';

import { LibraryMemberListItem } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/components/LibraryMemberListItem';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

interface LibraryWorkspaceMemberListItemProps {
  member: LibraryMemberWithInfo;
  renderSuffix?: (member: LibraryMemberWithInfo) => ReactNode;
}

export const LibraryWorkspaceMemberListItem = memo(({ member, renderSuffix }: LibraryWorkspaceMemberListItemProps) => {
  const name = formatFullName(member.firstName, member.lastName);
  const initials = formatInitials(member.firstName, member.lastName);

  return (
    <LibraryMemberListItem
      email={member.email}
      prefix={
        <Avatar
          alt={name}
          appearance="circle"
          colorSeed={member.accountId}
          size={40}
          src={getAvatarImageSrc(member.avatar, 40)}
          text={initials}
        />
      }
      name={name}
      suffix={renderSuffix?.(member)}
    />
  );
});

LibraryWorkspaceMemberListItem.displayName = 'LibraryWorkspaceMemberListItem';
