import type { Library } from '@air/api/types';

import type { LibraryPrivacySettingsValue } from '~/components/LibraryBeta/LibraryPrivacySettings/LibraryPrivacySettings';

export const getLibraryPrivacyValue = (
  value: LibraryPrivacySettingsValue,
): Required<Pick<Library, 'accessibility' | 'visibility'>> => {
  switch (value) {
    case 'invite-only':
      return {
        accessibility: 'private',
        visibility: 'libraryMembers',
      };
    case 'public-to-workspace':
      return {
        accessibility: 'public',
        visibility: 'workspaceMembers',
      };
    case 'request-to-join':
      return {
        accessibility: 'private',
        visibility: 'workspaceMembers',
      };
    default:
      return {
        accessibility: 'private',
        visibility: 'workspaceMembers',
      };
  }
};
