import { useRouter } from 'next/router';
import { useMemo } from 'react';

export type UseIsActiveRouteParams = {
  route: string;
};

export const useIsActiveRoute = ({ route }: UseIsActiveRouteParams) => {
  const router = useRouter();
  const isActiveRoute = useMemo(() => router.pathname.startsWith(route), [route, router.pathname]);

  return { isActiveRoute };
};
