import { formatBytes } from '@air/utilities';
import pluralize from 'pluralize';
import { memo } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useShouldShowStorageUsage } from '~/swr-hooks/subscriptions/useShouldShowStorageUsage';
import { useStorage } from '~/swr-hooks/subscriptions/useStorage';
import { useWorkspaceTotals } from '~/swr-hooks/workspaces/useWorkspaceTotals';

const AVATAR_SIZE = 40;

export const CurrentWorkspaceHeaderInfo = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { shouldShowStorageUsage } = useShouldShowStorageUsage();
  const { data: totals } = useWorkspaceTotals();
  const { data: storage } = useStorage();

  if (!totals || !currentWorkspace || !storage) {
    return null;
  }

  const formattedBytes = shouldShowStorageUsage ? formatBytes(storage.usedStorage) : null;

  return (
    <div className="flex items-center">
      <img
        alt=""
        className="mr-3 overflow-hidden rounded bg-pigeon-25 object-cover"
        src={`${currentWorkspace?.image}?h=${AVATAR_SIZE * 3}&w=${AVATAR_SIZE * 3}=&fit=crop`}
        style={{
          width: AVATAR_SIZE,
          height: AVATAR_SIZE,
        }}
      />
      <div className="flex shrink flex-col gap-0.5">
        <div className="truncate text-14 font-semibold text-grey-12">{currentWorkspace.name}</div>
        <div className="text-12 text-grey-10">
          {`${pluralize('member', totals.memberCount, true)}`} {!!formattedBytes && `\u00B7 ${formattedBytes}`}{' '}
        </div>
      </div>
    </div>
  );
});

CurrentWorkspaceHeaderInfo.displayName = 'CurrentWorkspaceHeaderInfo';
