import { LibraryWithPermissions } from '@air/api/types';
import { Skeleton } from '@air/component-skeleton';
import { Button } from '@air/primitive-button';
import classNames from 'classnames';
import { isUndefined } from 'lodash';
import { forwardRef, useCallback } from 'react';

import { useLibraryJoinMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryJoinMutation';
import { useLibraryRequestToJoinMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryRequestToJoinMutation';
import { LibraryItem, LibraryItemProps } from '~/components/LibraryBeta/LibraryBrowseModal/components/LibraryItem';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useAccount } from '~/swr-hooks/account/useAccount';
import { convertUnknownToError } from '~/utils/ErrorUtils';

export type LibraryItemNonMemberContainerProps = Pick<LibraryItemProps, 'className'> & {
  library: LibraryWithPermissions;
  stats: null | string[];
};

export const LibraryItemNonMemberContainer = forwardRef<HTMLDivElement, LibraryItemNonMemberContainerProps>(
  ({ className, library, stats }, ref) => {
    const { showLibraryToast } = useLibraryToasts();
    const { data: account } = useAccount();
    const { libraryJoinMutation } = useLibraryJoinMutation({ libraryId: library.id });
    const { libraryRequestToJoinMutation } = useLibraryRequestToJoinMutation({ libraryId: library.id });

    const onLibraryJoin = useCallback(() => {
      if (!isUndefined(account)) {
        libraryJoinMutation.mutate(
          [
            {
              accountId: account.id,
            },
          ],
          {
            onError: (error) => showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
            onSuccess: () =>
              showLibraryToast({
                message: (
                  <>
                    {library.accessibility === 'public' ? 'Joined' : 'Requested to join'}{' '}
                    <b className="text-jay-200">{library.title}</b> library.
                  </>
                ),
              }),
          },
        );
      }
    }, [account, library, libraryJoinMutation, showLibraryToast]);

    const onLibraryRequestToJoin = useCallback(() => {
      if (!isUndefined(account)) {
        libraryRequestToJoinMutation.mutate(
          [
            {
              accountId: account.id,
            },
          ],
          {
            onError: (error) => showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
            onSuccess: () =>
              showLibraryToast({
                message: (
                  <>
                    {library.accessibility === 'public' ? 'Joined' : 'Requested to join'}{' '}
                    <b className="text-jay-200">{library.title}</b> library.
                  </>
                ),
              }),
          },
        );
      }
    }, [account, library, libraryRequestToJoinMutation, showLibraryToast]);

    return (
      <LibraryItem
        actions={
          <Button
            appearance="filled"
            color="grey"
            isLoading={libraryJoinMutation.isPending || libraryRequestToJoinMutation.isPending}
            data-testid="LIBRARY_ITEM_JOIN_BUTTON"
            onClick={library.accessibility === 'public' ? onLibraryJoin : onLibraryRequestToJoin}
            size="small"
          >
            {library.accessibility === 'public' ? 'Join' : 'Request to join'}
          </Button>
        }
        className={classNames('border-b border-b-pigeon-100', className)}
        description={library.description}
        key={library.id}
        meta={stats || <Skeleton height={26} isText width={120} />}
        ref={ref}
        title={library.title}
      />
    );
  },
);

LibraryItemNonMemberContainer.displayName = 'LibraryItemContainer';
