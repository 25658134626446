import { Library, LibraryMemberWithInfo } from '@air/api/types';
import pluralize from 'pluralize';

import {
  LibraryRoleDropdownMenu,
  LibraryRoleDropdownMenuProps,
} from '~/components/LibraryBeta/LibraryRoleDropdownMenu';
import { LibraryWorkspaceMemberListItem } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/components/LibraryWorkspaceMemberListItem';
import { LibraryMemberList } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/LibraryMemberList';
import { LibraryMemberListSkeleton } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/LibraryMemberListSkeleton';

export type LibraryActiveMemberListProps = Pick<LibraryRoleDropdownMenuProps, 'onLeave'> & {
  libraryId: Library['id'];
  members: LibraryMemberWithInfo[] | undefined;
};

export const LibraryActiveMemberList = ({ libraryId, members, onLeave }: LibraryActiveMemberListProps) => {
  if (!members) {
    return <LibraryMemberListSkeleton />;
  }

  const numberOfActiveMembers = members.length;

  return (
    <LibraryMemberList
      label={`${pluralize('member', numberOfActiveMembers, false)} (${numberOfActiveMembers})`}
      members={members?.map((member) => (
        <LibraryWorkspaceMemberListItem
          key={member.id}
          member={member}
          renderSuffix={(member) => <LibraryRoleDropdownMenu libraryId={libraryId} member={member} onLeave={onLeave} />}
        />
      ))}
    />
  );
};
