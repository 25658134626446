import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentPropsWithoutRef } from 'react';

export type FlagInHandAssetProps = Pick<ComponentPropsWithoutRef<'svg'>, 'className'>;

export const FlagInHandAsset = ({ className }: FlagInHandAssetProps) => {
  return (
    <svg
      className={tailwindMerge('h-[120px] w-[120px]', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      fill="none"
    >
      <path
        stroke="var(--colors-grey9)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
        d="M51.247 54.269c-3.249-16.11-6.58-32.927-8.08-41.484"
      />
      <path
        stroke="var(--colors-grey8)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
        d="M42.682 12.136c42.412-12.463 62.932.012 62.932.012s-12.286 23.391-60.51 32.95"
      />
      <path
        stroke="var(--colors-grey9)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
        d="m72.441 99.246 25.837-7.093a2.362 2.362 0 0 0 1.56-3.163 3.07 3.07 0 0 0-3.143-1.9l-21.17 2.124 22.162-12.757a1.698 1.698 0 0 0 .402-2.62l-.06-.06a6.75 6.75 0 0 0-7.513-1.699c-8.896 3.611-19.67 4.72-19.67 4.72s-.059-4.897-.638-7.186c-.65-2.609-2.445-5.146-5.706-7.931l-10.171-6.822c-1.3-.873-3.072-.212-3.474 1.298a6.605 6.605 0 0 0 2.446 7.058l2.398 1.782c3.024 2.254 6.781 7.046 4.844 10.28l-4.88 5.44a3.662 3.662 0 0 1-5.198.484 3.665 3.665 0 0 1-.98-4.284l2.233-4.567c.768-1.558 1.193-3.27 1.146-5.004-.012-.284-.118-.65-.307-1.05-1.395-2.987-5.423-3.6-7.762-1.275l-.083.082s-8.648 8.651-12.912 19.934"
      />
      <path
        stroke="var(--colors-grey9)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
        d="M32.144 81.531h-3.166c-7.277.225-12.818 6.574-11.873 13.785l.768 9.276c.827 6.232 5.446 11.177 17.72 10.304 13.764-.968 21.986-2.337 35.182-9.1 5.34-2.738 16.67-8.285 16.67-8.285-.024-1.085.059-1.44-.981-2.124M69.169 92.543l10.278-5.583M67.35 85.875l3.496-9.088"
      />
      <path
        stroke="var(--colors-grey9)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
        d="M59.316 93.805s-3.402-13.95-6.829-30.862M43.166 12.785c-.968-5.464-3.709-5.17-4.772-3.6-.745 1.098-.06 4.261 3.036 3.329.425-.13.839-.248 1.252-.378"
      />
      <path
        stroke="var(--colors-grey9)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
        d="M34.117 29.532 22.634 32.86"
      />
      <path
        stroke="var(--colors-grey4)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
        d="m32.463 42.892 4.076-7.152M28.73 18.214l5.73 5.936"
      />
      <path
        stroke="var(--colors-grey8)"
        stroke-miterlimit="10"
        stroke-width="3"
        d="M23.32 72.043a5.821 5.821 0 0 0 5.823-5.819 5.821 5.821 0 0 0-5.824-5.818 5.821 5.821 0 0 0-5.824 5.818 5.821 5.821 0 0 0 5.824 5.819Z"
      />
      <path
        fill="var(--colors-grey6)"
        d="M86.5 45.417c1.853 0 3.355-1.5 3.355-3.351a3.353 3.353 0 0 0-3.356-3.352 3.353 3.353 0 0 0-3.355 3.352 3.353 3.353 0 0 0 3.355 3.351Z"
      />
      <path
        stroke="var(--colors-grey9)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
        d="M48.046 38.277c-2.068-10.397-3.875-19.78-4.891-25.492"
      />
      <path fill="var(--colors-grey9)" d="m26.556 82.334 7.077-1.853-1.654 5.358-5.423-3.505Z" />
      <path fill="var(--colors-grey4)" d="M9.706 113.413a2.707 2.707 0 1 0 0-5.413 2.707 2.707 0 0 0 0 5.413Z" />
      <path
        stroke="var(--colors-grey6)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3"
        d="M101.926 98c.861 7.955-4.926 15.09-12.926 15.947"
      />
      <path
        stroke="var(--colors-grey8)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="3.529"
        d="m55.047 24.192 4.312 7.857s10.353-16.481 26.747-18.637"
      />
    </svg>
  );
};
