import { ConfirmationModal } from '@air/component-confirmation-modal';
import { InputFile } from '@air/component-upload-dropzone';
import pluralize from 'pluralize';

import { UPLOAD_VERSIONS_MODAL } from '~/constants/testIDs';

export interface UploadVersionsModalProps extends AirModalProps {
  onUpload: (...anything: any) => void;
  onCancel: (...anything: any) => void;
  files?: InputFile[];
}

export const UploadVersionsModal = ({ onUpload, onCancel, files }: UploadVersionsModalProps) => {
  return (
    <ConfirmationModal
      id="upload-versions-modal"
      isOpen
      data-testid={UPLOAD_VERSIONS_MODAL}
      onDismiss={onCancel}
      title={`Upload ${pluralize('version', (files && files.length) || 0, true)}?`}
      description={`You are about to upload ${pluralize('version', (files && files.length) || 0, true)} to this asset.`}
      ctaPrimaryText="Upload"
      onConfirm={onUpload}
    />
  );
};
