import { rejectUploadsAction, Upload } from '@air/redux-uploader';
import { extname } from 'path';

import { containsRestrictedPathChars } from '~/utils/FileUtils';

type InputFile = Upload['file'];
export const getSortedFilesAndMetadata = (
  acceptedFiles: InputFile[],
): {
  emptyFiles: InputFile[];
  filesWithNoExtension: InputFile[];
  filesWithRestrictedChars: InputFile[];
  files: InputFile[];
  totalSize: number;
} => {
  const emptyFiles: any[] = [];
  const filesWithNoExtension: any[] = [];
  const filesWithRestrictedChars: any[] = [];
  const files: any[] = [];
  let totalSize = 0;

  acceptedFiles.forEach((file) => {
    if (!file.size) {
      emptyFiles.push(file);
    } else if (!extname(file.name)) {
      filesWithNoExtension.push(file);
    } else if (containsRestrictedPathChars(file.name)) {
      filesWithRestrictedChars.push(file);
      totalSize += file.size;
    } else {
      files.push(file);
      totalSize += file.size;
    }
  });

  return {
    emptyFiles,
    files,
    totalSize,
    filesWithNoExtension,
    filesWithRestrictedChars,
  };
};

export const mapWithReason = (reason: 'unsupported' | 'empty' | 'noExtension') => (files: InputFile[]) =>
  files.map(({ name }) => ({ name, reason }));

export const handleRejectedFiles = ({
  rejectedFiles,
  emptyFiles,
  filesWithNoExtension,
}: {
  rejectedFiles: InputFile[];
  emptyFiles: InputFile[];
  filesWithNoExtension: InputFile[];
}) => {
  return rejectUploadsAction({
    files: [
      ...mapWithReason('unsupported')(rejectedFiles),
      ...mapWithReason('empty')(emptyFiles),
      ...mapWithReason('noExtension')(filesWithNoExtension),
    ],
  });
};
