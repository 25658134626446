import { useTrackCreatedLibrary } from '@air/analytics';
import { CreateLibraryInput, Libraries } from '@air/api';
import { useMutation } from '@tanstack/react-query';

import { isLibariesKey } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { queryClient } from '~/swr-hooks/queryClient';
import { getWorkspaceRolesKey } from '~/swr-hooks/workspaces/useWorkspaceRoles';

export const useLibrariesCreateMutation = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackCreatedLibrary } = useTrackCreatedLibrary();

  const librariesCreateMutation = useMutation({
    mutationFn: (variables: CreateLibraryInput) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.create({ workspaceId, library: variables });
    },

    onSuccess: (_data, variables) => {
      trackCreatedLibrary({
        library_accessibility: variables.accessibility,
        library_name: variables.title,
        library_visibility: variables.visibility,
      });

      /**
       * Library permissions aren't available until the user has a library in
       * their workspace. This is a temporary fix to refetch the workspace
       * roles after a library is present.
       * @see https://air-labs-team.slack.com/archives/C04G14SRBFG/p1681924232591519
       */
      queryClient.invalidateQueries({ queryKey: getWorkspaceRolesKey(currentWorkspace?.id) });

      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return isLibariesKey(queryKey) && queryKey[1].workspaceId === currentWorkspace?.id;
        },
      });
    },
  });

  return { librariesCreateMutation };
};
