import { Library, LibraryMemberWithInfo } from '@air/api/types';
import { Check, Close } from '@air/next-icons';
import { ButtonGroup } from '@air/primitive-button-group';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { formatFullName } from '@air/utilities';

import { useLibraryApproveMemberMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryMemberApproveMutation';
import { useLibraryMembersDenyMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryMemberDenyMutation';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { convertUnknownToError } from '~/utils/ErrorUtils';

export type LibraryPendingActionsProps = {
  libraryId: Library['id'];
  member: LibraryMemberWithInfo;
};

export const LibraryPendingActions = ({ libraryId, member }: LibraryPendingActionsProps) => {
  const { showLibraryToast } = useLibraryToasts();
  const { libraryMembersDenyMutation } = useLibraryMembersDenyMutation({ libraryId });
  const { libraryMemberApproveMutation } = useLibraryApproveMemberMutation({ libraryId });

  return (
    <ButtonGroup>
      <Tooltip label="Deny join request">
        <IconButton
          appearance="filled"
          color="grey"
          icon={Close}
          label="Deny"
          isLoading={libraryMembersDenyMutation.isPending}
          onClick={() => {
            const fullName = formatFullName(member.firstName, member.lastName);

            libraryMembersDenyMutation.mutate([{ accountId: member.accountId }], {
              onError: (error) => showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
              onSuccess: () =>
                showLibraryToast({
                  message: (
                    <>
                      Denied <b className="text-jay-200">{fullName}</b> join request.
                    </>
                  ),
                }),
            });
          }}
        />
      </Tooltip>
      <Tooltip label="Approve join request">
        <IconButton
          appearance="filled"
          color="grey"
          icon={Check}
          label="Approve"
          isLoading={libraryMemberApproveMutation.isPending}
          onClick={() => {
            const fullName = formatFullName(member.firstName, member.lastName);

            libraryMemberApproveMutation.mutate(
              { accountId: member.accountId },
              {
                onError: (error) => showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
                onSuccess: () =>
                  showLibraryToast({
                    message: (
                      <>
                        Approved <b className="text-jay-200">{fullName}</b> join request.
                      </>
                    ),
                  }),
              },
            );
          }}
        />
      </Tooltip>
    </ButtonGroup>
  );
};
