import type { Library } from '@air/api/types';

import type { LibraryPrivacySettingsValue } from '~/components/LibraryBeta/LibraryPrivacySettings/LibraryPrivacySettings';

export const getLibraryPrivacyInitialValue = (library?: Library): LibraryPrivacySettingsValue => {
  if (library?.accessibility === 'public' && library.visibility === 'workspaceMembers') {
    return 'public-to-workspace';
  }

  if (library?.accessibility === 'private' && library.visibility === 'libraryMembers') {
    return 'invite-only';
  }

  return 'request-to-join';
};
