import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { ComponentProps, forwardRef } from 'react';

export const workspaceNavButton = tailwindVariants({
  base: 'flex h-8 w-8 shrink-0 cursor-pointer items-center justify-center rounded-md border-none bg-grey-1',
  variants: {
    state: {
      active: 'ring-2 ring-macaw-200',
      default: 'ring-0 ring-transparent',
    },
  },
  defaultVariants: {
    state: 'default',
  },
});

type WorkspaceNavButtonProps = ComponentProps<'button'> & VariantProps<typeof workspaceNavButton>;
export const WorkspaceNavButton = forwardRef<HTMLButtonElement, WorkspaceNavButtonProps>(
  ({ children, className, state, ...restOfProps }: WorkspaceNavButtonProps, forwardedRef) => {
    return (
      <button className={workspaceNavButton({ state, className })} {...restOfProps} ref={forwardedRef}>
        {children}
      </button>
    );
  },
);
WorkspaceNavButton.displayName = 'WorkspaceNavButton';
