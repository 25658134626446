import { Close } from '@air/next-icons';
import { memo } from 'react';

import { CompletedAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { BoardRestorationTask, GetCompletedMetadata } from '~/store/tasks/types';

export type RestoreBoardCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<BoardRestorationTask>;
  onClear: () => void;
};

export const RestoreBoardCompletedPanelItem = memo(
  ({ metadata: { title }, onClear }: RestoreBoardCompletedPanelItemProps) => {
    return (
      <PaneItemTW
        truncate={false}
        avatar={<CompletedAvatar />}
        title={<StatusTrackingTitle>Restored successfully</StatusTrackingTitle>}
        subtitle={
          <StatusTrackingSubtitle>
            Restored board <strong>{title}</strong>
          </StatusTrackingSubtitle>
        }
        buttons={<PaneButton label="Clear" Icon={Close} onClick={onClear} />}
      />
    );
  },
);

RestoreBoardCompletedPanelItem.displayName = 'RestoreBoardCompletedPanelItem';
