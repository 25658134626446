import { Task } from '@air/classes-task-scheduler';
import { largeUploadByIdSelector } from '@air/redux-uploader';
import { getLargeFileChunks, useCanLargeChunkBeUploaded, useLargeUploadCanBeCompleted } from '@air/upload-utils';
import { useCallback } from 'react';

import { PrivateUploadTaskParams } from '~/components/Upload/hooks/types';
import { useCompleteLargePrivateUpload } from '~/components/Upload/privateUpload/useCompleteLargePrivateUpload';
import { useCreateLargePrivateUpload } from '~/components/Upload/privateUpload/useCreateLargePrivateUpload';
import { useIsBlockedByParentBoard } from '~/components/Upload/privateUpload/useIsBlockedByParentBoard';
import { useUploadLargePrivateFileWithRetries } from '~/components/Upload/privateUpload/useUploadLargePrivateFileWithRetries';
import { useAirStore } from '~/utils/ReduxUtils';

export const useGetLargePrivateUploadTasks = () => {
  const store = useAirStore();

  const { isBlockedByParentBoard } = useIsBlockedByParentBoard();
  const { createLargePrivateUpload } = useCreateLargePrivateUpload();
  const { largeUploadCanBeCompleted } = useLargeUploadCanBeCompleted();
  const { completeLargePrivateUpload } = useCompleteLargePrivateUpload();
  const { canLargeChunkBeUploaded } = useCanLargeChunkBeUploaded();
  const { uploadLargePrivateFileWithRetries } = useUploadLargePrivateFileWithRetries();

  const getStartLargeUploadTask = useCallback(
    ({ uploadId, onUploadStarted, onBoardCreated, onError }: PrivateUploadTaskParams): Task => ({
      id: `${uploadId}-start`,
      getIsBlocked: () => isBlockedByParentBoard(uploadId),
      run: () => createLargePrivateUpload({ uploadId, onUploadStarted, onBoardCreated, onError }),
    }),
    [createLargePrivateUpload, isBlockedByParentBoard],
  );

  const getCompleteLargeUploadTask = useCallback(
    ({ uploadId, onUploadFinished, onError }: PrivateUploadTaskParams): Task => {
      return {
        id: `${uploadId}-complete`,
        getIsBlocked: () => !largeUploadCanBeCompleted(uploadId),
        run: () => completeLargePrivateUpload({ uploadId, onUploadFinished, onError }),
      };
    },
    [completeLargePrivateUpload, largeUploadCanBeCompleted],
  );

  const getChunkUploadTasks = useCallback(
    (params: PrivateUploadTaskParams): Task[] => {
      const getIsBlocked = () => !canLargeChunkBeUploaded(params.uploadId);

      const upload = largeUploadByIdSelector(store.getState(), params.uploadId);
      if (upload) {
        const fileChunks = getLargeFileChunks(upload);

        return fileChunks.map(
          (chunk, index): Task => ({
            id: `${params.uploadId}#${index}`,
            getIsBlocked,
            run: () => uploadLargePrivateFileWithRetries({ uploadId: params.uploadId, chunk, partNumber: index + 1 }),
          }),
        );
      }
      return [];
    },
    [canLargeChunkBeUploaded, store, uploadLargePrivateFileWithRetries],
  );

  const getLargeUploadTasks = useCallback(
    (params: PrivateUploadTaskParams): Task[] => {
      return [getStartLargeUploadTask(params), ...getChunkUploadTasks(params), getCompleteLargeUploadTask(params)];
    },
    [getChunkUploadTasks, getCompleteLargeUploadTask, getStartLargeUploadTask],
  );

  return {
    getLargeUploadTasks,
  };
};
