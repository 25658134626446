import type { SearchBoardsResponse, SearchSavedFiltersResponse } from '@air/api/types';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { LibraryIcon } from '~/_components/LibraryIcon';
import { MobileSearch, MobileSearchProps } from '~/_components/MobileSearch';
import { MobileSearchToken } from '~/_components/MobileSearchToken';
import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { useGetSavedFilter } from '~/components/SavedFilter/shared/hooks/useGetSavedFilter';
import { createSavedFilterRoute, isBoardRoute, isLibraryRoute, isSavedFilterRoute } from '~/constants/routes';
import { useSearchParams } from '~/hooks/search/useSearchParams';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { usePrivateSearchSuggestions } from '~/swr-hooks/search/usePrivateSearchSuggestions';

export type PrivateMobileSearchProps = Pick<MobileSearchProps, 'onClose'>;

export const PrivateMobileSearch = ({ onClose }: PrivateMobileSearchProps) => {
  const { pathname, push, query } = useRouter();
  const savedFilterId = typeof query.savedFilterId === 'string' ? query.savedFilterId : undefined;
  const libraryId = typeof query.libraryId === 'string' ? query.libraryId : undefined;

  const [searchTerm, setSearchTerm] = useState('');
  const [isBoardTokenRemoved, setIsBoardTokenRemoved] = useState(false);
  const [isLibraryTokenRemoved, setIsLibraryTokenRemoved] = useState(false);
  const { data: savedFilter } = useGetSavedFilter(savedFilterId);
  const { search, setSearch, clearSearch } = useSearchParams();
  const { currentWorkspace } = useCurrentWorkspace();
  const board = useSelector(centralizedBoardSelector);
  const { data: library } = useLibrary({ libraryId });
  const { goToBoardPage } = useGoToBoardPage();

  const {
    data: suggestions,
    isLoading,
    refetch,
  } = usePrivateSearchSuggestions({
    search: searchTerm,
    canFetch: true,
    libraryId: isLibraryTokenRemoved ? undefined : libraryId,
    boardId: isBoardTokenRemoved ? undefined : board?.id,
  });

  const meta = useMemo(() => {
    if (library && isLibraryRoute(pathname) && !isLibraryTokenRemoved) {
      return (
        <div className="flex gap-1 pl-1">
          <MobileSearchToken
            onRemove={() => setIsLibraryTokenRemoved(true)}
            prefix={
              <LibraryIcon background={library.color.backgroundHex} color={library.color.fontHex}>
                {library.icon}
              </LibraryIcon>
            }
          >
            {library.title}
          </MobileSearchToken>
        </div>
      );
    }

    if (board && isBoardRoute(pathname) && (!isLibraryTokenRemoved || !isBoardTokenRemoved)) {
      return (
        <div className="flex gap-1 pl-1">
          {board.library && !isLibraryTokenRemoved && (
            <MobileSearchToken
              onRemove={isBoardTokenRemoved ? () => setIsLibraryTokenRemoved(true) : undefined}
              prefix={
                <LibraryIcon background={board.library.color.backgroundHex} color={board.library.color.fontHex}>
                  {board.library.icon}
                </LibraryIcon>
              }
            >
              {isBoardTokenRemoved && board.library.title}
            </MobileSearchToken>
          )}
          {!isBoardTokenRemoved && (
            <MobileSearchToken onRemove={() => setIsBoardTokenRemoved(true)}>{board.title}</MobileSearchToken>
          )}
        </div>
      );
    }

    if (savedFilter && isSavedFilterRoute(pathname)) {
      return savedFilter.name;
    }

    return currentWorkspace?.name;
  }, [board, currentWorkspace?.name, isBoardTokenRemoved, isLibraryTokenRemoved, library, pathname, savedFilter]);

  const onBoardClick = useCallback(
    (board: SearchBoardsResponse) => {
      goToBoardPage({ board, trackLocation: 'search' });
    },
    [goToBoardPage],
  );

  const onSavedFilterClick = useCallback(
    (savedFilter: SearchSavedFiltersResponse) => {
      push(createSavedFilterRoute(savedFilter.id));
    },
    [push],
  );

  const onSuggestionClick = useCallback(
    (suggestion: string) => {
      setSearch(suggestion);
      refetch();
    },
    [refetch, setSearch],
  );

  return (
    <MobileSearch
      defaultValue={searchTerm || search}
      isLoading={isLoading}
      onBoardClick={onBoardClick}
      onClear={clearSearch}
      onClose={onClose}
      onSavedFilterClick={onSavedFilterClick}
      onSubmit={setSearch}
      onSuggestionClick={onSuggestionClick}
      onValueChange={setSearchTerm}
      meta={meta}
      suggestions={suggestions}
    />
  );
};
