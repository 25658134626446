import { Close } from '@air/next-icons';
import { memo } from 'react';

import { CompletedAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { BoardDeletionTask, GetCompletedMetadata } from '~/store/tasks/types';

export type DeleteBoardCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<BoardDeletionTask>;
  onClear: () => void;
};

export const DeleteBoardCompletedPanelItem = memo(
  ({ metadata: { boardIds }, onClear }: DeleteBoardCompletedPanelItemProps) => {
    return (
      <PaneItemTW
        truncate={false}
        avatar={<CompletedAvatar />}
        title={<StatusTrackingTitle>Deleted successfully</StatusTrackingTitle>}
        subtitle={
          <StatusTrackingSubtitle>
            {`${boardIds.length} board${boardIds.length > 1 ? 's' : ''} deleted`}
          </StatusTrackingSubtitle>
        }
        buttons={<PaneButton label="Clear" Icon={Close} onClick={onClear} />}
      />
    );
  },
);

DeleteBoardCompletedPanelItem.displayName = 'DeleteBoardCompletedPanelItem';
