import { AssetSearchOptions, SortDirection } from '@air/api/types';

/**
 * @description This is the criteria to spread when creating a new Saved Filter. This should be generic and desired for all instances of a Saved Filter
 */
export const DEFAULT_CRITERIA: Pick<
  AssetSearchOptions,
  'limit' | 'search' | 'cursor' | 'sortField' | 'bookmarked'
> = {
  limit: 10,
  search: '',
  cursor: null,
  sortField: { direction: SortDirection.desc, name: 'dateModified' },
  bookmarked: false,
};
