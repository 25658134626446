import { SubscriptionPlan } from '@air/api/types';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

type CannyMethod = 'identify';

/**
 * Read more about the custom fields the product team came up with here @see https://www.notion.so/airinc/Canny-Setup-2521650bbc0440ed97e9f029bd8ba715
 */
export interface IdentifyParams {
  appID: string;
  user: {
    avatarURL?: string; // optional
    companies: {
      created?: string; // optional
      id: string;
      monthlySpend?: number; // optional
      name: string;
      customFields?: {
        plan?: SubscriptionPlan;
        ['Total Workspace Members']?: number;
        ['Total Workspace Storage']?: number | null;
      };
    }[];
    created?: string;
    email: string;
    id: string;
    name: string;
  };
}

type Canny = (method: CannyMethod, params: IdentifyParams) => void;

/**
 * Canny install instructions aren't for a SPA so created this class. Took inspriation from @see https://github.com/kearisp/react-canny
 */
class CannyLoader {
  get Canny() {
    return (window as any).Canny as Canny;
  }

  async load() {
    if (this.Canny) {
      return this.Canny;
    }

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://canny.io/sdk.js';

    return new Promise<Canny>((resolve, reject) => {
      script.onload = () => {
        resolve((window as any).Canny);
      };

      script.onerror = (err) => {
        reject(err);
      };

      document.head.append(script);
    });
  }
}

interface CannyContextInterface {
  canny?: {
    identify: (params: Omit<IdentifyParams, 'appID'>) => void;
  };
  getCannyUrl: (params: Omit<IdentifyParams, 'appID'>) => string;
}

const defaultValue = { canny: undefined, getCannyUrl: () => '' };

const CannyContext = createContext<CannyContextInterface>(defaultValue);

export const CannyProvider = ({ children }: PropsWithChildren<object>) => {
  const [canny, setCanny] = useState<undefined | Canny>();

  useEffect(() => {
    const loader = new CannyLoader();

    loader.load().then((_canny) => {
      setCanny(() => _canny);
    });
  }, []);

  /**
   * Canny updated their SDK recently that requires a `clientToken` to be passed over to their URL.
   * This is usually handled by default with the usage of their script, however, it only gets applied
   * automatically in cases where we use `<a />`. In our case, we use a button to open the Canny
   * so we'll need to generate the URL ourselves.
   */
  const getCannyUrl = useCallback((params: Omit<IdentifyParams, 'appID'>) => {
    try {
      const objectToDecode = JSON.stringify({
        appID: '6329c7f4cb27d744c5d02bb9',
        ...params,
      });

      /**
       * This is taken from Canny SDK source code.
       * It prepares a string to be correctly encoded by btoa to aboid problems with non-latin  characters
       */
      const paramsToEncode = encodeURIComponent(objectToDecode).replace(/%([0-9A-F]{2})/g, (_e, t) =>
        String.fromCharCode(Number('0x' + t)),
      );
      const token = btoa(paramsToEncode);

      return `https://airlabs.canny.io/?clientToken=${token}`;
    } catch (_error) {
      return 'https://airlabs.canny.io';
    }
  }, []);

  const value = useMemo(
    (): CannyContextInterface => ({
      canny: canny
        ? {
            identify: (params) => {
              canny('identify', {
                appID: '6329c7f4cb27d744c5d02bb9',
                ...params,
              });
            },
          }
        : undefined,
      getCannyUrl,
    }),
    [canny, getCannyUrl],
  );

  return <CannyContext.Provider value={value}>{children}</CannyContext.Provider>;
};

export function useCanny() {
  const canny = useContext(CannyContext);

  if (canny === defaultValue) {
    const error = 'CannyProviderContext used outside of CannyProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return canny;
}
