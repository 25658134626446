import { Skeleton } from '@air/component-skeleton';

const getLine = (percentage: string | number, index: number) => (
  <Skeleton height={14} key={index} className="mb-6 bg-grey-5" width={`${percentage}%`} />
);

interface getBoardsNavLoadingSkeletonProps {
  sectionLinesData: number[];
}

export const BoardsNavLoadingSkeleton = ({ sectionLinesData }: getBoardsNavLoadingSkeletonProps) => {
  return (
    <div className="my-2 flex w-full flex-col">{sectionLinesData.map((width, index) => getLine(width, index))}</div>
  );
};
