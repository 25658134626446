import { useCurrentUserInfo } from '@air/utils-auth';

export const useIsCurrentUserEmailVerified = () => {
  const { data: userInfo, isFetched } = useCurrentUserInfo();

  return {
    userEmailIsVerified: userInfo?.attributes.email_verified,
    isFetched,
  };
};
