import { VisuallyHidden } from '@air/primitive-visually-hidden';
import { tailwindMerge, tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import { type ComponentProps, type ReactNode } from 'react';

export const notification = tailwindVariants({
  base: 'relative flex items-start px-4',
  variants: {
    state: {
      read: 'bg-grey-1 hover:bg-grey-3',
      unread: 'bg-grey-2 hover:bg-grey-3',
    },
  },
});

export type NotificationVariants = VariantProps<typeof notification>;

export type NotificationsItemProps = Omit<ComponentProps<'div'>, 'prefix' | 'title'> &
  Pick<ComponentProps<'button'>, 'onClick'> &
  NotificationVariants & {
    content?: ReactNode;
    createdAt: string;
    meta?: ReactNode;
    prefix?: ReactNode;
    suffix?: ReactNode;
    title?: ReactNode;
  };

export const NotificationsItem = ({
  className,
  content,
  createdAt,
  onClick,
  prefix,
  suffix,
  meta,
  state = 'unread',
  title,
  ...restOfProps
}: NotificationsItemProps) => {
  return (
    <div
      className={notification({ state, className })}
      data-state={state}
      data-testid="NOTIFICATIONS_ITEM"
      {...restOfProps}
    >
      <div className="flex grow items-start gap-4 border-b border-grey-4 py-4">
        <div className="flex shrink-0 items-center justify-end gap-2" data-testid="NOTIFICATIONS_ITEM_PREFIX">
          <div
            className={tailwindMerge('h-2 w-2 rounded-full', state === 'unread' ? 'bg-red-8' : 'bg-transparent')}
            data-state={state}
            data-testid="NOTIFICATIONS_ITEM_DOT"
          />
          {prefix}
        </div>

        <div className="flex grow flex-col gap-2">
          {!!title && <div className="text-14 text-grey-12 [&>b]:break-words [&>b]:font-medium">{title}</div>}
          {!!content && <div className="line-clamp-2 text-14 text-grey-9">{content}</div>}
          {(!!meta || !!createdAt) && (
            <div className="flex items-center gap-1 text-12 text-grey-8">
              {meta}
              {!!meta ? ` · ${createdAt}` : createdAt}
            </div>
          )}
        </div>

        {!!suffix && (
          <div className="flex shrink-0 items-start justify-end gap-2" data-testid="NOTIFICATIONS_ITEM_SUFFIX">
            {suffix}
          </div>
        )}

        <button
          className={tailwindMerge(
            'absolute inset-0 h-full w-full border-0 bg-transparent',
            !!onClick ? 'cursor-pointer' : 'cursor-default',
          )}
          data-testid="NOTIFICATIONS_ITEM_BUTTON"
          onClick={onClick}
        >
          <VisuallyHidden>Select notification</VisuallyHidden>
        </button>
      </div>
    </div>
  );
};
