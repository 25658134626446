import { CustomFieldColor } from '@air/api/types';
import { Check } from '@air/next-icons';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';

import { CustomFieldModalColorMenuTrigger } from '~/components/CustomFields/CustomFieldModal/CustomFieldModalForm/CustomFieldModalColorMenuTrigger';
import { CUSTOM_FIELD_MODAL_OPTION_COLOR_OPTION } from '~/constants/testIDs';

interface CustomFieldModalColorMenuProps {
  selectedColor: string;
  fontHex: string;
  handleColorClick: (backgroundColor: string, index: number) => void;
  index: number;
  colors: Array<CustomFieldColor>;
}

const CustomFieldModalColorMenu = ({
  selectedColor,
  index,
  handleColorClick,
  colors,
  fontHex,
}: CustomFieldModalColorMenuProps) => {
  const menuOptions: DropdownMenuOption[] = colors.map((color) => {
    return {
      id: color.id,
      label: color.name,
      prefix: (
        <div
          className="mr-2 h-6 w-6 rounded"
          style={{ backgroundColor: color.backgroundHex }}
          data-testid={`${CUSTOM_FIELD_MODAL_OPTION_COLOR_OPTION}-${color.name}`}
        />
      ),
      onSelect: () => handleColorClick(color.backgroundHex, index),
      suffix:
        color.backgroundHex?.toLowerCase() === selectedColor?.toLowerCase() ? <Check className="h-4 w-4" /> : null,
      type: 'item',
    };
  });

  return (
    <DropdownMenu
      size="small"
      trigger={<CustomFieldModalColorMenuTrigger selectedColor={selectedColor} fontHex={fontHex} />}
    >
      {renderDropdownItems({ options: menuOptions })}
    </DropdownMenu>
  );
};

export { CustomFieldModalColorMenu };
