import type { SearchBoardsResponse, SearchSavedFiltersResponse, SuggestionsResponse } from '@air/api/types';
import { Search } from '@air/next-icons';
import { Spinner } from '@air/primitive-spinner';
import { type ReactNode } from 'react';

import { MobileSearchHeader, MobileSearchHeaderProps } from '~/_components/MobileSearchHeader';
import { MobileSearchList } from '~/_components/MobileSearchList';
import { MobileSearchMeta } from '~/_components/MobileSearchMeta';
import BoardThumbnail from '~/components/BoardThumbnail/BoardThumbnail';

export type MobileSearchProps = Pick<
  MobileSearchHeaderProps,
  'defaultValue' | 'onClear' | 'onClose' | 'onSubmit' | 'onValueChange'
> & {
  isLoading?: boolean;
  meta?: ReactNode;
  onBoardClick?: (board: SearchBoardsResponse) => void;
  onSavedFilterClick?: (savedFilter: SearchSavedFiltersResponse) => void;
  onSuggestionClick?: (suggestion: string) => void;
  suggestions?: SuggestionsResponse;
};

export const MobileSearch = ({
  defaultValue,
  isLoading,
  meta,
  onBoardClick,
  onClear,
  onClose,
  onSavedFilterClick,
  onSuggestionClick,
  onSubmit,
  onValueChange,
  suggestions,
}: MobileSearchProps) => {
  return (
    <div className="flex h-full flex-col">
      <MobileSearchHeader
        defaultValue={defaultValue}
        onClear={onClear}
        onClose={onClose}
        onSubmit={onSubmit}
        onValueChange={onValueChange}
      />
      {!!meta && <MobileSearchMeta>{meta}</MobileSearchMeta>}
      {isLoading ? (
        <div className="flex h-12 items-center justify-center">
          <Spinner className="h-6 w-6 text-blue-9" />
        </div>
      ) : (
        <div className="touch-pan-y overflow-y-auto">
          {!!suggestions?.suggestions?.length && (
            <MobileSearchList
              items={suggestions?.suggestions.map((suggestion, index) => ({
                id: `${suggestion}-${index}`,
                label: suggestion,
                onClick: () => onSuggestionClick?.(suggestion),
                thumbnail: (
                  <div className="flex h-6 w-6 items-center justify-center">
                    <Search className="h-4 w-4" />
                  </div>
                ),
              }))}
            />
          )}

          {!!suggestions?.savedFilters?.length && (
            <MobileSearchList
              items={suggestions?.savedFilters.map((savedFilter) => ({
                id: savedFilter.id,
                label: savedFilter.name,
                onClick: () => onSavedFilterClick?.(savedFilter),
                thumbnail: <div className="flex h-6 w-6 items-center justify-center">{savedFilter.icon}</div>,
              }))}
              title="Recent saved filters"
            />
          )}

          {!!suggestions?.boards?.length && (
            <MobileSearchList
              items={suggestions?.boards.map((board) => ({
                id: board.id,
                label: board.title,
                onClick: () => onBoardClick?.(board),
                thumbnail: <BoardThumbnail board={board} height={40} width={40} sizes="40" />,
              }))}
              title="Recent boards"
            />
          )}
        </div>
      )}
    </div>
  );
};
