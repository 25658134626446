import { NotificationFilter } from '@air/api/types';
import { useRouter } from 'next/router';
import { memo, useState } from 'react';

import { AirNotificationList } from '~/components/AirNotifications/components/AirNotificationList';
import { MobileNotificationFooter } from '~/components/AirNotifications/components/MobileNotificationFooter';
import { MobileNotificationsHeader } from '~/components/AirNotifications/components/MobileNotificationsHeader';
import { useGetNotificationSummary } from '~/components/AirNotifications/hooks/useGetNotificationSummary';
import { useMarkAllNotificationsAsRead } from '~/components/AirNotifications/hooks/useMarkAllNotificationsAsRead';
import { Routes } from '~/constants/routes';

export type MobileAirNotificationsProps = {
  onClose?: () => void;
};

export const MobileNotifications = memo(({ onClose }: MobileAirNotificationsProps) => {
  const [activeFilter, setActiveFilter] = useState<NotificationFilter | undefined>(undefined);
  const router = useRouter();
  const { markAllNotificationsAsRead } = useMarkAllNotificationsAsRead();
  const { data: notificationSummary } = useGetNotificationSummary();

  return (
    <div className="flex h-full flex-col">
      <MobileNotificationsHeader
        onClose={onClose}
        onMarkAllAsRead={markAllNotificationsAsRead}
        onNotificationSettings={() => {
          onClose?.();
          router.push(Routes.account.notificationSettings);
        }}
      />

      <AirNotificationList filter={activeFilter} onNotificationClick={onClose} />

      <MobileNotificationFooter
        filters={[
          {
            id: 'all',
            name: 'All',
            isActive: !activeFilter,
            count: notificationSummary?.unread?.all,
          },
          {
            id: 'mentions',
            name: 'Mentions',
            isActive: activeFilter === 'mentions',
            count: notificationSummary?.unread?.mentions,
          },
          {
            id: 'following',
            name: 'Following',
            isActive: activeFilter === 'following',
            count: notificationSummary?.unread?.following,
          },
          {
            id: 'shortUrl',
            name: 'Links',
            isActive: activeFilter === 'shortUrl',
            count: notificationSummary?.unread?.shortUrl,
          },
        ]}
        onFilterClick={(filter) => {
          if (filter.id === 'all') {
            setActiveFilter(undefined);
          } else {
            setActiveFilter(filter.id as NotificationFilter);
          }
        }}
      />
    </div>
  );
});

MobileNotifications.displayName = 'MobileNotifications';
