import { Board, Library } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { resetSelectedItemsAction } from '@air/redux-selected-items';
import pluralize from 'pluralize';
import { useDispatch } from 'react-redux';

import { SelectableGalleryBoardItem } from '~/store/selectedItems/types';
import { useMoveBoards } from '~/swr-hooks/boards/useMoveBoards';

export interface HandleDragBoardsToLibraryParams {
  library: Library;
  boards: SelectableGalleryBoardItem<Board>[];
}

export const useHandleDragBoardsToLibrary = () => {
  const dispatch = useDispatch();
  const { showToast } = useToasts();
  const {
    moveBoards: { mutate: moveBoards },
  } = useMoveBoards();

  const handleDragBoardsToLibrary = ({ boards = [], library }: HandleDragBoardsToLibraryParams) => {
    if (boards.length > 0) {
      moveBoards({
        newParentId: null,
        boards: boards.map(({ item }) => item),
        library,
      });
    }

    dispatch(resetSelectedItemsAction());

    const toastMessage = `${pluralize('board', boards.length, true)} moved to "${library.title}" library`;

    showToast(toastMessage);
  };

  return {
    handleDragBoardsToLibrary,
  };
};
