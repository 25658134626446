import { ColorPickerMenu, ColorPickerMenuProps } from '@air/component-color-picker-menu';
import { useField } from 'formik';

export type FormikColorPickerMenuProps = ColorPickerMenuProps & {
  name: string;
};

export const FormikColorPickerMenu = ({ name, ...restOfProps }: FormikColorPickerMenuProps) => {
  const [field, _meta, helpers] = useField(name);

  return <ColorPickerMenu {...field} {...restOfProps} selectedColor={field.value} onSelect={helpers.setValue} />;
};
