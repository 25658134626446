import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { useAsync } from 'react-use';

export const useStripe = () => {
  const [isStripeScriptLoaded, setIsStripeScriptLoaded] = useState(false);
  const [stripeInstance, setStripeInstance] = useState<null | stripe.Stripe>(null);

  useAsync(async () => {
    const loadScript = (src: string, uniqueId: string) =>
      new Promise<{ successful: boolean }>((resolve, reject) => {
        const scriptElement = document.getElementById(uniqueId);

        if (!scriptElement) {
          const script = document.createElement('script');
          script.src = src;
          script.id = uniqueId;

          const handleLoadScriptSuccess = () => resolve({ successful: true });
          const handleLoadScriptFail = (event: any) => reject({ error: event });

          // on load, `window` has a "Stripe" property defined
          script.addEventListener('load', handleLoadScriptSuccess, { once: true });

          // we have no error handling because we want to notice when Stripe isnt working.
          script.addEventListener('error', handleLoadScriptFail, { once: true });

          document.head.appendChild(script);
        } else {
          resolve({ successful: true });
        }
      });

    const result = await loadScript('https://js.stripe.com/v3/', 'stripe-js');
    setIsStripeScriptLoaded(result?.successful ?? false);
  });

  const isStripeReady = isStripeScriptLoaded && !isUndefined(window.Stripe);
  const apiKey = process.env.NEXT_PUBLIC_STRIPE_KEY;

  useEffect(() => {
    if (isStripeReady) {
      if (!apiKey) {
        console.error('No Stripe key was provided! Missing the NEXT_PUBLIC_STRIPE_KEY environment variable.');
      } else {
        // initialize stripe and force a re-render
        setStripeInstance(window.Stripe(apiKey));
      }
    }
  }, [isStripeReady, apiKey]);

  return { canStripeProviderRender: !!stripeInstance, apiKey };
};
