import { FormikField } from '@air/formik-field';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { Form, Formik, FormikConfig } from 'formik';
import { memo } from 'react';
import { object, string } from 'yup';

import { GeneralLibraryInfo } from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibraryInfo';

export const VALIDATION_SCHEMA = object().shape({
  title: string()
    .max(255, 'Library name must be less than 255 characters.')
    .matches(/^[^:|,]*$/, 'Characters (:, |) not allowed')
    .trim()
    .required('Library name is required.'),
});

export type GeneralLibraryDetailsValues = {
  title: string;
};

export interface UpdateGeneralLibraryTitleValues {
  title: string;
}

export type GeneralLibraryUpdateDetails = Pick<
  FormikConfig<GeneralLibraryDetailsValues>,
  'initialValues' | 'onSubmit'
> & {
  isSubmitting?: boolean;
  workspaceName: string;
};

export const GeneralLibraryUpdateDetails = memo(
  ({ initialValues, isSubmitting, onSubmit, workspaceName }: GeneralLibraryUpdateDetails) => {
    return (
      <Formik<UpdateGeneralLibraryTitleValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ dirty }) => (
          <Form className="flex flex-col gap-3">
            <div className="flex flex-col gap-6">
              <FormikField component={<Input />} id="library-title" label="Name" name="title" />
              <GeneralLibraryInfo workspaceName={workspaceName} />
            </div>
            <div className="-mx-8 -mb-6 mt-2 flex items-center justify-end border-t border-t-pigeon-100 px-8 py-4">
              <Button
                appearance="filled"
                color="blue"
                disabled={!dirty || isSubmitting}
                isLoading={isSubmitting}
                size="large"
                type="submit"
              >
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  },
);

GeneralLibraryUpdateDetails.displayName = 'EditableGeneralLibraryDetails';
