import { useCallback, useEffect, useState } from 'react';

export type UseSidebarSectionCollapseStateParams = {
  storageKey?: string;
};

export const useSidebarSectionCollapseState = (params?: UseSidebarSectionCollapseStateParams) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (params?.storageKey) {
      const isCollapsed = localStorage.getItem(params.storageKey);

      if (isCollapsed) {
        setIsCollapsed(isCollapsed === 'true');
      }
    }
  }, [params?.storageKey]);

  const handleToggleCollapse = useCallback(() => {
    setIsCollapsed((prev) => {
      if (params?.storageKey) {
        localStorage.setItem(params.storageKey, String(!prev));
      }

      return !prev;
    });
  }, [params?.storageKey]);

  return {
    isCollapsed,
    handleToggleCollapse,
  };
};
