import { useTrackToggledFieldRequiredStatus } from '@air/analytics';
import { Form, FormField, FormFieldType } from '@air/api/types';
import produce from 'immer';
import { capitalize } from 'lodash';
import { memo } from 'react';

import { useUpdateForm } from '~/swr-hooks/forms/useUpdateForm';

import { CollectionFormField } from './CollectionFormField';

interface SettingsItemProps {
  formField: FormField;
  form: Form;
}
export const SettingsItem = memo(({ formField, form }: SettingsItemProps) => {
  const fieldName = capitalize(formField?.type);
  const required = formField?.required || false;
  const disabled = formField?.type === FormFieldType.title;

  const { trackToggledFieldRequiredStatus } = useTrackToggledFieldRequiredStatus();
  const { updateForm } = useUpdateForm();

  return (
    <CollectionFormField
      onSelect={async (required: boolean) => {
        const updatedFields = produce(form.fields, (draft) => {
          const fieldToUpdate = draft?.find(({ type }) => formField.type === type);
          if (fieldToUpdate) draft?.splice(draft?.indexOf(fieldToUpdate), 1, { ...fieldToUpdate, required: required });
        });

        await updateForm({
          ...form,
          fields: updatedFields,
        });

        trackToggledFieldRequiredStatus({ fieldName: 'Description', required });
      }}
      fieldName={fieldName}
      required={required}
      disabled={disabled}
    />
  );
});

SettingsItem.displayName = 'SettingsItem';
