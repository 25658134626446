import { useTrackUpdatedLibraryMember } from '@air/analytics';
import { Libraries, LibraryMembersWithPending } from '@air/api';
import { Library } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useMutation } from '@tanstack/react-query';

import { getLibraryMembersKey } from '~/components/LibraryBeta/hooks/useLibraryMembers';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { queryClient } from '~/swr-hooks/queryClient';

export type UseLibraryUpdateMemberMutationParams = {
  libraryId: Library['id'];
};

export const useLibraryMemberUpdateMutation = ({ libraryId }: UseLibraryUpdateMemberMutationParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackUpdatedLibraryMember } = useTrackUpdatedLibraryMember();

  const libraryMemberUpdateMutation = useMutation({
    mutationFn: (data: Parameters<typeof Libraries.updateMember>[0]['member']) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.updateMember({ workspaceId, libraryId, member: data });
    },

    onMutate: (variables) => {
      const previousLibraryMembers = queryClient.getQueryData<LibraryMembersWithPending>(
        getLibraryMembersKey(libraryId),
      );

      queryClient.cancelQueries({ queryKey: getLibraryMembersKey(libraryId) });

      if (previousLibraryMembers) {
        queryClient.setQueryData<LibraryMembersWithPending>(
          getLibraryMembersKey(libraryId),
          previousLibraryMembers.map((member) =>
            member.email === variables.email ? { ...member, ...variables } : member,
          ),
        );
      }

      return {
        previousLibraryMembers,
      };
    },

    onError: (error, variables, context) => {
      if (context) {
        queryClient.setQueryData(getLibraryMembersKey(libraryId), context.previousLibraryMembers);
      }

      reportErrorToBugsnag({
        error,
        context: 'Unable to update library member',
        metadata: {
          key: 'data',
          data: {
            id: variables.email,
            member: { ...variables },
          },
        },
      });
    },
    onSuccess: (_data, variables) => {
      trackUpdatedLibraryMember({
        library_id: libraryId,
        library_member_email: variables.email,
        library_member_role_id: variables.roleId,
      });
      queryClient.invalidateQueries({ queryKey: getLibraryMembersKey(libraryId) });
    },
  });

  return { libraryMemberUpdateMutation };
};
