import { EllipsisVertical, Gear } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DropdownMenu, type DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { type ComponentProps } from 'react';

import { NotificationsFilters, type NotificationsFiltersProps } from './NotificationsFilters';

export type NotificationsHeaderProps = ComponentProps<'header'> &
  NotificationsFiltersProps & {
    options?: DropdownMenuOption[];
    onMarkAllAsRead?: () => void;
    onSettingsClick?: () => void;
  };

export const NotificationsHeader = ({
  filters,
  onFilterClick,
  onMarkAllAsRead,
  onSettingsClick,
  options,
  ...restOfProps
}: NotificationsHeaderProps) => {
  return (
    <header
      className="sticky top-0 z-0 shrink-0 border-b border-b-grey-4 bg-grey-1 pt-4"
      data-testid="NOTIFICATIONS_HEADER"
      {...restOfProps}
    >
      <div className="flex items-center justify-between px-4">
        <h3 className="text-14 font-semibold text-grey-12">Notifications</h3>

        <div className="flex shrink-0 items-center gap-2">
          {!!onSettingsClick && (
            <IconButton
              appearance="ghost"
              color="grey"
              label="Notification settings"
              icon={Gear}
              onClick={onSettingsClick}
            />
          )}

          {!!options && (
            <DropdownMenu
              align="end"
              trigger={<IconButton appearance="ghost" color="grey" icon={EllipsisVertical} label="Settings" />}
            >
              {renderDropdownItems({ options })}
            </DropdownMenu>
          )}
        </div>
      </div>

      <div className="flex min-h-[40px] items-center justify-between pr-4">
        <NotificationsFilters filters={filters} onFilterClick={onFilterClick} />

        <Button appearance="ghost" className="shrink-0" color="grey" onClick={onMarkAllAsRead} size="small">
          Mark all as read
        </Button>
      </div>
    </header>
  );
};
