import { LibraryWithPermissions } from '@air/api/types';
import { renderDropdownItems } from '@air/primitive-dropdown-menu';

import { useLibraryMenuOptions } from '~/components/LibraryBeta/hooks/useLibraryMenuOptions';

export type LibraryNaigationDropdownMenuContentProps = {
  library: LibraryWithPermissions;
};

export const LibraryNaigationDropdownMenuContent = ({ library }: LibraryNaigationDropdownMenuContentProps) => {
  const { menuOptions } = useLibraryMenuOptions({
    initialData: library,
    libraryId: library.id,
  });

  if (!menuOptions.length) return null;

  return <>{renderDropdownItems({ options: menuOptions })}</>;
};
