import { type Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { NotificationClip } from '~/components/AirNotifications/components/NotificationClip';
import { CommentDisplay } from '~/components/Discussions/CommentDisplay';

export type NotificationCommentTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification:
    | Notification<'commentCreated_userIsBoardFollower', 'account'>
    | Notification<'commentCreated_userIsShareLinkCreator', 'account'>
    | Notification<'commentCreated_userIsDiscussionParticipant', 'account'>;
};

export const NotificationCommentType = ({ notification, onClick }: NotificationCommentTypeProps) => {
  const { actor, board, clip, comment } = notification.data;

  const meta = notification.data.shortUrl ? 'via share link' : !!board?.title && `in ${board.title}`;

  return (
    <NotificationBaseItem
      content={<CommentDisplay className="max-h-[64px] max-w-[300px] truncate text-inherit" text={comment?.body} />}
      meta={meta}
      notification={notification}
      onClick={onClick}
      suffix={<NotificationClip clip={clip} />}
      title={
        <>
          <b>{formatActorNameInitialsOrDefault(actor)}</b>
          {` commented on `}
          <b>{clip.title + (clip.ext ? `.${clip.ext}` : '')}</b>
        </>
      }
    />
  );
};
