import { CustomFieldColor } from '@air/api/types';
import { Check, Edit, Trash } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Input } from '@air/primitive-input';
import classNames from 'classnames';
import { useField } from 'formik';
import { forwardRef, useEffect, useRef } from 'react';

import { CustomField } from '~/components/CustomField';
import {
  CUSTOM_FIELD_DELETE_OPTION,
  CUSTOM_FIELD_MODAL_OPTION,
  CUSTOM_FIELD_MODAL_OPTION_INPUT,
  CUSTOM_FIELD_SAVE_OPTION,
} from '~/constants/testIDs';

import { CustomFieldModalColorMenu } from './CustomFieldModalColorMenu';
import { ModalCustomFieldValue } from './CustomFieldModalForm/types';

interface CustomFieldModalOptionProps {
  colors: Array<CustomFieldColor>;
  handleColorClick: (backgroundColor: string, index: number) => void;
  handleDeleteItem: (index: number) => void;
  handleSaveOption: () => void;
  isSaveCurrentOptionDisabled?: boolean;
  disableOptionClick?: boolean;
  index: number;
  inEditMode: boolean;
  onToggleEditMode: () => void;
  option: ModalCustomFieldValue;
}

const CustomFieldModalOption = forwardRef<HTMLDivElement, CustomFieldModalOptionProps>(
  (
    {
      colors,
      handleColorClick,
      handleDeleteItem,
      handleSaveOption,
      isSaveCurrentOptionDisabled = false,
      index,
      disableOptionClick = false,
      inEditMode,
      onToggleEditMode,
      option,
    }: CustomFieldModalOptionProps,
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const fieldName = `values.${index}.value`;

    const [field, meta] = useField(fieldName);

    useEffect(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    }, [inEditMode]);

    if (inEditMode) {
      return (
        <div className="relative flex h-10 items-center gap-2">
          <Input
            ref={inputRef}
            className="w-full pl-9"
            data-1p-ignore
            size="large"
            data-testid={`${CUSTOM_FIELD_MODAL_OPTION_INPUT}-${option.value}`}
            {...field}
          />
          {!!meta.error && <span className="absolute -bottom-5 text-12 text-red-9">{meta.error}</span>}
          <CustomFieldModalColorMenu
            colors={colors}
            fontHex={option.color.fontHex}
            selectedColor={option.color?.backgroundHex}
            index={index}
            handleColorClick={handleColorClick}
          />
          <IconButton
            onClick={handleSaveOption}
            data-testid={CUSTOM_FIELD_SAVE_OPTION}
            size="medium"
            appearance="filled"
            color="grey"
            disabled={isSaveCurrentOptionDisabled}
            icon={Check}
            label="Save"
          />
          <IconButton
            data-testid={CUSTOM_FIELD_DELETE_OPTION}
            onClick={() => handleDeleteItem(index)}
            size="medium"
            appearance="filled"
            color="grey"
            icon={Trash}
            label="Delete"
          />
        </div>
      );
    }

    return (
      <div
        className={classNames('h-10', disableOptionClick ? 'pointer-events-none' : 'pointer-events-auto')}
        onClick={onToggleEditMode}
        ref={ref}
      >
        <button
          className="group/custom-field-modal-options flex w-full cursor-pointer items-center justify-between rounded p-2 hover:bg-grey-4"
          data-testid={`${CUSTOM_FIELD_MODAL_OPTION}-${option.value}`}
          type="button"
        >
          <CustomField field={{ ...option, value: option.value || '' }} />
          <Edit className="hidden h-4 w-4 group-hover/custom-field-modal-options:block" />
        </button>
      </div>
    );
  },
);

CustomFieldModalOption.displayName = 'CustomFieldModalOption';
export { CustomFieldModalOption };
