import { InvitedMember, useTrackInvitedMember } from '@air/analytics';
import { WorkspaceMemberType } from '@air/api/types';
import { matchesAirror, OVER_MAX_MEMBERS } from '@air/errors';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';

import { InviteByEmailInput } from '~/components/Modals/InviteMembers/InviteMembersModal/InviteByEmailInput';
import { MembersList } from '~/components/Modals/InviteMembers/InviteMembersModal/MembersList';
import { MemberLimitModal } from '~/components/Modals/MemberLimitModal';
import { useAddWorkspaceMembers } from '~/swr-hooks/members/useAddWorkspaceMembers';
import { sanitizeEmail } from '~/utils/EmailUtils';

interface InviteByEmailProps {
  workspaceTitle: string;
  location: InvitedMember['location'];
}

export const InviteByEmail = ({ workspaceTitle, location }: InviteByEmailProps) => {
  const { addWorkspaceMember } = useAddWorkspaceMembers();
  const { showToast } = useToasts();
  const [showLimitMemberModal] = useAirModal(MemberLimitModal);
  const { trackInvitedMember } = useTrackInvitedMember();

  const onAddMemberError = (error: unknown) => {
    matchesAirror(error, OVER_MAX_MEMBERS)
      ? showLimitMemberModal()
      : showToast('Failed to add members. Please try again later.');
  };

  return (
    <div className="mt-6">
      <div className="text-14 text-pigeon-400">
        Invite your teammates to join {workspaceTitle}. Enter an email, choose permission levels, and send.
      </div>
      <InviteByEmailInput
        submitMember={async ({ email, selectedRole }) => {
          await addWorkspaceMember([
            {
              email: sanitizeEmail(email),
              roleId: selectedRole.id,
              type: WorkspaceMemberType.member,
              emailTemplate: 'default',
            },
          ]);
          trackInvitedMember({ location, role: selectedRole.displayName, type: selectedRole.type });
          showToast('Invitation sent');
        }}
        onError={onAddMemberError}
      />
      <MembersList />
    </div>
  );
};
