import { FormikConfig } from 'formik';
import { useCallback, useMemo } from 'react';

import { GeneralLibraryReadonlyDetails } from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibraryReadonlyDetails';
import {
  GeneralLibraryDetailsValues,
  GeneralLibraryUpdateDetails,
} from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibraryUpdateDetails';
import { useGeneralLibraryTitle } from '~/components/LibraryBeta/hooks/useGeneralLibraryTitle';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateWorkspace } from '~/swr-hooks/workspaces/useUpdateWorkspace';
import { convertUnknownToError } from '~/utils/ErrorUtils';
import { canChangeWorkspaceDetails } from '~/utils/permissions/workspacePermissions';

export const GeneralLibraryUpdateDetailsContainer = () => {
  const { generalLibraryTitle } = useGeneralLibraryTitle();
  const { updateWorkspace } = useUpdateWorkspace();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const { showLibraryToast } = useLibraryToasts();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canEditWorkspace = canChangeWorkspaceDetails(permissions);

  const initialValues = useMemo(() => ({ title: generalLibraryTitle ?? '' }), [generalLibraryTitle]);

  const onSubmit = useCallback<FormikConfig<GeneralLibraryDetailsValues>['onSubmit']>(
    (values) => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      updateWorkspace.mutate(
        {
          workspaceId,
          defaultLibrary: values.title,
        },
        {
          onError: (error) => showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
          onSuccess: (data) => {
            showLibraryToast({
              message: (
                <>
                  Updated library name to <b className="text-jay-200">{data.defaultLibrary}</b>.
                </>
              ),
            });
          },
        },
      );
    },
    [showLibraryToast, updateWorkspace, workspaceId],
  );

  if (!currentWorkspace || !generalLibraryTitle) {
    return null;
  }

  if (!canEditWorkspace) {
    return <GeneralLibraryReadonlyDetails />;
  }

  return (
    <GeneralLibraryUpdateDetails
      initialValues={initialValues}
      isSubmitting={updateWorkspace.isPending}
      onSubmit={onSubmit}
      workspaceName={currentWorkspace.name}
    />
  );
};
