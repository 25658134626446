import { ShortUrl } from '@air/api';
import { Board, Clip, Form } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

import { getShortIdInformationKey } from './useGetExistingShortIdInformation';

export const useDeleteShortId = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const deleteShortId = useMutation({
    mutationFn: ({
      shortId,
      boardContext,
    }: Parameters<typeof ShortUrl.deleteByShortId>[0] & {
      id: Board['id'] | Clip['id'] | Form['id'];
      boardContext: Board['id'] | undefined;
    }) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return ShortUrl.deleteByShortId({ workspaceId, shortId, boardContext });
    },
    onMutate: ({ id, boardContext }) => {
      queryClient.setQueryData(getShortIdInformationKey({ objectId: id, boardContext }), null);
    },
    onSuccess: (_data, { id, boardContext }) => {
      queryClient.invalidateQueries({ queryKey: getShortIdInformationKey({ objectId: id, boardContext }) });
    },
    onError: (error, params) => {
      throw reportErrorToBugsnag({
        error,
        context: 'Failed to delete short id',
        metadata: { data: params },
      });
    },
  });

  return {
    deleteShortId,
  };
};
