import { ConfirmationModal } from '@air/component-confirmation-modal';
import { useCallback } from 'react';

export interface CancelAllUploadsModalProps {
  onCancelUpload: () => void;
  onContinueUpload?: () => void;
}

export const CancelAllUploadsModal = ({
  onClose,
  onCancelUpload,
  onContinueUpload,
}: AirModalProps<CancelAllUploadsModalProps>) => {
  const onContinue = useCallback(() => {
    onContinueUpload?.();
    onClose?.();
  }, [onClose, onContinueUpload]);

  return (
    <ConfirmationModal
      id="cancel-all-uploads-modal"
      isOpen
      title="Cancel Upload"
      description="This will affect any assets that have not been uploaded. Completed uploads will still be in the workspace."
      onDismiss={onContinue}
      ctaPrimaryText="Cancel Upload"
      onConfirm={onCancelUpload}
      isDangerous
      ctaSecondaryText="Continue Uploading"
    />
  );
};
