import classNames from 'classnames';
import { ComponentProps, forwardRef, memo, ReactNode } from 'react';

export type LibraryItemProps = ComponentProps<'div'> & {
  actions?: ReactNode;
  description?: string | ReactNode;
  title: string | ReactNode;
  meta?: string | ReactNode;
};

export const LibraryItem = memo(
  forwardRef<HTMLDivElement, LibraryItemProps>(
    ({ actions, className, description, title, meta, ...restOfProps }, forwardedRef) => {
      return (
        <div className={classNames('flex flex-col gap-2 px-2 py-3', className)} {...restOfProps} ref={forwardedRef}>
          <div className="flex justify-between gap-2">
            <h3 className="text-16 font-semibold text-grey-12">{title}</h3>
            {actions ? <div className="flex shrink-0 gap-2">{actions}</div> : null}
          </div>
          {description ? <p className="text-14 text-grey-12">{description}</p> : null}
          {meta ? <div className="flex items-center py-1 text-12 text-grey-9">{meta}</div> : null}
        </div>
      );
    },
  ),
);

LibraryItem.displayName = 'LibraryItem';
