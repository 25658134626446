import { Banner, BannerProps } from '@air/primitive-banner';

import { useFixPlanAction } from '~/hooks/useFixPlanAction';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';
import { useReachedMemberLimit } from '~/swr-hooks/workspaces/useReachedMemberLimit';

export type MemberLimitMiniBannerProps = Pick<BannerProps, 'children'> & {
  onClick?: () => void;
  className?: string;
};

export const MemberLimitMiniBanner = ({ children, onClick, className }: MemberLimitMiniBannerProps) => {
  const { reachedMemberLimit } = useReachedMemberLimit();
  const actionProps = useFixPlanAction({ onClickAction: onClick });
  const { members } = useWorkspaceMembers();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();

  if (!members || !currentWorkspaceMember || !reachedMemberLimit) return null;

  return (
    <Banner className={className} data-testid="MEMBER_LIMIT_BANNER" appearance="warning">
      <div className="flex justify-between gap-2">
        {children}
        <a className="font-semibold text-inherit" {...actionProps} />
      </div>
    </Banner>
  );
};
