import { WorkspaceMemberResponseWithRoles } from '@air/api/types';
import { Avatar } from '@air/primitive-avatar';
import { memo } from 'react';

import { formatFullName } from '~/utils/formatFullName';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

export interface GeneralLibraryMemberProps {
  member: Pick<WorkspaceMemberResponseWithRoles, 'accountId' | 'firstName' | 'lastName' | 'avatar' | 'roles'>;
}

export const GeneralLibraryMember = memo(
  ({ member: { avatar, firstName, lastName, roles, accountId } }: GeneralLibraryMemberProps) => (
    <li className="flex items-center gap-3">
      <Avatar
        alt={`${firstName ?? ''} ${lastName ?? ''} avatar`}
        appearance="circle"
        size={40}
        src={getAvatarImageSrc(avatar, 40)}
        text={`${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`}
        colorSeed={accountId}
      />
      <p className="grow truncate text-14 font-medium text-pigeon-600">
        {firstName && lastName ? formatFullName(firstName, lastName) : ''}
      </p>
      <p className="shrink-0 text-14 text-pigeon-600">{roles.member.displayName}</p>
    </li>
  ),
);

GeneralLibraryMember.displayName = 'GeneralLibraryMember';
