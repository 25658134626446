import { type Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { NotificationClip } from '~/components/AirNotifications/components/NotificationClip';
import { CommentDisplay } from '~/components/Discussions/CommentDisplay';

export type NotificationCommentMentionedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification: Notification<'commentCreated_userIsMentioned', 'account'>;
};

export const NotificationCommentMentionedType = ({ notification, onClick }: NotificationCommentMentionedTypeProps) => {
  const { actor, board, clip, comment } = notification.data;

  const meta = notification.data.shortUrl ? 'via share link' : !!board?.title && `in ${board.title}`;

  return (
    <NotificationBaseItem
      content={<CommentDisplay className="max-h-[64px] max-w-[300px] truncate text-inherit" text={comment?.body} />}
      meta={meta}
      notification={notification}
      onClick={onClick}
      suffix={<NotificationClip clip={clip} />}
      title={
        <>
          <b>{formatActorNameInitialsOrDefault(actor)}</b>
          {` mentioned you in a comment on `}
          <b>{clip.title}</b>
        </>
      }
    />
  );
};
