import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { useState } from 'react';

export interface CustomFieldDeleteModalProps extends AirModalProps {
  handleConfirmDeleteClick: () => Promise<void>;
}

export const CustomFieldDeleteModal = ({ onClose, handleConfirmDeleteClick }: CustomFieldDeleteModalProps) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const onDeleteClick = async () => {
    try {
      setIsDeleting(true);
      await handleConfirmDeleteClick();
    } finally {
      setIsDeleting(false);
      onClose();
    }
  };

  return (
    <Modal isOpen onDismiss={onClose}>
      <div className="flex flex-col gap-6">
        <header className="flex justify-between gap-1">
          <ModalTitle>Are you sure?</ModalTitle>
          <ModalCloseButton className="shrink-0" onClick={onClose} />
        </header>
        <ModalDescription>
          Deleting this field will remove it from every asset and board in your workspace.
        </ModalDescription>
      </div>
      <div className="mt-6 flex justify-end gap-3">
        <Button disabled={isDeleting} appearance="ghost" color="grey" onClick={onClose} size="large">
          Cancel
        </Button>
        <Button
          data-testid="CUSTOM_FIELD_DELETE_CONFIRM"
          isLoading={isDeleting}
          appearance="filled"
          color="red"
          size="large"
          onClick={onDeleteClick}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};
