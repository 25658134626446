import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import { type ComponentProps } from 'react';

export const notificationFiltersItem = tailwindVariants({
  base: 'relative flex h-10 items-center gap-2 px-2 text-12 font-semibold',
  variants: {
    state: {
      default: 'text-grey-11',
      active: 'text-grey-12',
    },
  },
  defaultVariants: {
    state: 'default',
  },
});

export const notificationFiltersItemBadge = tailwindVariants({
  base: 'flex h-4 items-center rounded-3xl px-1.5 text-10 font-bold',
  variants: {
    state: {
      default: 'bg-grey-4 text-grey-10',
      active: 'bg-red-8 text-white',
    },
  },
  defaultVariants: {
    state: 'default',
  },
});

export type NotificationsFiltersItemVariants = VariantProps<typeof notificationFiltersItem>;

export type NotificationsFiltersItem = ComponentProps<'button'> &
  NotificationsFiltersItemVariants & {
    count?: number;
  };

export const NotificationsFiltersItem = ({
  children,
  className,
  count,
  state,
  ...restOfProps
}: NotificationsFiltersItem) => {
  return (
    <button
      className={notificationFiltersItem({ state, className })}
      data-testid="NOTIFICATIONS_FILTERS_ITEM"
      {...restOfProps}
    >
      {children}
      {!!count && <div className={notificationFiltersItemBadge({ state })}>{count}</div>}
      {state === 'active' && <div className="absolute inset-x-2 bottom-0 h-0.5 bg-blue-9" />}
    </button>
  );
};
