import { type ListNotificationsResponse } from '@air/api/types';
import { type InfiniteData } from '@tanstack/react-query';
import produce from 'immer';

export const markAllNotificationsInCacheAsRead = (data?: InfiniteData<ListNotificationsResponse>) => {
  const date = new Date().toISOString();

  if (!data) return;

  return produce(data, (draft) => {
    draft.pages.forEach((page) => {
      page.items.forEach((notification) => {
        if (!notification.readAt) notification.readAt = date;
      });
    });
  });
};
