import { Form } from '@air/api/types';
import { EditableText } from '@air/zephyr-editable-text';
import { memo } from 'react';

import { CONTENT_COLLECTION_FORM_MODAL_EDITABLE_DESCRIPTION } from '~/constants/testIDs';
import { useUpdateForm } from '~/swr-hooks/forms/useUpdateForm';

const inputStyles = {
  wordBreak: 'break-word',
  color: 'pigeon400',
  fontWeight: 'regular',
  ml: -8,
  width: '100%',
  borderRadius: 4,
  EditableTextButton: {
    color: 'pigeon400',
    fontWeight: 'regular',
  },
};

type FormDescriptionProps = {
  form: Form;
};

export const FormDescription = memo(({ form }: FormDescriptionProps) => {
  const { updateForm } = useUpdateForm();

  const onSubmit = async (values: { ['editable-text-value']: string }) => {
    const description = values['editable-text-value'];

    await updateForm({
      ...form,
      description,
    });
  };

  return (
    <EditableText
      label="Content description"
      placeholder="Add description here..."
      value={form?.description || ''}
      onSubmit={onSubmit}
      tx={inputStyles}
      variant="text-ui-16"
      data-testid={CONTENT_COLLECTION_FORM_MODAL_EDITABLE_DESCRIPTION}
    />
  );
});

FormDescription.displayName = 'FormDescription';
