import { useTrackGeneratedInviteMemberLink } from '@air/analytics';
import { WorkspaceUserRole } from '@air/api/types';

import { InviteByLinkInput } from '~/components/Modals/InviteMembers/InviteMembersModal/InviteByLinkInput';
import { InviteLinksList } from '~/components/Modals/InviteMembers/InviteMembersModal/InviteLinksList';
import { useCreateInviteToken } from '~/swr-hooks/members/useCreateInviteToken';
import { useWorkspaceMemberRoles } from '~/swr-hooks/members/useWorkspaceMemberRoles';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export interface InviteByLinkProps {
  workspaceTitle: string;
}

export const InviteByLink = ({ workspaceTitle }: InviteByLinkProps) => {
  const { memberRoles = [] } = useWorkspaceMemberRoles();
  const { createInviteToken } = useCreateInviteToken();
  const { trackGeneratedInviteMemberLink } = useTrackGeneratedInviteMemberLink();

  const handleGenerateLink = async (role: WorkspaceUserRole) => {
    if (role) {
      await createInviteToken(role.id);
      trackGeneratedInviteMemberLink({ roleId: role.id, role: role.displayName });
    } else {
      // if permission is not found, we have a mismatch between defined roles on UI and backend - send info to bugnsag
      reportErrorToBugsnag({
        error: new Error('Mismatched permissions and role options'),
        context: 'Role option not found in user roles',
        metadata: {
          data: {
            role,
            roles: memberRoles,
          },
        },
      });
    }
  };

  return (
    <div className="mt-6">
      <p className="text-14 text-pigeon-400">{`Create a secret link to allow anyone to join ${workspaceTitle}. Set the permission levels you want to give to new members.`}</p>
      <InviteByLinkInput onLinkGenerate={handleGenerateLink} />
      <InviteLinksList roles={memberRoles} />
    </div>
  );
};
