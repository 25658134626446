import { WorkspaceMemberResponse } from '@air/api/types';
import { Avatar } from '@air/primitive-avatar';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

export interface MemberInfoProps {
  member: Pick<WorkspaceMemberResponse, 'firstName' | 'lastName' | 'avatar' | 'email' | 'accountId'> & {
    inactive?: boolean;
    pending?: boolean;
  };
  nameAdornment?: ReactNode;
}

export const MemberInfo = ({
  member: { avatar, firstName, lastName, email, accountId, pending, inactive },
  nameAdornment,
}: MemberInfoProps) => {
  const isPending = !!pending;
  const isInactive = !!inactive;

  return (
    <div className="flex">
      <Avatar
        alt={`${firstName ?? ''} ${lastName ?? ''} avatar`}
        appearance="circle"
        className="shrink-0"
        size={48}
        src={getAvatarImageSrc(avatar, 48)}
        text={isPending ? '' : `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`}
        colorSeed={accountId}
      />
      <div className="ml-4 overflow-x-hidden">
        <div className="flex items-center">
          <div className={classNames('truncate text-16 font-medium', isInactive ? 'text-grey-10' : 'text-grey-12')}>
            {isPending ? email : `${firstName ?? ''} ${lastName ?? ''}`}
          </div>
          {nameAdornment}
        </div>

        <div
          className={classNames('truncate text-14', isInactive ? 'text-grey-8' : 'text-grey-10', isPending && 'italic')}
        >
          {isPending ? 'Pending...' : email}
        </div>
      </div>
    </div>
  );
};
