import { Libraries, LibraryMemberPending } from '@air/api';
import { LibraryMemberListOptions } from '@air/api/dist/libraries';
import { Library, LibraryMemberWithInfo, WorkspaceMemberRoleDisplayName } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { useLibraryPermissions } from '~/components/LibraryBeta/hooks/useLibraryPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useWorkspaceRolesContext } from '~/providers/WorkspaceRolesProvider';
import { sortMembersByRole } from '~/swr-hooks/members/utils/sortMembersByRole';
import {
  canSeeMembersInHiddenLibrary,
  canSeeMembersInPrivateOrPublicLibrary,
} from '~/utils/permissions/libraryPermissions';

export type LibraryMembersDefaultOptions = {
  hideSysadmins: boolean;
};

export const LIBRARY_MEMBERS_DEFAULT_OPTIONS: LibraryMembersDefaultOptions = {
  hideSysadmins: true,
};

export const LIBRARY_MEMBERS_QUERY_KEY = 'LIBRARY_MEMBERS';

export const getLibraryMembersKey = (libraryId?: string, options?: LibraryMembersDefaultOptions) => [
  LIBRARY_MEMBERS_QUERY_KEY,
  {
    libraryId,
    ...options,
  },
];

export interface UseLibraryMembersParams<Options extends LibraryMemberListOptions> {
  libraryId: Library['id'] | undefined;
  options?: LibraryMembersDefaultOptions & Options;
}

export const useLibraryMembers = <Options extends LibraryMemberListOptions>({
  libraryId,
  options: _options,
}: UseLibraryMembersParams<Options>) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: roles } = useWorkspaceRolesContext();
  const options = {
    ...LIBRARY_MEMBERS_DEFAULT_OPTIONS,
    ..._options,
  };

  const { data: library } = useLibrary({ libraryId });
  const { data: currentWorkspacePermissions } = useCurrentWorkspacePermissionsContext();
  const { libraryPermissions } = useLibraryPermissions({ libraryId });

  const canSeeMembers = library
    ? library?.visibility === 'libraryMembers'
      ? canSeeMembersInHiddenLibrary({
          libraryContext: libraryPermissions,
          workspaceContext: currentWorkspacePermissions,
        })
      : canSeeMembersInPrivateOrPublicLibrary({
          libraryContext: libraryPermissions,
          workspaceContext: currentWorkspacePermissions,
        })
    : false;

  const libraryMembers = useQuery({
    queryKey: getLibraryMembersKey(libraryId, options),

    queryFn: () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.listMembers({
        workspaceId,
        libraryId: libraryId!,
        options: {
          withPendingWorkspaceMembers: options.withPendingWorkspaceMembers,
        },
      })
        .then((data) => (!!roles?.length ? sortMembersByRole(data, roles) : data))
        .then((data) =>
          data.filter((member) =>
            options.hideSysadmins ? member.role?.displayName !== WorkspaceMemberRoleDisplayName.Sysadmin : member,
          ),
        );
    },
    enabled: !!currentWorkspace?.id && !!libraryId && !!roles && canSeeMembers,
    refetchOnWindowFocus: true,
  });

  return libraryMembers;
};

export const isLibraryNonWorkspaceMember = (
  member: LibraryMemberPending | LibraryMemberWithInfo,
): member is LibraryMemberPending => !('pending' in member);

export const isLibraryWorkspaceMember = (
  member: LibraryMemberPending | LibraryMemberWithInfo,
): member is LibraryMemberWithInfo => 'pending' in member;
