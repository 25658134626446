import { format, formatDistance, isBefore, subWeeks } from 'date-fns';

import { parseAirDateToISO } from '~/utils/DateUtils';

/**
 * Formats the çreatedAt date to a human readable format.
 *
 * If the date is older than 2 weeks, it will be formatted as "Month Day, Year", otherwise it will be formatted as "X days ago".
 */
export const formatCreatedAt = (createdAt: string) => {
  const createdAtDate = parseAirDateToISO(createdAt);
  const cutoffDate = subWeeks(new Date(), 2);

  if (isBefore(createdAtDate, cutoffDate)) {
    return format(createdAtDate, 'MMMM d, yyyy');
  }

  return formatDistance(createdAtDate, new Date(), { addSuffix: true });
};
