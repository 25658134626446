import { type Notification } from '@air/api/types';
import { type NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';
import pluralize from 'pluralize';
import { useMemo } from 'react';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { NotificationClip } from '~/components/AirNotifications/components/NotificationClip';
import { NotificationCustomFields } from '~/components/AirNotifications/components/NotificationCustomFields';

export type NotificationAssetCustomFieldUpdatedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification: Notification<
    'assetCustomFieldUpdated_userIsBoardFollower' | 'assetCustomFieldUpdated_userIsShareLinkCreator',
    'account'
  >;
};

export const NotificationAssetCustomFieldUpdatedType = ({
  notification,
  onClick,
}: NotificationAssetCustomFieldUpdatedTypeProps) => {
  const { actor, boards, clips, customFields, shortUrl } = notification.data;

  const meta = useMemo(() => {
    if (shortUrl) {
      return 'via share link';
    } else if (boards.length === 0) {
      return null;
    } else if (boards.length === 1) {
      return `in ${boards[0].title}`;
    } else {
      return `in ${pluralize('board', boards.length, true)}`;
    }
  }, [boards, shortUrl]);

  const assetsTitle = useMemo(() => {
    if (clips.length === 0) {
      return '[deleted asset(s)]';
    } else if (clips.length === 1) {
      return clips[0].title + (clips[0].ext ? `.${clips[0].ext}` : '');
    } else {
      return `${pluralize('item', clips.length, true)}`;
    }
  }, [clips]);

  return (
    <NotificationBaseItem
      content={<NotificationCustomFields customFields={customFields} />}
      meta={meta}
      notification={notification}
      onClick={clips.length > 0 && boards.length > 0 ? onClick : undefined}
      suffix={clips.length > 0 ? <NotificationClip clip={clips[0]} /> : undefined}
      title={
        <>
          <b>{formatActorNameInitialsOrDefault(actor)}</b>
          {` edited custom fields on `}
          <b>{assetsTitle}</b>
        </>
      }
    />
  );
};
