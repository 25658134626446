import { Workspaces } from '@air/api';
import { InviteToken } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getWorkspaceTokensKey } from './useInviteTokens';

export const useDeleteInviteToken = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const deleteInviteToken = useCallback(
    async (tokenId: string) => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      try {
        queryClient.setQueryData<InviteToken[] | undefined>(getWorkspaceTokensKey(workspaceId), (tokens) => {
          return tokens?.filter((token) => token.id !== tokenId) ?? undefined;
        });

        await Workspaces.deleteInviteToken({ id: tokenId, workspaceId });
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to delete invite token',
          metadata: {
            data: {
              tokenId,
            },
          },
        });
      }
    },
    [queryClient, workspaceId],
  );

  return {
    deleteInviteToken,
  };
};
