import { Plus as PlusIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { useAirModal } from '@air/provider-modal';
import { memo } from 'react';

import { CreateBoardModal } from '~/components/Modals/CreateBoardModal';
import { BOARDS_SELECT_EMPTY_OPTION } from '~/constants/BoardSearch';
import { intercomIDs } from '~/constants/intercomIDs';
import { CREATE_BOARD_BUTTON_END } from '~/constants/testIDs';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';

export const CurrentWorkspaceNavCreateBoardButton = memo(() => {
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canCreateRootBoards = canCreateBoard(permissions);
  const [showCreateBoardModal] = useAirModal(CreateBoardModal);

  if (!canCreateRootBoards) {
    return null;
  }

  return (
    <Button
      appearance="ghost"
      className="w-full justify-start"
      color="grey"
      data-testid={CREATE_BOARD_BUTTON_END}
      data-intercomid={intercomIDs.CURRENT_WORKSPACE_NAV_CREATE_NEW_BOARD}
      onClick={() => {
        showCreateBoardModal({
          selectedParentBoard: BOARDS_SELECT_EMPTY_OPTION,
          trackLocation: 'side-nav-extension-end-button',
        });
      }}
      prefix={<PlusIcon className="h-4 w-4" />}
      size="medium"
    >
      New board
    </Button>
  );
});

CurrentWorkspaceNavCreateBoardButton.displayName = 'CreateBoardButtonEnd';
