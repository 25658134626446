import { Upload } from '@air/redux-uploader';
import { useCallback } from 'react';

import { metadataForPrivateUploadSelector } from '~/store/privateUploadMetadata/selectors';
import { uploadingPathToBoardIdSelector } from '~/store/uploadingPathToBoardId/selector';
import { getUploadBoardIdUtil } from '~/store/uploadingPathToBoardId/utils';
import { useAirStore } from '~/utils/ReduxUtils';

export const useGetPrivateUploadBoardId = () => {
  const store = useAirStore();

  const getUploadBoardId = useCallback(
    (upload: Upload) => {
      const pathToBoardId = uploadingPathToBoardIdSelector(store.getState());
      const metadata = metadataForPrivateUploadSelector(store.getState(), upload.id);
      if (metadata) {
        return getUploadBoardIdUtil({
          batchId: upload.batchId,
          uploadMetadata: metadata,
          pathToBoardIdState: pathToBoardId,
        });
      }
    },
    [store],
  );

  return {
    getUploadBoardId,
  };
};
