import { GetNotificationSummaryResponse, ListNotificationsResponse } from '@air/api/types';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';

import { getNotificationSummaryKey } from '~/components/AirNotifications/hooks/useGetNotificationSummary';
import { getNotificationsKey } from '~/components/AirNotifications/hooks/useNotifications';
import { AccountNotificationsType } from '~/components/AirNotifications/utils/types';
import { useEventSubscription } from '~/hooks/useEventSubscription';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const useNotificationsEvent = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();

  useEventSubscription({
    currentWorkspaceId: currentWorkspace?.id,
    event: {
      eventType: 'System.Notification.created',
      pattern: {
        notification: {
          receiverType: 'account',
        },
      },
    },
    options: {
      onUpdate: (message) => {
        if (currentWorkspace?.id) {
          const unreadKey = getNotificationSummaryKey(currentWorkspace?.id);
          if (unreadKey) {
            queryClient.setQueryData<GetNotificationSummaryResponse | undefined>(unreadKey, (data) => {
              if (data && !!data.unseen) {
                const newUnseen = data.unseen + 1;

                return {
                  ...data,
                  unseen: newUnseen,
                };
              } else {
                return {
                  unread: {
                    all: 1,
                    shortUrl: 0,
                    mentions: 0,
                    following: 0,
                  },
                  unseen: 1,
                };
              }
            });
          }

          queryClient.setQueryData<InfiniteData<ListNotificationsResponse> | undefined>(
            getNotificationsKey(currentWorkspace?.id),
            (data) => {
              if (!data) return;
              if (message.data.notification.receiverType === 'account') {
                const notification = message.data.notification as AccountNotificationsType;

                return produce(data, (draft) => {
                  if (draft.pages[0]) {
                    draft.pages[0] = {
                      ...draft.pages[0],
                      items: [notification, ...draft.pages[0].items],
                    };
                  }
                });
              }
              return data;
            },
          );
        }
      },
    },
  });
};
