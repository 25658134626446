import { CustomFieldColor, CustomFieldType } from '@air/api/types';
import { useFormikContext } from 'formik';
import { memo, useEffect } from 'react';

import { CustomFieldModalFormAPIInformation } from '~/components/CustomFields/CustomFieldModal/CustomFieldModalForm/CustomFieldModalFormAPIInformation';
import { initialFormValue } from '~/components/CustomFields/initialCustomFieldFormData';
import { getIsFieldTypeSelectable } from '~/swr-hooks/customFields/utils';

import { CustomFieldModalFormBaseFields } from './CustomFieldModalFormBaseFields/CustomFieldModalFormBaseFields';
import { CustomFieldModalFormContainer } from './CustomFieldModalFormContainer';
import { CustomFieldModalFormOptionFields } from './CustomFieldModalFormOptionFields';
import { CustomFieldModalFormData } from './types';

const EDIT_MODAL_DESCRIPTION = 'Once saved, these changes will be applied across your workspace.';
const CREATE_MODAL_DESCRIPTION =
  'Create a single-select, multi-select, plain-text, or date custom field below. The color and value of each option can be customized. Once saved, this field will be available on every asset in your workspace.';

export interface CustomFieldModalFormProps {
  modalType: 'edit' | 'create';
  colors: CustomFieldColor[];
  fieldTypes: CustomFieldType[];
  setIsSaveDisabled: (isSaveDisabled: boolean) => void;
  setIsSaveFocused: (isSaveFocused: boolean) => void;
  setIsEditingCustomFieldOptions?: (isEditingCFOptions: boolean) => void;
}

export const CustomFieldModalForm = memo(
  ({
    modalType,
    colors,
    fieldTypes,
    setIsSaveDisabled,
    setIsSaveFocused,
    setIsEditingCustomFieldOptions,
  }: CustomFieldModalFormProps) => {
    const { values, setValues } = useFormikContext<CustomFieldModalFormData>();

    const isFieldTypeSelectable = getIsFieldTypeSelectable({ fieldTypeId: values.fieldTypeId, fieldTypes });

    useEffect(() => {
      if (isFieldTypeSelectable && !values.values) {
        // we need to create initial values
        setValues({ ...values, values: initialFormValue.values });
      } else if (!isFieldTypeSelectable && !!values.values) {
        // we need to remove the values
        setValues({ ...values, values: undefined });
      }
    }, [setValues, values, isFieldTypeSelectable]);

    return (
      <CustomFieldModalFormContainer innerScroll={isFieldTypeSelectable}>
        <CustomFieldModalFormBaseFields
          canEditFieldType={modalType === 'create'}
          fieldTypes={fieldTypes}
          handleDisableSave={setIsSaveDisabled}
          modalDescription={modalType === 'edit' ? EDIT_MODAL_DESCRIPTION : CREATE_MODAL_DESCRIPTION}
        />
        {isFieldTypeSelectable && (
          <CustomFieldModalFormOptionFields
            handleFocusSave={setIsSaveFocused}
            colors={colors}
            handleEditingOptions={setIsEditingCustomFieldOptions}
            handleDisableSave={setIsSaveDisabled}
          />
        )}
        <CustomFieldModalFormAPIInformation />
      </CustomFieldModalFormContainer>
    );
  },
);
CustomFieldModalForm.displayName = 'CustomFieldModalForm';
