import { type ResponseCustomFieldValue } from '@air/api/types';
import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';

export const notificationCustomFieldValue = tailwindVariants({
  base: 'rounded px-1.5 py-0.5 text-12 font-medium',
  variants: {
    state: {
      set: 'no-underline opacity-100',
      unset: 'line-through opacity-40',
    },
  },
  defaultVariants: {
    state: 'set',
  },
});

export type NotificationCustomFieldValueVariants = VariantProps<typeof notificationCustomFieldValue>;

export type NotificationCustomFieldValueProps = NotificationCustomFieldValueVariants & {
  value: ResponseCustomFieldValue;
};

export const NotificationCustomFieldSingleSelectValue = ({ state, value }: NotificationCustomFieldValueProps) => {
  return (
    <div
      className={notificationCustomFieldValue({ state })}
      data-background-color={value.color.backgroundHex}
      data-color={value.color.fontHex}
      data-id={value.id}
      data-state={state}
      style={{
        backgroundColor: value.color.backgroundHex,
        color: value.color.fontHex,
      }}
    >
      {value.value}
    </div>
  );
};
