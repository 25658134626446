import { Avatar } from '@air/primitive-avatar';
import { ReactNode } from 'react';

import { LibraryMemberListItem } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/components/LibraryMemberListItem';

interface LibraryPendingMemberListItemProps<Member extends { email: string }> {
  member: Member;
  renderSuffix?: (member: Member) => ReactNode;
}

export const LibraryPendingMemberListItem = <Member extends { email: string }>({
  member,
  renderSuffix,
}: LibraryPendingMemberListItemProps<Member>) => {
  return (
    <LibraryMemberListItem
      email={member.email}
      prefix={
        <Avatar alt={member.email} appearance="circle" colorSeed={member.email} size={40} text={member.email[0]} />
      }
      name={member.email}
      suffix={renderSuffix?.(member)}
    />
  );
};
