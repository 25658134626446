import { Clips } from '@air/api';
import { Board, Clip, ClipSource } from '@air/api/types';
import { Upload } from '@air/redux-uploader';

import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface GetSmallPrivateUploadUrlsParams {
  apiUploadData: Upload['apiUploadData'];
  assetId?: Clip['assetId'];
  parentBoardId?: Board['id'];
  workspaceId: string;
}

export const fetchSmallPrivateUploadUrl = async ({
  apiUploadData,
  parentBoardId,
  assetId,
  workspaceId,
}: GetSmallPrivateUploadUrlsParams) => {
  try {
    const urls = await Clips.getUploadUrls({
      workspaceId,
      options: {
        files: [apiUploadData],
        source: ClipSource.web,
        boardId: parentBoardId,
        assetId,
      },
    });
    return urls[0];
  } catch (error) {
    throw reportErrorToBugsnag({
      error,
      context: `Failed to get private small upload url`,
      metadata: {
        data: {
          apiUploadData,
        },
      },
    });
  }
};
