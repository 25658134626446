import { Modal } from '@air/primitive-modal';
import Router from 'next/router';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import type { CreateDeleteLibraryTaskParams } from '~/components/DeleteLibraryTasks/hooks/userCreateDeleteLibraryTask';
import { useCreateDeleteLibraryTask } from '~/components/DeleteLibraryTasks/hooks/userCreateDeleteLibraryTask';
import { LibraryDeleteAssetsModalFooter } from '~/components/LibraryBeta/LibraryDeleteAssetsModal/components/LibraryDeleteAssetsModalFooter';
import { LibraryDeleteAssetsModalHeader } from '~/components/LibraryBeta/LibraryDeleteAssetsModal/components/LibraryDeleteAssetsModalHeader';
import { Routes } from '~/constants/routes';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { centralizedBoardLibrary } from '~/store/centralizedBoard/selectors';
import { convertUnknownToError } from '~/utils/ErrorUtils';
import { getLibraryIdFromPath } from '~/utils/PathUtils';

export type LibraryDeleteAssetsModalProps = {
  libraryId: string;
  name: string;
};

export const LibraryDeleteAssetsModal = ({
  libraryId,
  name,
  onClose,
}: AirModalProps<LibraryDeleteAssetsModalProps>) => {
  const { showLibraryToast } = useLibraryToasts();
  const { createDeleteLibraryTask } = useCreateDeleteLibraryTask();
  const [isPending, setIsPending] = useState(false);
  const currentLibraryInBoardView = useSelector(centralizedBoardLibrary);

  const handleCreateDeleteLibraryTask = useCallback(
    async (params: CreateDeleteLibraryTaskParams) => {
      try {
        setIsPending(true);
        await createDeleteLibraryTask(params);
        const libraryIdInUrl = getLibraryIdFromPath(window.location.pathname);
        if (libraryIdInUrl === libraryId || currentLibraryInBoardView?.id === libraryId) {
          Router.push(Routes.media.all);
        }
        onClose();
      } catch (error) {
        showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' });
      }

      setIsPending(false);
    },
    [createDeleteLibraryTask, currentLibraryInBoardView?.id, libraryId, onClose, showLibraryToast],
  );

  return (
    <Modal isOpen onDismiss={onClose}>
      <LibraryDeleteAssetsModalHeader name={name} onClose={onClose} />

      <LibraryDeleteAssetsModalFooter
        loading={isPending}
        onDelete={(params) => handleCreateDeleteLibraryTask({ ...params, libraryId })}
      />
    </Modal>
  );
};
