import { useEffect } from 'react';

import { getStorageMeterColor } from '~/components/CurrentWorkspaceNav/utils';
import { useShouldShowStorageUsage } from '~/swr-hooks/subscriptions/useShouldShowStorageUsage';
import { useStorage } from '~/swr-hooks/subscriptions/useStorage';
import { getUsedStoragePercentage } from '~/utils/WorkspaceUtils';

import { WorkspaceStorageMeter } from './WorkspaceStorageMeter';

export const CurrentWorkspaceStorageMeter = () => {
  const { data: storage, refetch } = useStorage();
  const { shouldShowStorageUsage } = useShouldShowStorageUsage();
  const percentOfStorageUsed = getUsedStoragePercentage(storage?.usedStorage, storage?.maxStorage);
  const progressColor = getStorageMeterColor(percentOfStorageUsed || 0, storage?.isOutOfStorage || false);

  /**
   * Whenever they open this popover, we want to refetch the storage data
   * to make sure we have the latest info but we don't want to do update the
   * stale time cause we don't want other places to fetch it
   *
   * @see https://air-labs-team.slack.com/archives/C018C7U0DQU/p1690572803551579
   */
  useEffect(() => {
    refetch();
  }, [refetch]);

  return shouldShowStorageUsage ? (
    <WorkspaceStorageMeter
      progressColor={progressColor}
      formattedMaxBytes={storage?.formattedMaxBytes}
      formattedUsedBytes={storage?.formattedUsedBytes}
      percentOfStorageUsed={percentOfStorageUsed}
      showPercentage
    />
  ) : null;
};
