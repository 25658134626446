import { Notification, ShortUrlObjectType } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';
import pluralize from 'pluralize';
import { useMemo } from 'react';

import { NotificationAvatar } from '~/components/AirNotifications/components/NotificationAvatar';
import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { NotificationClip } from '~/components/AirNotifications/components/NotificationClip';
import { NotificationClips } from '~/components/AirNotifications/components/NotificationClips';

export type NotificationClipDownloadedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification: Notification<'clipDownloaded_userIsShareLinkCreator', 'account'>;
};

export const NotificationClipDownloadedType = ({ notification, onClick }: NotificationClipDownloadedTypeProps) => {
  const { actor, clips, count, shortUrl } = notification.data;

  const actionText = useMemo(() => {
    if (clips.length === 0) {
      return '';
    }

    if (clips.length > 1) {
      return pluralize('item', clips.length, true);
    }

    return clips[0].title + (clips[0].ext ? `.${clips[0].ext}` : '');
  }, [clips]);

  const hasBoardName = shortUrl.objectType === ShortUrlObjectType.board;

  return (
    <NotificationBaseItem
      content={clips.length > 1 ? <NotificationClips clips={clips} totalClips={clips.length} /> : undefined}
      meta="via share link"
      notification={notification}
      onClick={onClick}
      suffix={clips.length === 1 ? <NotificationClip clip={clips[0]} /> : undefined}
      prefix={count > 1 ? <NotificationAvatar actor={null} /> : <NotificationAvatar actor={actor} />}
      title={
        <>
          <b>{count > 1 ? pluralize('people', count, true) : formatActorNameInitialsOrDefault(actor)}</b>
          {` downloaded `}
          <b>{actionText}</b>
          {hasBoardName && (
            <>
              {` in `}
              {shortUrl.objectName}
            </>
          )}
        </>
      }
    />
  );
};
