import { ViewTypeName } from '@air/api/types';
import { isNull } from 'lodash';
import { CSSProperties, useEffect, useMemo, useRef } from 'react';
import { useDragLayer } from 'react-dnd';
import { useSelector } from 'react-redux';

import dragTypes, { DragContainerIds } from '~/components/Shared/Drag/dragTypes';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';

import { GalleryItemsDragPreview } from './GalleryItemsDragPreview';
import { NavBoardDragPreview } from './NavBoardDragPreview';
import { TableItemsDragPreview } from './TableItemsDragPreview';
import { useDragScrolling } from './useDragScrolling';
import { WorkspaceDragPreview } from './WorkspaceDragPreview';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
};

export const ReactDndDragLayer = () => {
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);
  const dragItem = useRef<HTMLDivElement>(null);
  const requestRef = useRef<number | null>(null);
  const isDraggingRef = useRef<boolean | null>(null);
  const offsetRef = useRef<{ x: number; y: number } | null>(null);

  const { itemType, isDragging, item, clientOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));

  offsetRef.current = clientOffset;

  /**
   * TODO: We shouldn't be assigning a specific drag type to a specific scroll container.
   * Ideally a user can drag any type over any scrollable container in our application and
   * it will cause that container to scroll
   */
  const dragContainer = useMemo(() => {
    if (typeof document === 'undefined') {
      return null;
    }

    switch (itemType) {
      case dragTypes.boardNavFavorites:
      case dragTypes.boardNavLibrary:
        return document.getElementById(DragContainerIds['navBoard']);
      case dragTypes.workspace:
        return document.getElementById(DragContainerIds['workspace']);
      case dragTypes.version:
        return document.getElementById(DragContainerIds['versions']);
      default:
        return document.getElementById(DragContainerIds['other']);
    }
  }, [itemType]);

  useDragScrolling({ scrollElement: dragContainer ?? undefined });

  const moveItem = () => {
    if (!!dragItem.current && offsetRef.current) {
      const { x, y } = offsetRef.current;
      const transform = `translate(${x}px, ${y}px)`;
      dragItem.current.style.transform = transform;
      dragItem.current.style.webkitTransform = transform;
      if (dragItem.current.style.display !== 'block') {
        dragItem.current.style.display = 'block';
      }
    }
    requestRef.current = requestAnimationFrame(moveItem);
  };

  useEffect(() => {
    if (isNull(isDraggingRef.current)) {
      isDraggingRef.current = isDragging;
    }
    if (isDraggingRef.current !== isDragging && isDragging) {
      // Started dragging.
      requestRef.current = requestAnimationFrame(moveItem);
    } else if (isDraggingRef.current !== isDragging && !isDragging) {
      // Stopped dragging.
      if (dragItem.current) {
        dragItem.current.style.display = 'none';
        dragItem.current.style.transform = 'none';
        dragItem.current.style.webkitTransform = 'none';
      }
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    }
    isDraggingRef.current = isDragging;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  useEffect(() => {
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, []);

  const itemToRender = useMemo(() => {
    switch (itemType) {
      case dragTypes.boardNavWorkspace:
      case dragTypes.boardNavFavorites:
      case dragTypes.boardNavLibrary:
        return <NavBoardDragPreview board={item} />;
      case dragTypes.boardCard:
      case dragTypes.asset:
      case dragTypes.file:
        return currentViewTypeName === ViewTypeName.gallery ? (
          <GalleryItemsDragPreview items={item} />
        ) : (
          <TableItemsDragPreview items={item} />
        );
      case dragTypes.workspace:
        return <WorkspaceDragPreview item={item} />;
      default:
        return null;
    }
  }, [itemType, item, currentViewTypeName]);

  return useMemo(() => {
    if (!isDragging) return null;
    return (
      <div style={layerStyles}>
        <div ref={dragItem}>{itemToRender}</div>
      </div>
    );
  }, [isDragging, itemToRender]);
};
