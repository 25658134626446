import { Workspaces } from '@air/api';
import { BetweenWorkspaceMembersInput, WorkspaceListResponse } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { useCallback } from 'react';

import { DragWorkspaceItem, RearrangableItem } from '~/components/Shared/Drag/dragTypes';
import { useAccountContext } from '~/providers/AccountProvider';
import { getWorkspacesListKey, useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';
import { moveItemToIndex } from '~/utils/ArrayUtils';
import { calculateRearrangeData } from '~/utils/DragUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export function useRearrangeWorkspacePosition() {
  const { data: account } = useAccountContext();
  const { data: workspaces } = useWorkspaces();
  const queryClient = useQueryClient();

  const rearrangeWorkspacePosition = useCallback(
    async (draggedItem: DragWorkspaceItem, adjacentItem: RearrangableItem) => {
      if (!account || !workspaces) {
        return;
      }

      const userId = account.id;

      const { newIndex, nextItemId, previousItemId } = calculateRearrangeData(workspaces, draggedItem, adjacentItem);

      const betweenWorkspaceIds: BetweenWorkspaceMembersInput = {};

      if (!!previousItemId) {
        betweenWorkspaceIds.moveBeforeId = {
          workspaceId: previousItemId,
          userId,
        };
      }

      if (!!nextItemId) {
        betweenWorkspaceIds.moveAfterId = {
          workspaceId: nextItemId,
          userId,
        };
      }

      queryClient.setQueryData(
        getWorkspacesListKey(),
        (workspaces?: WorkspaceListResponse): WorkspaceListResponse =>
          workspaces
            ? produce(workspaces, (draft) => {
                moveItemToIndex(draft, draggedItem.index, newIndex);
              })
            : [],
      );

      try {
        await Workspaces.updateAccountWorkspacePositions({
          idsToMove: [{ userId, workspaceId: draggedItem.id }],
          betweenIds: betweenWorkspaceIds,
        });
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: `Could not update workspace positions`,
          metadata: {
            data: {
              workspaceId: draggedItem.id,
              betweenWorkspaceIds,
            },
          },
        });
      } finally {
        queryClient.invalidateQueries({ queryKey: getWorkspacesListKey() });
      }
    },
    [account, queryClient, workspaces],
  );

  return {
    rearrangeWorkspacePosition,
  };
}
