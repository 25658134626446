import { isIntercomLauncherVisible, subscribeToIntercomLauncherVisibility } from '@air/analytics';
import { hasSelectedItemsSelector } from '@air/redux-selected-items';
import { hasUploadsSelector } from '@air/redux-uploader';
import { useIsLoggedIn } from '@air/utils-auth';
import { useWindowSize } from '@react-hook/window-size';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { INTERCOM_LAUNCHER_HEIGHT } from '~/constants/intercom';
import {
  SELECTION_ACTION_BAR_ID,
  SELECTION_BAR_ANIMATION_SECONDS,
  SELECTION_BAR_DELAY,
} from '~/constants/selectionActionBar';

const START_BOTTOM_POS = 16;

const initialWidth = typeof window === 'undefined' ? 0 : window.innerWidth;
const initialHeight = typeof window === 'undefined' ? 0 : window.innerHeight;

/**
 * This hook is used to determine bottom position for uploader pane.
 * Putting UploadManager in PopupProvider gives a lot of problems:
 * - UploadManager needs access to redux - it is in AppProviderLayout, not in _app, where PopupProvider is currently placed
 * - PopupProvider can not be moved to AppProviderLayout, because pages like login.tsx use toasts
 * - store provider could be potentially moved to _app - but won't it break anything? This may be task for dev week
 * - Moving UploadManager to PopupProvider will cause moving it up when toast appears even if it doesn't overlap
 * - Moving UploadManager to PopupProvider will cause moving it up when selection bar appears even if it doesn't overlap
 */
export const useFileTrackingPaneBottomPos = () => {
  const [bottomPos, setBottomPos] = useState(START_BOTTOM_POS);
  const [windowWidth] = useWindowSize({
    initialWidth,
    initialHeight,
    wait: 100,
  });
  const uploaderRef = useRef<HTMLDivElement>(null);
  const checkBottomPosTaskRef = useRef(0);
  const { isLoggedIn } = useIsLoggedIn();
  const hasSelectedItems = useSelector(hasSelectedItemsSelector);
  const hasUploads = useSelector(hasUploadsSelector);

  const checkBottomPosTask = useCallback(() => {
    const selectionBar = document.getElementById(SELECTION_ACTION_BAR_ID);
    if (selectionBar && uploaderRef.current) {
      const selectionRect = selectionBar.getBoundingClientRect();
      const uploaderRect = uploaderRef.current?.getBoundingClientRect();

      if (selectionRect.right >= uploaderRect.left) {
        setBottomPos(START_BOTTOM_POS + (selectionRect.bottom - selectionRect.y) + 10);
      } else if (bottomPos !== START_BOTTOM_POS) {
        setBottomPos(START_BOTTOM_POS);
      }
    } else if (bottomPos !== START_BOTTOM_POS) {
      setBottomPos(START_BOTTOM_POS);
    }
  }, [bottomPos]);

  useEffect(() => {
    const callback = subscribeToIntercomLauncherVisibility((isVisible) => {
      isVisible ? setBottomPos(START_BOTTOM_POS + INTERCOM_LAUNCHER_HEIGHT) : checkBottomPosTask();
    });

    return () => {
      callback.unsubscribe();
    };
  }, [checkBottomPosTask]);

  useEffect(() => {
    // intercom launcher is high enough to move it above selection bar
    if (isIntercomLauncherVisible()) {
      setBottomPos(START_BOTTOM_POS + INTERCOM_LAUNCHER_HEIGHT);
    } else {
      checkBottomPosTaskRef.current = setTimeout(
        checkBottomPosTask,
        SELECTION_BAR_DELAY + SELECTION_BAR_ANIMATION_SECONDS * 1000,
      );
    }

    return () => {
      clearTimeout(checkBottomPosTaskRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, isLoggedIn, hasSelectedItems, hasUploads]);

  return {
    uploaderRef,
    bottomPos,
  };
};
