import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { Filters, Info, Pencil, Plus } from '@air/next-icons';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { useAirModal } from '@air/provider-modal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Router from 'next/router';
import { useMemo } from 'react';

import { CreateSavedFilterModal } from '~/components/SavedFilter/CreateSavedFilterModal';
import { EditSavedFilterModal } from '~/components/SavedFilter/EditSavedFilterModal';
import { useGetSavedFilters } from '~/components/SavedFilter/shared/hooks/useGetSavedFilters';
import { createSavedFilterRoute } from '~/constants/routes';
import { SIDEBAR_NAVIGATION_ITEM_SAVED_FILTERS, SIDEBAR_NAVIGATION_SAVED_FILTERS_EDIT } from '~/constants/testIDs';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canCreateSavedFilters, canEditSavedFilters } from '~/utils/permissions/workspacePermissions';

export const SavedFiltersNavigationItem = () => {
  const { savedFilters: hasSavedFiltersFeatureFlag } = useFlags();
  const { data: savedFiltersResponse } = useGetSavedFilters();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canAdd = canCreateSavedFilters(permissions);
  const canEdit = canEditSavedFilters(permissions);
  const [showCreateSavedFilterModal] = useAirModal(CreateSavedFilterModal);
  const [showEditSavedFilterModal] = useAirModal(EditSavedFilterModal);
  const numberOfSavedFilters = savedFiltersResponse?.data?.length ?? 0;

  const dropdownMenuOptions = useMemo<DropdownMenuOption[]>(() => {
    const options: DropdownMenuOption[] = [];

    if (!!savedFiltersResponse?.data?.length) {
      savedFiltersResponse?.data?.map((savedFilter) =>
        options.push({
          id: savedFilter.name,
          label: savedFilter.name,
          onSelect: () => Router.push(createSavedFilterRoute(savedFilter.id)),
          prefix: savedFilter.icon,
          suffix: canEdit && (
            <IconButton
              appearance="ghost"
              color="grey"
              data-id={savedFilter.id}
              data-name={savedFilter.name}
              data-testid={SIDEBAR_NAVIGATION_SAVED_FILTERS_EDIT}
              icon={Pencil}
              label="Edit"
              onClick={(event) => {
                event.preventDefault();
                showEditSavedFilterModal({ savedFilter });
              }}
              size="small"
            />
          ),
          type: 'item',
        }),
      );
    }

    if (canAdd) {
      options.push({
        id: 'create',
        label: 'Create saved filter',
        onSelect: () =>
          showCreateSavedFilterModal({
            filters: {},
            trackLocation: 'side-nav-saved-filters',
          }),
        prefix: <Plus className="h-4 w-4" />,
        suffix: (
          <Tooltip
            className="max-w-[240px]"
            label="Saved filters appear here for all workspace members and auto-update as new content fits their criteria."
          >
            <Info className="h-4 w-4" />
          </Tooltip>
        ),
        type: 'item',
      });
    }

    return options;
  }, [canAdd, canEdit, savedFiltersResponse?.data, showCreateSavedFilterModal, showEditSavedFilterModal]);

  if (!(hasSavedFiltersFeatureFlag && (canAdd || !!savedFiltersResponse?.data.length))) {
    return null;
  }

  return (
    <DropdownMenu
      alignOffset={32}
      align="start"
      className="max-h-[320px] w-[240px] overflow-auto"
      trigger={
        <SidebarNavigationItem
          data-testid={SIDEBAR_NAVIGATION_ITEM_SAVED_FILTERS}
          prefix={<Filters className="h-4 w-4" />}
        >
          Saved filters
          {!!numberOfSavedFilters && ` (${numberOfSavedFilters})`}
        </SidebarNavigationItem>
      }
    >
      {renderDropdownItems({ options: dropdownMenuOptions })}
    </DropdownMenu>
  );
};
