import { Skeleton } from '@air/component-skeleton';

export const LibraryMemberListItemSkeleton = () => {
  return (
    <div className="flex gap-3">
      <div className="shrink-0">
        <Skeleton height={40} width={40} variant="round" />
      </div>

      <div className="flex min-w-0 grow flex-col gap-0.5">
        <Skeleton height={20} isText width={80} />
        <Skeleton height={18} isText width={120} />
      </div>
    </div>
  );
};
