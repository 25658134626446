import { Library, WorkspaceMemberRoleDisplayName, WorkspaceMemberType } from '@air/api/types';
import { useMemo } from 'react';

import { useLibraryUpdateMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryUpdateMutation';
import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import {
  LibraryAdvancedSettingsDefaultRole,
  LibraryAdvancedSettingsDefaultRoleProps,
} from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsDefaultRole';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useWorkspaceRoles } from '~/swr-hooks/workspaces/useWorkspaceRoles';
import { convertUnknownToError } from '~/utils/ErrorUtils';

export type LibraryAdvancedSettingsDefaultRoleContainerProps = {
  libraryId: Library['id'];
  onChangeRole?: () => void;
};

export const LibraryAdvancedSettingsDefaultRoleContainer = ({
  libraryId,
  onChangeRole,
}: LibraryAdvancedSettingsDefaultRoleContainerProps) => {
  const { data: library } = useLibrary({ libraryId });
  const { libraryUpdateMutation } = useLibraryUpdateMutation({ libraryId });
  const { data: roles } = useWorkspaceRoles();
  const { showLibraryToast } = useLibraryToasts();

  const selectedRole = useMemo<LibraryAdvancedSettingsDefaultRoleProps['selectedRole']>(() => {
    if (!library?.defaultRoleId || !roles) return undefined;

    const currentRole = roles.find((role) => role.id === library.defaultRoleId);

    return {
      id: library?.defaultRoleId,
      label: currentRole?.displayName.replace('Library ', ''),
    };
  }, [library, roles]);

  const filteredRoles = useMemo<LibraryAdvancedSettingsDefaultRoleProps['roles']>(() => {
    if (!roles) return [];

    return roles
      .filter(
        (role) =>
          role.type === WorkspaceMemberType.libraryMember &&
          role.displayName !== WorkspaceMemberRoleDisplayName.LibraryOwner,
      )
      .map((role) => ({
        description: role.description || undefined,
        id: role.id,
        label: role.displayName.replace('Library ', ''),
        onSelect: () => {
          libraryUpdateMutation.mutate(
            { defaultRoleId: role.id },
            {
              onError: (error) => showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
              onSuccess: (data) => {
                const role = roles.find((role) => role.id === data.defaultRoleId);

                showLibraryToast({
                  message: role?.displayName ? (
                    <>
                      Default role has been set to <b className="text-jay-200">{role.displayName}</b>.
                    </>
                  ) : (
                    'Default role has been changed.'
                  ),
                  type: 'info',
                });
              },
            },
          );

          onChangeRole?.();
        },
        type: 'item',
      }));
  }, [libraryUpdateMutation, onChangeRole, roles, showLibraryToast]);

  return (
    <LibraryAdvancedSettingsDefaultRole
      isLoading={libraryUpdateMutation.isPending}
      selectedRole={selectedRole}
      roles={filteredRoles}
    />
  );
};
