import { Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { Check, Close } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { formatActorNameInitialsOrDefault, formatFullName } from '@air/utilities';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { useLibraryApproveMemberMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryMemberApproveMutation';
import { useLibraryMembersDenyMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryMemberDenyMutation';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { convertUnknownToError } from '~/utils/ErrorUtils';

export type NotificationLibraryMemberRequestedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification: Notification<'libraryMemberInvited_userIsAdmin', 'account'>;
  onApprove: () => void;
  onDeny: () => void;
};

export const NotificationLibraryMemberRequestedType = ({
  notification,
  onApprove,
  onClick,
  onDeny,
}: NotificationLibraryMemberRequestedTypeProps) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { showLibraryToast } = useLibraryToasts();
  const { libraryMembersDenyMutation } = useLibraryMembersDenyMutation({
    libraryId: notification.data.library.id,
  });
  const { libraryMemberApproveMutation } = useLibraryApproveMemberMutation({
    libraryId: notification.data.library.id,
  });

  /**
   * If the actor.id is not the same as the libraryMember.id, then we know that the
   * actor is inviting the libraryMember to the library.
   */
  const isMemberInvite = notification.data.actor.id !== notification.data.libraryMember.id;

  return (
    <NotificationBaseItem
      content={
        /**
         * We handle the showing and hiding of buttons based on the readAt property
         * because we only mark the notifications as read when the user approves or
         * denies the join request.
         */
        !notification.readAt && (
          <div className="relative z-10 flex gap-2">
            <Button
              appearance="outline"
              color="grey"
              isLoading={libraryMembersDenyMutation.isPending}
              onClick={() => {
                const fullName = formatFullName(
                  notification.data.libraryMember.firstName,
                  notification.data.libraryMember.lastName,
                );

                libraryMembersDenyMutation.mutate([{ accountId: notification.data.libraryMember.id }], {
                  onError: (error) =>
                    showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
                  onSuccess: () => {
                    onDeny?.();
                    showLibraryToast({
                      message: (
                        <>
                          Denied <b className="text-jay-200">{fullName}</b> join request.
                        </>
                      ),
                    });
                  },
                });
              }}
              prefix={<Close className="h-3 w-3" />}
              size="small"
            >
              Deny
            </Button>
            <Button
              appearance="outline"
              color="grey"
              isLoading={libraryMemberApproveMutation.isPending}
              onClick={() => {
                const fullName = formatFullName(
                  notification.data.libraryMember.firstName,
                  notification.data.libraryMember.lastName,
                );

                libraryMemberApproveMutation.mutate(
                  { accountId: notification.data.libraryMember.id },
                  {
                    onError: (error) =>
                      showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
                    onSuccess: () => {
                      onApprove?.();
                      showLibraryToast({
                        message: (
                          <>
                            Approved <b className="text-jay-200">{fullName}</b> join request.
                          </>
                        ),
                      });
                    },
                  },
                );
              }}
              prefix={<Check className="h-3 w-3" />}
              size="small"
            >
              Approve
            </Button>
          </div>
        )
      }
      meta={!!currentWorkspace?.name && `in ${currentWorkspace?.name}`}
      notification={notification}
      onClick={!!notification.readAt ? onClick : undefined}
      title={
        <>
          <b>{formatActorNameInitialsOrDefault(notification.data.actor)}</b>

          {isMemberInvite ? (
            <>
              {` has invited `}
              <b>
                {formatActorNameInitialsOrDefault({
                  firstName: notification.data.libraryMember.firstName,
                  lastName: notification.data.libraryMember.lastName,
                })}
              </b>
            </>
          ) : (
            ` has requested`
          )}
          {` to join `}
          <b>{notification.data.library.title}</b>
        </>
      }
    />
  );
};
