import { Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';
import pluralize from 'pluralize';
import { ReactNode, useMemo } from 'react';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { NotificationBoard } from '~/components/AirNotifications/components/NotificationBoard';
import { NotificationClip } from '~/components/AirNotifications/components/NotificationClip';
import { NotificationDownloadedItems } from '~/components/AirNotifications/components/NotificationDownloadedItems';

export type NotificationItemsDownloadedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification: Notification<'itemsDownloaded_userIsShareLinkCreator', 'account'>;
};

export const NotificationItemsDownloadedType = ({ notification, onClick }: NotificationItemsDownloadedTypeProps) => {
  const { actor, boards, downloads, downloadsCount, shortUrl, clips } = notification.data;

  const totalItemsCount = downloads.reduce((acc, download) => acc + download.clipsCount + download.boardsCount, 0);

  const actionText = useMemo(() => {
    let action: ReactNode = null;

    if (downloads.length === 0) {
      action = 'downloaded';
    } else if (downloads.length > 1) {
      action = (
        <>
          downloaded <b>{totalItemsCount} items </b>
          {downloadsCount} times
        </>
      );
    } else {
      const download = downloads[0];

      // The case where someone downloads the entire board share link
      if (boards.length === 1 && shortUrl.objectId === boards[0].id) {
        action = (
          <>
            downloaded <b>{shortUrl.objectName}</b>
          </>
        );
      } else {
        const boards = download.boardsCount > 0 ? `${pluralize('sub-board', download.boardsCount, true)}` : '';
        const assets = download.clipsCount > 0 ? `${pluralize('asset', download.clipsCount, true)}` : '';

        action = (
          <>
            downloaded <b>{boards}</b>
            {boards.length && assets.length ? ', ' : ''}
            <b>{assets}</b>
          </>
        );

        /* If we decide to ever show the full file count in the downloaded zip:
        const total =
          download.boardsCount > 1
            ? `(total ${download.clipsTotal} ${pluralize('asset', download.clipsTotal, true)})`
            : '';
        action = `downloaded ${boards}${boards.length ? ', ' : ''}${assets}${total}`;
        */
      }
    }

    return action;
  }, [boards, downloads, downloadsCount, shortUrl.objectId, shortUrl.objectName, totalItemsCount]);

  const hasOnlyOneClip = clips.length === 1 && boards.length === 0;
  const hasOnlyOneBoard = clips.length === 0 && boards.length === 1;

  const suffix = useMemo(() => {
    if (hasOnlyOneClip) {
      return <NotificationClip clip={clips[0]} />;
    } else if (hasOnlyOneBoard) {
      return <NotificationBoard board={boards[0]} />;
    }
  }, [boards, clips, hasOnlyOneBoard, hasOnlyOneClip]);

  const content = useMemo(() => {
    const hasMoreThanOneItem = clips.length > 1 || boards.length > 1;
    if ((!hasOnlyOneClip && !hasOnlyOneBoard && hasMoreThanOneItem) || downloadsCount > 1) {
      return <NotificationDownloadedItems clips={clips} boards={boards} totalCount={totalItemsCount} />;
    }
  }, [boards, clips, downloadsCount, hasOnlyOneBoard, hasOnlyOneClip, totalItemsCount]);

  return (
    <NotificationBaseItem
      content={content}
      notification={notification}
      suffix={suffix}
      meta={!!shortUrl.objectName && `in ${shortUrl.objectName}`}
      onClick={onClick}
      title={
        <>
          <b>{formatActorNameInitialsOrDefault(actor)}</b>
          &nbsp;
          {actionText}
        </>
      }
    />
  );
};
