import { useWorkspaceMembers } from './useWorkspaceMembers';

export const useBillableUsersCount = () => {
  const { members } = useWorkspaceMembers();

  if (!members) {
    return {
      totalBillableMembers: 0,
      totalBillableGuests: 0,
      totalBillableSeats: 0,
    };
  }

  const billableMembersEmails = new Set(members.billableMembers.map((m) => m.email));

  // Guests with 'Can Comment' or 'Can Edit' permissions are billable.
  const nonMemberBillableGuests = members.billableGuests.filter((m) => !billableMembersEmails.has(m.email));

  const totalBillableMembers = members.billableMembers.length;
  const totalBillableGuests = nonMemberBillableGuests.length;
  const totalBillableSeats = totalBillableMembers + totalBillableGuests;

  return {
    totalBillableMembers,
    totalBillableGuests,
    totalBillableSeats,
  };
};
