import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { Meta } from '../types/docs';

const META: Meta = {
  name: 'clicked_libraries_cta',
  description: 'Clicked libraries CRA',
};

export const useTrackClickedLibrariesCTA = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedLibrariesCTA = useCallback(() => {
    track(META.name);
  }, [track]);

  return {
    trackClickedLibrariesCTA,
  };
};
