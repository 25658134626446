import { MemberAdd } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Tooltip } from '@air/primitive-tooltip';

export type LibraryAdvancedSettingsAssignOwnersProps = {
  onAssignOwners?: () => void;
};

export const LibraryAdvancedSettingsAssignOwners = ({ onAssignOwners }: LibraryAdvancedSettingsAssignOwnersProps) => {
  return (
    <div>
      <h3 className="mb-2 text-10 font-bold uppercase tracking-wide text-pigeon-500">Leave library</h3>
      <p className="mb-4 text-12 text-pigeon-500">Remove library from sidebar and my access to its contents</p>
      {onAssignOwners && (
        <Tooltip label="Assign another owner to leave library" side="right">
          <Button appearance="outline" color="grey" onClick={onAssignOwners} prefix={<MemberAdd className="h-4 w-4" />}>
            Assign owners
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
