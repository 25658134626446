import { NotificationShortUrl } from '@air/api/types';
import { useMemo } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

/**
 * This hook returns parent name of share url:
 * - for board shared links, it returns board name
 * - for assets links:
 *   - if shared link has boardContext lives in a board, it returns parent board name
 *   - if there is no boardContext, it returns current workspace name
 * @param shortUrl
 */
export const useShortUrlNotificationParentName = (shortUrl: NotificationShortUrl) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const shortUrlParentName = useMemo(() => {
    if (shortUrl.objectType === 'clip') {
      if (shortUrl.boardContext) {
        return shortUrl.boardContext.title;
      } else {
        return currentWorkspace?.name;
      }
    }
  }, [currentWorkspace?.name, shortUrl.boardContext, shortUrl.objectType]);

  return {
    shortUrlParentName,
  };
};
