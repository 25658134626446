import { isUndefined } from 'lodash';

import { useSubscriptionContext } from '~/providers/SubscriptionProvider';

import { useWorkspaceTotals } from './useWorkspaceTotals';

/**
 * This hook returns a boolean indicating if the max number of members has been reached in the workspace.
 * It returns undefined if the underlying allMembers data is undefined though (hasn't been loaded yet.)
 */
export function useReachedMemberLimit() {
  const { data: subscription } = useSubscriptionContext();
  const { data: totals } = useWorkspaceTotals();

  const UNLIMITED_MEMBERS = null;

  return {
    reachedMemberLimit:
      isUndefined(totals) || isUndefined(subscription)
        ? undefined
        : subscription.maxMembers === UNLIMITED_MEMBERS
        ? false
        : totals.memberCount >= subscription.maxMembers,
  };
}
