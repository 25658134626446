import { noop } from 'lodash';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { SIDEBAR_COLLAPSED } from '~/constants/localStorageKeys';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface SideNavContextValue {
  isSideNavOpen: boolean;
  toggleSideNav: () => void;
  openSideNav: () => void;
  closeSideNav: () => void;
}

const defaultValue: SideNavContextValue = {
  isSideNavOpen: false,
  toggleSideNav: noop,
  openSideNav: noop,
  closeSideNav: noop,
};

const SideNavProviderContext = createContext(defaultValue);

export const SideNavProvider = ({ children }: { children?: ReactNode | undefined }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const localStorageValue = window.localStorage.getItem(SIDEBAR_COLLAPSED);

    setIsCollapsed(localStorageValue === 'true');
  }, []);

  const handleToggle = useCallback(() => {
    setIsCollapsed((isCollapsed) => {
      window.localStorage.setItem(SIDEBAR_COLLAPSED, String(!isCollapsed));

      return !isCollapsed;
    });
  }, []);

  const handleOpen = () => {
    setIsCollapsed(false);
    window.localStorage.setItem(SIDEBAR_COLLAPSED, 'false');
  };

  const handleClose = () => {
    setIsCollapsed(true);
    window.localStorage.setItem(SIDEBAR_COLLAPSED, 'true');
  };

  const value = useMemo(
    () => ({
      isSideNavOpen: !isCollapsed,
      toggleSideNav: handleToggle,
      openSideNav: handleOpen,
      closeSideNav: handleClose,
    }),
    [handleToggle, isCollapsed],
  );

  return <SideNavProviderContext.Provider value={value}>{children}</SideNavProviderContext.Provider>;
};

export function useSideNavContext() {
  const context = useContext(SideNavProviderContext);

  if (context === defaultValue) {
    const error = 'useSideNavContext used outside of SideNavProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
}
