import { dropdownMenuItem } from '@air/primitive-dropdown-menu/src/DropdownMenuItem';
import { ComponentProps, ReactNode } from 'react';

export type LibraryAdvancedSettingsCustomFieldsSelectListItemProps = Pick<
  ComponentProps<'button'>,
  'children' | 'onClick'
> & {
  children: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
};

export const LibraryAdvancedSettingsCustomFieldsSelectListItem = ({
  children,
  onClick,
  prefix,
  suffix,
}: LibraryAdvancedSettingsCustomFieldsSelectListItemProps) => {
  return (
    <button className={dropdownMenuItem({ size: 'small', className: 'gap-2' })} onClick={onClick} type="button">
      {!!prefix ? <div className="flex shrink-0 items-center gap-1 text-grey-10">{prefix}</div> : null}

      <div className="flex min-w-0 grow">{children}</div>

      {!!suffix ? <div className="flex shrink-0 items-center gap-1 text-grey-10">{suffix}</div> : null}
    </button>
  );
};
