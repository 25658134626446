import { Boards, Clips } from '@air/api';
import { Board, BoardInput, Clip, ClipSource, CreateMultipartResponse } from '@air/api/types';
import { Upload, UploadPart } from '@air/redux-uploader';

import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export const createPrivateBoardForUpload = async ({
  board,
  workspaceId,
}: {
  board: BoardInput;
  workspaceId: string;
}) => {
  try {
    return await Boards.create({ board, workspaceId });
  } catch (error) {
    throw reportErrorToBugsnag({
      error,
      context: `Failed to add board`,
      metadata: {
        data: {
          board,
        },
      },
    });
  }
};

export const sendAbortPrivateMultipartUpload = async ({
  uploadUrlInfo,
  workspaceId,
}: {
  uploadUrlInfo: CreateMultipartResponse[0];
  workspaceId: string;
}) => {
  try {
    return await Clips.abortMultipartUpload({ workspaceId, key: uploadUrlInfo.Key, uploadId: uploadUrlInfo.UploadId });
  } catch (error) {
    throw reportErrorToBugsnag({
      error,
      context: `Failed to abort private multipart upload board`,
      metadata: {
        data: {
          uploadUrlInfo,
        },
      },
    });
  }
};

interface SendPrivateMultipartComplete {
  uploadUrlInfo: CreateMultipartResponse[0];
  uploadedParts: UploadPart[];
  parentBoardId: Board['id'] | undefined;
  workspaceId: string;
}

export const sendPrivateMultiPartUploadComplete = async ({
  uploadedParts,
  uploadUrlInfo,
  parentBoardId,
  uploadUrlInfo: { UploadId, Key },
  workspaceId,
}: SendPrivateMultipartComplete) => {
  try {
    return await Clips.completeMultipartUpload({
      key: Key,
      uploadId: UploadId,
      parts: uploadedParts,
      options: { boardId: parentBoardId },
      workspaceId,
    });
  } catch (error) {
    throw reportErrorToBugsnag({
      error,
      context: `Failed to complete multipart upload`,
      metadata: {
        data: {
          uploadUrlInfo: uploadUrlInfo,
        },
      },
    });
  }
};

interface SendPrivateCreateMultipartUploadParams {
  apiUploadData: Upload['apiUploadData'];
  parentBoardId?: Board['id'];
  assetId?: Clip['assetId'];
  workspaceId: string;
}

export const sendCreatePrivateMultipartUpload = async ({
  parentBoardId,
  assetId,
  apiUploadData,
  workspaceId,
}: SendPrivateCreateMultipartUploadParams) => {
  try {
    const urlsInfo = await Clips.createMultipartUploads({
      workspaceId,
      options: {
        files: [apiUploadData],
        source: ClipSource.web,
        boardId: parentBoardId,
        assetId,
      },
    });
    return urlsInfo[0];
  } catch (error) {
    throw reportErrorToBugsnag({
      error,
      context: `Failed to create private multipart upload`,
      metadata: {
        data: {
          apiUploadData,
        },
      },
    });
  }
};

interface GetPrivateUploadPartUrl {
  s3Info: CreateMultipartResponse[0];
  partNumber: number;
  parentBoardId: Board['id'] | undefined;
  workspaceId: string;
}

export const fetchPrivatePartUploadUrl = async ({
  partNumber,
  parentBoardId,
  s3Info,
  workspaceId,
}: GetPrivateUploadPartUrl) => {
  try {
    return await Clips.getPartUploadUrl({
      workspaceId,
      key: s3Info.Key,
      uploadId: s3Info.UploadId,
      partNumber,
      options: {
        boardId: parentBoardId,
      },
    });
  } catch (error) {
    throw reportErrorToBugsnag({
      error,
      context: `Failed to getPrivatePartUploadUrl`,
      metadata: {
        data: {
          s3Info,
        },
      },
    });
  }
};
