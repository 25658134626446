import { ProgressBarVariantsType } from '~/components/ProgressBar/ProgressBar';

export const getStorageMeterColor = (
  percentOfStorageUsed: number,
  isOutOfStorage?: boolean,
): ProgressBarVariantsType['color'] => {
  if (isOutOfStorage) {
    return 'red';
  }

  if (percentOfStorageUsed >= 70) {
    return 'orange';
  }

  return 'teal';
};
