import { ChevronDown } from '@air/next-icons';
import { memo } from 'react';

import { ProgressingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { BoardRestorationTask, GetInProgressMetadata } from '~/store/tasks/types';

export type RestoreBoardPanelInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<BoardRestorationTask>, 'title'>;
  onCancel: () => void;
};

export const RestoreBoardPanelInProgressPanelItem = memo(
  ({ onCancel, metadata: { title } }: RestoreBoardPanelInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<ProgressingAvatar />}
        title={
          <StatusTrackingTitle>
            Restoring board <strong>{title}</strong>
          </StatusTrackingTitle>
        }
        buttons={<PaneButton label="Hide" Icon={ChevronDown} onClick={onCancel} />}
      />
    );
  },
);

RestoreBoardPanelInProgressPanelItem.displayName = 'RestoreBoardPanelInProgressPanelItem';
