import { useTrackSwitchedSortType } from '@air/analytics';
import { SortDirection } from '@air/api/types';
import { Check as CheckIcon, EllipsisVertical } from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { noop } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';

import { DropdownMenuWithActionSheet } from '~/components/Menus/DropdownMenuWithActionSheet';
import {
  CURRENT_WORKSPACE_NAV_SHOW_SORT_LIST_OPTIONS_BUTTON,
  CURRENT_WORKSPACE_NAV_SORT_LIST_OPTIONS_DROPDOWN,
} from '~/constants/testIDs';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSubnavSort } from '~/swr-hooks/subnav/useSubnavSort';
import { useSubnavSortOptions } from '~/swr-hooks/subnav/useSubnavSortOptions';
import { useUpdateSubnavSort } from '~/swr-hooks/subnav/useUpdateSubnavSort';
import { canChangeWorkspaceDetails } from '~/utils/permissions/workspacePermissions';

export const BoardsSortOptions = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();

  const { data: currentSubnavSort } = useSubnavSort();
  const { updateSubnavSort } = useUpdateSubnavSort();
  const { data: sortOptionsData } = useSubnavSortOptions();
  const { trackSwitchedSortType } = useTrackSwitchedSortType();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canChangeSubnavOption = canChangeWorkspaceDetails(permissions);

  const onChangeSubnavOption = useCallback(
    (name: string, direction: SortDirection) => {
      updateSubnavSort(name, direction);
      trackSwitchedSortType({ sortingFieldName: name, direction, location: 'sub navigation' });
    },
    [trackSwitchedSortType, updateSubnavSort],
  );

  const options = useMemo(() => {
    const optionsArray: DropdownMenuOption[] = [
      {
        id: 'sort-boards-by',
        label: 'Sort boards by...',
        type: 'title',
      },
    ];

    sortOptionsData?.map(({ displayName, name, direction }) => {
      const isActiveOption =
        name === currentSubnavSort?.boardSort?.name && direction === currentSubnavSort?.boardSort.direction;

      optionsArray.push({
        id: `${name}-${direction}`,
        label: displayName,
        onSelect: !isActiveOption ? () => onChangeSubnavOption(name, direction) : noop,
        suffix: isActiveOption ? <CheckIcon className="h-4 w-4" /> : null,
        type: 'item',
      });
    });

    return optionsArray;
  }, [
    currentSubnavSort?.boardSort.direction,
    currentSubnavSort?.boardSort?.name,
    onChangeSubnavOption,
    sortOptionsData,
  ]);

  if (!currentWorkspace || !sortOptionsData || !canChangeSubnavOption) {
    return null;
  }

  return (
    <DropdownMenuWithActionSheet
      title="Boards list options"
      isTitleHidden={true}
      options={options}
      trigger={
        <IconButton
          appearance="ghost"
          color="grey"
          className="pointer-events-none opacity-0 group-hover:pointer-events-auto group-hover:opacity-100"
          data-testid={CURRENT_WORKSPACE_NAV_SHOW_SORT_LIST_OPTIONS_BUTTON}
          icon={EllipsisVertical}
          label="Show list options"
          size="small"
        />
      }
      data-testid={CURRENT_WORKSPACE_NAV_SORT_LIST_OPTIONS_DROPDOWN}
    />
  );
});

BoardsSortOptions.displayName = 'BoardsSortOptions';
