import { type Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';

export type NotificationBoardCreatedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification: Notification<'boardAppended_userIsBoardFollower', 'account'>;
};

export const NotificationBoardCreatedType = ({ notification, onClick }: NotificationBoardCreatedTypeProps) => {
  const { actor, child, parent } = notification.data;

  return (
    <NotificationBaseItem
      meta={!!parent.title && `in ${parent.title}`}
      notification={notification}
      onClick={onClick}
      title={
        <>
          <b>{formatActorNameInitialsOrDefault(actor)}</b>
          {` created `}
          <b>{child.title}</b>
        </>
      }
    />
  );
};
