import {
  AirActionTrackingLocation,
  useTrackAddedCustomFieldToForm,
  useTrackClickedAddCustomField,
  useTrackClickedSaveCustomField,
  useTrackRemovedCustomFieldFromForm,
  useTrackToggledFieldRequiredStatus,
} from '@air/analytics';
import { BaseCustomField, CustomFieldTypeName, Form, FormField, FormFieldInput, FormFieldType } from '@air/api/types';
import { TriangleDown as TriangleDownIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import produce from 'immer';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CustomFieldsCreateModal } from '~/components/CustomFields/CustomFieldsCreateModal';
import { useLibraryCustomFields } from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';
import { CONTENT_COLLECTION_FORM_MODAL_ADD_FIELD_BUTTON } from '~/constants/testIDs';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { usePrivateCustomFieldsContext } from '~/providers/PrivateCustomFieldsProvider';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import { useUpdateForm } from '~/swr-hooks/forms/useUpdateForm';
import { getContentCollectionCustomFieldsMenuOptions } from '~/utils/menuOptions/getContentCollectionCustomFieldsMenuOptions';
import { canCreateCustomFields, canSeeCustomFields } from '~/utils/permissions/workspacePermissions';

import { CustomFieldItem } from './CustomFieldItem';

interface CustomFieldsSectionProps {
  fields?: FormField[];
  onCreate: () => void;
  trackLocation: AirActionTrackingLocation;
  form: Form;
}

export const CustomFieldsSection = memo(({ fields, onCreate, trackLocation, form }: CustomFieldsSectionProps) => {
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canCreate = canCreateCustomFields(permissions);
  const canViewCustomFields = canSeeCustomFields(permissions);
  const { customFields } = usePrivateCustomFieldsContext();
  const [showCreateModal] = useAirModal(CustomFieldsCreateModal);
  const { trackClickedSaveCustomField } = useTrackClickedSaveCustomField();
  const { trackClickedAddCustomField } = useTrackClickedAddCustomField();
  const { trackAddedCustomFieldToForm } = useTrackAddedCustomFieldToForm();
  const { trackRemovedCustomFieldFromForm } = useTrackRemovedCustomFieldFromForm();
  const { trackToggledFieldRequiredStatus } = useTrackToggledFieldRequiredStatus();
  const { updateForm } = useUpdateForm();
  const urlLibraryId = useURLLibraryIdSelector();
  const libraryId = useSelector(centralizedBoardLibraryIdSelector);
  const { data: libraryCustomFieldsVisbility } = useLibraryCustomFields({ libraryId: libraryId || urlLibraryId });

  const addField = async (fieldId: string, fieldName: string) => {
    if (!form) {
      return;
    }

    await updateForm({
      ...form,
      fields: form.fields
        ? [...form.fields, { customFieldId: fieldId, type: FormFieldType.customField, required: false }]
        : [{ customFieldId: fieldId, type: FormFieldType.customField, required: false }],
    });

    trackAddedCustomFieldToForm({ fieldId, fieldName });
  };

  const removeField = async (fieldId: string, fieldName: string) => {
    if (!form) {
      return;
    }

    const updatedFields = produce(form.fields, (draft) => {
      const fieldToUpdate = draft?.find((existingField) => existingField.customFieldId === fieldId);
      if (fieldToUpdate) draft?.splice(draft.indexOf(fieldToUpdate), 1);
    });

    await updateForm({
      ...form,
      fields: updatedFields,
    });

    trackRemovedCustomFieldFromForm({ fieldId, fieldName });
  };

  const toggleFieldRequired = async (fieldId: string, fieldName: string, required: boolean) => {
    if (!form) {
      return;
    }

    const updatedFields = produce(form.fields, (draft) => {
      const fieldToUpdate = draft?.find((existingField) => existingField.customFieldId === fieldId);
      if (fieldToUpdate) draft?.splice(draft?.indexOf(fieldToUpdate), 1, { ...fieldToUpdate, required: required });
    });

    await updateForm({
      ...form,
      fields: updatedFields,
    });

    trackToggledFieldRequiredStatus({ fieldId, fieldName, required });
  };

  const onCustomFieldCreate = useCallback(async () => {
    await onCreate();
    trackClickedSaveCustomField({ location: trackLocation });
  }, [onCreate, trackLocation, trackClickedSaveCustomField]);

  const onAddNewFieldClick = useCallback(() => {
    showCreateModal({
      onCreate: onCustomFieldCreate,
    });
    trackClickedAddCustomField({ location: trackLocation });
  }, [onCustomFieldCreate, showCreateModal, trackLocation, trackClickedAddCustomField]);

  const fieldsWithNames = useMemo(
    () =>
      fields?.reduce(
        (acc, curr) => {
          const matchedField = customFields?.find((cField) => cField.id === curr.customFieldId);
          const fieldWithName = { ...curr, name: matchedField?.name || '' };
          if (fieldWithName.type === FormFieldType.customField) {
            acc.push(fieldWithName);
          }
          return acc;
        },
        [] as (FormFieldInput & Pick<BaseCustomField, 'name'>)[],
      ),
    [customFields, fields],
  );

  const fieldMenuOptions = getContentCollectionCustomFieldsMenuOptions({
    fieldsWithNames,
    workspaceCustomFields: customFields,
    addFieldToForm: addField,
    addNewField: onAddNewFieldClick,
    canCreateCustomFields: canCreate,
  });

  const filteredFieldMenuOptions = useMemo(() => {
    if (!!libraryCustomFieldsVisbility?.data.length) {
      return fieldMenuOptions?.filter((option) => {
        return !!libraryCustomFieldsVisbility?.data.find((field) => field.id === option.id)?.visible;
      });
    }

    return fieldMenuOptions;
  }, [fieldMenuOptions, libraryCustomFieldsVisbility?.data]);

  return (
    <div>
      <div>
        {fieldsWithNames?.map((field) => (
          <CustomFieldItem
            key={field.id}
            fieldId={field.customFieldId || ''}
            removeField={removeField}
            onSelect={toggleFieldRequired}
            fieldName={field.name || ''}
            required={field.required}
            fieldTypeName={
              customFields?.find((cf) => cf.id === field.customFieldId)?.type.title || CustomFieldTypeName.singleSelect
            }
          />
        ))}
      </div>
      {!!filteredFieldMenuOptions?.length && canViewCustomFields && (
        <DropdownMenu
          trigger={
            <Button
              appearance="filled"
              color="grey"
              size="large"
              data-testid={CONTENT_COLLECTION_FORM_MODAL_ADD_FIELD_BUTTON}
              suffix={<TriangleDownIcon className="h-4 w-4" />}
            >
              Add a field
            </Button>
          }
        >
          {renderDropdownItems({ options: filteredFieldMenuOptions })}
        </DropdownMenu>
      )}
    </div>
  );
});

CustomFieldsSection.displayName = 'CustomFieldsSection';
