import type { LibraryMembersWithPending } from '@air/api';
import type { Library } from '@air/api/types';

import { isLibraryWorkspaceMember } from '~/components/LibraryBeta/hooks/useLibraryMembers';
import { LibraryPendingActions } from '~/components/LibraryBeta/LibraryPendingActions';
import { LibraryRoleDropdownMenu } from '~/components/LibraryBeta/LibraryRoleDropdownMenu';
import { LibraryPendingMemberListItem } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/components/LibraryNonWorkspaceMemberListItem';
import { LibraryMemberList } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/LibraryMemberList';
import { LibraryMemberListSkeleton } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/LibraryMemberListSkeleton';

export type LibraryPendingMemberListProps = {
  libraryId: Library['id'];
  members: LibraryMembersWithPending | undefined;
};

export const LibraryPendingMemberList = ({ libraryId, members }: LibraryPendingMemberListProps) => {
  if (!members) {
    return <LibraryMemberListSkeleton />;
  }

  if (!members.length) {
    return null;
  }

  return (
    <LibraryMemberList
      label={`Pending (${members.length})`}
      members={members?.map((member) => (
        <LibraryPendingMemberListItem
          key={member.email}
          member={member}
          /**
           * Pending member may be one of LibraryMemberPending or LibraryMemberWithInfo.
           * LibraryMemberPending is a user who is not yet a workspace member.
           * LibraryMemberWithInfo is a user who is already a workspace member.
           */
          renderSuffix={(member) => (
            <>
              <LibraryRoleDropdownMenu libraryId={libraryId} member={member} />
              {isLibraryWorkspaceMember(member) && <LibraryPendingActions member={member} libraryId={libraryId} />}
            </>
          )}
        />
      ))}
    />
  );
};
