import { useTrackStartedDragging } from '@air/analytics';
import { WorkspaceInfo } from '@air/api/types';
import classnames from 'classnames';
import { ReactElement, useEffect, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import DragType, { DragWorkspaceItem } from '~/components/Shared/Drag/dragTypes';

interface DraggableWorkspaceProps {
  children: ReactElement;
  index: number;
  workspace: WorkspaceInfo;
}

/**
 * This component is used as container for workspace item - it handles its dragging logic
 */
const DraggableWorkspaceItem = ({ children, index, workspace }: DraggableWorkspaceProps) => {
  const { trackStartedDragging } = useTrackStartedDragging();

  const dragItem = useMemo(
    (): DragWorkspaceItem => ({
      id: workspace.id,
      index,
      type: DragType.workspace,
      image: workspace.image,
    }),
    [workspace, index],
  );

  const [{ isDragging }, drag, preview] = useDrag({
    item: dragItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    begin: () => {
      trackStartedDragging({ workspaceId: workspace.id });
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <div
      data-draggable="true"
      className={classnames({
        dragging: isDragging,
      })}
      ref={drag}
      style={{
        opacity: isDragging ? 0.2 : 1,
      }}
    >
      {children}
    </div>
  );
};

export default DraggableWorkspaceItem;
