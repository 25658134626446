import { ChevronDown } from '@air/next-icons';
import { memo } from 'react';

import { ProgressingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { GetInProgressMetadata, LibraryRestorationTask } from '~/store/tasks/types';

export type RestoreLibraryPanelInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<LibraryRestorationTask>, 'title'>;
  onCancel: () => void;
};

export const RestoreLibraryPanelInProgressPanelItem = memo(
  ({ onCancel, metadata: { title } }: RestoreLibraryPanelInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<ProgressingAvatar />}
        title={
          <StatusTrackingTitle>
            Restoring board <strong>{title}</strong>
          </StatusTrackingTitle>
        }
        buttons={<PaneButton label="Hide" Icon={ChevronDown} onClick={onCancel} />}
      />
    );
  },
);

RestoreLibraryPanelInProgressPanelItem.displayName = 'RestoreLibraryPanelInProgressPanelItem';
