import { useTrackOpenIntercomChat } from '@air/analytics';
import { Badge } from '@air/primitive-badge';
import { useAirModal } from '@air/provider-modal';
import { tailwindMerge } from '@air/tailwind-variants';
import { memo, useMemo } from 'react';

import { PlansModal } from '~/components/Modals/PlansModal/PlansModal';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useStorage } from '~/swr-hooks/subscriptions/useStorage';
import { canManageSubscription } from '~/utils/permissions/workspacePermissions';

export const StorageUpgradeCTA = memo(() => {
  const { data: subscription } = useSubscriptionContext();
  const { data: storage } = useStorage();
  const isFreePlan = !!subscription?.isInFreePlan;
  const isOutOfStorage = !!storage?.isOutOfStorage;
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canEditPlan = canManageSubscription(permissions);
  const [showPlansModal] = useAirModal(PlansModal);
  const { trackOpenIntercomChat } = useTrackOpenIntercomChat();
  const isFull = !isFreePlan && isOutOfStorage;
  const isAlmostFull = !isFreePlan && !isOutOfStorage;

  const content = useMemo(
    () => (
      <>
        <div className="flex w-full">
          <p className="flex-1 text-12 font-medium">
            {isFreePlan
              ? `You're on the free plan`
              : !isOutOfStorage
              ? `You're almost out of space!`
              : `Your workspace is out of storage.`}
          </p>
          {(isFreePlan || !isOutOfStorage) && <Badge color={isAlmostFull ? 'yellow' : 'blue'}>Upgrade</Badge>}
        </div>
        <p className="mt-1 whitespace-pre-wrap text-12">
          {isFreePlan
            ? 'Upgrade now for unlimited storage, members, and more.'
            : subscription?.isEnterprise
            ? 'Talk to Support about upgrading your storage.'
            : 'Upgrade to Enterprise for more space, custom roles, and more.'}

          {!canEditPlan ? ' Contact your workspace admin to upgrade.' : ''}
        </p>
      </>
    ),
    [canEditPlan, isAlmostFull, isFreePlan, isOutOfStorage, subscription?.isEnterprise],
  );

  const parentClassNames = tailwindMerge(
    'mt-3 flex flex-col rounded bg-blue-3 p-2 text-left text-blue-11 hover:bg-blue-4',
    isFull && 'bg-red-3 text-red-11 hover:bg-red-4',
    isAlmostFull && 'group bg-yellow-3 text-yellow-11 hover:bg-yellow-4',
  );

  if (!canEditPlan) {
    return <div className={parentClassNames}>{content}</div>;
  }

  return (
    <button
      className={parentClassNames}
      onClick={() => {
        if (subscription?.isEnterprise) {
          trackOpenIntercomChat();
        } else {
          showPlansModal();
        }
      }}
    >
      {content}
    </button>
  );
});

StorageUpgradeCTA.displayName = 'StorageUpgradeCTA';
