import { WorkspaceMemberType } from '@air/api/types';
import { useMemo } from 'react';

import { useWorkspaceRolesContext } from '~/providers/WorkspaceRolesProvider';

export const useWorkspaceMemberRoles = () => {
  const { data: roles } = useWorkspaceRolesContext();
  const memberRoles = useMemo(() => roles?.filter(({ type }) => type === WorkspaceMemberType.member), [roles]);

  return { memberRoles };
};
