import { SavedFilter } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { Label } from '@air/primitive-label';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import React, { ReactNode, useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { SavedFilterNameInput } from './SavedFilterNameInput';

export interface ModalFormData {
  name: string;
  icon: string;
  description: string;
}

export interface SavedFiltersModalProps extends AirModalProps {
  modalLabel: ReactNode;
  onSubmit: (savedFilter: ModalFormData) => void;
  primaryCTACopy: string;
  isEditing?: boolean;
  modalDescription?: ReactNode;
  savedFilter?: SavedFilter;
  tertiaryCTA?: JSX.Element;
}

const DEFAULT_EMOJI = '🙂';

export const SavedFilterModal = ({
  modalDescription,
  modalLabel,
  onClose,
  onSubmit,
  primaryCTACopy,
  savedFilter,
  tertiaryCTA,
  isEditing = false,
}: SavedFiltersModalProps) => {
  const [sfTitle, setSFTitle] = useState(savedFilter?.name || '');
  const [sfDescription, setSFDescription] = useState(savedFilter?.description || '');
  const [hasError, setHasError] = useState(false);
  const [emoji, setEmoji] = useState(savedFilter?.icon || DEFAULT_EMOJI);
  const hasNotBeenEdited =
    savedFilter?.name === sfTitle && savedFilter?.description === sfDescription && savedFilter?.icon === emoji;
  const emptyTitle = !sfTitle.trim();
  const isSubmitDisabled = emptyTitle || hasError || (isEditing && hasNotBeenEdited);

  const resetModalFormData = useCallback(() => {
    setSFTitle('');
    setSFDescription('');
    setEmoji(DEFAULT_EMOJI);
  }, []);

  const onApplyClick = useCallback(() => {
    onSubmit({ name: sfTitle.trim(), description: sfDescription.trim(), icon: emoji });
    resetModalFormData();
  }, [emoji, onSubmit, resetModalFormData, sfDescription, sfTitle]);

  useHotkeys('enter', onApplyClick, { enableOnFormTags: ['INPUT'], enabled: !isSubmitDisabled }, [onApplyClick]);

  return (
    <Modal data-testid="SAVED_FILTER_MODAL" isOpen onDismiss={onClose} dangerouslyBypassFocusLock>
      <div className="flex flex-col gap-4">
        <header className="flex items-center justify-between gap-2">
          <ModalTitle data-testid="SAVED_FILTER_MODAL_TITLE">{modalLabel}</ModalTitle>
          <ModalCloseButton onClick={onClose} />
        </header>
        {!!modalDescription && (
          <ModalDescription data-testid="SAVED_FILTER_MODAL_DESCRIPTION">{modalDescription}</ModalDescription>
        )}

        <div className="flex flex-col gap-3">
          <SavedFilterNameInput
            inputValue={sfTitle}
            onEmojiPick={setEmoji}
            onError={setHasError}
            onInputChange={setSFTitle}
            selectedEmoji={emoji}
          />
          <div>
            <Label htmlFor="filter-desc">Description</Label>
            <Input
              id="filter-desc"
              data-testid="SAVED_FILTER_MODAL_DESCRIPTION_INPUT"
              name="filter-desc"
              placeholder="What is the description?"
              value={sfDescription}
              size="large"
              className="mt-1.5 w-full"
              onChange={(e) => {
                setSFDescription(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="flex justify-between">
          {tertiaryCTA}
          <footer className="ml-auto flex justify-end gap-3">
            <Button
              data-testid="SAVED_FILTER_MODAL_CANCEL_BUTTON"
              appearance="ghost"
              color="grey"
              onClick={onClose}
              size="large"
            >
              Cancel
            </Button>
            <Button
              data-testid="SAVED_FILTER_MODAL_SAVE_BUTTON"
              disabled={isSubmitDisabled}
              appearance="filled"
              color="blue"
              size="large"
              onClick={onApplyClick}
            >
              {primaryCTACopy}
            </Button>
          </footer>
        </div>
      </div>
    </Modal>
  );
};
