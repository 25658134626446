import { Auth } from '@air/api';
import { AlertBanner } from '@air/primitive-alert-banner';
import { useCurrentUserInfo } from '@air/utils-auth';
import { isUndefined } from 'lodash';
import { memo, useCallback, useEffect } from 'react';
import { useToggle } from 'react-use';

import { EMAIL_CONFIRMATION_MESSAGE } from '~/constants/testIDs';
import { useIsCurrentUserEmailVerified } from '~/swr-hooks/account/useIsCurrentUserEmailVerified';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export const VerifyEmailBanner = memo(() => {
  const [wasClicked, toggleWasClicked] = useToggle(false);
  const { userEmailIsVerified } = useIsCurrentUserEmailVerified();
  const { data: currentAccountInfo, error: userInfoError } = useCurrentUserInfo();

  useEffect(() => {
    if (userInfoError) {
      reportErrorToBugsnag({
        error: userInfoError,
        context: 'Failed to get current user info for verify email',
      });
    }
  }, [userInfoError]);

  const handleClick = useCallback(() => {
    toggleWasClicked();
    Auth.verifyEmail();
  }, [toggleWasClicked]);

  if (isUndefined(currentAccountInfo) || userEmailIsVerified) {
    return null;
  }

  return (
    <AlertBanner color="blue" onClick={handleClick} data-testid={EMAIL_CONFIRMATION_MESSAGE}>
      Please verify your email address by clicking the link sent to {currentAccountInfo.attributes.email}.&nbsp;
      <button className="font-semibold" onClick={handleClick}>
        {wasClicked ? 'Sent!' : 'Resend email'}
      </button>
    </AlertBanner>
  );
});

VerifyEmailBanner.displayName = 'VerifyEmailBanner';
