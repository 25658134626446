import { Emoji as EmojiIcon } from '@air/next-icons';
import { Button, ButtonVariants } from '@air/primitive-button';
import { Emoji, EmojiPickerProps } from '@air/primitive-emoji-picker';
import { IconButton } from '@air/primitive-icon-button';
import { Popover } from '@air/primitive-popover';
import { Tooltip } from '@air/primitive-tooltip';
import dynamic from 'next/dynamic';
import { memo, ReactNode, useCallback, useMemo, useState } from 'react';

const EmojiPicker = dynamic<EmojiPickerProps>(
  () => import('@air/primitive-emoji-picker').then((mod) => mod.EmojiPicker),
  {
    ssr: false,
  },
);

export interface SavedFilterEmojiPickerProps {
  onEmojiPick?: (emoji: string) => void;
  onOpenChange?: (nextIsOpen: boolean) => void;
  emoji?: string;
  className?: string;
  size: ButtonVariants['size'];
}

export const SavedFilterEmojiPicker = memo(
  ({ onOpenChange, onEmojiPick, emoji, className, size = 'medium' }: SavedFilterEmojiPickerProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState(emoji || '');
    const isTriggerDisabled = !!!onEmojiPick;

    const iconButtonTrigger = useMemo(() => {
      return (
        <Tooltip label="Insert emoji" side="top">
          <IconButton
            className="border-none"
            label="Insert emoji"
            appearance="filled"
            color="grey"
            size={size}
            icon={EmojiIcon}
            disabled={isTriggerDisabled}
          />
        </Tooltip>
      );
    }, [isTriggerDisabled, size]);

    const selectedEmojiTrigger = useMemo(() => {
      return (
        <Button appearance="filled" color="grey" size={size} disabled={isTriggerDisabled} className="border-none">
          {selectedEmoji}
        </Button>
      );
    }, [isTriggerDisabled, selectedEmoji, size]);

    const trigger: ReactNode = !!selectedEmoji ? selectedEmojiTrigger : iconButtonTrigger;

    const handleOpenChange = useCallback(
      (nextIsOpen: boolean) => {
        onOpenChange?.(nextIsOpen);
        setIsOpen(nextIsOpen);
      },
      [onOpenChange],
    );

    const onSelect = useCallback(
      (data: Emoji) => {
        const emoji = data.native;
        onEmojiPick?.(emoji);
        setSelectedEmoji(emoji);
        handleOpenChange(false);
      },
      [handleOpenChange, onEmojiPick],
    );

    return (
      <Popover
        align="start"
        className="overflow-hidden p-0"
        side="bottom"
        open={isOpen}
        onOpenChange={handleOpenChange}
        trigger={<div className={className}>{trigger}</div>}
      >
        {isOpen && <EmojiPicker onSelect={onSelect} />}
      </Popover>
    );
  },
);

SavedFilterEmojiPicker.displayName = 'SavedFilterEmojiPicker';
