import { Libraries } from '@air/api';
import { Library } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const LIBRARY_STATS_QUERY_KEY = 'LIBRARY_STATS';

export const getLibraryStatsKey = (libraryId?: string) => [LIBRARY_STATS_QUERY_KEY, { libraryId }];

export type UseLibraryStatsParams = {
  isEnabled?: boolean;
  libraryId: Library['id'];
};

export const useLibraryStats = ({ isEnabled, libraryId }: UseLibraryStatsParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  return useQuery({
    queryKey: getLibraryStatsKey(libraryId),

    queryFn: () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Libraries.getStats({ workspaceId, libraryId });
    },
    enabled: isEnabled && !!libraryId && !!currentWorkspace?.id,
  });
};
