import { Board, Clip } from '@air/api/types';

export type DroppableBoardInterface = Pick<Board, 'id' | 'ancestors'>;

/**
 * This utility function checks to see if boards can be moved to another board by checking the ancestors and parentIds
 * @param boardsToMove {Array} - An array of boards that you want to move
 * @param parentBoard {Object} - The parent board you want to move the boards to
 */
export const canMoveBoardsToBoard = (
  boardsToMove: Pick<Board, 'id' | 'parentId'>[],
  { id: parentId, ancestors: parentAncestors = [] }: DroppableBoardInterface,
): boolean =>
  boardsToMove.every(
    (boardToMove) =>
      !!boardToMove &&
      !!boardToMove.id &&
      boardToMove.id !== parentId &&
      !parentAncestors.some((a) => a.id === boardToMove.id) &&
      boardToMove.parentId !== parentId,
  );

export const checkIfCanMoveAssets = (assetsToMerge: Clip['assetId'][], assetId: Clip['assetId']) =>
  !assetsToMerge.includes(assetId);
