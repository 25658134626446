import { Form } from '@air/api/types';
import { EditableText } from '@air/zephyr-editable-text';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { CONTENT_COLLECTION_FORM_MODAL_EDITABLE_TITLE } from '~/constants/testIDs';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { useUpdateForm } from '~/swr-hooks/forms/useUpdateForm';

const inputStyles = {
  wordBreak: 'break-word',
  color: 'pigeon600',
  fontWeight: 'medium',
  width: '100%',
  ml: -8,
  borderRadius: 4,
  EditableTextButton: {
    color: 'pigeon600',
    fontWeight: 'medium',
  },
};

type FormTitleProps = {
  form: Form;
};

export const FormTitle = memo(({ form }: FormTitleProps) => {
  const [forceEditableTextMount, setForceEditableTextMount] = useState(0);
  const boardTitle = useSelector(centralizedBoardTitleSelector);
  const { updateForm } = useUpdateForm();

  const onSubmit = async (values: { ['editable-text-value']: string }) => {
    if (!form) {
      return;
    }

    const title = values['editable-text-value'];
    if (!title) {
      setForceEditableTextMount((v) => v + 1);
      return;
    }

    await updateForm({
      ...form,
      title,
    });
  };

  return (
    <EditableText
      key={forceEditableTextMount}
      isSingleLine
      label="Content name"
      placeholder="Add your form title"
      value={form?.title || boardTitle || ''}
      onSubmit={onSubmit}
      tx={inputStyles}
      variant="text-ui-18"
      data-testid={CONTENT_COLLECTION_FORM_MODAL_EDITABLE_TITLE}
    />
  );
});

FormTitle.displayName = 'FormTitle';
