import { Notifications } from '@air/api';
import { type NotificationFilter } from '@air/api/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const WORKSPACE_NOTIFICATIONS_KEY = 'WORKSPACE_NOTIFICATIONS';

export const getNotificationsKey = (workspaceId?: string, filter?: NotificationFilter) => {
  return workspaceId ? [WORKSPACE_NOTIFICATIONS_KEY, { workspaceId, filter }] : [WORKSPACE_NOTIFICATIONS_KEY];
};

export type UseNotificationsParams = {
  filter?: NotificationFilter;
};

export const useNotifications = (params?: UseNotificationsParams) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const {
    data: notifications,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: getNotificationsKey(currentWorkspace?.id, params?.filter),
    queryFn: ({ pageParam }) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Notifications.listNotifications({ workspaceId, cursor: pageParam || undefined, filter: params?.filter });
    },
    enabled: !!currentWorkspace?.id,
    getNextPageParam: (lastPage) => lastPage?.cursor || null,
    initialPageParam: '',
  });

  const data = useMemo(() => (notifications ? notifications.pages.flatMap((page) => page.items) : []), [notifications]);

  return {
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    data,
  };
};
