import { useTrackClickedCollectContent } from '@air/analytics';
import { UploadContext } from '@air/component-upload-dropzone';
import { MobileNavigation, MobileNavigationItem } from '@air/mobile-navigation';
import { Bell, DashedPlus, Hamburger, Photos, PlusSquare, Search } from '@air/next-icons';
import { ActionSheetOption } from '@air/primitive-action-sheet';
import { useAirModal } from '@air/provider-modal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PrivateMobileSearch } from '~/_app/(private)/_components/PrivateMobileSearch';
import { MobileNotifications } from '~/components/AirNotifications/MobileNotifications';
import { ActionSheetModal } from '~/components/Mobile/ActionSheetModal';
import { AirMobileWorkspaceNavigation } from '~/components/Mobile/CoreMobileWorkspaceNavigation';
import { ContentCollectionModal } from '~/components/Modals/ContentCollectionModal/ContentCollectionModal';
import { CreateBoardModal } from '~/components/Modals/CreateBoardModal';
import { BOARDS_SELECT_EMPTY_OPTION } from '~/constants/BoardSearch';
import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { useCanUploadAssets } from '~/swr-hooks/members/useCanUploadAssets';
import { useActiveSubscriptionCallback } from '~/swr-hooks/subscriptions/useActiveSubscriptionCallback';
import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';

export const CoreMobileNavigation = memo(() => {
  const { mobileSearch: mobileSearchFlag } = useFlags();
  const { openUploader } = useContext(UploadContext);
  const [showMobileActionsActionSheet, closeMobileActionsActionSheet] = useAirModal(ActionSheetModal);
  const [showCreateBoardModal] = useAirModal(CreateBoardModal);
  const [showContentCollectionModal] = useAirModal(ContentCollectionModal);
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const { boardPermissions } = useCurrentBoardPermissions();
  const { activeSubscriptionCallback } = useActiveSubscriptionCallback();
  const centralizedBoard = useSelector(centralizedBoardSelector);
  const { trackClickedCollectContent } = useTrackClickedCollectContent();

  const canCreateRootBoards = canCreateBoard(workspacePermissions);
  const canCreateSubBoard = canCreateBoard(boardPermissions);
  const { canUploadAssets } = useCanUploadAssets();

  const onCollectContentClick = useCallback(() => {
    if (!centralizedBoard) return;
    trackClickedCollectContent();
    showContentCollectionModal({ boardId: centralizedBoard?.id, boardTitle: centralizedBoard.title });
    closeMobileActionsActionSheet();
  }, [centralizedBoard, closeMobileActionsActionSheet, showContentCollectionModal, trackClickedCollectContent]);

  const options = useMemo(() => {
    const optionsArray: ActionSheetOption[] = [];

    if (canCreateRootBoards || canCreateSubBoard) {
      optionsArray.push({
        id: 'create-board',
        label: 'Create a new board',
        onSelect: () => {
          showCreateBoardModal({
            selectedParentBoard: BOARDS_SELECT_EMPTY_OPTION,
            trackLocation: 'mobile-bottom-nav',
          });

          closeMobileActionsActionSheet();
        },
        prefix: <DashedPlus className="h-4 w-4" />,
        type: 'item',
      });
    }

    if (canUploadAssets) {
      optionsArray.push({
        id: 'upload-from-device',
        label: 'Upload from device',
        onSelect: () => {
          closeMobileActionsActionSheet();

          activeSubscriptionCallback(() => {
            openUploader();
          });
        },
        prefix: <Photos className="h-4 w-4" />,
        type: 'item',
      });
    }

    if (centralizedBoard) {
      optionsArray.push(
        {
          id: 'content-collection-separator',
          type: 'separator',
        },
        {
          ...getDropdownMenuOption('content-collection'),
          description: 'Collect content directly from anyone, no Air account needed.',
          onSelect: onCollectContentClick,
          type: 'item',
        },
      );
    }

    return optionsArray;
  }, [
    activeSubscriptionCallback,
    canCreateRootBoards,
    canCreateSubBoard,
    canUploadAssets,
    centralizedBoard,
    closeMobileActionsActionSheet,
    onCollectContentClick,
    openUploader,
    showCreateBoardModal,
  ]);

  const items = useMemo(() => {
    const itemsArray: MobileNavigationItem[] = [
      {
        icon: <Hamburger className="h-6 w-6" />,
        id: 'menu',
        label: 'Menu',
        renderContent: ({ onClose }) => <AirMobileWorkspaceNavigation onClose={onClose} />,
      },
    ];

    if (mobileSearchFlag) {
      itemsArray.push({
        icon: <Search className="h-6 w-6" />,
        id: 'search',
        label: 'Search',
        renderContent: ({ onClose }) => <PrivateMobileSearch onClose={onClose} />,
      });
    }

    if (options.length) {
      itemsArray.push({
        icon: <PlusSquare className="h-6 w-6" />,
        id: 'actions',
        label: 'Actions',
        onClick: () =>
          showMobileActionsActionSheet({
            options,
            title: 'Actions',
          }),
      });
    }

    itemsArray.push({
      icon: <Bell className="h-6 w-6" />,
      id: 'notifications',
      label: 'Notifications',
      renderContent: ({ onClose }) => <MobileNotifications onClose={onClose} />,
    });

    return itemsArray;
  }, [mobileSearchFlag, options, showMobileActionsActionSheet]);

  return <MobileNavigation items={items} />;
});

CoreMobileNavigation.displayName = 'CoreMobileNavigation';
