import { useTrackMovedBoards } from '@air/analytics/src/events/useTrackMovedBoards';
import { Boards } from '@air/api';
import {
  BetweenBoardClipsInput,
  BetweenBoardsInput,
  BetweenPersonalBoardsInput,
  Board,
  Clip,
  PersonalBoardIdentifier,
} from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { GalleryItemType } from '~/components/Gallery/types';
import { GalleryItemToRearrange } from '~/components/Shared/ClipList/RearrangeableRow';
import { GetBoardDragItem, RearrangableItem } from '~/components/Shared/Drag/dragTypes';
import { getFavoritedMessage } from '~/constants/messages';
import { useAccountContext } from '~/providers/AccountProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { moveBoardRequestAction } from '~/store/centralizedBoard/actions';
import { selectedClipIdsSelector, selectedPrivateBoardsSelector } from '~/store/selectedItems/selectors';
import { moveItemToIndex } from '~/utils/ArrayUtils';
import { calculateNewBoardsPositions } from '~/utils/BoardUtils';
import { calculateRearrangeData } from '~/utils/DragUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useRearrangeBoardsInAllLists } from '~/utils/mutateUtils/AllBoards';
import { useRearrangeAssetsInGalleryView } from '~/utils/mutateUtils/GalleryAssets';
import { useRemoveBoardsFromAllViews } from '~/utils/mutateUtils/GalleryBoards';
import {
  useAddBoardsToSideNav,
  useChangeSideBoardsParent,
  useRearrangeFavoriteBoardsInSideNav,
  useRemoveBoardsFromSideNav,
} from '~/utils/mutateUtils/SideNavBoards';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

import { useFavoriteBoards } from '../boards/useFavoriteBoards';
import { useGetSubnavSortValue } from '../subnav/useSubnavSort';

export const useRearrangeBoards = () => {
  const dispatch = useDispatch();
  const { showToast } = useToasts();
  const { removeBoardsFromAllViews } = useRemoveBoardsFromAllViews();
  const { rearrangeBoardsInAllLists } = useRearrangeBoardsInAllLists();
  const { changeSideBoardsParent } = useChangeSideBoardsParent();
  const { addBoardsToSideNav } = useAddBoardsToSideNav();
  const { removeBoardsFromSideNav } = useRemoveBoardsFromSideNav();
  const { rearrangeFavoriteBoardsInSideNav } = useRearrangeFavoriteBoardsInSideNav();
  const store = useAirStore();
  const { data: account } = useAccountContext();
  const { favoriteBoards } = useFavoriteBoards();
  const { getSubNavSortValue } = useGetSubnavSortValue();
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackMovedBoards } = useTrackMovedBoards();

  const workspaceId = currentWorkspace?.id;

  const rearrangeFavoriteBoards = useCallback(
    async ({
      parentBoardId = null,
      boards,
      draggedItem,
      adjacentItem,
    }: {
      boards: Board[];
      draggedItem: GetBoardDragItem;
      adjacentItem: RearrangableItem;
      parentBoardId: string | null;
    }) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      if (!account?.id) {
        throw new Error(`No user account!`);
      }

      const updatedBoards = [...boards];
      let boardsToMove: string[];
      const { newIndex, nextItemId, previousItemId } = calculateRearrangeData(boards, draggedItem, adjacentItem);

      const selectedBoards = selectedPrivateBoardsSelector(store.getState());
      const selectedBoardsIds = selectedBoards.map(({ id }) => id);
      const draggedBoard = draggedItem.firstItem as Board;
      const boardsById = [draggedBoard, ...selectedBoards, ...boards].reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {} as { [id: string]: Board },
      );
      const draggedBoardIsFavorited = draggedBoard.hasCurrentUser;

      if (selectedBoardsIds.length) {
        selectedBoardsIds.forEach((boardId, index) => {
          const dragBoard = boardId === draggedBoard.id ? draggedBoard : boardsById[boardId];
          const draggedBoardIsFavorited = draggedBoard.hasCurrentUser;
          if (draggedBoardIsFavorited) {
            const currentIndex = updatedBoards.findIndex((b) => b.id === boardId);

            moveItemToIndex(updatedBoards, currentIndex, newIndex);
          } else {
            updatedBoards.splice(newIndex + 1 + index, 0, dragBoard);
          }
        });

        boardsToMove = [...selectedBoardsIds];
      } else {
        // we dragged already favorited board - change its index
        if (draggedBoardIsFavorited) {
          moveItemToIndex(updatedBoards, draggedItem.index, newIndex);
        } else {
          // we dragged non-favorited board to Favorites section - add a new item at correct index
          updatedBoards.splice(newIndex + 1, 0, draggedBoard);
        }

        boardsToMove = [draggedItem.firstItem.id];
      }

      const betweenIds: BetweenPersonalBoardsInput = {};

      if (previousItemId) {
        betweenIds.moveBeforeId = {
          accountId: account?.id,
          boardId: previousItemId,
        };
      }

      if (nextItemId) {
        betweenIds.moveAfterId = {
          accountId: account?.id,
          boardId: nextItemId,
        };
      }

      const previousBoard = previousItemId ? boardsById[previousItemId] : undefined;
      const nextBoard = nextItemId ? boardsById[nextItemId] : undefined;

      const boardsPositions = calculateNewBoardsPositions({
        previousPosition: previousBoard?.pos,
        nextPosition: nextBoard?.pos,
        boardsToMove,
      });

      const idsToMove: PersonalBoardIdentifier[] = boardsToMove.map((boardId) => ({
        accountId: account?.id,
        boardId,
      }));

      if (!draggedBoardIsFavorited) {
        favoriteBoards({
          boards: boardsToMove
            .filter((boardId) => boardsById[boardId] && !boardsById[boardId].hasCurrentUser)
            .map((boardId) => ({ ...boardsById[boardId], title: draggedBoard.title })),
          trackLocation: 'drag-n-drop',
        });

        showToast(getFavoritedMessage(true, boardsToMove.length));
      }

      const movedBoardsInfo = boardsToMove.map((boardId) => ({
        ...boardsById[boardId],
        pos: boardsPositions.find(({ id }) => id === boardId)?.pos || 0,
      }));

      try {
        Boards.updatePersonalBoardPositions({
          idsToMove,
          betweenIds,
          workspaceId,
        });

        const rearrangedWithParent = movedBoardsInfo.map((board) => ({
          ...board,
          parentId: parentBoardId === 'root' || !parentBoardId ? null : parentBoardId,
          hasCurrentUser: true,
        }));

        /**
         * Subboards can be favorited independently from its ancestors
         * repositioning them around the main level of the FAVORITES BOARD section should not be treated like they are being dragged out as a root level
         */
        const isFavoritedSubboardReposition = draggedBoard.hasCurrentUser && parentBoardId === null;

        if (!isFavoritedSubboardReposition && draggedItem.parentId !== parentBoardId) {
          changeSideBoardsParent(
            movedBoardsInfo[0].workspaceId,
            rearrangedWithParent,
            draggedItem.parentId,
            draggedBoard.library?.id,
          );
        }

        rearrangeFavoriteBoardsInSideNav(rearrangedWithParent);
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: `Could not update personal positions`,
          metadata: {
            data: {
              boardsToMove,
              betweenIds,
              idsToMove,
            },
          },
        });
      }
    },
    [
      account?.id,
      changeSideBoardsParent,
      favoriteBoards,
      rearrangeFavoriteBoardsInSideNav,
      showToast,
      store,
      workspaceId,
    ],
  );

  const rearrangeBoards = useCallback(
    async ({
      parentBoardId = null,
      library,
      boards,
      draggedItem,
      adjacentItem,
    }: {
      boards: Board[];
      draggedItem: GetBoardDragItem;
      adjacentItem: RearrangableItem;
      parentBoardId: string | null;
      library?: Board['library'];
    }) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      let boardsToMove: string[];
      const { nextItemId, previousItemId } = calculateRearrangeData(boards, draggedItem, adjacentItem);

      const draggedBoard = draggedItem.firstItem as Board;
      const selectedBoards = selectedPrivateBoardsSelector(store.getState());
      const selectedBoardsIds = selectedBoards.map(({ id }) => id);
      const oldLibraryId = draggedBoard?.library?.id;
      const boardsById = [draggedBoard, ...selectedBoards, ...boards].reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {} as { [id: string]: Board },
      );

      if (selectedBoardsIds.length) {
        boardsToMove = [...selectedBoardsIds];
      } else {
        boardsToMove = [draggedItem.firstItem.id];
      }

      const adjacentBoards: BetweenBoardsInput = {};

      if (previousItemId) {
        adjacentBoards.moveBeforeId = previousItemId;
      }

      if (nextItemId) {
        adjacentBoards.moveAfterId = nextItemId;
      }

      const previousBoard = previousItemId ? boardsById[previousItemId] : undefined;
      const nextBoard = nextItemId ? boardsById[nextItemId] : undefined;

      const boardsPositions = calculateNewBoardsPositions({
        previousPosition: previousBoard?.pos,
        nextPosition: nextBoard?.pos,
        boardsToMove,
      });

      const movedBoardsInfo = boardsToMove.map((boardId) => ({
        ...boardsById[boardId],
        pos: boardsPositions.find(({ id }) => id === boardId)?.pos || 0,
      }));

      if (draggedItem.parentId !== parentBoardId || library?.id !== oldLibraryId) {
        dispatch(
          moveBoardRequestAction({
            boards: [...boards, ...selectedBoards].map((board) => ({
              ...board,
              parentId: parentBoardId,
              ancestors: parentBoardId ? board.ancestors : [],
              library,
            })),
            newParentId: parentBoardId,
            oldParentId: draggedItem.parentId,
          }),
        );

        Boards.batchMoveBoards({
          boardIds: boardsToMove,
          newParentId: parentBoardId,
          libraryId: library?.id,
          workspaceId,
        }).then(() => {
          trackMovedBoards({
            board_ids: boardsToMove,
            new_parent_board_id: parentBoardId,
            new_library_id: library?.id,
          });
        });
      }

      try {
        if (adjacentBoards.moveAfterId || adjacentBoards.moveBeforeId) {
          // when all boards are library boards, no boards are shown in workspace boards sidenav
          Boards.updateBoardPositions({
            idsToMove: boardsToMove,
            betweenIds: adjacentBoards,
            parentBoardId: parentBoardId ?? '',
            workspaceId,
          });
        }

        if (movedBoardsInfo) {
          const rearrangedWithParent = movedBoardsInfo.map((board) => ({
            ...board,
            parentId: parentBoardId === 'root' ? null : parentBoardId,
            library: oldLibraryId === library?.id ? board.library : library,
          }));

          const boardIdsToRearrange = movedBoardsInfo.map((board) => board.id);

          if (draggedItem.parentId !== parentBoardId) {
            if (draggedItem.parentId) {
              removeBoardsFromAllViews({ parentBoardId: draggedItem.parentId, boardsIdsToRemove: boardIdsToRearrange });
            }

            changeSideBoardsParent(
              movedBoardsInfo[0].workspaceId,
              rearrangedWithParent,
              draggedItem.parentId,
              oldLibraryId,
            );
          } else if (oldLibraryId !== library?.id) {
            removeBoardsFromAllViews({ libraryId: oldLibraryId, boardsIdsToRemove: boardIdsToRearrange });

            const boardSort = getSubNavSortValue()?.boardSort;

            removeBoardsFromSideNav({
              workspaceId: movedBoardsInfo[0].workspaceId,
              boardIds: rearrangedWithParent.map((b) => b.id),
              libraryId: oldLibraryId,
              navSortParams: boardSort,
            });
            addBoardsToSideNav(rearrangedWithParent, boardSort);
          }
          rearrangeBoardsInAllLists(rearrangedWithParent);
        }
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: `Could not update board positions`,
          metadata: {
            data: {
              boardsToMove,
              adjacentBoards,
              parentBoardId,
            },
          },
        });
      }
    },
    [
      workspaceId,
      store,
      dispatch,
      trackMovedBoards,
      rearrangeBoardsInAllLists,
      changeSideBoardsParent,
      removeBoardsFromAllViews,
      getSubNavSortValue,
      removeBoardsFromSideNav,
      addBoardsToSideNav,
    ],
  );

  return {
    rearrangeBoards,
    rearrangeFavoriteBoards,
  };
};

export const useRearrangeAssets = () => {
  const store = useAirStore();
  const { rearrangeAssetsInGalleryView } = useRearrangeAssetsInGalleryView();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const rearrangeAssets = useCallback(
    async (
      assets: Clip[],
      draggedItem: GalleryItemToRearrange,
      adjacentItem: RearrangableItem,
      type: GalleryItemType.asset | GalleryItemType.file,
    ) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      const updatedAssets = [...assets];
      let boardClipsToMove: Array<{
        reelId: string;
        clipId: Clip['id'];
      }> = [];

      const { newIndex, nextItemId, previousItemId } = calculateRearrangeData(assets, draggedItem, adjacentItem);

      const adjacentClips: BetweenBoardClipsInput = {};

      const currentBoardId = getBoardIdFromPath(window.location.pathname);
      if (!currentBoardId) return;

      // TODO: move it to hook, but be careful with this selector - we don't want to update everything when selected items change
      const selectedClips = selectedClipIdsSelector(store.getState());

      if (previousItemId) {
        adjacentClips.moveBeforeBoardClip = {
          reelId: currentBoardId,
          clipId: previousItemId,
        };
      }

      if (nextItemId) {
        adjacentClips.moveAfterBoardClip = {
          reelId: currentBoardId,
          clipId: nextItemId,
        };
      }

      if (selectedClips.length) {
        selectedClips.forEach((asset) => {
          const currentIndex = updatedAssets.findIndex((item) => item.id === asset);
          moveItemToIndex(updatedAssets, currentIndex, newIndex);
        });

        boardClipsToMove = selectedClips.map((asset) => ({ reelId: currentBoardId, clipId: asset }));
      } else {
        const assetToMove = assets[draggedItem.index];

        moveItemToIndex(updatedAssets, draggedItem.index, newIndex);
        boardClipsToMove = [{ reelId: currentBoardId, clipId: assetToMove.id }];
      }

      try {
        Boards.updateBoardClipPositions({
          boardClipsToMove,
          betweenBoardClips: adjacentClips,
          parentBoardId: currentBoardId,
          workspaceId,
        });

        if (currentBoardId) {
          await rearrangeAssetsInGalleryView(currentBoardId, updatedAssets, type);
        }
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: `Could not update assets positions`,
          metadata: {
            data: {
              boardClipsToMove,
              adjacentClips,
              currentBoardId,
            },
          },
        });
      }
    },
    [rearrangeAssetsInGalleryView, store, workspaceId],
  );

  return {
    rearrangeAssets,
  };
};
