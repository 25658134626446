import { Check } from '@air/next-icons';
import classNames from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';
import isEqual from 'react-fast-compare';

/**
 * Determines the text color based on the background color.
 */
const getTextColor = (hex: string) => {
  const r = parseInt(hex.substring(1, 2), 16);
  const g = parseInt(hex.substring(3, 2), 16);
  const b = parseInt(hex.substring(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? 'text-black' : 'text-white';
};

export type Color = {
  hex: string;
  id: string;
  name: string;
};

export type ColorPickerProps = Omit<ComponentPropsWithoutRef<'div'>, 'onSelect'> & {
  colors?: Color[];
  onSelect?: (color: Color) => void;
  selectedColors?: Color[];
};

export const ColorPicker = ({ className, colors, onSelect, selectedColors, ...restOfProps }: ColorPickerProps) => {
  return (
    <div className={classNames('flex flex-wrap gap-2', className)} {...restOfProps}>
      {colors?.map((color) => {
        const isSelected = selectedColors?.some((selectedColor) => isEqual(selectedColor, color));
        const textColor = getTextColor(color.hex);

        return (
          <button
            className={classNames(
              'flex h-5 w-5 items-center justify-center rounded',
              color.hex === '#FFFFFF' ? 'border-gray-4 border' : 'border-none',
              textColor,
            )}
            data-hex={color.hex}
            data-name={color.name}
            key={color.id || color.name || color.hex}
            style={{ backgroundColor: color.hex }}
            onClick={() => onSelect?.(color)}
          >
            <Check
              className={classNames(
                'block h-4 w-4 transition-all ease-in-out',
                isSelected ? 'scale-100 opacity-100' : 'scale-0 opacity-0',
              )}
            />
          </button>
        );
      })}
    </div>
  );
};
