import {
  BillingCyclePricing,
  PlanValue,
  PricingModel,
  SubscriptionBillingCycle,
  SubscriptionPlan,
} from '@air/api/types';
import { isNull, isNumber, isUndefined } from 'lodash';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useBillableUsersCount } from '~/swr-hooks/members/useUserBillingStatusByAccountIdOrEmail';
import { useSubscriptionPlan } from '~/swr-hooks/subscriptions/useSubscriptionPlan';
import { useUpcomingInvoice } from '~/swr-hooks/subscriptions/useUpcomingInvoice';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export interface BillingProviderContextValues {
  /**
   * The cost of additional storage per GB.
   */
  additionalStoragePrice?: number;

  /**
   * The total cost of additional storage.
   */
  additionalStorageTotalPrice?: number;

  /**
   * If the user has auto renew enabled.
   */
  autoRenew?: boolean;

  /**
   * Whether or not a subscription is eligible for accrual of storage charges.
   */
  canAccrueStorageCharges?: boolean;

  /**
   * The expiration date of the current subscription.
   */
  expirationDate?: string | null;

  /**
   * The total cost of the current subscription which is fetched from Stripe.
   */
  invoiceTotal?: number;

  /**
   * Plan checks
   */
  isFreePlan?: boolean;
  isPlusPlan?: boolean;
  isProPlan?: boolean;
  isEnterprisePlan?: boolean;

  /**
   * If data is still being fetched.
   */
  isLoading: boolean;

  /**
   * If there was an error fetching data.
   */
  isError?: boolean;

  /**
   * Max amount of members allowed for the plan.
   */
  membersMax?: PlanValue;

  /**
   * Max amount of boards allowed for the plan.
   */
  boardsMax?: PlanValue;

  /**
   * The subscription plan information.
   */
  plan?: BillingCyclePricing;

  /**
   * The billing cycle of the current subscription plan.
   */
  planBillingCycle?: SubscriptionBillingCycle;

  /**
   * The name of the current subscription plan.
   */
  planName?: SubscriptionPlan;

  /**
   * The price per a member of the current subscription plan.
   */
  planPrice?: number;

  /**
   * The total price of the current subscription plan.
   */
  planTotalPrice?: number;

  /**
   * The monthly total price of the current subscription plan.
   */
  planMonthlyTotalPrice?: number;

  /**
   * The pricing model of the current subscription plan.
   */
  planPricingModel?: PricingModel;

  /**
   * The prorated charges for the current subscription plan. This is calculated using
   * the current subscription plan and the upcoming invoice.
   */
  proratedCharges?: number;

  /**
   * The maximum amount of storage allowed for the plan.
   */
  storageMax?: number | null;

  /**
   * The amount of billable members.
   */
  totalBillableMembers?: number;

  /**
   * The amount of billable members.
   */
  totalBillableSeats?: number;

  /**
   * The total price of the current subscription plan.
   */
  totalPrice?: number;
}

const DEFAULT_VALUE: BillingProviderContextValues = {
  additionalStoragePrice: undefined,
  additionalStorageTotalPrice: undefined,
  autoRenew: false,
  canAccrueStorageCharges: false,
  expirationDate: undefined,
  invoiceTotal: undefined,
  isLoading: true,
  isFreePlan: true,
  isPlusPlan: false,
  isProPlan: false,
  membersMax: undefined,
  plan: undefined,
  planBillingCycle: undefined,
  planMonthlyTotalPrice: undefined,
  planName: undefined,
  planPrice: undefined,
  planTotalPrice: undefined,
  planPricingModel: undefined,
  proratedCharges: undefined,
  storageMax: undefined,
  totalBillableMembers: undefined,
  totalPrice: undefined,
};

const PlanProviderContext = createContext<BillingProviderContextValues>(DEFAULT_VALUE);

export interface PlanProviderProps {
  children: ReactNode;
}

export const PlanProvider = ({ children }: PlanProviderProps) => {
  const { data: subscription, error: subscriptionError } = useSubscriptionContext();
  const { data: subscriptionPlan, isLoading: isSubscriptionPlanLoading } = useSubscriptionPlan();
  const { totalBillableMembers, totalBillableSeats } = useBillableUsersCount();
  const {
    data: upcomingInvoice,
    error: upcomingInvoiceError,
    isLoading: isUpcomingInvoiceLoading,
  } = useUpcomingInvoice();

  const values = useMemo<BillingProviderContextValues>(() => {
    const plan =
      subscription?.billingCycle &&
      subscriptionPlan &&
      subscriptionPlan.plan.billingCycles.find((cycle) => cycle.id === subscription.billingCycle);

    const planTotalPrice =
      plan?.planPrice && subscription?.pricingModel
        ? subscription.pricingModel === 'perMember'
          ? plan.planPrice * totalBillableSeats
          : plan.planPrice
        : undefined;

    const planMonthlyTotalPrice = planTotalPrice
      ? subscription?.billingCycle === 'annual'
        ? planTotalPrice / 12
        : subscription?.billingCycle === 'monthly'
        ? planTotalPrice
        : subscription?.billingCycle === 'quarter'
        ? planTotalPrice / 4
        : subscription?.billingCycle === 'semiAnnual'
        ? planTotalPrice / 2
        : undefined
      : undefined;

    const GB_IN_BYTES = 1e9;
    const usedStorage = subscription?.usedStorage;
    const includedStorage =
      subscription && isNumber(subscription?.includedGb)
        ? subscription.includedGb * GB_IN_BYTES
        : subscription?.includedGb;

    const canAccrueStorageCharges = !!subscription?.trackAdditionalStorageCharges;

    const overStorageLimit =
      canAccrueStorageCharges && isNumber(usedStorage) && isNumber(includedStorage)
        ? Math.max(usedStorage - includedStorage, 0)
        : undefined;

    const overage =
      subscriptionPlan && subscriptionPlan.plan.additionalStorage
        ? !isUndefined(overStorageLimit) && overStorageLimit >= 0
          ? Math.ceil(overStorageLimit / subscriptionPlan.plan.additionalStorage)
          : 0
        : undefined;

    const additionalStorageTotalPrice =
      plan?.additionalStoragePrice && !isUndefined(overage) && overage >= 0
        ? plan.additionalStoragePrice * overage
        : undefined;

    const totalPrice =
      !isUndefined(planTotalPrice) && !isUndefined(additionalStorageTotalPrice)
        ? planTotalPrice + additionalStorageTotalPrice
        : undefined;

    const amountDue = upcomingInvoice?.amountDue;

    const proratedCharges = !isUndefined(amountDue) && !isUndefined(totalPrice) ? amountDue - totalPrice : undefined;

    return {
      additionalStorageTotalPrice,
      additionalStoragePrice: plan?.additionalStoragePrice,
      autoRenew: !!subscription?.autoRenew,
      canAccrueStorageCharges,
      expirationDate: subscription?.expirationDate,
      invoiceTotal: upcomingInvoice?.amountDue,
      isFreePlan: subscription?.isInFreePlan,
      isLoading:
        (!subscription && isNull(subscriptionError)) ||
        isSubscriptionPlanLoading ||
        (isUpcomingInvoiceLoading && isUndefined(upcomingInvoiceError)),
      isPlusPlan: subscription?.isOnPlusPlan,
      isProPlan: subscription?.isOnProPlan,
      isEnterprisePlan: subscription?.isEnterprise,
      isError: !!subscriptionError || !!upcomingInvoiceError,
      membersMax: subscription?.maxMembers,
      boardsMax: subscription?.maxBoards,
      plan,
      planBillingCycle: subscription?.billingCycle,
      planMonthlyTotalPrice,
      planName: subscription?.plan,
      planPrice: plan?.planPrice,
      planTotalPrice,
      planPricingModel: subscription?.pricingModel,
      proratedCharges,
      storageMax: subscription?.maxStorage,
      totalBillableMembers,
      totalBillableSeats,
      totalPrice,
    };
  }, [
    isSubscriptionPlanLoading,
    isUpcomingInvoiceLoading,
    subscription,
    subscriptionError,
    subscriptionPlan,
    totalBillableMembers,
    totalBillableSeats,
    upcomingInvoice,
    upcomingInvoiceError,
  ]);

  return <PlanProviderContext.Provider value={values}>{children}</PlanProviderContext.Provider>;
};

export const usePlanContext = () => {
  const context = useContext(PlanProviderContext);

  if (context === DEFAULT_VALUE) {
    const error = 'PlanProviderContext used outside of PlanProvider';

    if (isDevOrTestStage()) {
      throw new Error(error);
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
