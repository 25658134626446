import { type Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';
import pluralize from 'pluralize';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { NotificationClip } from '~/components/AirNotifications/components/NotificationClip';
import { NotificationClips } from '~/components/AirNotifications/components/NotificationClips';

export type NotificationClipCreatedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification:
    | Notification<'clipAppended_userIsBoardFollower', 'account'>
    | Notification<'clipAppended_userIsShareLinkCreator', 'account'>;
};

export const NotificationClipCreatedType = ({ notification, onClick }: NotificationClipCreatedTypeProps) => {
  const { actor, board, clips, count } = notification.data;

  const meta = notification.data.shortUrl ? 'via content collection link' : !!board?.title && `in ${board.title}`;

  return (
    <NotificationBaseItem
      content={clips && clips.length > 1 && <NotificationClips clips={clips} totalClips={count} />}
      meta={meta}
      notification={notification}
      onClick={onClick}
      suffix={clips?.length === 1 && clips?.[0] && <NotificationClip clip={clips[0]} />}
      title={
        <>
          <b>{formatActorNameInitialsOrDefault(actor)}</b>
          {` uploaded ${pluralize('item', count, true)} to `}
          <b>{board.title}</b>
        </>
      }
    />
  );
};
