import { LibraryMembersWithPending } from '@air/api';
import { Library, LibraryMemberWithInfo } from '@air/api/types';
import { memo } from 'react';

import { isLibraryWorkspaceMember, useLibraryMembers } from '~/components/LibraryBeta/hooks/useLibraryMembers';
import { useLibraryPermissions } from '~/components/LibraryBeta/hooks/useLibraryPermissions';
import { LibraryInviteMemberContainer } from '~/components/LibraryBeta/LibraryInviteMember/LibraryInviteMember.container';
import { LibraryActiveMemberList } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/LibraryActiveMemberList';
import { LibraryPendingMemberList } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/LibraryPendingMemberList';
import {
  canAddOthersToPrivateOrHiddenLibrary,
  canAddOthersToPublicLibrary,
} from '~/utils/permissions/libraryPermissions';

interface LibraryMembersProps {
  library: Pick<Library, 'id' | 'title' | 'accessibility' | 'visibility'>;
  onLeave: () => void;
}

interface MembersTypes {
  activeMembers: LibraryMemberWithInfo[];
  pendingMembers: LibraryMembersWithPending;
}

export const LibraryMembers = memo(({ library, onLeave }: LibraryMembersProps) => {
  const { libraryPermissions } = useLibraryPermissions({ libraryId: library.id });

  const { data: libraryMembers = [] } = useLibraryMembers({
    libraryId: library.id,
    options: {
      hideSysadmins: true,
      withPendingWorkspaceMembers: true,
    },
  });

  const canInviteMembers =
    library?.accessibility === 'public'
      ? canAddOthersToPublicLibrary(libraryPermissions)
      : canAddOthersToPrivateOrHiddenLibrary(libraryPermissions);

  const { activeMembers, pendingMembers } = libraryMembers.reduce<MembersTypes>(
    (collected, member) => {
      if (isLibraryWorkspaceMember(member)) {
        if (member.pending) {
          collected.pendingMembers.push(member);
        } else {
          collected.activeMembers.push(member);
        }
      } else {
        collected.pendingMembers.push(member);
      }
      return collected;
    },
    { activeMembers: [], pendingMembers: [] },
  );

  if (canInviteMembers) {
    return (
      <>
        <LibraryInviteMemberContainer libraryId={library.id} />
        <div className="flex flex-col gap-6">
          <LibraryPendingMemberList members={pendingMembers} libraryId={library.id} />
          <LibraryActiveMemberList members={activeMembers} libraryId={library.id} onLeave={onLeave} />
        </div>
      </>
    );
  }

  return <LibraryActiveMemberList members={activeMembers} libraryId={library.id} onLeave={onLeave} />;
});

LibraryMembers.displayName = 'LibraryMembers';
