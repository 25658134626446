import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, useState } from 'react';

import { FavoriteBoards } from '~/components/CurrentWorkspaceNav/BoardsNav/FavoriteBoards';
import { WorkspaceBoards } from '~/components/CurrentWorkspaceNav/BoardsNav/WorkspaceBoards';
import { WorkspaceNavigation } from '~/components/CurrentWorkspaceNav/WorkspaceNavigation';
import { LibraryNavigation } from '~/components/LibraryBeta/LibraryNavigation/LibraryNavigation';
import { CoreMobileWorkspaceNavigationHeader } from '~/components/Mobile/CoreMobileWorkspaceNavigationHeader';

export type AirMobileWorkspaceNavigationProps = {
  onClose?: () => void;
};

export const AirMobileWorkspaceNavigation = memo(({ onClose }: AirMobileWorkspaceNavigationProps) => {
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>();
  const { libraries: librariesFlag } = useFlags();

  return (
    <div className="flex h-full flex-col px-2">
      <CoreMobileWorkspaceNavigationHeader onClose={onClose} />
      <div className="-mx-2 grow overflow-y-auto px-2" ref={setScrollElement}>
        <div className="-mx-2 my-2">
          <WorkspaceNavigation />
        </div>

        {!!scrollElement && (
          <>
            <FavoriteBoards scrollElement={scrollElement} />
            {librariesFlag ? (
              <LibraryNavigation scrollElement={scrollElement} />
            ) : (
              <WorkspaceBoards scrollElement={scrollElement} />
            )}
          </>
        )}
      </div>
    </div>
  );
});

AirMobileWorkspaceNavigation.displayName = 'AirMobileWorkspaceNavigation';
