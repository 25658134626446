import Router from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export type MobileNavigationContextValue = {
  activePanel: string | undefined;
  closePanel: () => void;
  setPanel: (panel: string) => void;
  togglePanel: (panel: string) => void;
};

export const MobileNavigationContext = createContext<MobileNavigationContextValue | undefined>(undefined);

export type MobileNavigationContextProviderProps = {
  children: ReactNode;
};

export const MobileNavigationContextProvider = ({ children }: MobileNavigationContextProviderProps) => {
  const [activePanel, setActivePanel] = useState<string | undefined>(undefined);

  const closePanel = useCallback(() => setActivePanel(undefined), []);

  const setPanel = useCallback((panel: string) => setActivePanel(panel), []);

  const togglePanel = useCallback(
    (panel: string) => {
      if (activePanel === panel) {
        closePanel();
      } else {
        setPanel(panel);
      }
    },
    [activePanel, closePanel, setPanel],
  );

  useEffect(() => {
    Router.events.on('routeChangeComplete', closePanel);

    return () => Router.events.off('routeChangeComplete', closePanel);
  }, [closePanel]);

  const value = useMemo(
    () => ({
      activePanel,
      closePanel,
      setPanel,
      togglePanel,
    }),
    [activePanel, closePanel, setPanel, togglePanel],
  );

  return <MobileNavigationContext.Provider value={value}>{children}</MobileNavigationContext.Provider>;
};

export const useMobileNavigationContext = () => {
  const context = useContext(MobileNavigationContext);

  if (!context) {
    throw new Error('useMobileNavigationContext must be used within a MobileNavigationContextProvider');
  }

  return context;
};
