import { Notification, NotificationType } from '@air/api/types';
import { NotificationsItem, NotificationsItemProps } from '@air/component-notifications';

import { NotificationAvatar } from '~/components/AirNotifications/components/NotificationAvatar';
import { formatCreatedAt } from '~/components/AirNotifications/utils/formatCreatedAt';

export type NotificationBaseItemProps = Omit<NotificationsItemProps, 'createdAt' | 'state'> & {
  notification: Notification<NotificationType, 'account'>;
};

export const NotificationBaseItem = ({ notification, ...restOfProps }: NotificationBaseItemProps) => {
  return (
    <NotificationsItem
      createdAt={formatCreatedAt(notification.createdAt)}
      data-id={notification.id}
      prefix={'actor' in notification.data ? <NotificationAvatar actor={notification.data.actor} /> : undefined}
      state={notification.readAt ? 'read' : 'unread'}
      {...restOfProps}
    />
  );
};
