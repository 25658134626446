import { useTrackCopiedContentCollectionLink } from '@air/analytics';
import { ShortUrlResponse } from '@air/api/types';
import { memo, useMemo } from 'react';

import { CopyField, CopyFieldProps } from '~/components/UI/CopyField';
import { NEW_TAB } from '~/constants/urls';

type ContentCollectionCopyLinkProps = {
  shortIdInfo: ShortUrlResponse;
  menuActions?: CopyFieldProps['menuActions'];
};

export const ContentCollectionCopyLink = memo(({ shortIdInfo, menuActions = [] }: ContentCollectionCopyLinkProps) => {
  const { trackCopiedContentCollectionLink } = useTrackCopiedContentCollectionLink();
  const shareUrl = `${window.location.origin}/d/${shortIdInfo.id}`;

  const _menuActions = useMemo(
    () => [
      {
        text: 'Preview link',
        action: () => {
          window.open(shareUrl, NEW_TAB);
        },
      },
      ...menuActions,
    ],
    [menuActions, shareUrl],
  );

  return (
    <div className="flex flex-col gap-1">
      <p className="text-12 font-bold uppercase text-grey-9">Link access</p>
      <CopyField url={shareUrl} menuActions={_menuActions} onCopy={trackCopiedContentCollectionLink} />
    </div>
  );
});

ContentCollectionCopyLink.displayName = 'ContentCollectionCopyLink';
