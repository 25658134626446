import { FormikColorPickerMenu } from '@air/formik-color-picker-menu';
import { FormikEmojiMenuProps } from '@air/formik-emoji-menu';
import { Color } from '@air/primitive-color-picker';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import dynamic from 'next/dynamic';

import { UpdateLibraryDetailsValues } from '~/components/LibraryBeta/LibrarySettingsModal/components/UpdateLibraryDetails';

const FormikEmojiMenu = dynamic<FormikEmojiMenuProps>(
  () => import('@air/formik-emoji-menu').then((mod) => mod.FormikEmojiMenu),
  { ssr: false },
);

export type UpdateLibraryIconProps = Pick<FormikEmojiMenuProps, 'className'> & {
  colors?: Color[];
};

export const UpdateLibraryIcon = ({ className, colors }: UpdateLibraryIconProps) => {
  const { values } = useFormikContext<UpdateLibraryDetailsValues>();

  return (
    <FormikEmojiMenu
      colorPicker={!!colors?.length && <FormikColorPickerMenu className="max-w-[256px]" colors={colors} name="color" />}
      name="icon"
      trigger={
        <button
          className={classNames(
            "relative flex h-8 w-8 items-center justify-center rounded bg-pigeon-50 text-14 leading-5 text-pigeon-800 before:absolute before:inset-0 before:content-[''] hover:before:bg-pigeon-800/10",
            className,
          )}
          style={{ backgroundColor: values.color?.hex }}
          type="button"
        >
          {values.icon}
        </button>
      }
    />
  );
};
