import { Plus } from '@air/next-icons';

import {
  LibraryAdvancedSettingsCustomFieldsPopover,
  LibraryAdvancedSettingsCustomFieldsPopoverProps,
} from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsCustomFieldsPopover';
import { LibraryAdvancedSettingsCustomFieldsSelectListItem } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsCustomFieldsPopoverItem';
import {
  LibraryAdvancedSettingsCustomFieldsSelectList,
  LibraryAdvancedSettingsCustomFieldsSelectListProps,
} from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsCustomFieldsSelectList';

export type LibraryAdvancedSettingsCustomFieldsProps = LibraryAdvancedSettingsCustomFieldsSelectListProps &
  Pick<LibraryAdvancedSettingsCustomFieldsPopoverProps, 'isOpen' | 'label' | 'onOpenChange'> & {
    onCreateCustomField?: () => void;
  };

export const LibraryAdvancedSettingsCustomFields = ({
  customFields,
  label,
  isLoading,
  isOpen,
  onApply,
  onCreateCustomField,
  onOpenChange,
}: LibraryAdvancedSettingsCustomFieldsProps) => {
  return (
    <div className="flex flex-col gap-4" data-testid="LIBRARY_SETTINGS_CUSTOM_FIELDS">
      <div className="flex flex-col gap-1">
        <h3 className="text-10 font-bold uppercase tracking-wide text-grey-12">Custom fields</h3>
        <div className="flex items-center justify-between gap-6">
          <p className="text-14 text-grey-10">Set the visibility of custom fields for all content in this library</p>
        </div>
      </div>
      <LibraryAdvancedSettingsCustomFieldsPopover label={label} isOpen={isOpen} onOpenChange={onOpenChange}>
        {!!customFields?.length ? (
          <LibraryAdvancedSettingsCustomFieldsSelectList
            customFields={customFields}
            isLoading={isLoading}
            onApply={onApply}
          />
        ) : (
          <div className="flex flex-col p-1">
            {!!onCreateCustomField ? (
              <LibraryAdvancedSettingsCustomFieldsSelectListItem
                onClick={onCreateCustomField}
                prefix={<Plus className="h-4 w-4" />}
              >
                Add a custom field to your workspace
              </LibraryAdvancedSettingsCustomFieldsSelectListItem>
            ) : (
              <div className="px-2 py-1 text-14 text-grey-11">No custom fields in your workspace.</div>
            )}
          </div>
        )}
      </LibraryAdvancedSettingsCustomFieldsPopover>
    </div>
  );
};
