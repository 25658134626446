import { Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export type NotificationLibraryMemberInvitedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification: Notification<'libraryMemberInvited_userIsMember', 'account'>;
};

export const NotificationLibraryMemberInvitedType = ({
  notification,
  onClick,
}: NotificationLibraryMemberInvitedTypeProps) => {
  const { currentWorkspace } = useCurrentWorkspace();

  return (
    <NotificationBaseItem
      meta={!!currentWorkspace?.name && `in ${currentWorkspace?.name}`}
      notification={notification}
      onClick={onClick}
      title={
        <>
          <b>{formatActorNameInitialsOrDefault(notification.data.actor)}</b>
          {` invited you to `}
          <b>{notification.data.library.title}</b>
        </>
      }
    />
  );
};
