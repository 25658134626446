import { CustomFieldType, CustomFieldTypeName } from '@air/api/types';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useMemo } from 'react';

import { customFieldIconMap } from '~/constants/customFieldIconMap';

interface CustomFieldTypeSelectListItemProps {
  fieldTypeId: string;
  fieldTypes: CustomFieldType[];
}

const fieldDescriptionMap: Record<CustomFieldTypeName, string> = {
  [CustomFieldTypeName.singleSelect]: `Values can be arranged as columns in a Kanban board.
e.g. “Status”`,

  [CustomFieldTypeName.multiSelect]: `Useful when an item can have multiple values.
e.g. “Social media platform”`,

  [CustomFieldTypeName.date]: `For storing time-based info.
e.g. “Expiration date”`,

  [CustomFieldTypeName.plainText]: `Free text field for more descriptive metadata.
e.g. “Credit notes”`,
};

export const CustomFieldTypeSelectListItem = ({ fieldTypeId, fieldTypes }: CustomFieldTypeSelectListItemProps) => {
  const fieldType = useMemo(() => fieldTypes.find((ft) => ft.id === fieldTypeId), [fieldTypeId, fieldTypes]);
  if (!fieldType) return null;

  const Icon = customFieldIconMap[fieldType.title];
  return (
    <div className={classNames('flex grow cursor-pointer items-center gap-3 rounded p-1.5 hover:bg-grey-4')}>
      <Icon className="mt-1 h-4 w-4 shrink-0 self-start text-grey-10" />

      <div className="flex grow flex-col">
        <div className="text-14 font-medium text-grey-11">{capitalize(fieldType.title)}</div>

        <div className="text-12 text-grey-10">{fieldDescriptionMap[fieldType.title]}</div>
      </div>
    </div>
  );
};
