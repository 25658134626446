import { CustomFieldType } from '@air/api/types';
import { FormikField } from '@air/formik-field';
import { Input } from '@air/primitive-input';
import { useField } from 'formik';
import { memo, useEffect } from 'react';

import { CUSTOM_FIELD_MODAL_FORM_DESCRIPTION, CUSTOM_FIELD_MODAL_FORM_TITLE } from '../../CustomFieldModal';
import { CustomFieldModalFormData } from '../types';
import { FormikCustomFieldTypeSelect } from './FormikCustomFieldTypeSelect';

export interface CustomFieldModalFormBaseFieldsProps {
  modalDescription: string;
  canEditFieldType?: boolean;
  fieldTypes: CustomFieldType[];
  handleDisableSave: (isDisabled: boolean) => void;
}

export const CustomFieldModalFormBaseFields = memo(
  ({ modalDescription, canEditFieldType, fieldTypes, handleDisableSave }: CustomFieldModalFormBaseFieldsProps) => {
    const [_t, titleMetadata] = useField<CustomFieldModalFormData['name']>(CUSTOM_FIELD_MODAL_FORM_TITLE);
    const [_d, descriptionMetadata] = useField<CustomFieldModalFormData['description']>(
      CUSTOM_FIELD_MODAL_FORM_DESCRIPTION,
    );
    const hasBaseFieldErrors = !!(descriptionMetadata.error || titleMetadata.error);

    useEffect(() => {
      handleDisableSave(hasBaseFieldErrors);
    }, [hasBaseFieldErrors, handleDisableSave]);

    return (
      <div className="mt-6 flex flex-col gap-6">
        <p className="text-16 text-grey-11">{modalDescription}</p>
        <div className="flex flex-col items-baseline gap-3 md:flex-row">
          <FormikField
            id="name"
            className="flex-1"
            component={<Input autoFocus data-1p-ignore placeholder="e.g. Priority" size="large" />}
            label="Field Title *"
            name="name"
          />
          <FormikCustomFieldTypeSelect className="flex-1" canEditFieldType={canEditFieldType} fieldTypes={fieldTypes} />
        </div>
        <FormikField
          id="description"
          className="flex-1"
          component={<Input data-1p-ignore placeholder="Describe your custom field in a sentence" size="large" />}
          label="Description"
          name="description"
        />
      </div>
    );
  },
);

CustomFieldModalFormBaseFields.displayName = 'CustomFieldModalFormBaseFields';
