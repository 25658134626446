import { permissionsSelector } from '~/store/permissions/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface UseShortUrlPermissionsParams {
  shortId: string | undefined;
}

export const useShortUrlPermissions = ({ shortId }: UseShortUrlPermissionsParams) => {
  const shortUrlPermissions = useAirSelector((st) => permissionsSelector(st, 'shortIds', shortId));

  return {
    shortUrlPermissions,
  };
};
