import { type NotificationBoard as NotificationBoardType } from '@air/api/types';
import classNames from 'classnames';

import BoardThumbnail from '~/components/BoardThumbnail/BoardThumbnail';

export type NotificationBoardProps = {
  board: NotificationBoardType;
};

export const NotificationBoard = ({ board }: NotificationBoardProps) => {
  return (
    <div className={classNames('relative flex h-14 w-14 items-center justify-center rounded')}>
      <BoardThumbnail
        board={board}
        width={56}
        height={56}
        sizes="56"
        className="![&>img]:h-[inherit] [&>img]:object-cover"
      />
    </div>
  );
};
