import { getIsSAMLEnforced, useCurrentUserInfo } from '@air/utils-auth';
import { useEffect } from 'react';

import { useSamlSSOSigninRedirect } from '~/hooks/useSamlSSOSigninRedirect.ts';

/**
 * Our SAML users, after logging out (whether manually or by some session duration/activity timeout),
 * want to be redirected to the SAML login screen instead of the generic one. This hook checks
 * if the user is a SAML user and, if so, configures the redirect URL for the signout flow.
 */
export const useHandleConfigureSignoutUrl = () => {
  const { data: currentUserInfo } = useCurrentUserInfo();
  const email = currentUserInfo?.attributes.email;
  const { enable } = useSamlSSOSigninRedirect();

  useEffect(() => {
    if (email) {
      getIsSAMLEnforced(email).then((isEnforced) => {
        if (isEnforced) {
          enable();
        }
      });
    }
  }, [email, enable]);
};
