import { useTrackCreatedContentCollectionForm } from '@air/analytics';
import { Forms } from '@air/api';
import { useMutation } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export const useCreateForm = () => {
  const { trackCreatedContentCollectionForm } = useTrackCreatedContentCollectionForm();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const createForm = useMutation({
    mutationFn: ({ form }: Omit<Parameters<typeof Forms.createForm>[0], 'workspaceId'>) => {
      if (!workspaceId) {
        throw new Error('Workspace ID is not defined');
      }

      return Forms.createForm({
        workspaceId,
        form,
      });
    },
    onSuccess: (data) => {
      trackCreatedContentCollectionForm();

      return data;
    },
    onError: (error, params) => {
      throw reportErrorToBugsnag({
        error,
        context: `Failed to create a new content collection form`,
        metadata: {
          params,
        },
      });
    },
  });

  return {
    createForm,
  };
};
