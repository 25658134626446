import { useTrackAssetsMerged } from '@air/analytics';
import { Assets } from '@air/api';
import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { updateCentralizedClipAction } from '~/store/centralizedClip/actions';
import { SelectableGalleryAssetItem, SelectableGalleryFileItem } from '~/store/selectedItems/types';
import { useUpdateBoardStats } from '~/utils/mutateUtils/BoardStats';
import { useConvertAssetToVersionInAllViews } from '~/utils/mutateUtils/GalleryAssets';

type UseMergeVersionReturn = {
  mergeVersions: (props: {
    targetAsset: Clip;
    assetsToMerge: Clip['assetId'][];
    draggedClips: (SelectableGalleryAssetItem | SelectableGalleryFileItem)[];
    boardId?: Board['id'];
  }) => void;
};

export const useMergeVersions = () => {
  const dispatch = useDispatch();
  const { convertAssetToVersionInAllViews } = useConvertAssetToVersionInAllViews();
  const { updateBoardStats } = useUpdateBoardStats();
  const { trackAssetsMerged } = useTrackAssetsMerged();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const mergeAssets: UseMergeVersionReturn['mergeVersions'] = useCallback(
    async ({ targetAsset, assetsToMerge, draggedClips, boardId }) => {
      if (!workspaceId) {
        return;
      }

      const clipIdsToRemove: Clip['id'][] = [];
      const filesIdsToRemove: Clip['id'][] = [];

      const draggedDiscussionCount = draggedClips.reduce((acc, curr) => acc + (curr.item?.openDiscussionCount || 0), 0);

      draggedClips.map((clip) => {
        clip.item.type === 'nonMedia' ? filesIdsToRemove.push(clip.id) : clipIdsToRemove.push(clip.id);
      });

      const newDiscussionNumber = targetAsset.openDiscussionCount
        ? draggedDiscussionCount + targetAsset.openDiscussionCount
        : draggedDiscussionCount;

      const init = 0;
      const draggedVersionsCount = draggedClips.reduce((acc, clip) => acc + clip.item.version, init);

      const newVersionCount = draggedVersionsCount + targetAsset.version;

      const lastDraggedClip = draggedClips[draggedClips.length - 1];
      const newTopVersion = lastDraggedClip.item as Clip;

      const newVersion = {
        ...newTopVersion,
        assetId: targetAsset.assetId,
        version: newVersionCount,
        assetVersionCount: newVersionCount,
        openDiscussionCount: newDiscussionNumber,
      };

      convertAssetToVersionInAllViews({
        parentBoardId: boardId,
        draggedIds: draggedClips.map((clip) => clip.id),
        targetAsset,
        newVersion,
      });

      await Assets.merge({
        assetId: targetAsset.assetId,
        workspaceId,
        assetsToMerge,
        options: { boardId },
      });
      if (boardId) updateBoardStats(boardId);
      dispatch(updateCentralizedClipAction({ clip: newVersion }));
      trackAssetsMerged();
    },
    [workspaceId, convertAssetToVersionInAllViews, updateBoardStats, dispatch, trackAssetsMerged],
  );

  return {
    mergeAssets,
  };
};
