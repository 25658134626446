import { Notifications } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const WORKSPACES_NOTIFICATIONS_SUMMARY = 'WORKSPACES_NOTIFICATIONS_SUMMARY';

export const getNotificationSummaryKey = (workspaceId?: string) =>
  workspaceId ? [WORKSPACES_NOTIFICATIONS_SUMMARY, { workspaceId }] : [WORKSPACES_NOTIFICATIONS_SUMMARY];

export const useGetNotificationSummary = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const key = getNotificationSummaryKey(currentWorkspace?.id);

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Notifications.getNotificationSummary({ workspaceId });
    },
    enabled: !!currentWorkspace?.id,
  });
};
