import { type LibraryWithPermissions } from '@air/api/types';
import { TreeHeader, TreeList } from '@air/component-tree';
import { Plus } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { useAirModal } from '@air/provider-modal';
import { memo, useCallback, useMemo, useState } from 'react';

import { BoardsNavLoadingSkeleton } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsNavLoadingSkeleton';
import { useSidebarSectionCollapseState } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/hooks/useSidebarSectionCollapseState';
import { useLibraries } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { LibraryCreateModal } from '~/components/LibraryBeta/LibraryCreateModal/LibraryCreateModal';
import { LibraryNavigationGeneralItem } from '~/components/LibraryBeta/LibraryNavigation/components/LibraryNavigationGeneralItem/LibraryNavigationGeneralItem';
import {
  LibraryNavigationItem,
  LibraryNavigationItemProps,
} from '~/components/LibraryBeta/LibraryNavigation/components/LibraryNavigationItem';
import { SIDEBAR_LIBRARIES_COLLAPSED } from '~/constants/localStorageKeys';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useLibrariesBeingDeleted } from '~/swr-hooks/libraries/useLibrariesBeingDeleted';
import { canCreateLibrary } from '~/utils/permissions/libraryPermissions';

import { BoardsNavSectionTextButton } from '../../CurrentWorkspaceNav/BoardsNav/BoardsNavSection/BoardsNavSectionTextButton';

const LIBRARIES_LINE_WIDTH_PERCENTAGES = [47, 68, 41, 60, 52];

export type LibraryNavigationProps = {
  scrollElement: HTMLDivElement;
};

export const LibraryNavigation = memo(({ scrollElement }: LibraryNavigationProps) => {
  const [expandedTreeNodes, setExpandedTreeNodes] = useState<{ [libraryId: string]: boolean }>({});
  const { isCollapsed, handleToggleCollapse } = useSidebarSectionCollapseState({
    storageKey: SIDEBAR_LIBRARIES_COLLAPSED,
  });
  const [showLibraryCreateModal] = useAirModal(LibraryCreateModal);
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const canCreateLibraries = canCreateLibrary(workspacePermissions);
  const librariesBeingDeleted = useLibrariesBeingDeleted();
  const { data: libraries, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useLibraries();
  const flattenedLibraries = useMemo(
    () => libraries?.pages.flatMap((page) => page.data).filter((Item) => !librariesBeingDeleted.includes(Item.id)),
    [libraries?.pages, librariesBeingDeleted],
  );

  useFetchObjectsPermissions({
    objects: {
      libraryIds: flattenedLibraries?.map(({ id }) => id),
    },
  });

  const onOpenChange = useCallback<Required<LibraryNavigationItemProps>['onOpenChange']>(({ isOpen, id }) => {
    setExpandedTreeNodes((prev) => ({
      ...prev,
      [id]: isOpen,
    }));
  }, []);

  return (
    <>
      <TreeHeader
        adornmentRight={
          canCreateLibraries && (
            <IconButton
              data-testid="LIBRARIES_NAVIGATION_CREATE_LIBRARY_BUTTON"
              appearance="ghost"
              color="grey"
              icon={Plus}
              label="Create new library"
              onClick={showLibraryCreateModal}
              size="small"
            />
          )
        }
        isExpanded={!isCollapsed}
        onTitleClick={handleToggleCollapse}
        showAdornmentRight
        title="Libraries"
      />

      {isLoading && <BoardsNavLoadingSkeleton sectionLinesData={LIBRARIES_LINE_WIDTH_PERCENTAGES} />}

      {!isLoading && !isCollapsed && (
        <>
          <LibraryNavigationGeneralItem />

          {!!flattenedLibraries?.length && (
            <TreeList<LibraryWithPermissions>
              scrollElement={scrollElement}
              getEstimateSize={() => 36}
              items={flattenedLibraries}
              renderListItem={({ data }) => {
                return (
                  <LibraryNavigationItem
                    library={data}
                    onOpenChange={onOpenChange}
                    open={expandedTreeNodes[data.id]}
                    key={data.id}
                  />
                );
              }}
            />
          )}

          {hasNextPage && !isFetchingNextPage && (
            <BoardsNavSectionTextButton onClick={fetchNextPage} text="Show more" />
          )}

          {isFetchingNextPage && <BoardsNavSectionTextButton text="Loading..." />}
        </>
      )}
    </>
  );
});

LibraryNavigation.displayName = 'LibraryNavigation';
