import { ImporterListItem, ImportStatus } from '@air/api/types';
import { useEffect } from 'react';
import { usePrevious } from 'react-use';

import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getImportsInProgress } from '~/swr-hooks/useImports';
import { useRefreshAssetsInAllViews } from '~/utils/mutateUtils/GalleryAssets';
import { useRefreshBoardsInAllViews } from '~/utils/mutateUtils/GalleryBoards';
import { useRefreshSideNavBoards } from '~/utils/mutateUtils/SideNavBoards';

interface ImportedFilesRefreshProps {
  imports: ImporterListItem[];
}

export const ImportedFilesRefresh = ({ imports }: ImportedFilesRefreshProps) => {
  const currentBoardId = useURLBoardIdSelector();
  const previousImports = usePrevious(imports);
  const { currentWorkspace } = useCurrentWorkspace();
  const { refreshBoardsInAllViews } = useRefreshBoardsInAllViews();
  const { refreshAssetsInAllViews } = useRefreshAssetsInAllViews();
  const { refreshSideNavSubBoards, refreshRootSideNavBoards } = useRefreshSideNavBoards();

  useEffect(() => {
    const previousImportsInProgress = getImportsInProgress(previousImports);
    const completedImports = imports.filter(({ status }) => status === ImportStatus.completed);

    previousImportsInProgress.forEach((oldImport) => {
      const newImport = completedImports.find(({ id }) => id === oldImport.id);
      if (newImport) {
        if (newImport.board && newImport.board.id === currentBoardId) {
          refreshBoardsInAllViews(currentBoardId);
        }

        if (!currentBoardId || currentBoardId === newImport.board?.id) {
          refreshAssetsInAllViews({ parentBoardId: newImport.board?.id });
        }

        if (currentWorkspace && !newImport.board?.id) {
          refreshRootSideNavBoards(currentWorkspace?.id);
        } else if (newImport.board?.id) {
          refreshSideNavSubBoards(newImport.board.id);
        }
      }
    });
    // we want to call it only when imports array changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imports]);

  return null;
};
