import { CompleteLargeUploadParams, useCompleteLargeUpload } from '@air/upload-utils';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { privateUploadBoardIdSelector } from '~/store/privateUploadMetadata/selectors';
import { useAirStore } from '~/utils/ReduxUtils';
import { sendPrivateMultiPartUploadComplete } from '~/utils/uploadApi/privateUploadApi/privateLargeUploadApi';

export const useCompleteLargePrivateUpload = () => {
  const store = useAirStore();
  const { completeLargeUpload } = useCompleteLargeUpload();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const completeLargePrivateUpload = useCallback(
    (params: Pick<CompleteLargeUploadParams, 'onUploadFinished' | 'uploadId' | 'onError'>) => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return completeLargeUpload({
        ...params,
        sendUploadComplete: (upload, sortedParts) => {
          const parentBoardId = privateUploadBoardIdSelector(store.getState(), upload.id);

          return sendPrivateMultiPartUploadComplete({
            uploadedParts: sortedParts,
            uploadUrlInfo: upload.s3Info.uploadUrlInfo,
            parentBoardId,
            workspaceId,
          });
        },
      });
    },
    [completeLargeUpload, store, workspaceId],
  );

  return {
    completeLargePrivateUpload,
  };
};
