import { RecentlyDeleted } from '@air/api';
import { type Workspace } from '@air/api/types';
import { type QueryObserverOptions, useInfiniteQuery } from '@tanstack/react-query';
import { isObject } from 'lodash';
import invariant from 'tiny-invariant';

import { useRecentlyDeletedTableViewItems } from '~/components/RecentlyDeleted/hooks/useRecentlyDeletedTableViewItems';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { callAirApi } from '~/swr-hooks/callAirApi';

import { RecentlyDeletedResponse } from '../../types';

export const RECENTLY_DELETED_KEY = 'RECENTLY_DELETED';

export type RecentlyDeletedKey = [
  typeof RECENTLY_DELETED_KEY,
  {
    workspaceId?: Workspace['id'];
  },
];

export const getRecentlyDeletedKey = (workspaceId?: Workspace['id']): RecentlyDeletedKey => [
  RECENTLY_DELETED_KEY,
  {
    workspaceId,
  },
];

export const isRecentlyDeletedKey = (key: unknown): key is RecentlyDeletedKey => {
  return Array.isArray(key) && key[0] === RECENTLY_DELETED_KEY && isObject(key[1]);
};

export type UseRecentlyDeletedParams = Pick<QueryObserverOptions, 'enabled'> & {
  key?: RecentlyDeletedKey;
};

export const useRecentlyDeleted = (params?: UseRecentlyDeletedParams) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey: getRecentlyDeletedKey(currentWorkspace?.id),
    queryFn: async ({ pageParam }) => {
      invariant(currentWorkspace, 'Current workspace should be defined');

      return callAirApi<RecentlyDeletedResponse>(() =>
        RecentlyDeleted.list({
          cursor: pageParam || undefined,
          workspaceId: currentWorkspace.id,
        }),
      );
    },
    enabled: params?.enabled && !!currentWorkspace?.id,
    getNextPageParam: (prevPage) => prevPage?.pagination?.cursor,
    initialPageParam: '',
  });

  return useRecentlyDeletedTableViewItems({
    data: data?.pages ?? [],
    fetchNextPage,
    hasNextPage: !!hasNextPage,
    isFetchingNextPage,
    isInitialLoading: isLoading,
  });
};
