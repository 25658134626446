import { Search as SearchIcon } from '@air/next-icons';
import { Input, InputProps } from '@air/primitive-input';
import { memo } from 'react';

export type LibrarySearchProps = Pick<InputProps, 'onChange'>;

export const LibrarySearch = memo(({ onChange }: LibrarySearchProps) => {
  return (
    <div className="relative w-full">
      <Input
        size="large"
        name="search-libraries"
        onChange={onChange}
        placeholder="Search libraries"
        type="search"
        className="w-full pl-9"
      />
      <SearchIcon className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-grey-10" />
    </div>
  );
});

LibrarySearch.displayName = 'LibrarySearch';
