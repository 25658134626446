import { AlertModal, AlertModalCloseButton, AlertModalTitle } from '@air/primitive-alert-modal';
import { Banner } from '@air/primitive-banner';
import { Button } from '@air/primitive-button';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { BoardSearchLibrary, useSingleBoardSearchState } from '~/components/BoardSearch/shared/hooks';
import { BoardSelectAllAssetsAndFilesListItem } from '~/components/BoardSelect/components/BoardSelectAllAssetsAndFilesListItem';
import { PrivateBoardSelect, PrivateBoardSelectProps } from '~/components/BoardSelect/PrivateBoardSelect';
import { BOARDS_SELECT_EMPTY_OPTION } from '~/constants/BoardSearch';
import { ORGANIZE_YOUR_FILES_MODAL, TRANSACTION_MODAL_PRIMARY_CTA } from '~/constants/testIDs';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useSavedFilterIdSelector } from '~/hooks/useSavedFilterIdSelector';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { BoardsSelectOption } from '~/types/BoardSearch';
import { canCreateAsset } from '~/utils/permissions/assetPermissions';

export interface PreUploadModalProps {
  onCancel: () => void;
  initialBoard?: PrivateBoardSelectProps['initialBoard'];
  onUpload: (selectedBoard: BoardsSelectOption | null) => void;
  initialLibrary?: BoardSearchLibrary;
}

export const PreUploadModal = ({ initialBoard, onClose, onUpload, onCancel }: AirModalProps<PreUploadModalProps>) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const isViewingSavedFilter = useSavedFilterIdSelector();
  const isViewingLibraryPage = useURLLibraryIdSelector();
  const board = useSelector(centralizedBoardSelector);
  const { selectedBoard, isBoardSelected, onSelectBoard, shouldShowSubBoards, isLibrarySelected, onSelectLibrary } =
    useSingleBoardSearchState({
      initialBoard,
      initialLibrary: board?.library,
    });

  const { getBoardPermissions } = useBoardPermissionsCache();

  const upload = () => {
    onUpload(selectedBoard);
    onClose();
  };

  const onDismissModal = () => {
    onSelectBoard(BOARDS_SELECT_EMPTY_OPTION);
    onCancel();
    onClose();
  };

  return (
    <AlertModal
      className="flex flex-col gap-4"
      data-testid={ORGANIZE_YOUR_FILES_MODAL}
      isOpen
      leastDestructiveRef={cancelButtonRef}
      onDismiss={onDismissModal}
    >
      <header className="flex items-center justify-between">
        <AlertModalTitle>Upload files to…</AlertModalTitle>
        <AlertModalCloseButton onClick={onDismissModal} />
      </header>
      <div className="flex flex-col gap-4">
        {!!isViewingSavedFilter && (
          <Banner appearance="warning">
            <p className="font-medium">Unable to upload to a saved filter</p>
            <p>
              Saved Filters are unable to receive direct content. Please upload to the All assets & files, or a board
            </p>
          </Banner>
        )}

        {!!isViewingLibraryPage && (
          <Banner appearance="warning">
            <p className="font-medium">Unable to upload to a Library</p>
            <p>Libraries are unable to receive direct content. Please upload to a board in the library.</p>
          </Banner>
        )}

        <PrivateBoardSelect
          getBoardDisabledMessage={() => `You don't have permission to upload to this board`}
          initialBoard={initialBoard}
          isBoardDisabled={(board) => !canCreateAsset(getBoardPermissions(board.id))}
          isBoardSelected={isBoardSelected}
          isLibrarySelected={isLibrarySelected}
          onSelectBoard={onSelectBoard}
          onSelectLibrary={onSelectLibrary}
          shouldShowSubBoards={shouldShowSubBoards}
        >
          <BoardSelectAllAssetsAndFilesListItem isSelected={!selectedBoard} onSelect={() => onSelectBoard(null)} />
        </PrivateBoardSelect>
      </div>
      <footer className="flex justify-end gap-3">
        <Button appearance="ghost" color="grey" onClick={onDismissModal} size="large" ref={cancelButtonRef}>
          Cancel
        </Button>
        <Button
          appearance="filled"
          color="blue"
          data-testid={TRANSACTION_MODAL_PRIMARY_CTA}
          disabled={!!isViewingLibraryPage && !selectedBoard}
          onClick={upload}
          size="large"
        >
          Upload
        </Button>
      </footer>
    </AlertModal>
  );
};
