import { Link } from '@air/next-icons';
import { Button } from '@air/primitive-button';

import { CREATE_LINK_BUTTON, NO_SHARE_LINK } from '~/constants/testIDs';

interface SharePrivateItemCreateLinkProps {
  onCreate: () => void;
  itemName?: string;
  description?: string;
  isCreating?: boolean;
}

export const SharePrivateItemCreateLink = ({
  onCreate,
  itemName = 'Link',
  description = 'No shareable link',
  isCreating,
}: SharePrivateItemCreateLinkProps) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <Link className="h-4 w-4 text-pigeon-300" />
        <div className="pl-2 text-16 text-pigeon-400" data-testid={NO_SHARE_LINK}>
          {description}
        </div>
      </div>
      <Button
        appearance="filled"
        color="blue"
        data-testid={CREATE_LINK_BUTTON}
        isLoading={isCreating}
        onClick={onCreate}
        size="large"
      >
        Create {itemName}
      </Button>
    </div>
  );
};
