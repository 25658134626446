import { tailwindMerge } from '@air/tailwind-variants';
import { constant } from 'lodash';
import { ComponentProps, memo } from 'react';
import { DragObjectWithType, DropTargetMonitor, useDrop } from 'react-dnd';

import DragType, { DropData, DropLocation } from '~/components/Shared/Drag/dragTypes';

export interface DropRearrangeAreaProps<T extends DragObjectWithType> {
  dropLocation: DropLocation;
  onItemRearrange: (draggedItem: T) => void;
  canDropItem?: (draggedItem: T) => boolean;
  dragTypes: DragType[];
  testId?: string;
  containerProps?: ComponentProps<'div'>;
  barProps?: ComponentProps<'div'>;
}

function collectDropData<T extends DragObjectWithType>(
  canDropItem: DropRearrangeAreaProps<T>['canDropItem'] = constant(true),
) {
  return (monitor: DropTargetMonitor): { isOver: boolean } => {
    const isOver = monitor.isOver({ shallow: false });
    const item = monitor.getItem();

    return {
      isOver: isOver && item && canDropItem(item),
    };
  };
}

/**
 * This component is used to handle rearranging boards and workspaces in navigation
 * As well as rearranging versions in the asset modal
 * It displays horizontal line if dragged item is over it
 */
const _DropRearrangeArea = <T extends DragObjectWithType>({
  canDropItem = constant(true),
  dragTypes,
  dropLocation,
  onItemRearrange,
  containerProps = {},
  barProps = {},
  testId,
}: DropRearrangeAreaProps<T>) => {
  const onDrop = (item: T): DropData | undefined => {
    const canDrop = canDropItem(item);

    if (canDrop) {
      onItemRearrange(item);

      return {
        type: 'rearrange',
        location: dropLocation,
      };
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: dragTypes,
    collect: collectDropData(canDropItem),
    drop: onDrop,
  });

  const { className: containerClassName, ...restOfContainerProps } = containerProps;
  const { className: barPropsClassName, ...restOfBarProps } = barProps;

  return (
    <div
      data-testid={testId}
      ref={drop}
      className={tailwindMerge('absolute z-10 flex h-1 w-full', containerClassName)}
      {...restOfContainerProps}
    >
      <div
        className={tailwindMerge('flex h-1 w-full rounded-full', isOver && 'bg-blue-9', barPropsClassName)}
        {...restOfBarProps}
      />
    </div>
  );
};

export const DropRearrangeArea = memo(_DropRearrangeArea) as typeof _DropRearrangeArea;

// @ts-ignore - can't get displayName to work with a generic prop - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
DropRearrangeArea.displayName = 'DropRearrangeArea';
