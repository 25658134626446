import pluralize from 'pluralize';

import { MemberInfo } from '~/components/MemberInfo/MemberInfo';
import { MemberListRow } from '~/components/MemberListRow';
import { MemberRoleMenu } from '~/components/MemberRoleMenu';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';

export const MembersList = () => {
  const { members } = useWorkspaceMembers();

  if (!members) {
    return null;
  }

  return (
    <>
      <h3 className="text-14 font-semibold text-pigeon-500">{pluralize('member', members.allMembers.length, true)}</h3>
      <div>
        {members.allMembers.map((member) => (
          <MemberListRow key={member.email}>
            <MemberInfo member={member} />
            <MemberRoleMenu member={member} />
          </MemberListRow>
        ))}
      </div>
    </>
  );
};
