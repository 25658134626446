import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';

import { DNDAssetGalleryCardDragPreview } from '~/components/DndAssetDragPreview';
import { DNDBoardGalleryCardDragPreview } from '~/components/DndBoardDragPreview';
import { DndDragPreviewContainer } from '~/components/DndDragPreviewContainer';
import { DNDFileGalleryCardDragPreview } from '~/components/DNDFileGalleryCardDragPreview';
import DragType, { DraggingItems } from '~/components/Shared/Drag/dragTypes';

import { ReactDndDragItemsCount } from './shared/ReactDndDragItemsCount';

interface MoveItemsDragPreviewProps {
  items: DraggingItems<Board | Clip>;
}

export const GalleryItemsDragPreview = ({
  items: { type, firstItem, mixedTypes, count = 1 },
}: MoveItemsDragPreviewProps) => {
  const renderContent = useCallback(() => {
    switch (type) {
      case DragType.boardNavWorkspace:
      case DragType.boardNavFavorites:
      case DragType.boardCard:
      case DragType.boardNavLibrary:
        return <DNDBoardGalleryCardDragPreview board={firstItem as Board} />;
      case DragType.asset:
        return <DNDAssetGalleryCardDragPreview asset={firstItem as Clip} />;
      case DragType.file:
        return <DNDFileGalleryCardDragPreview file={firstItem as Clip} />;
    }
  }, [type, firstItem]);

  return (
    <div className="relative">
      {count > 2 && <DndDragPreviewContainer className="absolute left-2 top-2" />}
      {count > 1 && <DndDragPreviewContainer className="absolute left-1 top-1" />}
      <DndDragPreviewContainer className="absolute left-0 top-0 flex">
        {renderContent()}
        {count > 1 && (
          <div className="absolute left-3 top-3">
            <ReactDndDragItemsCount type={type} mixedTypes={mixedTypes} count={count} />
          </div>
        )}
      </DndDragPreviewContainer>
    </div>
  );
};
