import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { BoardMove } from '@air/next-icons';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

export const SidebarNavigationItemUnusedItems = () => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.media.unusedItems });

  return (
    <SidebarNavigationItem
      asChild
      data-id="unused-items"
      prefix={<BoardMove className="h-4 w-4" />}
      state={isActiveRoute ? 'active' : 'default'}
    >
      <Link href={Routes.media.all}>Unused items</Link>
    </SidebarNavigationItem>
  );
};
