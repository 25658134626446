import { Library } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';

import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { LibraryDeleteConfirmationModal } from '~/components/LibraryBeta/LibraryDeleteConfirmationModal/LibraryDeleteConfirmationModal';
import {
  LibraryAdvancedSettingsDeleteLibrary,
  LibraryAdvancedSettingsDeleteLibraryProps,
} from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsDeleteLibrary';

export type LibraryAdvancedSettingsDeleteLibraryContainerProps = LibraryAdvancedSettingsDeleteLibraryProps & {
  libraryId: Library['id'];
};

export const LibraryAdvancedSettingsDeleteLibraryContainer = ({
  libraryId,
  onDelete,
}: LibraryAdvancedSettingsDeleteLibraryContainerProps) => {
  const { data: library } = useLibrary({ libraryId });
  const [setShowLibraryDeleteConfirmationModal] = useAirModal(LibraryDeleteConfirmationModal);

  if (!library) return null;

  return (
    <LibraryAdvancedSettingsDeleteLibrary
      onDelete={() => {
        setShowLibraryDeleteConfirmationModal({
          libraryId: library.id,
          name: library.title,
        });
        onDelete?.();
      }}
    />
  );
};
