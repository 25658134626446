import {
  NotificationBoard as NotificationBoardType,
  type NotificationClip as NotificationClipType,
} from '@air/api/types';
import { memo, useCallback, useMemo } from 'react';

import { NotificationBoard } from '~/components/AirNotifications/components/NotificationBoard';
import { NotificationClip } from '~/components/AirNotifications/components/NotificationClip';
import {
  NotificationItemsThumbnails,
  NotificationItemsThumbnailsProps,
} from '~/components/AirNotifications/components/NotificationItemsThumbnails';

interface NotificationDownloadedItemsProps {
  clips: NotificationClipType[];
  boards: NotificationBoardType[];
  totalCount: number;
}

interface DownloadedClip {
  type: 'clip';
  data: NotificationClipType;
  id: string;
}

interface DownloadedBoard {
  id: string;
  type: 'board';
  data: NotificationBoardType;
}

type DownloadedItem = DownloadedClip | DownloadedBoard;

export const NotificationDownloadedItems = memo(({ clips, boards, totalCount }: NotificationDownloadedItemsProps) => {
  const items = useMemo<DownloadedItem[]>(() => {
    const downloadedClips = clips.map<DownloadedClip>((clip) => ({
      type: 'clip',
      data: clip,
      id: clip.id,
    }));

    const downloadedBoards = boards.map<DownloadedBoard>((board) => ({
      type: 'board',
      data: board,
      id: board.id,
    }));

    return [...downloadedClips, ...downloadedBoards];
  }, [boards, clips]);

  const renderItem = useCallback<NotificationItemsThumbnailsProps<DownloadedItem>['renderItem']>((item) => {
    if (item.type === 'clip') {
      return <NotificationClip clip={item.data} key={item.id} />;
    } else if (item.type === 'board') {
      return <NotificationBoard board={item.data} key={item.id} />;
    }
  }, []);

  return <NotificationItemsThumbnails items={items} renderItem={renderItem} totalItemsCount={totalCount} />;
});

NotificationDownloadedItems.displayName = 'NotificationDownloadedItems';
