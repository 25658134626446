import { Members } from '@air/api';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getWorkspaceTotalsKey } from '~/swr-hooks/workspaces/useWorkspaceTotals';

import { getWorkspaceMembersKey } from './useGetWorkspaceMembers';

type MembersUpdateParams = Parameters<typeof Members.update>[0];

export const useReactivateWorkspaceMembers = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const reactivateWorkspaceMembers = useCallback(
    async ({ members }: Pick<MembersUpdateParams, 'members'>) => {
      try {
        if (!currentWorkspace?.id) {
          throw new Error('No workspace id');
        }

        const response = await Members.update({
          workspaceId: currentWorkspace?.id,
          members,
        });

        if (!!currentWorkspace?.id) {
          queryClient.invalidateQueries({ queryKey: getWorkspaceMembersKey(currentWorkspace?.id) });
          queryClient.invalidateQueries({ queryKey: getWorkspaceTotalsKey(currentWorkspace.id) });
        }

        return response;
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to reactivate workspace members',
          metadata: {
            data: {
              members: JSON.stringify(members),
            },
          },
        });
      }
    },
    [currentWorkspace?.id, queryClient],
  );

  return {
    reactivateWorkspaceMembers,
  };
};
