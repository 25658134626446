import { CustomFieldTypeName } from '@air/api/types';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { memo, useCallback, useMemo } from 'react';

import { customFieldIconMap } from '~/constants/customFieldIconMap';

import { CollectionFormField } from './CollectionFormField';

interface CustomFieldItemProps {
  fieldName: string;
  onSelect: (fieldId: string, fieldName: string, isRequired: boolean) => Promise<any>;
  removeField: (fieldId: string, fieldName: string) => Promise<any>;
  fieldId: string;
  required: boolean;
  fieldTypeName: CustomFieldTypeName;
}
export const CustomFieldItem = memo(
  ({ onSelect, fieldName, removeField, fieldId, required, fieldTypeName }: CustomFieldItemProps) => {
    const ellipsisMenuOptions = useMemo<DropdownMenuOption[]>(
      () => [
        {
          id: 'remove',
          label: 'Remove',
          onSelect: () => removeField(fieldId, fieldName),
          type: 'item',
        },
      ],
      [removeField, fieldId, fieldName],
    );
    const Icon = fieldTypeName ? customFieldIconMap[fieldTypeName] : null;
    return (
      <CollectionFormField
        onSelect={useCallback(
          (isRequired: boolean) => onSelect(fieldId, fieldName, isRequired),
          [fieldId, fieldName, onSelect],
        )}
        fieldName={fieldName}
        leftAdornment={
          Icon && (
            <div className="mr-1 flex text-pigeon-400">
              <Icon width={16} />
            </div>
          )
        }
        ellipsisMenuOptions={ellipsisMenuOptions}
        required={required}
      />
    );
  },
);

CustomFieldItem.displayName = 'CustomFieldItem';
