import { useTrackSwitchedWorkspace } from '@air/analytics';
import { Workspaces } from '@air/api';
import { Workspace, WorkspaceListResponse } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getWorkspacesListKey } from '~/swr-hooks/workspaces/useWorkspaces';

export const useSwitchWorkspace = () => {
  const { currentWorkspaceRef } = useCurrentWorkspace();
  const { trackSwitchedWorkspace } = useTrackSwitchedWorkspace();
  const queryClient = useQueryClient();

  const switchWorkspace = useCallback(
    ({ id }: Pick<Workspace, 'id'>) => {
      if (currentWorkspaceRef.current && currentWorkspaceRef.current.id !== id) {
        trackSwitchedWorkspace({
          fromId: currentWorkspaceRef.current.id,
          fromName: currentWorkspaceRef.current.name,
          toId: id,
        });

        Workspaces.switchWorkspaces({
          workspaceId: id,
        });

        const workspaces = queryClient.getQueryData<WorkspaceListResponse>(getWorkspacesListKey());

        if (workspaces?.length) {
          // this updates the workspace locally to be marked as the current workspace
          // since the "current workspace" is identified by the whatever workspace has
          // the most recent lastUsed date
          queryClient.setQueryData(getWorkspacesListKey(), (data?: WorkspaceListResponse) => {
            return data
              ? data.map((ws) =>
                  ws.id === id
                    ? {
                        ...ws,
                        lastUsed: new Date().toISOString(),
                      }
                    : ws,
                )
              : [];
          });
        }
      }
    },
    [currentWorkspaceRef, queryClient, trackSwitchedWorkspace],
  );

  return { switchWorkspace };
};
