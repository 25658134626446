import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton } from '@air/primitive-modal';
import { useAirModal } from '@air/provider-modal';
import Image from 'next/image';
import Link from 'next/link';
import { memo, useCallback } from 'react';

import { ChilipiperModal } from '~/components/Modals/ChilipiperModal';

export const UpgradeForLibrariesModal = memo(({ onClose }: AirModalProps) => {
  const [showContactSalesModal] = useAirModal(ChilipiperModal);

  const onContactSalesClick = useCallback(() => {
    onClose();
    showContactSalesModal({
      uiLocation: 'libraries_cta',
    });
  }, [onClose, showContactSalesModal]);

  return (
    <Modal isOpen onDismiss={onClose}>
      <ModalCloseButton className="absolute right-6 shrink-0" onClick={onClose} />
      <div className="mt-6 flex flex-col items-center gap-4">
        <Image src="/assets/images/upgrade-for-libraries.jpg" alt="Upgrade for Libraries" width={367} height={230} />
        <div className="mt-4 flex items-center">
          <p className="mr-2 text-20 font-semibold">Control Access to Assets with Libraries</p>
        </div>
        <p className="text-center text-14 text-grey-12">
          Upgrade to an Enterprise plan to make it easier to organize your assets and set which team members can see
          them with <b>Libraries</b>.
        </p>
        <div className="flex items-center justify-center gap-2">
          <Link target="_blank" className="px-3 text-14 font-medium text-grey-11" href="https://air.inc/enterprise">
            Learn more
          </Link>
          <Button size="large" appearance="filled" color="blue" onClick={onContactSalesClick}>
            Upgrade
          </Button>
        </div>
      </div>
    </Modal>
  );
});

UpgradeForLibrariesModal.displayName = 'UpgradeForLibrariesModal';
