import { Input } from '@air/primitive-input';
import { Label } from '@air/primitive-label';
import { ChangeEvent, useState } from 'react';

import { SavedFilterEmojiPicker } from './SavedFilterEmojiPicker';

export interface SavedFilterNameInputProps {
  onEmojiPick: (emoji: string) => void;
  onInputChange: (text: string) => void;
  inputValue: string;
  selectedEmoji?: string;
  onError: (hasError: boolean) => void;
}

export const MAX_SAVED_FILTER_NAME_LENGTH = 65;

export const SavedFilterNameInput = ({
  onEmojiPick,
  onInputChange,
  inputValue,
  selectedEmoji,
  onError,
}: SavedFilterNameInputProps) => {
  const [error, setError] = useState(inputValue?.length >= MAX_SAVED_FILTER_NAME_LENGTH);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const latestInputValue = e.target.value;
    const hasError = latestInputValue?.length >= MAX_SAVED_FILTER_NAME_LENGTH;

    onInputChange(latestInputValue);
    setError(hasError);
    onError(hasError);
  };

  return (
    <div>
      <Label htmlFor="filter-name">Name & Emoji</Label>
      <div className="relative mt-1.5 flex w-full items-center justify-evenly">
        <SavedFilterEmojiPicker
          size="medium"
          onEmojiPick={onEmojiPick}
          emoji={selectedEmoji}
          className="absolute left-1.5 top-1/2 -translate-y-1/2"
        />
        <Input
          aria-invalid={error}
          autoFocus
          data-testid="SAVED_FILTER_MODAL_NAME_INPUT"
          name="filter-name"
          onChange={onChange}
          placeholder="Untitled saved filter"
          className="w-full min-w-[auto] grow pl-[42px]"
          value={inputValue}
          size="large"
        />
      </div>
      {error && (
        <p className="text-12 text-red-9" data-testid="APP_error">
          Title must be less than 65 characters
        </p>
      )}
    </div>
  );
};
