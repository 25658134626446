import {
  DoubleChevronLeft as DoubleChevronLeftIcon,
  DoubleChevronRight as DoubleChevronRightIcon,
} from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import classNames from 'classnames';
import { CSSProperties } from 'react';

import { useGetNotificationSummary } from '~/components/AirNotifications/hooks/useGetNotificationSummary';
import { CountBadge } from '~/components/CountBadge/CountBadge';
import {
  CURRENT_WORKSPACE_NAV_HIDE_TOGGLE_BUTTON,
  CURRENT_WORKSPACE_NAV_SHOW_TOGGLE_BUTTON,
} from '~/constants/testIDs';
import { useSideNavContext } from '~/providers/SideNavProvider';
interface SideNavToggleProps {
  visibility: CSSProperties['visibility'];
}

export const SideNavToggle = ({ visibility }: SideNavToggleProps) => {
  const { data: notificationSummary } = useGetNotificationSummary();
  const { isSideNavOpen, toggleSideNav } = useSideNavContext();
  const notificationCount = !!notificationSummary && notificationSummary.unseen ? notificationSummary.unseen : 0;
  const label = `${isSideNavOpen ? 'Hide' : 'Show'} sidebar (⌘+\\)`;

  return (
    <CountBadge
      className={isSideNavOpen ? 'z-10' : undefined}
      count={isSideNavOpen ? 0 : notificationCount}
      style={{ visibility }}
    >
      <Tooltip label={label} side="right">
        <IconButton
          appearance="filled"
          color="grey"
          className={classNames(
            'absolute rounded-full bg-grey-1',
            isSideNavOpen ? '-left-1/2 top-6 -translate-x-1/2' : 'left-1 top-2',
          )}
          data-testid={
            isSideNavOpen ? CURRENT_WORKSPACE_NAV_HIDE_TOGGLE_BUTTON : CURRENT_WORKSPACE_NAV_SHOW_TOGGLE_BUTTON
          }
          icon={isSideNavOpen ? DoubleChevronLeftIcon : DoubleChevronRightIcon}
          label={label}
          onClick={toggleSideNav}
          size="small"
        />
      </Tooltip>
    </CountBadge>
  );
};

SideNavToggle.displayName = 'SideNavToggle';
