import { TriangleDown } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { SelectDropdownMenu, SelectDropdownMenuProps } from '@air/primitive-dropdown-menu';
import { Spinner } from '@air/primitive-spinner';

export type LibraryAdvancedSettingsDefaultRoleProps = {
  isLoading?: boolean;
  roles?: SelectDropdownMenuProps['options'];
  selectedRole: SelectDropdownMenuProps['selectedOption'];
};

export const LibraryAdvancedSettingsDefaultRole = ({
  isLoading = false,
  roles,
  selectedRole,
}: LibraryAdvancedSettingsDefaultRoleProps) => {
  return (
    <div className="flex flex-col gap-4" data-testid="LIBRARY_SETTINGS_DEFAULT_ROLE">
      <div className="flex flex-col gap-1">
        <h3 className="text-10 font-bold uppercase tracking-wide text-grey-12">Default role</h3>
        <div className="flex items-center justify-between gap-6">
          <p className="text-14 text-grey-10">Set a default role for all members who join or are invited</p>
        </div>
      </div>

      <SelectDropdownMenu
        className="w-full"
        disabled={!roles?.length || isLoading}
        selectedOption={selectedRole}
        trigger={
          <Button
            appearance="outline"
            className="w-full justify-between gap-1"
            color="grey"
            size="medium"
            suffix={isLoading ? <Spinner className="h-4 w-4" /> : <TriangleDown className="h-4 w-4" />}
          >
            {selectedRole?.label || 'Select a role'}
          </Button>
        }
        options={roles}
      />
    </div>
  );
};
