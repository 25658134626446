import { Permissions } from '@air/api';
import { GetClipWithNoWorkspaceResponse } from '@air/api/build/clips';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { centralizedClipSelector } from '~/store/centralizedClip/selectors';
import { setPermissionsAction } from '~/store/permissions/actions';
import { ClipGetOptionsWithBoards } from '~/swr-hooks/useGetClipWithNoWorkspaceId';
import { useAirStore } from '~/utils/ReduxUtils';

export const useNavigateToNewVersionOnUpload = () => {
  const store = useAirStore();
  const dispatch = useDispatch();
  const { goToAssetPage } = useGoToAssetPage();
  const { currentWorkspace } = useCurrentWorkspace();

  const navigateToNewVersionOnUpload = useCallback(
    async (clip: GetClipWithNoWorkspaceResponse<ClipGetOptionsWithBoards>) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      /**
       * When we fetch the clip and go to navigate to it in the asset modal,
       * we need to fetch the permissions BEFORE we navigate to it so that the
       * asset modal doesn't bounce all around because there are no permissions for a second.
       */
      const permissions = await Permissions.getFromObjects({
        workspaceId,
        objects: {
          clipIds: [clip.id],
        },
        mode: 'all',
      });

      dispatch(
        setPermissionsAction({
          clipIds: {
            [clip.id]: permissions[clip.id],
          },
        }),
      );

      const centralizedClip = centralizedClipSelector(store.getState());
      clip.assetVersionCount = (centralizedClip.assetVersionCount ?? 0) + 1;
      clip.version = (centralizedClip.version ?? 0) + 1;
      goToAssetPage({ asset: clip });
    },
    [dispatch, goToAssetPage, store, currentWorkspace?.id],
  );

  return {
    navigateToNewVersionOnUpload,
  };
};
