import Router, { useRouter } from 'next/router';
import { useCallback } from 'react';

import { QueryParamNames } from '~/constants/search';
import { getPathWithOmittedParams, pushWithExistingQuery } from '~/utils/PathUtils';

export const useNotificationsPanelVisibility = () => {
  const { asPath, query } = useRouter();

  const closeNotificationsPanel = useCallback(
    () =>
      Router.replace(getPathWithOmittedParams(asPath, [QueryParamNames.notificationsPanel]), undefined, {
        shallow: true,
      }),
    [asPath],
  );

  const openNotificationsPanel = useCallback(
    () =>
      pushWithExistingQuery({
        newQuery: { [QueryParamNames.notificationsPanel]: true },
        method: 'replace',
      }),
    [],
  );

  return {
    openNotificationsPanel,
    closeNotificationsPanel,
    isNotificationsPanelOpen: query[QueryParamNames.notificationsPanel] === 'true',
  };
};
