import { memo } from 'react';

import { GeneralLibraryInfo } from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibraryInfo';
import { useGeneralLibraryTitle } from '~/components/LibraryBeta/hooks/useGeneralLibraryTitle';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const GeneralLibraryReadonlyDetails = memo(() => {
  const { generalLibraryTitle } = useGeneralLibraryTitle();
  const { currentWorkspace } = useCurrentWorkspace();

  if (!currentWorkspace) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-1">
        <div className="text-10 font-bold uppercase text-pigeon-500">Name</div>
        <div className="text-14 text-pigeon-600">{generalLibraryTitle}</div>
      </div>
      <GeneralLibraryInfo workspaceName={currentWorkspace.name} />
    </div>
  );
});

GeneralLibraryReadonlyDetails.displayName = 'ReadonlyGeneralLibraryDetails';
