import { ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { memo } from 'react';

interface GeneralLibrarySettingsModalHeaderProps {
  onClose: () => void;
  title: string | undefined;
}

export const GeneralLibrarySettingsModalHeader = memo(({ onClose, title }: GeneralLibrarySettingsModalHeaderProps) => (
  <div className="mb-3 flex justify-between px-2">
    <ModalTitle>{title}</ModalTitle>
    <ModalCloseButton className="shrink-0" onClick={onClose} />
  </div>
));

GeneralLibrarySettingsModalHeader.displayName = 'GeneralLibrarySettingsModalHeader';
