import { BoxLogo, BrandfolderLogo, Dropbox as DropboxIcon, GoogleDrive } from '@air/next-icons';
import { Box, BoxProps } from '@air/zephyr';

export interface ImportIconProps {
  isActive: boolean;
  tx?: BoxProps['tx'];
}

const sourceImageStyle: BoxProps['tx'] = {
  width: 24,
  height: 24,
  color: 'pigeon400',
  mr: 10,
};

const googleDriveActiveColors: BoxProps['tx'] = {
  '& path:nth-child(1)': {
    fill: '#3777E3',
  },
  '& path:nth-child(2)': {
    fill: '#FFCF63',
  },
  '& path:nth-child(3)': {
    fill: '#11A861',
  },
};

export const GoogleDriveImportIcon = ({ isActive, tx }: ImportIconProps) => {
  const style: BoxProps['tx'] = isActive
    ? { ...sourceImageStyle, ...googleDriveActiveColors, ...tx }
    : { ...sourceImageStyle, ...tx };

  return <Box as={GoogleDrive} tx={style} />;
};

export const DropboxImportIcon = ({ isActive, tx }: ImportIconProps) => {
  const style: BoxProps['tx'] = isActive
    ? { ...sourceImageStyle, color: 'dropbox', ...tx }
    : { ...sourceImageStyle, ...tx };

  return <Box as={DropboxIcon} tx={style} />;
};

export const HomeBoxImportIcon = ({ isActive, tx }: ImportIconProps) => {
  const style: BoxProps['tx'] = isActive
    ? { ...sourceImageStyle, color: 'dropbox', ...tx }
    : { ...sourceImageStyle, ...tx };

  return <Box as={BoxLogo} tx={style} />;
};

export const BrandfolderImportIcon = ({ isActive, tx }: ImportIconProps) => {
  const style: BoxProps['tx'] = isActive
    ? { ...sourceImageStyle, color: 'dropbox', ...tx }
    : { ...sourceImageStyle, ...tx };

  return <Box as={BrandfolderLogo} tx={style} />;
};
