import { Library } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';

import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { useCanLeaveLibrary } from '~/components/LibraryBeta/hooks/useCanLeaveLibrary';
import { LibraryLeaveModal } from '~/components/LibraryBeta/LibraryLeaveModal/LibraryLeaveModal';
import { LibraryAdvancedSettingsLeaveLibrary } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsLeaveLibrary';

export type LibraryAdvancedSettingsLeaveLibraryContainerProps = {
  libraryId: Library['id'];
  onLeaveLibrary?: () => void;
};

export const LibraryAdvancedSettingsLeaveLibraryContainer = ({
  libraryId,
  onLeaveLibrary,
}: LibraryAdvancedSettingsLeaveLibraryContainerProps) => {
  const { data: library } = useLibrary({ libraryId });
  const [setShowLibraryLeaveModal] = useAirModal(LibraryLeaveModal);
  const { reason } = useCanLeaveLibrary({ libraryId });

  if (!library) return null;

  return (
    <LibraryAdvancedSettingsLeaveLibrary
      cantLeaveLibraryReason={reason}
      onLeaveLibrary={() => {
        setShowLibraryLeaveModal({ id: library.id, title: library.title });
        onLeaveLibrary?.();
      }}
    />
  );
};
