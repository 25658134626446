import { Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';
import pluralize from 'pluralize';

import { NotificationAvatar } from '~/components/AirNotifications/components/NotificationAvatar';
import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';

export type NotificationShareLinkViewedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification: Notification<'shareLinkViewed_userIsShareLinkCreator', 'account'>;
};
export const NotificationShareLinkViewedType = ({ notification, onClick }: NotificationShareLinkViewedTypeProps) => {
  return (
    <NotificationBaseItem
      meta="via share link"
      prefix={
        notification.data.count > 1 ? (
          <NotificationAvatar actor={null} />
        ) : (
          <NotificationAvatar actor={notification.data.actor} />
        )
      }
      notification={notification}
      onClick={onClick}
      title={
        <>
          <b>
            {notification.data.count > 1
              ? pluralize('people', notification.data.count, true)
              : formatActorNameInitialsOrDefault(notification.data.actor)}
          </b>
          {` viewed `}
          <b>{notification.data.shortUrl.objectName}</b>
        </>
      }
    />
  );
};
