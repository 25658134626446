import { CustomFieldType } from '@air/api/types';
import { Label } from '@air/primitive-label';
import { tailwindMerge } from '@air/tailwind-variants';
import { useField } from 'formik';
import { capitalize } from 'lodash';
import { useCallback, useMemo } from 'react';

import { SelectProps } from '~/components/Select/Select';
import { SingleSelect } from '~/components/Select/SingleSelect';
import { CUSTOM_FIELD_TYPE_INPUT } from '~/constants/testIDs';

import { CUSTOM_FIELD_MODAL_FORM_FIELD_TYPE } from '../../CustomFieldModal';
import { CustomFieldModalFormData } from '../types';
import { CustomFieldTypeLabel } from './CustomFieldTypeLabel';
import { CustomFieldTypeSelectListItem } from './CustomFieldTypeSelectListItem';

interface FormikCustomFieldTypeSelectProps {
  canEditFieldType?: boolean;
  fieldTypes: CustomFieldType[];
  className?: string;
}
export const FormikCustomFieldTypeSelect = ({
  canEditFieldType,
  fieldTypes,
  className,
}: FormikCustomFieldTypeSelectProps) => {
  const [fieldTypeProps] = useField<CustomFieldModalFormData['fieldTypeId']>(CUSTOM_FIELD_MODAL_FORM_FIELD_TYPE);

  const onSelectionChange: SelectProps['onSelectionChange'] = useCallback(
    ({ chips }) => {
      const value = chips[0].value;
      fieldTypeProps.onChange({ target: { value, name: CUSTOM_FIELD_MODAL_FORM_FIELD_TYPE } });
    },
    [fieldTypeProps],
  );

  const selectedOptions = useMemo(() => {
    const fieldType = fieldTypes.find((ft) => ft.id === fieldTypeProps.value);
    if (!fieldType) return undefined;
    return { value: fieldType.id, label: capitalize(fieldType.title) };
  }, [fieldTypeProps.value, fieldTypes]);

  return (
    <div className={tailwindMerge('flex flex-col gap-1', className)}>
      <Label>Field type</Label>
      <SingleSelect
        data-testid={CUSTOM_FIELD_TYPE_INPUT}
        chipRenderer={(chip) => <CustomFieldTypeLabel fieldTypeId={chip.value} fieldTypes={fieldTypes} />}
        onSelectionChange={onSelectionChange}
        renderAsInput={canEditFieldType}
        disabled={!canEditFieldType}
        size="large"
        closeMenuOnSelect
        listItemRenderer={(chip) => <CustomFieldTypeSelectListItem fieldTypeId={chip.value} fieldTypes={fieldTypes} />}
        filterFunc={(options) => options}
        selectedOption={selectedOptions}
        options={fieldTypes.map((ft) => ({
          value: ft.id,
          label: capitalize(ft.title),
        }))}
      />
    </div>
  );
};
