import { Megaphone } from '@air/next-icons';
import { AlertBanner } from '@air/primitive-alert-banner';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo } from 'react';

import { ALERT_BANNER_MAINTENANCE_MODE } from '~/constants/localStorageKeys';
import { MAINTENANCE_MODE_BANNER_MESSAGE } from '~/constants/testIDs';

export const MaintenanceModeBanner = memo(() => {
  const { maintenanceModeJson } = useFlags();

  if (!maintenanceModeJson?.bannerMessage) {
    return null;
  }

  return (
    <AlertBanner
      data-testid={MAINTENANCE_MODE_BANNER_MESSAGE}
      dismissDays={1}
      id={ALERT_BANNER_MAINTENANCE_MODE}
      prefix={<Megaphone className="h-4 w-4" />}
    >
      {maintenanceModeJson.bannerMessage}
    </AlertBanner>
  );
});

MaintenanceModeBanner.displayName = 'MaintenanceModeBanner';
