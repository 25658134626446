import { Check, Copy } from '@air/next-icons';
import { useCopyToClipboard } from 'react-use';

export type CustomFieldFormAPIInformationCopyValueProps = {
  id: string;
};

export const CustomFieldFormAPIInformationCopyValue = ({ id }: CustomFieldFormAPIInformationCopyValueProps) => {
  const [state, copyToClipboard] = useCopyToClipboard();

  return (
    <div className="group/custom-field-api-value inline-flex gap-1">
      <button className="select-all text-12 text-grey-9" onClick={() => copyToClipboard(id)} type="button">
        {id}
      </button>

      {!!state.value ? (
        <Check className="h-4 w-4 text-blue-9" />
      ) : (
        <Copy className="hidden h-4 w-4 text-grey-9 group-hover/custom-field-api-value:block" />
      )}
    </div>
  );
};
