export const samlSSOSigninRedirectKey = 'saml-enforced-logout';

const isEnabled = () =>
  typeof window !== 'undefined' ? !!window.sessionStorage.getItem(samlSSOSigninRedirectKey) : false;

const enable = () => {
  window.sessionStorage.setItem(samlSSOSigninRedirectKey, 'true');
};

const disable = () => {
  window.sessionStorage.setItem(samlSSOSigninRedirectKey, '');
};

export const useSamlSSOSigninRedirect = () => {
  return {
    isEnabled,
    enable,
    disable,
  };
};
