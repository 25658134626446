import { Board } from '@air/api/types';
import { TreeList } from '@air/component-tree';
import { memo, useCallback, useMemo, useState } from 'react';

import {
  BoardsTreeItem,
  BoardsTreeItemProps,
} from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/BoardsTreeItem';
import { DropRearrangeArea } from '~/components/DropRearrangeArea/DropRearrangeArea';
import DragType, { GetBoardDragItem, RearrangableItem } from '~/components/Shared/Drag/dragTypes';
import { useBoardsBeingDeleted } from '~/swr-hooks/boards/useBoardsBeingDeleted';

const dragTypes = [
  DragType.boardNavFavorites,
  DragType.boardNavWorkspace,
  DragType.boardNavLibrary,
  DragType.boardCard,
];

export interface BoardsTreeProps {
  boards: Board[];
  boardType: 'favorites' | 'workspace' | 'library';
  parentBoardId: Board['id'] | null;
  enableAutoExpand: boolean;
  id?: string;
  onBoardRearrange: (props: {
    adjacentItem: RearrangableItem;
    parentBoardId: Board['id'] | null;
    boards: Board[];
    board: GetBoardDragItem;
  }) => void;
  getCanRearrange: (props: { parentBoardId: Board['id'] | null; board: GetBoardDragItem }) => boolean;
  scrollElement?: HTMLDivElement;
}

export const BoardsTree = memo(
  ({
    boards,
    boardType,
    enableAutoExpand,
    id,
    onBoardRearrange,
    parentBoardId,
    getCanRearrange,
    scrollElement,
  }: BoardsTreeProps) => {
    const [treeNodes, setTreeNodes] = useState<{ touched: Set<Board['id']>; expanded: Set<Board['id']> }>({
      touched: new Set(),
      expanded: new Set(),
    });

    const onOpenChange = useCallback<Required<BoardsTreeItemProps>['onOpenChange']>(({ isOpen, id }) => {
      setTreeNodes((prev) => {
        const { touched, expanded } = prev;
        const newTouched = new Set(touched);
        const newExpanded = new Set(expanded);

        newTouched.add(id);

        if (!isOpen) {
          newExpanded.delete(id);
        } else {
          newExpanded.add(id);
        }

        return { touched: newTouched, expanded: newExpanded };
      });
    }, []);

    const _canRearrangeBoard = useCallback(
      (index: number) => (draggedBoard: GetBoardDragItem) => {
        /**
         * If the board the user is trying to move is an ancestor
         * of any of the boards on this level (they're trying to move the board into itself),
         * prevent them from doing it
         */
        const isAncestorOfAnyBoard = boards.find(({ ancestors = [] }) =>
          ancestors.find(({ id }) => id === draggedBoard.id),
        );

        if (isAncestorOfAnyBoard) {
          return false;
        }

        /**
         * If they are trying to move a board to the position it's already in, don't let them.
         *
         * For example, if they're moving Board 1...
         *
         * ...... <-- Don't allow Board 1 to be moved here
         * Board 1
         * ...... <-- or here (because this is the same position)
         * Board 2
         * ......
         * Board 3
         * ......
         */

        if (
          /**
           * if it's the same parent board (or both the root of the workspace/library)
           */
          draggedBoard.parentId === parentBoardId &&
          /** and the index is it's current position */
          (draggedBoard.index === index || draggedBoard.index === index + 1) &&
          /**
           * and it's the same library, prevent it.
           *
           * We can't just check the index because it could be the same index but of a different library
           * Just get the libraryId of the first board in the tree, since all boards in the tree will be in the same library
           * */
          draggedBoard.firstItem.library?.id === boards[0]?.library?.id
        ) {
          return false;
        }

        return getCanRearrange({ parentBoardId, board: draggedBoard });
      },
      [boards, getCanRearrange, parentBoardId],
    );

    const boardsBeingDeleted = useBoardsBeingDeleted();

    const filteredBoards = useMemo(() => {
      return boardsBeingDeleted.length === 0
        ? boards
        : boards.filter((board) => !boardsBeingDeleted.includes(board.id));
    }, [boards, boardsBeingDeleted]);

    const renderBoard = useCallback(
      (board: Board, index: number) => (
        <div
          data-testid="BOARD_SIDENAV_TREE_ITEM"
          data-title={board.title}
          key={board.id}
          className="relative my-0.5"
          style={{ marginBottom: index === boards.length - 1 ? 5 : 0 }}
        >
          {index === 0 && (
            /**
             * If it's the first one, put one above it so they can move it to the top
             */
            <DropRearrangeArea
              dragTypes={dragTypes}
              dropLocation="sub-nav-rearrange"
              containerProps={{
                style: {
                  marginTop: -4,
                },
              }}
              canDropItem={_canRearrangeBoard(-1)}
              onItemRearrange={(draggedBoard) =>
                onBoardRearrange({
                  adjacentItem: {
                    direction: 'after',
                    index: -1,
                  },
                  boards,
                  parentBoardId,
                  board: draggedBoard,
                })
              }
              testId="BOARD_SIDENAV_TREE_ITEM_PREVIOUS_DROP_AREA"
            />
          )}
          <BoardsTreeItem
            index={index}
            board={board}
            boardType={boardType}
            isOpen={treeNodes.expanded.has(board.id)}
            isTouched={treeNodes.touched.has(board.id)}
            enableAutoExpand={enableAutoExpand}
            onBoardRearrange={onBoardRearrange}
            onOpenChange={onOpenChange}
          />
          <DropRearrangeArea
            dragTypes={dragTypes}
            dropLocation="sub-nav-rearrange"
            containerProps={{
              style: {
                marginTop: -2,
              },
            }}
            canDropItem={_canRearrangeBoard(index)}
            onItemRearrange={(draggedBoard) =>
              onBoardRearrange({
                adjacentItem: {
                  direction: 'after',
                  index,
                },
                boards,
                parentBoardId,
                board: draggedBoard,
              })
            }
            testId="BOARD_SIDENAV_TREE_ITEM_AFTER_DROP_AREA"
          />
        </div>
      ),
      [
        _canRearrangeBoard,
        boardType,
        boards,
        enableAutoExpand,
        onBoardRearrange,
        onOpenChange,
        parentBoardId,
        treeNodes.expanded,
        treeNodes.touched,
      ],
    );

    return scrollElement ? (
      <TreeList<Board>
        data-id={id}
        getEstimateSize={() => 32}
        items={filteredBoards}
        renderListItem={({ data, index }) => renderBoard(data, index)}
        scrollElement={scrollElement}
      />
    ) : (
      <>{boards.map(renderBoard)}</>
    );
  },
);

BoardsTree.displayName = 'BoardsTree';
