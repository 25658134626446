import { useCallback } from 'react';

import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { getBoardIdFromPath } from '~/utils/PathUtils';

export type ParentChangeType = 'move' | 'add';

/**
 * This hook is used to determine if we should move item (remove it from current board) or add item to a board (do not remove it from current board)
 */
export const useItemParentChangeType = () => {
  const { isSearchActive } = useIsSearchActive();
  const { isFlattenedView } = useIsFlattenedView();

  const getParentChangeType = useCallback((): ParentChangeType => {
    const currentBoardId = getBoardIdFromPath(window.location.pathname);
    return !currentBoardId || isSearchActive || isFlattenedView ? 'add' : 'move';
  }, [isFlattenedView, isSearchActive]);

  return {
    getParentChangeType,
  };
};
