import { WorkspaceInfo } from '@air/api/types';
import { memo } from 'react';

import { DropRearrangeArea, DropRearrangeAreaProps } from '~/components/DropRearrangeArea/DropRearrangeArea';
import { WorkspaceButton } from '~/components/MainNavigation/WorkspacesNav/WorkspacesNavButton';
import { WORKSPACE_NAV_IMAGE_AVATAR_SIZE } from '~/components/MainNavigation/WorkspacesNav/WorkspacesNavImage';
import DragType, { DragWorkspaceItem } from '~/components/Shared/Drag/dragTypes';

export interface WorkspaceNavListItemProps {
  workspace: WorkspaceInfo;
  index: number;
  onItemRearrange: DropRearrangeAreaProps<DragWorkspaceItem>['onItemRearrange'];
  canDropItem: DropRearrangeAreaProps<DragWorkspaceItem>['canDropItem'];
  onClick: () => void;
  isActive: boolean;
}

export const WorkspaceNavListItem = memo(({ onItemRearrange, canDropItem, ...rest }: WorkspaceNavListItemProps) => {
  return (
    <>
      <WorkspaceButton {...rest} />

      <DropRearrangeArea
        containerProps={{ style: { width: WORKSPACE_NAV_IMAGE_AVATAR_SIZE, left: 10, marginTop: 4 } }}
        dropLocation="workspace-rearrange"
        onItemRearrange={onItemRearrange}
        dragTypes={[DragType.workspace]}
        canDropItem={canDropItem}
      />
    </>
  );
});

WorkspaceNavListItem.displayName = 'WorkspaceNavListItem';
