import { ConfirmationModal } from '@air/component-confirmation-modal';

export interface MergeAssetsModalProps extends AirModalProps {
  mergeAssets: () => void;
}

export const MergeAssetsModal = ({ onClose, mergeAssets }: MergeAssetsModalProps) => {
  return (
    <ConfirmationModal
      isOpen
      id="merge-assets-modal"
      title="Combine assets?"
      description="You’re about to move these assets into the same version stack."
      onDismiss={onClose}
      ctaPrimaryText="Continue"
      onConfirm={mergeAssets}
    />
  );
};
