import { Gizmo } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { tailwindMerge } from '@air/tailwind-variants';
import { noop } from 'lodash';
import { ReactChild, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

interface DragItemTypeDefault {
  type: string;
  index: number;
  [key: string]: any;
}

export interface ReactDndDragWrapperProps<T> {
  children: ReactChild;
  dragItem: T;
  isDraggable?: boolean;
  swapItems: (dragIndex: number, hoverIndex: number) => void;
  handleDrop?: (dragItem: T | any) => void; // TODO: Come back and resolve 'any' for handleDrop in CustomFieldDragOptions
  className?: string;
}

const ReactDndDragWrapper = <T extends DragItemTypeDefault>({
  children,
  dragItem,
  swapItems,
  isDraggable = true,
  handleDrop = noop,
  className,
}: ReactDndDragWrapperProps<T>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [{ isDragging }, drag] = useDrag({
    item: {
      ...dragItem,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [, drop] = useDrop({
    accept: dragItem.type,
    drop(item: T) {
      handleDrop(item);
    },
    hover(item: T, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = dragItem.index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset && clientOffset.y - hoverBoundingRect.top) || 0;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      swapItems(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={ref}
      draggable={isDraggable}
      className={tailwindMerge('flex items-center', className)}
      style={{ opacity }}
    >
      <IconButton icon={Gizmo} disabled={!isDraggable} size="small" appearance="ghost" color="grey" label="Move" />
      <div className="grow">{children}</div>
    </div>
  );
};

export { ReactDndDragWrapper };
