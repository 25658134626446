import { useAnalyticsUtilities } from '@air/analytics';
import Bugsnag from '@bugsnag/js';
import LogRocket from 'logrocket';

import { useAccountContext } from '~/providers/AccountProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useLaunchDarklyContext } from '~/providers/LaunchDarklyProvider';
import { useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';

export const useConfigureThirdPartyServices = () => {
  const { data: workspaces = [] } = useWorkspaces();
  const { currentWorkspace } = useCurrentWorkspace();
  const { setGlobalEventProps } = useAnalyticsUtilities();
  const { data: account } = useAccountContext();
  const { identify } = useLaunchDarklyContext();

  const isInPaidWorkspace = workspaces.some((workspace) => ['plus', 'pro', 'enterprise'].includes(workspace.plan));
  setGlobalEventProps({ is_in_paid_workspace: isInPaidWorkspace });

  if (currentWorkspace) {
    const workspaceId = currentWorkspace.id;
    const workspaceName = currentWorkspace.name;

    Bugsnag.clearMetadata('workspace');
    Bugsnag.addMetadata('workspace', currentWorkspace);

    setGlobalEventProps({
      workspaceId,
      workspaceName,
    });

    if (account) {
      identify({
        key: account.authId,
        workspaceId,
        workspaceName,
      });

      LogRocket.identify(account.email, {
        currentWorkspace: workspaceName,
        currentWorkspaceId: workspaceId,
      });
    }
  }
};
