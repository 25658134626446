import type { Library } from '@air/api/types';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { Tabs } from '@air/primitive-tabs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';

import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { useLibraryPermissions } from '~/components/LibraryBeta/hooks/useLibraryPermissions';
import { LibraryAdvancedSettings } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/LibraryAdvancedSettings';
import { LibraryDetailsContainer } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryDetails/LibraryDetails.container';
import { LibraryMembers } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/LibraryMembers';
import { UpdateLibraryDetailsContainer } from '~/components/LibraryBeta/LibrarySettingsModal/components/UpdateLibraryDetails.container';
import { canChangeLibraryDetails, canDeleteLibrary } from '~/utils/permissions/libraryPermissions';

export type LibrarySettingsModalProps = {
  defaultTab: 'advanced-settings' | 'details' | 'members';
  hideAdvancedSettings?: boolean;
  libraryId: Library['id'];
};

export const LibrarySettingsModal = ({
  defaultTab = 'details',
  hideAdvancedSettings,
  libraryId,
  onClose,
}: AirModalProps<LibrarySettingsModalProps>) => {
  const { customFieldVisibility } = useFlags();
  const [activeTab, setActiveTab] = useState<string>(defaultTab);
  const { data: library } = useLibrary({ libraryId });
  const { libraryPermissions } = useLibraryPermissions({ libraryId });
  const canEditLibrary = canChangeLibraryDetails(libraryPermissions);
  const canDelete = canDeleteLibrary(libraryPermissions);

  const panels = useMemo(() => {
    if (!library) {
      return [];
    }

    const panelArray = [];

    if (canEditLibrary) {
      panelArray.push({
        id: 'details',
        label: 'Details',
        content: <UpdateLibraryDetailsContainer libraryId={libraryId} />,
      });
    } else {
      panelArray.push({
        id: 'details',
        label: 'Details',
        content: <LibraryDetailsContainer libraryId={libraryId} />,
      });
    }

    panelArray.push({
      id: 'members',
      label: 'Members',
      content: <LibraryMembers library={library} onLeave={onClose} />,
    });

    if (!hideAdvancedSettings) {
      panelArray.push({
        id: 'advanced-settings',
        label: 'Advanced',
        content: (
          <LibraryAdvancedSettings
            libraryId={libraryId}
            onAssignOwners={() => setActiveTab('members')}
            onDeleteLibrary={onClose}
            onLeaveLibrary={onClose}
            showChangeDefaultRoles={canEditLibrary}
            showCustomFields={canEditLibrary && customFieldVisibility}
            showDeleteLibrary={canDelete}
            /** We should only show them the option to leave the libray if they're a member */
            showLeaveLibrary={!!library.roleId}
          />
        ),
      });
    }

    return panelArray;
  }, [canDelete, canEditLibrary, customFieldVisibility, hideAdvancedSettings, library, libraryId, onClose]);

  return (
    <Modal
      className="flex flex-col gap-6"
      dangerouslyBypassFocusLock
      data-testid="LIBRARY_SETTINGS_MODAL"
      isOpen
      title={library?.title || 'Untitled'}
      onDismiss={onClose}
    >
      <header className="flex flex-col gap-6">
        <div className="flex items-center justify-between gap-4">
          <ModalTitle>{library?.title || 'Untitled'}</ModalTitle>
          <ModalCloseButton className="shrink-0" onClick={onClose} />
        </div>
      </header>
      <Tabs
        className="-mx-6 -mt-1"
        defaultValue={defaultTab}
        listClassName="px-6"
        panels={panels}
        panelsClassName="px-8 pt-4"
        onValueChange={(value) => setActiveTab(value)}
        value={activeTab}
        data-testid="LIBRARY_SETTINGS_MODAL_TABS"
      />
    </Modal>
  );
};
