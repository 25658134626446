import { ChevronDown } from '@air/next-icons';
import { memo } from 'react';

import { ProgressingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { DuplicateBoardTask, GetInProgressMetadata } from '~/store/tasks/types';

import { StatusTrackingTitle } from '../FileStatusTrackingPane/StatusTrackingTitle';

export type DuplicateBoardPanelInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<DuplicateBoardTask>, 'sourceBoardName'>;
  onCancel: () => void;
};

export const DuplicateBoardPanelInProgressPanelItem = memo(
  ({ onCancel, metadata: { sourceBoardName } }: DuplicateBoardPanelInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<ProgressingAvatar />}
        title={
          <StatusTrackingTitle data-testid="DUPLICATING_BOARD_TITLE">{`Duplicating "${sourceBoardName}"`}</StatusTrackingTitle>
        }
        buttons={<PaneButton label="Hide" Icon={ChevronDown} onClick={() => onCancel()} />}
      />
    );
  },
);

DuplicateBoardPanelInProgressPanelItem.displayName = 'DuplicateBoardPanelInProgressPanelItem';
