import { memo } from 'react';

interface NotificationCustomFieldTextValueProps {
  value: string;
}

export const NotificationCustomFieldTextValue = memo(({ value }: NotificationCustomFieldTextValueProps) => {
  return <p className="rounded bg-grey-4 px-1 text-12 font-medium text-grey-12">{value}</p>;
});

NotificationCustomFieldTextValue.displayName = 'NotificationCustomFieldTextValue';
