import type { LibrarySortByOptions } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { DropdownMenuOptionItem, SelectDropdownMenu } from '@air/primitive-dropdown-menu';
import { memo } from 'react';

const SORT_OPTIONS: ({ id: keyof LibrarySortByOptions } & DropdownMenuOptionItem)[] = [
  {
    id: 'title',
    label: 'Name',
    type: 'item',
  },
  {
    id: 'createdAt',
    label: 'Date created',
    type: 'item',
  },
];

export type LibrarySortProps = {
  sort: keyof LibrarySortByOptions;
  onSort: (sort: keyof LibrarySortByOptions) => void;
};

export const LibrarySort = memo(({ sort, onSort }: LibrarySortProps) => {
  return (
    <SelectDropdownMenu
      align="end"
      options={SORT_OPTIONS.map((option) => ({
        ...option,
        onSelect: () => onSort(option.id),
      }))}
      selectedOption={{
        id: sort,
      }}
      sideOffset={8}
      trigger={
        <Button appearance="ghost" color="grey" size="small">
          {SORT_OPTIONS.find((option) => option.id === sort)?.label}
        </Button>
      }
    />
  );
});

LibrarySort.displayName = 'LibrarySort';
