import { Modal } from '@air/primitive-modal';
import { TabPanel, Tabs } from '@air/primitive-tabs';
import { memo, useMemo, useState } from 'react';

import { GeneralLibraryMembers } from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibraryMembers/GeneralLibraryMembers';
import { GeneralLibraryReadonlyDetails } from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibraryReadonlyDetails';
import { GeneralLibrarySettingsModalHeader } from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibrarySettingsModalHeader';
import { GeneralLibraryUpdateDetailsContainer } from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibraryUpdateDetails.container';
import { useGeneralLibraryTitle } from '~/components/LibraryBeta/hooks/useGeneralLibraryTitle';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canChangeWorkspaceDetails } from '~/utils/permissions/workspacePermissions';

export const GeneralLibrarySettingsModal = memo(({ onClose }: AirModalProps<object>) => {
  const [activeTab, setActiveTab] = useState<string>('details');

  const { generalLibraryTitle } = useGeneralLibraryTitle();

  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canRenameGeneralLibrary = canChangeWorkspaceDetails(permissions);

  const panels = useMemo<TabPanel[]>(() => {
    const panels: TabPanel[] = [
      {
        id: 'details',
        label: 'Details',
        content: canRenameGeneralLibrary ? <GeneralLibraryUpdateDetailsContainer /> : <GeneralLibraryReadonlyDetails />,
      },
      {
        id: 'members',
        label: 'Members',
        content: <GeneralLibraryMembers onGoToSettingsClick={onClose} />,
      },
    ];

    return panels;
  }, [canRenameGeneralLibrary, onClose]);

  return (
    <Modal className="flex flex-col gap-1" isOpen onDismiss={onClose}>
      <GeneralLibrarySettingsModalHeader onClose={onClose} title={generalLibraryTitle} />

      <Tabs
        className="-mx-6 -mt-1"
        defaultValue="details"
        listClassName="px-8"
        panels={panels}
        panelsClassName="px-8 pt-4"
        onValueChange={(value) => setActiveTab(value)}
        value={activeTab}
      />
    </Modal>
  );
});

GeneralLibrarySettingsModal.displayName = 'GeneralLibrarySettingsModal';
