import { type LibraryWithPermissions, WorkspaceMemberRoleDisplayName } from '@air/api/types';

import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { useLibraryMembers } from '~/components/LibraryBeta/hooks/useLibraryMembers';
import { useWorkspaceRolesContext } from '~/providers/WorkspaceRolesProvider';

export interface UseCanLeaveLibraryParams {
  initialData?: LibraryWithPermissions;
  libraryId: LibraryWithPermissions['id'] | undefined;
}

export type CantLeaveLibraryReason = 'sole-owner';

export type CantLeaveScenario = {
  canLeaveLibrary: false;
  isLoading: boolean;
  reason: CantLeaveLibraryReason;
};

export type CanLeaveScenario = {
  canLeaveLibrary: true;
  isLoading: boolean;
  reason: undefined;
};

export type CanLeaveLibraryReturn = CanLeaveScenario | CantLeaveScenario;

export const useCanLeaveLibrary = ({ initialData, libraryId }: UseCanLeaveLibraryParams): CanLeaveLibraryReturn => {
  const { data: library, isLoading: isLibraryLoading } = useLibrary({ initialData, libraryId });
  const { data: workspaceRoles } = useWorkspaceRolesContext();
  const { data: libraryMembers, isLoading: isLibraryMembersLoading } = useLibraryMembers({ libraryId });

  const isLoading = isLibraryLoading || isLibraryMembersLoading;

  const ownerRole = workspaceRoles?.find((role) => role.displayName === WorkspaceMemberRoleDisplayName.LibraryOwner);
  const isOwner = library?.roleId && ownerRole?.id && library.roleId === ownerRole.id;
  const owners = libraryMembers?.filter(
    (member) => member.role.displayName === WorkspaceMemberRoleDisplayName.LibraryOwner,
  );

  if (isOwner && owners?.length === 1) {
    return { canLeaveLibrary: false, isLoading, reason: 'sole-owner' };
  }

  return { canLeaveLibrary: true, isLoading, reason: undefined };
};
