import { useTrackLoggedOut } from '@air/analytics';
import Router from 'next/router';
import { useCallback } from 'react';
import { UrlObject } from 'url';

import { Routes } from '~/constants/routes';

interface LogoutParams {
  query?: UrlObject['query'];
}

/**
 * In this app, the only way to logout is by navigating to `/logout`. That page optionally
 * expects query parameters, but URLs have no type-safety, so this method is defined as a
 * means for you to correctly interface a "log out".
 */
export const useLogout = () => {
  const { trackLoggedOut } = useTrackLoggedOut();

  const logout = useCallback(
    ({ query }: LogoutParams = {}) => {
      Router.ready(() => {
        trackLoggedOut();
        Router.push({ pathname: Routes.auth.logout, query });
      });
    },
    [trackLoggedOut],
  );

  return { logout };
};
