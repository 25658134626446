import { useTrackClickedNotificationsIcon } from '@air/analytics';
import { Bell } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Popover } from '@air/primitive-popover';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import { memo, useCallback } from 'react';

import { DesktopNotifications } from '~/components/AirNotifications/DesktopNotifications';
import { useGetNotificationSummary } from '~/components/AirNotifications/hooks/useGetNotificationSummary';
import { useNotificationsPanelVisibility } from '~/components/AirNotifications/hooks/useNotificationsPanelVisibility';
import { useResetUnviewedCount } from '~/components/AirNotifications/hooks/useResetUnviewedCount';
import {
  AIR_NOTIFICATIONS_BUTTON,
  AIR_NOTIFICATIONS_BUTTON_BADGE,
  AIR_NOTIFICATIONS_POPOVER,
} from '~/constants/testIDs';

export const DesktopNotificationsPopover = memo(() => {
  const { openNotificationsPanel, closeNotificationsPanel, isNotificationsPanelOpen } =
    useNotificationsPanelVisibility();
  const { data: notificationSummary } = useGetNotificationSummary();
  const { resetUnviewedCount } = useResetUnviewedCount();
  const { trackClickedNotificationsIcon } = useTrackClickedNotificationsIcon();

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        openNotificationsPanel();
        trackClickedNotificationsIcon({ unviewedNotifications: notificationSummary?.unseen });
        resetUnviewedCount();
      } else {
        closeNotificationsPanel();
      }
    },
    [
      closeNotificationsPanel,
      notificationSummary?.unseen,
      openNotificationsPanel,
      resetUnviewedCount,
      trackClickedNotificationsIcon,
    ],
  );

  const onClose = useCallback(() => {
    closeNotificationsPanel();
  }, [closeNotificationsPanel]);

  return (
    <Popover
      className="w-full min-w-[500px] p-0"
      data-testid={AIR_NOTIFICATIONS_POPOVER}
      onOpenChange={handleOpenChange}
      open={isNotificationsPanelOpen}
      trigger={
        <IconButton appearance="ghost" color="grey" data-testid={AIR_NOTIFICATIONS_BUTTON} label="Notifications">
          <Bell className="h-4 w-4" />
          {!!notificationSummary?.unseen && (
            <div
              className="absolute left-4 top-0 flex h-4 min-w-[16px] items-center justify-center rounded-3xl border border-grey-1 bg-red-9 px-1 text-10 font-bold text-white"
              data-testid={AIR_NOTIFICATIONS_BUTTON_BADGE}
            >
              {notificationSummary.unseen}
              <VisuallyHidden>unseen notifications</VisuallyHidden>
            </div>
          )}
        </IconButton>
      }
    >
      <DesktopNotifications
        className="h-[800px] max-h-[calc(var(--radix-popper-available-height)-32px)]"
        onClose={onClose}
      />
    </Popover>
  );
});

DesktopNotificationsPopover.displayName = 'DesktopNotificationsPopover';
