export const SUPPORTED_EMOJIS = [
  '📚',
  '📷',
  '📸',
  '🔖',
  '🏛️',
  '⭐',
  '🍉',
  '🧇',
  '🎨',
  '🌍',
  '🖌️',
  '🎥',
  '⏳',
  '🚂',
  '⚓',
  '✈️',
  '🪐',
  '🌐',
  '🚫',
  '⚠️',
  '✅',
  '📹',
  '🎬',
  '📈',
  '🗃️',
  '🔒',
  '🖼️',
  '⚽',
  '🔐',
  '🧽',
  '🗿',
  '🔮',
  '📪',
  '📥',
  '📭',
  '🔍',
  '📣',
  '📗',
  '🎢',
  '🦧',
  '🦆',
  '💎',
  '🎵',
  '🐶',
  '🌺',
];

export const getRandomEmoji = () => {
  const randomIndex = Math.floor(Math.random() * SUPPORTED_EMOJIS.length);

  return SUPPORTED_EMOJIS[randomIndex];
};
