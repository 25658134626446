import { type Library } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { useCallback, useState } from 'react';

import { CustomFieldsCreateModal } from '~/components/CustomFields/CustomFieldsCreateModal';
import { useLibraryUpdateCustomFieldsMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryUpdateCustomFieldsMutation';
import {
  type LibraryCustomField,
  useLibraryCustomFields,
} from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';
import { LibraryAdvancedSettingsCustomFields } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryAdvancedSettings/components/LibraryAdvancedSettingsCustomFields';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canCreateCustomFields } from '~/utils/permissions/workspacePermissions';

export type LibraryAdvancedSettingsCustomFieldsContainerProps = {
  libraryId: Library['id'];
};

export const LibraryAdvancedSettingsCustomFieldsContainer = ({
  libraryId,
}: LibraryAdvancedSettingsCustomFieldsContainerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: libraryCustomFields, isLoading, refetch } = useLibraryCustomFields({ libraryId });
  const { libraryUpdateCustomFieldsMutation } = useLibraryUpdateCustomFieldsMutation({ libraryId });
  const { showLibraryToast } = useLibraryToasts();
  const [showCreateModal] = useAirModal(CustomFieldsCreateModal);
  const { data: permissions } = useCurrentWorkspacePermissionsContext();

  const getLabel = useCallback(
    (customFields?: Partial<Pick<LibraryCustomField, 'visible'>>[]) => {
      if (customFields === undefined || isLoading) {
        return 'Loading...';
      }

      /**
       * Displayed when there are no custom fields in the workspace
       */
      if (customFields.length === 0) {
        return 'No custom fields';
      }

      if (customFields.every((customField) => customField.visible)) {
        return 'All visible';
      }

      if (customFields?.every((customField) => !customField.visible)) {
        return 'All hidden';
      }

      return `${customFields.filter((customField) => !customField.visible).length} hidden`;
    },
    [isLoading],
  );

  const onApply = useCallback(
    (customFields: LibraryCustomField[]) => {
      libraryUpdateCustomFieldsMutation.mutate(
        {
          customFields: customFields.map(({ id, visible }) => ({ customFieldId: id, visible })),
        },
        {
          onSuccess: (_data, variables) => {
            setIsOpen(false);
            showLibraryToast({
              message: (
                <>
                  Custom fields has been updated to <b className="text-jay-200">{getLabel(variables.customFields)}</b>.
                </>
              ),
              type: 'info',
            });
          },
        },
      );
    },
    [getLabel, libraryUpdateCustomFieldsMutation, showLibraryToast],
  );

  if (!libraryCustomFields?.data?.length || isLoading) return null;

  return (
    <LibraryAdvancedSettingsCustomFields
      /**
       * Temporary fix until BE fixes types + always have undefined as `false`
       */
      customFields={libraryCustomFields.data.map((customField) => ({
        ...customField,
        visible: !!customField.visible,
      }))}
      isOpen={isOpen}
      isLoading={libraryUpdateCustomFieldsMutation.isPending}
      label={getLabel(libraryCustomFields?.data)}
      onOpenChange={setIsOpen}
      onCreateCustomField={
        canCreateCustomFields(permissions) ? () => showCreateModal({ onCreate: refetch }) : undefined
      }
      onApply={onApply}
    />
  );
};
