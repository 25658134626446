import { WorkspaceUserRole } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';

export interface RoleChangeConfirmationModalProps {
  onConfirm: () => void;
  currentRole: WorkspaceUserRole['displayName'];
  newRole: WorkspaceUserRole['displayName'];
}

export const RoleChangeConfirmationModal = ({
  onClose,
  onConfirm,
  currentRole,
  newRole,
}: AirModalProps<RoleChangeConfirmationModalProps>) => {
  const onSelectYes = () => {
    onConfirm();
    onClose();
  };

  return (
    <ConfirmationModal
      id="role-change-confirmation-modal"
      isOpen
      onDismiss={onClose}
      title="Are you sure?"
      description={`If you change your role from ${currentRole} to ${newRole} you will need to contact someone with higher permissions to change it back for you.`}
      isDangerous
      ctaPrimaryText="Change Role"
      onConfirm={onSelectYes}
    />
  );
};
