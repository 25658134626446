import { memo } from 'react';

import {
  GeneralLibraryMember,
  GeneralLibraryMemberProps,
} from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibraryMembers/GeneralLibraryMember';

interface GeneralLibraryMembersListProps {
  members: GeneralLibraryMemberProps['member'][];
}

export const GeneralLibraryMembersList = memo(({ members }: GeneralLibraryMembersListProps) => (
  <ul className="flex flex-col gap-4">
    {members.map((member) => (
      <GeneralLibraryMember key={member.accountId} member={member} />
    ))}
  </ul>
));

GeneralLibraryMembersList.displayName = 'GeneralLibraryMembersList';
