import { Skeleton } from '@air/component-skeleton';

import { LibraryMemberListItemSkeleton } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/components/LibraryMemberListItemSkeleton';

export const LibraryMemberListSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <Skeleton height={12} isText width={80} />

      <LibraryMemberListItemSkeleton />
    </div>
  );
};
