import { NotificationFilter } from '@air/api/types';
import { NotificationsHeader, type NotificationsProps } from '@air/component-notifications';
import { tailwindMerge } from '@air/tailwind-variants';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import { AirNotificationList } from '~/components/AirNotifications/components/AirNotificationList';
import { useGetNotificationSummary } from '~/components/AirNotifications/hooks/useGetNotificationSummary';
import { useMarkAllNotificationsAsRead } from '~/components/AirNotifications/hooks/useMarkAllNotificationsAsRead';
import { Routes } from '~/constants/routes';

export type DesktopNotificationsProps = Pick<NotificationsProps, 'className'> & {
  onClose?: () => void;
};

export const DesktopNotifications = ({ className, onClose }: DesktopNotificationsProps) => {
  const router = useRouter();
  const [activeFilter, setActiveFilter] = useState<NotificationFilter | undefined>(undefined);
  const { markAllNotificationsAsRead } = useMarkAllNotificationsAsRead();
  const { data: notificationSummary } = useGetNotificationSummary();

  const onSettingsClick = useCallback(() => {
    onClose?.();
    router.push(Routes.account.notificationSettings);
  }, [onClose, router]);

  return (
    <div className={tailwindMerge('flex flex-col', className)} data-testid="NOTIFICATIONS">
      <NotificationsHeader
        filters={[
          {
            id: 'all',
            name: 'All',
            isActive: !activeFilter,
            count: notificationSummary?.unread?.all,
          },
          {
            id: 'mentions',
            name: 'Mentions',
            isActive: activeFilter === 'mentions',
            count: notificationSummary?.unread?.mentions,
          },
          {
            id: 'following',
            name: 'Following',
            isActive: activeFilter === 'following',
            count: notificationSummary?.unread?.following,
          },
          {
            id: 'shortUrl',
            name: 'Links',
            isActive: activeFilter === 'shortUrl',
            count: notificationSummary?.unread?.shortUrl,
          },
        ]}
        onFilterClick={(filter) => {
          if (filter.id === 'all') {
            setActiveFilter(undefined);
          } else {
            setActiveFilter(filter.id as NotificationFilter);
          }
        }}
        onMarkAllAsRead={markAllNotificationsAsRead}
        onSettingsClick={onSettingsClick}
      />
      <AirNotificationList filter={activeFilter} onNotificationClick={onClose} />
    </div>
  );
};
