import { Color } from '@air/primitive-color-picker';

export type GetRandomColorParams = { colors?: Color[] };

export type GetRandomColorResults = Color | undefined;

export type GetRandomColor = (params: GetRandomColorParams) => GetRandomColorResults;

export const getRandomColor: GetRandomColor = ({ colors }) => {
  if (!colors?.length) return;

  const randomIndex = Math.floor(Math.random() * colors.length);

  return colors[randomIndex];
};
