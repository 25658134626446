import { Library, WorkspaceMemberRoleDisplayName, WorkspaceMemberType } from '@air/api/types';
import { TriangleDown as TriangleDownIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { SelectDropdownMenu, SelectDropdownMenuOption } from '@air/primitive-dropdown-menu';
import { Spinner } from '@air/primitive-spinner';
import { formatFullName } from '@air/utilities';
import { isUndefined } from 'lodash';
import { useMemo } from 'react';

import { useLibraryLeaveMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryLeaveMutation';
import { useLibraryMembersRemoveMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryMembersRemoveMutation';
import { useLibraryMemberUpdateMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryMemberUpdateMutation';
import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { useCanLeaveLibrary } from '~/components/LibraryBeta/hooks/useCanLeaveLibrary';
import { useCurrentWorkspaceLibraryMember } from '~/components/LibraryBeta/hooks/useCurrentWorkspaceLibraryMember';
import { useLibraryMembers } from '~/components/LibraryBeta/hooks/useLibraryMembers';
import { useLibraryPermissions } from '~/components/LibraryBeta/hooks/useLibraryPermissions';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useWorkspaceRolesContext } from '~/providers/WorkspaceRolesProvider';
import { convertUnknownToError } from '~/utils/ErrorUtils';
import {
  canChangeMemberRoleInPrivateOrHiddenLibrary,
  canChangeMemberRoleInPublicLibrary,
  canRemoveMemberInPrivateOrHiddenLibrary,
  canRemoveMemberInPublicLibrary,
} from '~/utils/permissions/libraryPermissions';
import { isPermissionsGTE } from '~/utils/PermissionsUtils';

export type LibraryRoleDropdownMenuProps = {
  libraryId: Library['id'];
  member: { email: string; roleId: string; firstName?: string; lastName?: string; accountId?: string };
  onLeave?: () => void;
};

export const LibraryRoleDropdownMenu = ({
  libraryId,
  member: { accountId, firstName, lastName, email, roleId },
  onLeave,
}: LibraryRoleDropdownMenuProps) => {
  const { showLibraryToast } = useLibraryToasts();
  const { currentWorkspaceLibraryMember } = useCurrentWorkspaceLibraryMember({ libraryId });
  const { data: roles } = useWorkspaceRolesContext();
  const userRole = roles?.find((role) => role.id === roleId);
  const { libraryMemberUpdateMutation } = useLibraryMemberUpdateMutation({ libraryId });
  const { canLeaveLibrary } = useCanLeaveLibrary({ libraryId });
  const { data: library } = useLibrary({ libraryId });
  const { libraryMembersRemoveMutation } = useLibraryMembersRemoveMutation({ libraryId });
  const { libraryLeaveMutation } = useLibraryLeaveMutation({ libraryId });
  const { libraryPermissions } = useLibraryPermissions({ libraryId });
  const { data: currentWorkspacePermissions } = useCurrentWorkspacePermissionsContext();

  const { data: activeMembers } = useLibraryMembers({
    libraryId,
    options: {
      withPendingWorkspaceMembers: false,
      hideSysadmins: true,
    },
  });

  const options = useMemo(() => {
    const optionsArray: SelectDropdownMenuOption[] = [];

    if (!library || !libraryPermissions || !userRole) {
      return [];
    }

    if (
      library.accessibility === 'public'
        ? canChangeMemberRoleInPublicLibrary({
            workspaceContext: currentWorkspacePermissions,
            libraryContext: libraryPermissions,
          })
        : canChangeMemberRoleInPrivateOrHiddenLibrary({
            workspaceContext: currentWorkspacePermissions,
            libraryContext: libraryPermissions,
          })
    ) {
      roles
        ?.filter(({ type }) => type === WorkspaceMemberType.libraryMember)
        .forEach((role) => {
          optionsArray.push({
            description: role.description ?? undefined,
            // disabled: !canEditMembers || (role.isAdmin && !canEditAdmin),
            id: role.id,
            label: role.displayName,
            onSelect: () => {
              libraryMemberUpdateMutation.mutate(
                accountId ? { roleId: role.id, accountId } : { email: email, roleId: role.id },
                {
                  onError: (error) =>
                    showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
                  onSuccess: () => {
                    const fullName = firstName && lastName ? formatFullName(firstName, lastName) : email;

                    showLibraryToast({
                      message: (
                        <>
                          Changed <b className="text-jay-200">{fullName}</b> role to{' '}
                          <b className="text-jay-200">{role.displayName}</b>.
                        </>
                      ),
                    });
                  },
                },
              );
            },
            type: 'item',
            disabled:
              !isPermissionsGTE(libraryPermissions, userRole.permissions) ||
              !isPermissionsGTE(libraryPermissions, role.permissions),
          });
        });
    }

    if (!!optionsArray?.length) {
      optionsArray.push({
        id: 'separator',
        type: 'separator',
      });
    }

    if (accountId && currentWorkspaceLibraryMember.accountId === accountId) {
      if (canLeaveLibrary) {
        optionsArray.push({
          id: 'leave-library',
          label: 'Leave library',
          onSelect: () => {
            libraryLeaveMutation.mutate(
              { accountId },
              {
                onError: (error) => showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
                onSuccess: () => {
                  showLibraryToast({
                    message: <>Left {library?.title ? <b className="text-jay-200">{library.title}</b> : 'library'}.</>,
                  });
                  onLeave?.();
                },
              },
            );
          },
          type: 'item',
        });
      }
    } else {
      if (
        (library.accessibility === 'public'
          ? canRemoveMemberInPublicLibrary({
              libraryContext: libraryPermissions,
              workspaceContext: currentWorkspacePermissions,
            })
          : canRemoveMemberInPrivateOrHiddenLibrary({
              libraryContext: libraryPermissions,
              workspaceContext: currentWorkspacePermissions,
            })) &&
        isPermissionsGTE(libraryPermissions, userRole.permissions)
      ) {
        optionsArray.push({
          id: 'remove-from-library',
          label: 'Remove from library',
          onSelect: () => {
            const fullName = firstName && lastName ? formatFullName(firstName, lastName) : email;

            libraryMembersRemoveMutation.mutate([accountId ? { accountId } : { email }], {
              onError: (error) => {
                showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' });
              },
              onSuccess: () => {
                showLibraryToast({
                  message: (
                    <>
                      Removed <b className="text-jay-200">{fullName}</b> from{' '}
                      {library?.title ? <b className="text-jay-200">{library.title}</b> : 'library'}.
                    </>
                  ),
                });
              },
            });
          },
          type: 'item',
        });
      }
    }

    return optionsArray;
  }, [
    library,
    libraryPermissions,
    userRole,
    currentWorkspacePermissions,
    accountId,
    currentWorkspaceLibraryMember.accountId,
    roles,
    libraryMemberUpdateMutation,
    email,
    showLibraryToast,
    firstName,
    lastName,
    canLeaveLibrary,
    libraryLeaveMutation,
    onLeave,
    libraryMembersRemoveMutation,
  ]);

  if (isUndefined(activeMembers) || isUndefined(roles)) {
    return <Spinner className="h-4 w-4 text-pigeon-300" />;
  }

  if (!userRole) {
    return null;
  }

  const libraryOwnerRole = roles?.find((role) => role.displayName === WorkspaceMemberRoleDisplayName.LibraryOwner);
  const isLibraryOwner = !!libraryOwnerRole && libraryOwnerRole.id === roleId;
  const isSoleLibraryOwner =
    isLibraryOwner && activeMembers?.filter((member) => member.roleId === libraryOwnerRole.id).length === 1;

  if (isSoleLibraryOwner || !options.length) {
    return (
      <div data-testid="LIBRARY_ROLE_DROPDOWN_ROLE_PLACEHOLDER" className="px-2 text-14 text-grey-11">
        {userRole.displayName}
      </div>
    );
  }

  return (
    <SelectDropdownMenu
      align="end"
      options={options}
      selectedOption={{
        id: roleId,
      }}
      sideOffset={8}
      trigger={
        <Button
          data-testid="LIBRARY_ROLE_DROPDOWN_MENU_TRIGGER"
          appearance="ghost"
          className="gap-1 font-normal"
          color="grey"
          size="medium"
        >
          {userRole.displayName}
          {libraryMemberUpdateMutation.isPending ? (
            <Spinner className="h-4 w-4" />
          ) : (
            <TriangleDownIcon className="h-4 w-4" />
          )}
        </Button>
      }
    />
  );
};
