import { Subscriptions } from '@air/api';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

import { getSubscriptionKey } from './useSubscription';
import { getSubscriptionPlanKey } from './useSubscriptionPlan';

/** This hook returns a function that updates the current payment method */
export function useUpdatePaymentMethod() {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const updatePaymentMethod = useCallback(
    async (...params: Parameters<typeof Subscriptions.updateStripeSubscriptionPaymentMethod>) => {
      await Subscriptions.updateStripeSubscriptionPaymentMethod(...params);
      if (!!currentWorkspace?.id) {
        queryClient.invalidateQueries({ queryKey: getSubscriptionKey(currentWorkspace?.id) });
        queryClient.invalidateQueries({ queryKey: getSubscriptionPlanKey(currentWorkspace?.id) });
      }
    },

    [currentWorkspace?.id, queryClient],
  );

  return {
    updatePaymentMethod,
  };
}
