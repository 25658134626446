import { type ReactNode } from 'react';

import { MobileSearchListItem } from '~/_components/MobileSearchListItem';
import { MobileSearchListTitle } from '~/_components/MobileSearchListTitle';

export type MobileSearchListProps = {
  items: {
    id: string;
    label: string;
    onClick: () => void;
    thumbnail: ReactNode;
  }[];
  title?: string;
};

export const MobileSearchList = ({ items, title }: MobileSearchListProps) => {
  return (
    <div className="flex flex-col py-3">
      {!!title && <MobileSearchListTitle title={title} />}

      <ul className="flex flex-col gap-2 p-2">
        {items?.map((item) => (
          <MobileSearchListItem key={item.id} label={item.label} onClick={item.onClick} thumbnail={item.thumbnail} />
        ))}
      </ul>
    </div>
  );
};
