import { Members } from '@air/api';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getWorkspaceTotalsKey } from '~/swr-hooks/workspaces/useWorkspaceTotals';

import { getWorkspaceMembersKey } from './useGetWorkspaceMembers';

export const useRemoveWorkspaceMembers = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();

  const removeWorkspaceMembers = useCallback(
    async (...params: Parameters<typeof Members.remove>) => {
      try {
        const { members } = await Members.remove(...params);

        if (!!currentWorkspace?.id) {
          queryClient.invalidateQueries({ queryKey: getWorkspaceMembersKey(currentWorkspace?.id) });
          queryClient.invalidateQueries({ queryKey: getWorkspaceTotalsKey(currentWorkspace.id) });
        }

        return members;
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to remove workspace members',
          metadata: {
            data: {
              members: JSON.stringify(...params),
            },
          },
        });
      }
    },
    [currentWorkspace?.id, queryClient],
  );

  return {
    removeWorkspaceMembers,
  };
};
