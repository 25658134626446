import { Plus as PlusIcon } from '@air/next-icons';
import {
  ActionSheet,
  ActionSheetHeader,
  ActionSheetOptionItem,
  renderActionSheetItems,
} from '@air/primitive-action-sheet';
import Image from 'next/image';
import Router from 'next/router';
import { useMemo } from 'react';

import { Routes } from '~/constants/routes';
import { useSwitchWorkspace } from '~/hooks/useSwitchWorkspace';
import { useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';

export const WorkspacesActionSheet = ({ onClose }: AirModalProps) => {
  const { data: workspaces = [] } = useWorkspaces();
  const { switchWorkspace } = useSwitchWorkspace();

  const options = useMemo<ActionSheetOptionItem[]>(() => {
    const optionsArray: ActionSheetOptionItem[] = [];

    workspaces.forEach((workspace) => {
      optionsArray.push({
        id: workspace.id,
        label: workspace.name,
        prefix: (
          <Image
            alt={workspace.name}
            className="block h-6 w-6 rounded"
            height={72}
            src={`${workspace.image}?h=${72}&w=${72}=&fit=crop`}
            width={72}
            unoptimized
          />
        ),
        onSelect: () => {
          onClose();
          switchWorkspace(workspace);
          Router.push(Routes.home);
        },
        type: 'item',
      });
    });

    optionsArray.push({
      className: 'text-blue-11',
      id: 'create-new-workspace',
      label: 'Create a new workspace',
      prefix: (
        <div className="flex h-6 w-6 items-center justify-center rounded border border-blue-11">
          <PlusIcon className="h-4 w-4" />
        </div>
      ),
      onSelect: () => {
        Router.push(Routes.createWorkspace);
        onClose();
      },
      type: 'item',
    });

    return optionsArray;
  }, [onClose, switchWorkspace, workspaces]);

  return (
    <ActionSheet onClose={onClose}>
      <ActionSheetHeader onClose={onClose}>Switch workspace</ActionSheetHeader>
      <div className="-m-2 flex h-full flex-col gap-3 overflow-y-auto px-2 py-3">
        {renderActionSheetItems({ options })}
      </div>
    </ActionSheet>
  );
};
