import { useAnalyticsUtilities } from '@air/analytics';
import { Workspaces } from '@air/api';
import { WorkspaceListResponse } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getWorkspacesListKey } from './useWorkspaces';

/** This hook returns a function which can be used to update a workspace (name, image, etc.) */
export function useUpdateWorkspace() {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const { setGlobalEventProps } = useAnalyticsUtilities();

  const updateWorkspace = useMutation({
    mutationFn: (...params: Parameters<typeof Workspaces.update>) => Workspaces.update(...params),
    onMutate: async (...params) => {
      if (!currentWorkspace?.id) {
        return;
      }

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: getWorkspacesListKey() });

      // Snapshot the previous value
      const previousWorkspaces = queryClient.getQueryData<WorkspaceListResponse>(getWorkspacesListKey());

      queryClient.setQueryData(getWorkspacesListKey(), (workspaces?: WorkspaceListResponse) =>
        workspaces ? workspaces.map((ws) => (ws.id === currentWorkspace.id ? { ...ws, ...params[0] } : ws)) : [],
      );

      // Return a context object with the snapshotted value
      return { previousWorkspaces };
    },
    onSuccess: (remoteWorkspace) => {
      queryClient.invalidateQueries({ queryKey: getWorkspacesListKey() });

      setGlobalEventProps({ workspaceName: remoteWorkspace.name });
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (error, params, context) => {
      reportErrorToBugsnag({
        error,
        context: 'Unable to update workspace',
        metadata: {
          key: 'Data',
          data: { params, workspaceId: currentWorkspace?.id },
        },
      });

      queryClient.setQueryData(getWorkspacesListKey(), context?.previousWorkspaces);
    },
  });

  return {
    updateWorkspace,
  };
}
