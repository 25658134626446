import { SidebarNavigationItem } from '@air/component-sidebar-navigation';
import { Import } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';
import Link from 'next/link';

import { Routes } from '~/constants/routes';
import { useIsActiveRoute } from '~/hooks/useIsActiveRoute';

export const SidebarNavigationItemRecentlyAdded = () => {
  const { isActiveRoute } = useIsActiveRoute({ route: Routes.media.recentlyAdded });

  return (
    <Tooltip label="Items that were added to All assets & files within the past month" side="right" sideOffset={4}>
      <SidebarNavigationItem
        asChild
        data-id="recently-added"
        prefix={<Import className="h-4 w-4" />}
        state={isActiveRoute ? 'active' : 'default'}
      >
        <Link href={Routes.media.recentlyAdded}>Recently added</Link>
      </SidebarNavigationItem>
    </Tooltip>
  );
};
