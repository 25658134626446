import { Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { formatActorNameInitialsOrDefault } from '@air/utilities';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';

export type NotificationMemberInvitedTypeProps = Pick<NotificationsItemProps, 'onClick'> & {
  notification: Notification<'memberInvited_userIsMember', 'account'>;
};
export const NotificationMemberInvitedType = ({ notification, onClick }: NotificationMemberInvitedTypeProps) => {
  return (
    <NotificationBaseItem
      notification={notification}
      onClick={onClick}
      title={
        <>
          <b>
            {formatActorNameInitialsOrDefault(notification.data.actor)}
            {` invited you to `}
            <b>{notification.data.board.title}</b>
          </b>
        </>
      }
    />
  );
};
