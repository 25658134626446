import { NotificationCustomField, Notifications } from '@air/api/types';

export type NotificationCustomFieldType = Pick<NotificationCustomField, 'id' | 'type'> & {
  set?: Omit<NotificationCustomField, 'id' | 'type' | 'name'>;
  unset?: Omit<NotificationCustomField, 'id' | 'type' | 'name'>;
};

export const isSingleValueSet = (field: NotificationCustomFieldType) =>
  field.set?.value || field?.set?.dateValue || field?.set?.plainTextValue;

export const isSingleValueUnset = (field: NotificationCustomFieldType) =>
  (field.type.title === 'date' && !field.set?.dateValue) ||
  (field.type.title === 'plain-text' && !field.set?.plainTextValue) ||
  (field.type.title === 'single-select' && !field.set?.value);

export type AccountNotificationsType = Notifications<'account'>;
