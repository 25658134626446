import { Notification } from '@air/api/types';
import { NotificationsItemProps } from '@air/component-notifications';
import { memo } from 'react';

import { NotificationBaseItem } from '~/components/AirNotifications/components/NotificationBaseItem';
import { NotificationShareLinkIcon } from '~/components/AirNotifications/components/NotificationShareLinkIcon';
import { useShortUrlNotificationParentName } from '~/components/AirNotifications/hooks/useShortUrlNotificationParentName';

interface NotificationShareLinkNotViewedTypeProps extends Pick<NotificationsItemProps, 'onClick'> {
  notification: Notification<'shareLinkNotViewed_userIsShareLinkCreator', 'account'>;
}

export const NotificationShareLinkNotViewedType = memo(
  ({ notification, onClick }: NotificationShareLinkNotViewedTypeProps) => {
    const { shortUrl } = notification.data;

    const { shortUrlParentName } = useShortUrlNotificationParentName(shortUrl);

    return (
      <NotificationBaseItem
        meta={shortUrlParentName ? `in ${shortUrlParentName}` : ''}
        onClick={onClick}
        notification={notification}
        prefix={<NotificationShareLinkIcon />}
        title={
          <>
            A link to <b>{shortUrl.objectName}</b> has not been viewed yet
          </>
        }
      />
    );
  },
);

NotificationShareLinkNotViewedType.displayName = 'NotificationShareLinkNotViewedType';
