import { Close } from '@air/next-icons';
import { memo } from 'react';

import { ErroredAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';

export type RestoreLibraryFailedPanelItemProps = {
  onClear: () => void;
};

export const RestoreLibraryFailedPanelItem = memo(({ onClear }: RestoreLibraryFailedPanelItemProps) => {
  return (
    <PaneItemTW
      avatar={<ErroredAvatar />}
      title={<StatusTrackingTitle>Failed to Restore board!</StatusTrackingTitle>}
      buttons={<PaneButton label="Clear" Icon={Close} onClick={onClear} />}
    />
  );
});

RestoreLibraryFailedPanelItem.displayName = 'RestoreLibraryFailedPanelItem';
