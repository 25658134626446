/**
 * @see https://docs.google.com/spreadsheets/d/1FNN_n0FiP49GCljsiqg1CMwv99KU6wWEloWiet3t_j8/edit#gid=2143410996
 */

import { ScopedPermissions } from '@air/api';

export const canCreateLibrary = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.library.canCreate;

export const canDeleteLibrary = (libraryContext: ScopedPermissions | undefined) => !!libraryContext?.library.canDelete;

export const canChangeLibraryDetails = (libraryContext: ScopedPermissions | undefined) =>
  !!libraryContext?.library.canEdit;

export const canSeeMembersInPrivateOrPublicLibrary = ({
  libraryContext,
  workspaceContext,
}: {
  libraryContext: ScopedPermissions | undefined;
  workspaceContext: ScopedPermissions | undefined;
}) => !!libraryContext?.library.canView || !!workspaceContext?.library.canView;

export const canSeeMembersInHiddenLibrary = ({
  libraryContext,
  workspaceContext,
}: {
  libraryContext: ScopedPermissions | undefined;
  workspaceContext: ScopedPermissions | undefined;
}) => !!libraryContext?.library.canView || !!workspaceContext?.library.canViewHidden;

export const canAddOthersToPrivateOrHiddenLibrary = (libraryContext: ScopedPermissions | undefined) =>
  !!libraryContext?.library.canManageMembers;

export const canAddOthersToPublicLibrary = (libraryContext: ScopedPermissions | undefined) =>
  !!libraryContext?.library.canView;

export const canRemoveMemberInPublicLibrary = ({
  libraryContext,
  workspaceContext,
}: {
  libraryContext: ScopedPermissions | undefined;
  workspaceContext: ScopedPermissions | undefined;
}) => !!libraryContext?.library.canManageMembers || !!workspaceContext?.library.canManageMembers;

export const canRemoveMemberInPrivateOrHiddenLibrary = ({
  libraryContext,
  workspaceContext,
}: {
  libraryContext: ScopedPermissions | undefined;
  workspaceContext: ScopedPermissions | undefined;
}) =>
  !!libraryContext?.library.canManageMembers ||
  !!(workspaceContext?.library.canManageMembers && workspaceContext.library.canViewHidden);

export const canChangeMemberRoleInPrivateOrHiddenLibrary = ({
  libraryContext,
  workspaceContext,
}: {
  libraryContext: ScopedPermissions | undefined;
  workspaceContext: ScopedPermissions | undefined;
}) => !!libraryContext?.library.canManageMembers || !!workspaceContext?.library.canManageMembers;

export const canChangeMemberRoleInPublicLibrary = ({
  libraryContext,
  workspaceContext,
}: {
  libraryContext: ScopedPermissions | undefined;
  workspaceContext: ScopedPermissions | undefined;
}) => !!libraryContext?.library.canView || !!workspaceContext?.library.canView;
