import { useTrackDuplicatedBoard } from '@air/analytics';
import { Boards } from '@air/api';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSocketConnectionChange } from '~/providers/SocketContext/hooks/useSocketConnectionChange';
import { removeTasksAction } from '~/store/tasks/actions';
import { makeDuplicateBoardTasksSelectorByWorkspaceId } from '~/store/tasks/selectors';
import { ReadyState } from '~/types/sockets';
import { useAddBoardsToAllLists } from '~/utils/mutateUtils/AllBoards';
import { useAirSelector } from '~/utils/ReduxUtils';
import { useCancelTask } from '~/utils/taskUtils/useCancelTask';
import { useHideTask } from '~/utils/taskUtils/useHideTask';
import { usePrivateSyncTasks } from '~/utils/taskUtils/usePrivateSyncTasks';

import { PaneContainer } from '../FileStatusTrackingPane/PaneContainer';
import { DuplicateBoardCompletedPanelItem } from './DuplicateBoardTaskCompletedPanelItem';
import { DuplicateBoardFailedPanelItem } from './DuplicateBoardTaskFailedPanelItem';
import { DuplicateBoardPanelInProgressPanelItem } from './DuplicateBoardTaskInProgressPanelItem';

export const PrivateDuplicateBoardTasksPanel = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const tasksSelector = useMemo(
    () => makeDuplicateBoardTasksSelectorByWorkspaceId(currentWorkspace?.id),
    [currentWorkspace?.id],
  );
  const dispatch = useDispatch();
  const duplicateBoardTasks = useAirSelector(tasksSelector);
  const { cancelTask } = useCancelTask();
  const { hideTask } = useHideTask();

  const { addBoardsToAllLists } = useAddBoardsToAllLists();
  const { trackDuplicatedBoard } = useTrackDuplicatedBoard();

  const { loadFromStorage, syncLocalTasks } = usePrivateSyncTasks({
    workspaceId: currentWorkspace?.id,
    tasksSelector,
    localType: 'DuplicateBoard',
    remoteType: 'ContentDuplicator',
    onComplete: async ({ task, localTask }) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      task.data?.duplicatedRootBoards.forEach(({ id: boardId, title: boardTitle }) => {
        Boards.get({
          workspaceId,
          boardId,
        }).then((board) => {
          addBoardsToAllLists([board], localTask.metadata.targetBoardId);

          if (localTask.metadata.trackLocation) {
            trackDuplicatedBoard({
              boardId,
              boardTitle,
              location: localTask.metadata.trackLocation,
            });
          }
        });
      });
    },
  });

  /**
   * On mount, take the tasks from local storage and add them to Redux
   */
  useEffect(() => {
    if (currentWorkspace?.id) {
      loadFromStorage();
    }
  }, [loadFromStorage, currentWorkspace?.id]);

  /**
   * Because sockets reconnect when the user's internet connection comes back online,
   * we don't need an explicit isOnline check but can piggyback off of the socket reconnecting
   */
  useSocketConnectionChange({
    onChange: (readyState) => {
      if (readyState === ReadyState.OPEN) {
        syncLocalTasks();
      }
    },
  });

  /**
   * When this panel unmounts (user logs out most likely),
   * reset the state
   */
  useUnmount(() => {
    dispatch(
      removeTasksAction({
        taskIds: duplicateBoardTasks.map(({ localTaskId }) => localTaskId),
      }),
    );
  });

  if (duplicateBoardTasks.length < 1) {
    return null;
  }

  return (
    <div>
      {duplicateBoardTasks.map((duplicateBoardTask) => {
        return (
          <PaneContainer key={duplicateBoardTask.localTaskId} className="mb-2 last:mb-0">
            {!duplicateBoardTask.hidden && duplicateBoardTask.status === 'in-progress' && (
              <DuplicateBoardPanelInProgressPanelItem
                onCancel={() => hideTask(duplicateBoardTask.localTaskId)}
                {...duplicateBoardTask}
              />
            )}
            {duplicateBoardTask.status === 'completed' && (
              <DuplicateBoardCompletedPanelItem
                onClear={() => cancelTask(duplicateBoardTask.localTaskId)}
                {...duplicateBoardTask}
              />
            )}
            {duplicateBoardTask.status === 'error' && (
              <DuplicateBoardFailedPanelItem
                onClear={() => cancelTask(duplicateBoardTask.localTaskId)}
                {...duplicateBoardTask}
              />
            )}
          </PaneContainer>
        );
      })}
    </div>
  );
});

PrivateDuplicateBoardTasksPanel.displayName = 'PrivateDuplicateBoardTasksPanel';
