import { FlagInHandAsset } from '~/components/Assets/FlagInHandAsset';
import { NOTIFICATIONS_NONE } from '~/constants/testIDs';

export const NotificationEmpty = () => {
  return (
    <div
      className="flex h-full min-h-[400px] flex-col items-center bg-grey-2 px-6 pt-[20%]"
      data-testid={NOTIFICATIONS_NONE}
    >
      <FlagInHandAsset className="mb-8" />
      <p className="mb-4 text-20 font-medium text-grey-10">You’re all caught up</p>
      <p className="max-w-[430px] text-center text-14 text-grey-9">
        You’ll be notified here for @mentions, updates to boards that you’re following, and any share links you’ve
        created
      </p>
    </div>
  );
};
