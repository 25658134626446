import { TriangleDown as TriangleDownIcon } from '@air/next-icons';
import { Button, ButtonProps } from '@air/primitive-button';
import classNames from 'classnames';
import { forwardRef, memo, ReactNode } from 'react';

import { CURRENT_WORKSPACE_BUTTON } from '~/constants/testIDs';

export type CurrentWorkspaceHeaderTriggerProps = ButtonProps & {
  avatar?: ReactNode;
  title?: string;
};

export const _CurrentWorkspaceHeaderTrigger = forwardRef<HTMLButtonElement, CurrentWorkspaceHeaderTriggerProps>(
  ({ avatar, className, title, ...restOfProps }, forwardedRef) => {
    return (
      <Button
        appearance="ghost"
        color="grey"
        className={classNames('-ml-2 min-w-0 px-2', className)}
        data-testid={CURRENT_WORKSPACE_BUTTON}
        prefix={avatar}
        suffix={<TriangleDownIcon className="w-4 fill-grey-10" />}
        ref={forwardedRef}
        {...restOfProps}
      >
        <div className="truncate text-18 font-semibold text-grey-10">{title}</div>
      </Button>
    );
  },
);

_CurrentWorkspaceHeaderTrigger.displayName = '_CurrentWorkspaceHeaderTrigger';

export const CurrentWorkspaceHeaderTrigger = memo(_CurrentWorkspaceHeaderTrigger);

CurrentWorkspaceHeaderTrigger.displayName = 'CurrentWorkspaceHeaderTrigger';
