import { useActionBar } from '@air/provider-action-bar';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useToasts } from '@air/provider-toast';
import { memo, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useToggle, useUnmount } from 'react-use';

import { CurrentWorkspaceNav } from '~/components/CurrentWorkspaceNav/CurrentWorkspaceNav';
import { CURRENT_WORKSPACE_NAV_WIDTH, WORKSPACE_NAV_WIDTH } from '~/constants/sideNav';
import { useSideNavContext } from '~/providers/SideNavProvider';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { MainNavigation } from './MainNavigation';
import { SideNavToggle } from './SideNavToggle';

export const SideNav = memo(() => {
  const [isHovering, setIsHovering] = useToggle(false);
  const { isSideNavOpen, toggleSideNav } = useSideNavContext();

  useHotkeys(
    'ctrl+\\, meta+\\',
    toggleSideNav,
    {
      preventDefault: true,
    },
    [toggleSideNav],
  );

  const { setToastXOffset } = useToasts();
  const { setActionBarXOffset } = useActionBar();
  const { isAboveMediumScreen } = useBreakpointsContext();

  useEffect(() => {
    const offset = !isAboveMediumScreen
      ? 0
      : isSideNavOpen
      ? WORKSPACE_NAV_WIDTH + CURRENT_WORKSPACE_NAV_WIDTH
      : WORKSPACE_NAV_WIDTH;

    /** We offset the horizontal offset of the toasts and action bar witht he width of the entire sidenav */
    setToastXOffset(offset);
    setActionBarXOffset(offset);
  }, [isAboveMediumScreen, isSideNavOpen, setActionBarXOffset, setToastXOffset]);

  useUnmount(() => {
    setToastXOffset(0);
    setActionBarXOffset(0);
  });

  return (
    <aside className="relative flex" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <MainNavigation />
      {isSideNavOpen && <CurrentWorkspaceNav />}
      <SideNavToggle visibility={isHovering || !isSideNavOpen || isMobileAgent ? 'visible' : 'hidden'} />
    </aside>
  );
});

SideNav.displayName = 'SideNav';
