import { Libraries } from '@air/api';
import { ListLibraryCustomFieldsResponse, type UpdateLibraryCustomFieldParams } from '@air/api/dist/libraries';
import type { Library } from '@air/api/types';
import { useMutation } from '@tanstack/react-query';

import { getLibraryCustomFieldsKey } from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { queryClient } from '~/swr-hooks/queryClient';

export type UseLibraryUpdateCustomFieldsMutationParams = {
  libraryId: Library['id'];
};

export const useLibraryUpdateCustomFieldsMutation = ({ libraryId }: UseLibraryUpdateCustomFieldsMutationParams) => {
  const { currentWorkspace } = useCurrentWorkspace();

  const libraryUpdateCustomFieldsMutation = useMutation({
    mutationFn: (variables: Pick<UpdateLibraryCustomFieldParams, 'customFields'>) => {
      const workspaceId = currentWorkspace?.id;

      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return Libraries.updateCustomFields({
        workspaceId,
        libraryId,
        ...variables,
      });
    },

    onMutate: async ({ customFields }) => {
      await queryClient.cancelQueries({ queryKey: getLibraryCustomFieldsKey(libraryId) });

      const previousLibraryCustomFields = queryClient.getQueryData<ListLibraryCustomFieldsResponse>(
        getLibraryCustomFieldsKey(libraryId),
      );

      queryClient.setQueryData<ListLibraryCustomFieldsResponse>(
        getLibraryCustomFieldsKey(libraryId),
        (previousData) => {
          if (!previousData) {
            return;
          }

          return {
            ...previousData,
            data: previousData.data.map((customField) => {
              const updatedCustomField = customFields.find((field) => field.customFieldId === customField.id);

              if (updatedCustomField) {
                return {
                  ...customField,
                  visible: updatedCustomField.visible,
                };
              }

              return customField;
            }),
          };
        },
      );

      return { previousLibraryCustomFields };
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getLibraryCustomFieldsKey(libraryId) });
    },

    onError: (_error, _variables, context) => {
      if (context?.previousLibraryCustomFields) {
        queryClient.setQueryData(getLibraryCustomFieldsKey(libraryId), context.previousLibraryCustomFields);
      }
    },
  });

  return { libraryUpdateCustomFieldsMutation };
};
