import { useTrackRegeneratedContentCollectionLink } from '@air/analytics';
import { ShortUrl } from '@air/api';
import { Board, Form } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getShortIdInformationKey } from './useGetExistingShortIdInformation';

export const useRegenerateShortIdForForm = () => {
  const queryClient = useQueryClient();
  const { trackRegeneratedContentCollectionLink } = useTrackRegeneratedContentCollectionLink();

  const regenerateShortIdForForm = useMutation({
    mutationFn: async (
      params: Parameters<typeof ShortUrl.regenerateShortIdForForm>[0] & {
        formId: Form['id'];
        boardContext: Board['id'] | undefined;
      },
    ) => ShortUrl.regenerateShortIdForForm(params),
    onSuccess: (shortIdInfo, { formId }) => {
      queryClient.setQueryData(getShortIdInformationKey({ objectId: formId, boardContext: undefined }), shortIdInfo);
      trackRegeneratedContentCollectionLink();

      return shortIdInfo;
    },
    onError: (error, params) => {
      throw reportErrorToBugsnag({
        error,
        context: 'Failed to regenerate short id for form',
        metadata: { data: params },
      });
    },
  });

  return {
    regenerateShortIdForForm,
  };
};
