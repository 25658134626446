import { Library } from '@air/api/types';
import { useMemo } from 'react';
import isEqual from 'react-fast-compare';

import { useLibraryUpdateMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryUpdateMutation';
import { useLibrary } from '~/components/LibraryBeta/hooks/queries/useLibrary';
import { UpdateLibraryDetails } from '~/components/LibraryBeta/LibrarySettingsModal/components/UpdateLibraryDetails';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useGetCustomFieldColors } from '~/swr-hooks/customFields/useGetCustomFieldColors';
import { convertUnknownToError } from '~/utils/ErrorUtils';
import { getLibraryPrivacyInitialValue } from '~/utils/getLibraryPrivacyInitialValue';
import { getLibraryPrivacyValue } from '~/utils/getLibraryPrivacyValue';

export type UpdateLibraryDetailsContainerProps = {
  libraryId: Library['id'];
};

export const UpdateLibraryDetailsContainer = ({ libraryId }: UpdateLibraryDetailsContainerProps) => {
  const { showLibraryToast } = useLibraryToasts();
  const { data: colors } = useGetCustomFieldColors();
  const { data: library, isLoading: isLibraryLoading } = useLibrary({ libraryId });
  const { libraryUpdateMutation } = useLibraryUpdateMutation({ libraryId });

  const initialValues = useMemo(
    () => ({
      color: {
        id: library?.color.id ?? '',
        hex: library?.color.backgroundHex ?? '',
        name: library?.color.name ?? '',
      },
      description: library?.description ?? '',
      icon: library?.icon ?? '',
      title: library?.title ?? '',
      privacy: getLibraryPrivacyInitialValue(library),
    }),
    [library],
  );

  return (
    <UpdateLibraryDetails
      colors={colors?.data.map((color) => ({ hex: color.backgroundHex, id: color.id, name: color.name }))}
      initialValues={initialValues}
      isDisabled={isLibraryLoading}
      isSubmitting={libraryUpdateMutation.isPending}
      onSubmit={(values) => {
        libraryUpdateMutation.mutate(
          {
            ...getLibraryPrivacyValue(values?.privacy),
            ...(values.color && !isEqual(values.color, library?.color) ? { colorId: values.color.id } : {}),
            ...(!isEqual(values.icon, library?.icon) ? { icon: values.icon } : {}),
            ...(!isEqual(values.description, library?.description) ? { description: values.description } : {}),
            ...(!isEqual(values.title, library?.title) ? { title: values.title } : {}),
          },
          {
            onError: (error) => showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
            onSuccess: (data) =>
              showLibraryToast({
                message: (
                  <>
                    Updated library details for <b className="text-jay-200">{data.title}</b>.
                  </>
                ),
              }),
          },
        );
      }}
    />
  );
};
