import { TreeHeader } from '@air/component-tree';
import { Button } from '@air/primitive-button';
import React, { memo, useCallback } from 'react';

import { BoardsNavLoadingSkeleton } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsNavLoadingSkeleton';
import { BoardsTree, BoardsTreeProps } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTree/BoardsTree';
import { useSidebarSectionCollapseState } from '~/components/CurrentWorkspaceNav/BoardsNav/BoardsTreeItem/hooks/useSidebarSectionCollapseState';
import { SIDEBAR_FAVORITES_COLLAPSED } from '~/constants/localStorageKeys';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useFavoriteBoardsList } from '~/swr-hooks/boards/useFavoriteBoardsList';
import { useRearrangeBoards } from '~/swr-hooks/gallery/useGalleryRearrange';

const favoritesLineWidthPercentages = [58, 70, 51, 47, 62];

export type FavoriteBoardsProps = Pick<BoardsTreeProps, 'scrollElement'>;

export const FavoriteBoards = memo(({ scrollElement }: FavoriteBoardsProps) => {
  const { isCollapsed, handleToggleCollapse } = useSidebarSectionCollapseState({
    storageKey: SIDEBAR_FAVORITES_COLLAPSED,
  });
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const {
    data: favoriteBoards,
    isLoadingMore: isLoadingMoreFav,
    hasMore: hasMoreFav,
    isInitialLoading: isInitialLoadingFav,
    loadNextPage: loadMoreFav,
  } = useFavoriteBoardsList(workspaceId);

  useFetchObjectsPermissions({
    objects: {
      boardIds: favoriteBoards.map((board) => board.id),
    },
  });

  const { rearrangeFavoriteBoards } = useRearrangeBoards();

  const onBoardRearrange: BoardsTreeProps['onBoardRearrange'] = useCallback(
    ({ adjacentItem, boards, parentBoardId, board }) =>
      rearrangeFavoriteBoards({
        boards,
        draggedItem: board,
        adjacentItem,
        parentBoardId,
      }),
    [rearrangeFavoriteBoards],
  );

  const getCanRearrange: BoardsTreeProps['getCanRearrange'] = useCallback(() => true, []);

  if (isInitialLoadingFav) {
    return <BoardsNavLoadingSkeleton sectionLinesData={favoritesLineWidthPercentages} />;
  }

  if (!favoriteBoards.length) {
    return null;
  }

  return (
    <>
      <TreeHeader isExpanded={!isCollapsed} onTitleClick={handleToggleCollapse} title="Favorite boards" />

      {!isCollapsed && (
        <>
          {isInitialLoadingFav ? (
            <BoardsNavLoadingSkeleton sectionLinesData={[47, 68, 41, 60, 52]} />
          ) : (
            <BoardsTree
              boards={favoriteBoards}
              boardType="favorites"
              enableAutoExpand={false}
              id="favorites"
              parentBoardId={null}
              onBoardRearrange={onBoardRearrange}
              getCanRearrange={getCanRearrange}
              scrollElement={scrollElement}
            />
          )}

          {hasMoreFav && (
            <Button
              appearance="ghost"
              className="ml-6 justify-start"
              color="grey"
              isLoading={isLoadingMoreFav}
              onClick={() => loadMoreFav()}
              size="small"
            >
              Show more
            </Button>
          )}
        </>
      )}
    </>
  );
});

FavoriteBoards.displayName = 'FavoriteBoards';
