import type { ReactNode } from 'react';

import { LibraryMembersListLabel } from '~/components/LibraryBeta/LibrarySettingsModal/components/LibraryMembersPanel/components/LibraryMemberList/components/LibraryMembersListLabel';

export type LibraryMemberListProps = {
  label?: ReactNode;
  members: ReactNode;
};

export const LibraryMemberList = ({ label, members }: LibraryMemberListProps) => {
  return (
    <div className="flex flex-col gap-4">
      {!!label && <LibraryMembersListLabel>{label}</LibraryMembersListLabel>}
      {members}
    </div>
  );
};
