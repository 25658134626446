import { memo } from 'react';

import { GeneralLibraryMembersDescription } from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibraryMembers/GeneralLibraryMembersDescription';
import { GeneralLibraryMembersList } from '~/components/LibraryBeta/GeneralLibrarySettingsModal/components/GeneralLibraryMembers/GeneralLibraryMembersList';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';

interface GeneralLibraryMembersProps {
  onGoToSettingsClick: () => void;
}

export const GeneralLibraryMembers = memo(({ onGoToSettingsClick }: GeneralLibraryMembersProps) => {
  const { members } = useWorkspaceMembers();

  return (
    <div className="flex flex-col gap-4">
      <GeneralLibraryMembersDescription
        membersCount={members?.activeMembers?.length}
        onGoToSettingsClick={onGoToSettingsClick}
      />

      <GeneralLibraryMembersList members={members?.activeMembers ?? []} />
    </div>
  );
});

GeneralLibraryMembers.displayName = 'GeneralLibraryMembers';
