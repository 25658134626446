import { Board } from '@air/api/types';
import { resetSelectedItemsAction } from '@air/redux-selected-items';
import { useDispatch } from 'react-redux';

import { SelectableGalleryBoardItem } from '~/store/selectedItems/types';
import { useMoveBoards } from '~/swr-hooks/boards/useMoveBoards';

export type HandleDragItemsToRootBoardParams = {
  boards?: SelectableGalleryBoardItem<Board>[];
};

export const useHandleDragItemsToRootBoard = () => {
  const dispatch = useDispatch();
  const {
    moveBoards: { mutate: moveBoards },
  } = useMoveBoards();

  const handleDragItemsToRootBoard = ({ boards = [] }: HandleDragItemsToRootBoardParams) => {
    moveBoards({
      newParentId: null,
      boards: boards.map(({ item }) => ({
        ...item,
        ancestors: item.ancestors,
      })),
    });

    dispatch(resetSelectedItemsAction());
  };

  return { handleDragItemsToRootBoard };
};
