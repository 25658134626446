import { ShortUrlObjectType } from '@air/api/types';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { memo, useEffect, useMemo, useState } from 'react';

import { ContentCollectionCopyLink } from '~/components/Modals/ContentCollectionModal/ContentCollectionCopyLink';
import { NoPermissionToCreateShareLink } from '~/components/NoPermissionToCreateShareLink';
import { SharePrivateContentSpinner } from '~/components/SharePrivateContentModal/SharePrivateContentSpinner';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useFormPermissions } from '~/hooks/useFormPermissions';
import { useShortUrlPermissions } from '~/hooks/useShortUrlPermissions';
import { useListForms } from '~/swr-hooks/forms/useListForms';
import { useGetExistingShortIdInformation } from '~/swr-hooks/shortUrl/useGetExistingShortIdInformation';
import { EditShareLinkView } from '~/types/ShareLink';
import {
  canCreateBoardContentCollectionForm,
  canEditBoardContentCollectionForm,
  canSeeBoardContentCollectionForm,
} from '~/utils/permissions/boardPermissions';
import { canViewShortUrl } from '~/utils/permissions/shortUrlPermissions';

import { ContentCollectionDeleteActions } from './ContentCollectionDeleteActions';
import { ContentCollectionEditActions } from './ContentCollectionEditActions';
import { ContentCollectionGenerateActions } from './ContentCollectionGenerateActions';
import { CreateContentCollectionLink } from './CreateContentCollectionLink';

export type ContentCollectionModalProps = Pick<AirModalProps, 'onClose'> & {
  boardId: string;
  boardTitle: string;
};

export const ContentCollectionModal = memo(({ onClose, boardId, boardTitle }: ContentCollectionModalProps) => {
  const [view, setView] = useState<EditShareLinkView>('loading');

  const { boardPermissions } = useBoardPermissions({
    boardId,
  });

  const { data: forms, isLoading: fetchingForms } = useListForms(boardId, {
    staleTime: Infinity,
    enabled: canSeeBoardContentCollectionForm(boardPermissions),
  });

  const form = forms?.data[0];

  useFetchObjectsPermissions({
    objects: {
      formIds: form?.id ? [form.id] : [],
    },
  });

  const { formPermissions } = useFormPermissions({
    formId: form?.id,
  });

  const { data: shortIdInfo, isFetched: fetchedShortIdInfo } = useGetExistingShortIdInformation({
    id: form?.id,
    type: ShortUrlObjectType.form,
    boardContext: undefined,
    canView: canViewShortUrl(formPermissions),
  });

  useFetchObjectsPermissions({
    objects: {
      shortIds: shortIdInfo?.id ? [shortIdInfo.id] : [],
    },
  });

  const { shortUrlPermissions } = useShortUrlPermissions({
    shortId: shortIdInfo?.id,
  });

  const isLoading = fetchingForms || !!(form && !fetchedShortIdInfo);

  useEffect(() => {
    if (isLoading) {
      setView('loading');
    } else {
      switch (view) {
        case 'loading':
          {
            if (shortIdInfo) {
              setView('edit');
            } else {
              setView('create');
            }
          }
          break;

        case 'create':
          {
            if (shortIdInfo) {
              setView('edit');
            }
          }
          break;

        default: {
          if (!shortIdInfo) {
            setView('create');
          }
        }
      }
    }
  }, [isLoading, setView, shortIdInfo, view]);

  const modalTitle = useMemo(() => {
    switch (view) {
      case 'create':
      case 'edit':
        return 'Share a content collection form';
      case 'delete':
        return 'Delete this link?';
      case 'regenerate':
        return 'Generate new link';
      case 'loading':
        break;
    }
  }, [view]);

  const modalDescription = useMemo(() => {
    switch (view) {
      case 'regenerate':
        return `This will permanently expire the old link. Visitors will need to use the new link to access this form.`;
      case 'delete':
        return 'Visitors will no longer be able to access this form.';
      case 'create':
      case 'edit':
        return 'Create an upload form to collect assets and files from anyone';
      case 'loading':
        break;
    }
  }, [view]);

  return (
    <Modal
      data-testid={`CONTENT_COLLECTION_FORM_MODAL-${view}`}
      isOpen
      onDismiss={onClose}
      dangerouslyBypassFocusLock
      className="w-[640px]"
    >
      <div className="relative flex flex-col gap-6">
        <header className="flex items-center justify-between gap-2">
          <ModalTitle>{modalTitle}</ModalTitle>
          <ModalCloseButton onClick={onClose} />
        </header>
        {!!modalDescription && <ModalDescription>{modalDescription}</ModalDescription>}
        {canSeeBoardContentCollectionForm(boardPermissions) ? (
          view === 'loading' ? (
            <div className="absolute left-1/2 top-2 -translate-x-1/2">
              <SharePrivateContentSpinner />
            </div>
          ) : view === 'create' || !shortIdInfo || !form ? (
            canCreateBoardContentCollectionForm(boardPermissions) ? (
              <CreateContentCollectionLink boardId={boardId} boardTitle={boardTitle} form={form} />
            ) : (
              <NoPermissionToCreateShareLink />
            )
          ) : view === 'edit' ? (
            canEditBoardContentCollectionForm(shortUrlPermissions) ? (
              <ContentCollectionEditActions setView={setView} form={form} shortIdInfo={shortIdInfo} />
            ) : (
              <ContentCollectionCopyLink shortIdInfo={shortIdInfo} />
            )
          ) : view === 'delete' ? (
            <ContentCollectionDeleteActions form={form} shortIdInfo={shortIdInfo} setView={setView} />
          ) : view === 'regenerate' ? (
            <ContentCollectionGenerateActions form={form} shortIdInfo={shortIdInfo} setView={setView} />
          ) : (
            <ContentCollectionCopyLink shortIdInfo={shortIdInfo} />
          )
        ) : (
          /**
           * This scenario should never happen as the button to open this modal
           * shouldn't have been shown if the user doesn't have permission to view the share link
           */
          <NoPermissionToCreateShareLink />
        )}
      </div>
    </Modal>
  );
});

ContentCollectionModal.displayName = 'ContentCollection';
