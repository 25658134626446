import type { ReactNode } from 'react';

export type LibraryDetailsProps = {
  accessibility?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
};

export const LibraryDetails = ({ accessibility, description, title }: LibraryDetailsProps) => {
  return (
    <div className="flex flex-col gap-6">
      {title ? (
        <div className="flex flex-col gap-1">
          <div className="text-12 font-semibold text-pigeon-500">Name</div>
          {title}
        </div>
      ) : null}

      {description ? (
        <div className="flex flex-col gap-1">
          <div className="text-12 font-semibold text-pigeon-500">Description</div>
          {description}
        </div>
      ) : null}

      {accessibility ? (
        <div className="flex flex-col gap-1">
          <div className="text-12 font-semibold text-pigeon-500">Privacy</div>

          {accessibility}
        </div>
      ) : null}
    </div>
  );
};
