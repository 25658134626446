import { Upload, uploadByIdSelector } from '@air/redux-uploader';
import { useCallback } from 'react';

import { useGetPrivateUploadBoardId } from '~/components/Upload/privateUpload/useGetPrivateUploadBoardId';
import { metadataForPrivateUploadSelector } from '~/store/privateUploadMetadata/selectors';
import { uploadingPathToBoardIdSelector } from '~/store/uploadingPathToBoardId/selector';
import { createPathRootId, getFileParentsPaths } from '~/store/uploadingPathToBoardId/utils';
import { useAirStore } from '~/utils/ReduxUtils';

export const useIsBlockedByParentBoard = () => {
  const store = useAirStore();
  const { getUploadBoardId } = useGetPrivateUploadBoardId();

  const isBlockedByParentBoard = useCallback(
    (uploadId: Upload['id']) => {
      const nextUpload = uploadByIdSelector(store.getState(), uploadId)!;
      const pathToBoardId = uploadingPathToBoardIdSelector(store.getState());
      if (nextUpload) {
        const parentBoardId = getUploadBoardId(nextUpload);
        const uploadMetadata = metadataForPrivateUploadSelector(store.getState(), uploadId);
        const pathRootId = createPathRootId(nextUpload.batchId, uploadMetadata?.originBoard?.id);

        if (!parentBoardId && uploadMetadata) {
          const parents = getFileParentsPaths(uploadMetadata.filepath);

          // check to see if any of the parent boards for the upload are being created
          return parents.some((path) => pathToBoardId?.[pathRootId]?.[path]?.isCreating);
        }
        return false;
      }
      return true;
    },
    [getUploadBoardId, store],
  );

  return {
    isBlockedByParentBoard,
  };
};
