import { ClipSource, ImporterListItem } from '@air/api/types';

import { sharedThumbnailStyles } from '~/components/FileStatusTrackingPane/shared';
import { DropboxImportIcon, GoogleDriveImportIcon, HomeBoxImportIcon, ImportIconProps } from '~/components/Import/ui';

const commonProps: ImportIconProps = {
  isActive: true,
  tx: sharedThumbnailStyles,
};

interface ImportItemIconProps {
  source: ImporterListItem['source'];
}

export const ImportItemIcon = ({ source }: ImportItemIconProps) => {
  switch (source) {
    case ClipSource.dropbox:
      return <DropboxImportIcon {...commonProps} />;
    case ClipSource.googleDrive:
      return <GoogleDriveImportIcon {...commonProps} />;
    case ClipSource.box:
      return <HomeBoxImportIcon {...commonProps} />;
    default:
      return null;
  }
};
