import { ChevronDown } from '@air/next-icons';
import { memo } from 'react';

import { ProgressingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { GetInProgressMetadata, LibraryDeletionTask } from '~/store/tasks/types';

export type DeleteBoardPanelInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<LibraryDeletionTask>, 'libraryId'>;
  onCancel: () => void;
};

export const DeleteLibraryPanelInProgressPanelItem = memo(({ onCancel }: DeleteBoardPanelInProgressPanelItemProps) => {
  return (
    <PaneItemTW
      avatar={<ProgressingAvatar />}
      title={<StatusTrackingTitle>Deleting library</StatusTrackingTitle>}
      buttons={<PaneButton label="Hide" Icon={ChevronDown} onClick={onCancel} />}
    />
  );
});

DeleteLibraryPanelInProgressPanelItem.displayName = 'DeleteLibraryPanelInProgressPanelItem';
