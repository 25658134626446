import { treeItem, TreeItemButton } from '@air/component-tree';
import { Check, ImageSearch } from '@air/next-icons';

export type BoardSelectAllAssetsAndFilesListItemProps = {
  isSelected?: boolean;
  onSelect: () => void;
};

export const BoardSelectAllAssetsAndFilesListItem = ({
  isSelected,
  onSelect,
}: BoardSelectAllAssetsAndFilesListItemProps) => {
  return (
    <div className={treeItem({ size: 'small', state: isSelected ? 'selected' : 'default' })}>
      <ImageSearch className="h-4 w-4 shrink-0" />

      <TreeItemButton onClick={onSelect}>All assets & files</TreeItemButton>

      {isSelected && (
        <div className="flex h-4 w-4 shrink-0 items-center justify-center rounded-full bg-blue-3">
          <Check className="h-3 w-3 text-blue-11" />
        </div>
      )}
    </div>
  );
};
