import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import pluralize from 'pluralize';
import { memo, useCallback } from 'react';

import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';

interface LibraryInviteToWorkspaceModalProps {
  nonWorkspaceMembers: string[];
  onConfirm: () => void;
}

export const LibraryInviteToWorkspaceModal = memo(
  ({ onClose, nonWorkspaceMembers, onConfirm }: AirModalProps<LibraryInviteToWorkspaceModalProps>) => {
    const { members } = useWorkspaceMembers();

    const newMembersCount = members
      ? members.allMembers.length + nonWorkspaceMembers.length
      : nonWorkspaceMembers.length;

    const onConfirmClick = useCallback(() => {
      onConfirm();
      onClose();
    }, [onClose, onConfirm]);

    return (
      <Modal isOpen>
        <div className="mb-4 flex items-center justify-between">
          <ModalTitle>Invite {pluralize('members', nonWorkspaceMembers.length, true)} to workspace?</ModalTitle>
          <ModalCloseButton className="shrink-0" onClick={onClose} />
        </div>
        <ModalDescription className="mb-6">
          Are you sure you want to invite {nonWorkspaceMembers.length} new{' '}
          {pluralize('members', nonWorkspaceMembers.length, false)} to the workspace? The following emails will be added
          as workspace <b>Editors</b> and increase total seats to {newMembersCount}:
          <ul className="mt-2">
            {nonWorkspaceMembers.map((email) => (
              <li key={email}>{` • ${email}`}</li>
            ))}
          </ul>
        </ModalDescription>
        <div className="flex justify-end gap-3">
          <Button appearance="ghost" color="grey" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button appearance="filled" color="blue" size="large" onClick={onConfirmClick}>
            Confirm
          </Button>
        </div>
      </Modal>
    );
  },
);

LibraryInviteToWorkspaceModal.displayName = 'LibraryInviteToWorkspaceModal';
