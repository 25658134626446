import { v4 as uuid } from 'uuid';

import { CustomFieldModalFormData } from '~/components/CustomFields/CustomFieldModal/CustomFieldModalForm/types';

export const initialFormValue: CustomFieldModalFormData = {
  fieldTypeId: '',
  description: '',
  name: '',
  values: [
    {
      value: 'Option 1',
      id: uuid(),
      color: {
        backgroundHex: '#d6ffc1',
        fontHex: 'black',
        name: 'Light green',
        id: uuid(),
      },
    },
    {
      value: 'Option 2',
      id: uuid(),
      color: {
        backgroundHex: '#ffd9d9',
        fontHex: 'black',
        name: 'Light red',
        id: uuid(),
      },
    },
  ],
};
