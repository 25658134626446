import { Board } from '@air/api/types';
import { TriangleRight as TriangleRightIcon } from '@air/next-icons';

import { DraggingItems } from '~/components/Shared/Drag/dragTypes';

interface NavBoardDragPreviewProps {
  board: DraggingItems<Board>;
}

export const NavBoardDragPreview = ({ board }: NavBoardDragPreviewProps) => {
  return (
    <div className="flex w-[240px] rounded-sm bg-grey-3 px-2 py-1.5 shadow-[0_0_8px] shadow-black/20">
      <TriangleRightIcon className="mr-2 w-4 shrink-0 text-grey-10" />
      <div className="truncate text-14 text-grey-11">{board.firstItem.title}</div>
    </div>
  );
};
