import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentPropsWithoutRef, memo } from 'react';

import { SidebarResizer, SidebarResizerProps } from './SidebarResizer';

export type SidebarProps = ComponentPropsWithoutRef<'div'> & Pick<SidebarResizerProps, 'localStorageKey' | 'size'>;

export const Sidebar = memo(({ children, className, localStorageKey, size, ...restOfProps }: SidebarProps) => {
  return (
    <div
      className={tailwindMerge(
        'flex h-full w-[var(--sidebar-width)] flex-col items-stretch border-r border-r-grey-4 bg-grey-1',
        className,
      )}
      data-testid="SIDEBAR"
      {...restOfProps}
    >
      {children}
      <SidebarResizer localStorageKey={localStorageKey} size={size} />
    </div>
  );
});

Sidebar.displayName = 'Sidebar';
