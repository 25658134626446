import { LibraryWithPermissions } from '@air/api/types';
import { isUndefined } from 'lodash';
import pluralize from 'pluralize';
import { memo, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { useLibraryStats } from '~/components/LibraryBeta/hooks/queries/useLibraryStats';
import { LibraryItemProps } from '~/components/LibraryBeta/LibraryBrowseModal/components/LibraryItem';
import { LibraryItemMemberContainer } from '~/components/LibraryBeta/LibraryBrowseModal/components/LibraryItemMember.container';
import { LibraryItemNonMemberContainer } from '~/components/LibraryBeta/LibraryBrowseModal/components/LibraryItemNonMember.container';
import { LibraryItemPendingContainer } from '~/components/LibraryBeta/LibraryBrowseModal/components/LibraryItemPending.container';

export type LibraryItemContainerProps = Pick<LibraryItemProps, 'className'> & {
  library: LibraryWithPermissions;
  onLeave: () => void;
};

export const LibraryItemContainer = memo(({ className, library, onLeave }: LibraryItemContainerProps) => {
  const { ref: inViewRef, inView } = useInView();
  const { data: libraryStats } = useLibraryStats({
    libraryId: library.id,
    isEnabled: inView,
  });

  const stats = useMemo(() => {
    if (isUndefined(libraryStats)) {
      return null;
    }

    return [
      pluralize('member', libraryStats.memberCount, true),
      ' • ',
      pluralize('item', libraryStats.assetCount + libraryStats.boardCount + libraryStats.fileCount, true),
    ];
  }, [libraryStats]);

  return library.pending ? (
    <LibraryItemPendingContainer stats={stats} ref={inViewRef} className={className} library={library} />
  ) : library.roleId ? (
    <LibraryItemMemberContainer
      stats={stats}
      ref={inViewRef}
      className={className}
      library={library}
      onLeave={onLeave}
    />
  ) : (
    <LibraryItemNonMemberContainer stats={stats} ref={inViewRef} className={className} library={library} />
  );
});

LibraryItemContainer.displayName = 'LibraryItemContainer';
