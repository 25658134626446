import { Forms } from '@air/api';
import { Board, FormsListResponse } from '@air/api/types';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const CONTENT_COLLECTION_FORMS_LIST = 'CONTENT_COLLECTION_FORMS_LIST';

export const getContentCollectionFormsKey = (boardId: string) => [CONTENT_COLLECTION_FORMS_LIST, { boardId }];

export const useListForms = (
  boardId?: Board['id'],
  options?: Omit<UseQueryOptions<FormsListResponse, unknown, FormsListResponse, QueryKey>, 'queryKey' | 'queryFn'>,
) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  return useQuery({
    queryKey: getContentCollectionFormsKey(boardId!),

    queryFn: () => {
      if (!workspaceId) {
        throw new Error('Workspace ID is not defined');
      }

      if (!boardId) {
        throw new Error('Board ID is not defined');
      }

      return Forms.listForms({ boardId, workspaceId });
    },
    enabled: !!boardId && !!workspaceId,
    ...options,
  });
};
