import { Badge } from '@air/primitive-badge';
import type { ComponentProps, ReactNode } from 'react';

export type LibraryMemberListItemProps = Omit<ComponentProps<'div'>, 'prefix'> & {
  isGuest?: boolean;
  email: string;
  name?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
};

export const LibraryMemberListItem = ({ email, isGuest, name, prefix, suffix }: LibraryMemberListItemProps) => {
  return (
    <div data-testid="LIBRARY_MEMBER_LIST_ITEM" data-id={email} className="flex items-center gap-3">
      {!!prefix && <div className="flex shrink-0 gap-2">{prefix}</div>}

      <div className="flex min-w-0 grow flex-col gap-0.5">
        <div className="flex items-center gap-2">
          <div className="truncate text-14 font-medium text-grey-12">{name}</div>
          {isGuest && (
            <Badge appearance="pill" className="shrink-0" color="yellow" size="small">
              Guest
            </Badge>
          )}
        </div>

        {!!email && <div className="truncate text-12 text-grey-10">{email}</div>}
      </div>

      {!!suffix && <div className="flex shrink-0 gap-2">{suffix}</div>}
    </div>
  );
};
