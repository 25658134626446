import Router from 'next/router';
import { memo, useCallback, useEffect, useState } from 'react';

import { FileStatusTrackingPane } from '~/components/FileStatusTrackingPane/FileStatusTrackingPane/FileStatusTrackingPane';
import { ImportedFilesRefresh } from '~/components/Import/ImportedFilesRefresh';
import { convertImportToFileTrackingPaneItem, getImportHeaderTitles } from '~/components/Import/utils';
import { Routes } from '~/constants/routes';
import { IMPORTER_PANE } from '~/constants/testIDs';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { LocationBoard } from '~/store/router/types';
import { useImports } from '~/swr-hooks/useImports';

export const ImportManager = memo(() => {
  const [isImportPaneVisible, setImportPaneVisibility] = useState(false);
  const [refetchInterval, setRefreshInterval] = useState(0);
  const { goToBoardPage } = useGoToBoardPage();

  const { imports, hasImportsInProgress } = useImports({
    refetchInterval,
  });

  const closePane = useCallback(() => {
    setImportPaneVisibility(false);
  }, [setImportPaneVisibility]);

  const onRowClick = useCallback(
    (board: LocationBoard) => {
      if (board.id) {
        goToBoardPage({
          board,
          trackLocation: 'import-popover',
        });
      } else {
        Router.push(Routes.media.all);
      }
    },
    [goToBoardPage],
  );

  const importItems = imports?.data?.map((item) => convertImportToFileTrackingPaneItem(item, onRowClick)) || [];

  useEffect(() => {
    if (hasImportsInProgress && refetchInterval === 0) {
      setRefreshInterval(3000);
    } else if (!hasImportsInProgress && refetchInterval !== 0) {
      setRefreshInterval(0);
    }
    // we want to call this method only when imports change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasImportsInProgress]);

  useEffect(() => {
    if (hasImportsInProgress && !isImportPaneVisible) {
      setImportPaneVisibility(true);
    }
    // we want to call this only when importsInProgress changes to true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasImportsInProgress]);

  return isImportPaneVisible ? (
    <div className="mt-3">
      <FileStatusTrackingPane
        getStatusTitles={getImportHeaderTitles}
        testId={IMPORTER_PANE}
        disableCancel
        onDismiss={closePane}
        items={importItems}
      />
      {imports?.data && <ImportedFilesRefresh imports={imports?.data} />}
    </div>
  ) : null;
});

ImportManager.displayName = 'ImportManager';
