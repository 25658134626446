import { ListNotificationsResponse } from '@air/api/types';
import { InfiniteData } from '@tanstack/react-query';
import produce from 'immer';

export const markNotificationAsReadInCache = (
  data: InfiniteData<ListNotificationsResponse> | undefined,
  notificationId: string,
) => {
  const date = new Date().toISOString();

  if (!data) return;

  return produce(data, (draft) => {
    draft.pages.forEach((page) => {
      page.items.forEach((item) => {
        if (item.id === notificationId && !item.readAt) {
          item.readAt = date;
        }
      });
    });
  });
};
