import { LibraryWithPermissions } from '@air/api/types';
import { Skeleton } from '@air/component-skeleton';
import { Check } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import classNames from 'classnames';
import { isNull } from 'lodash';
import { forwardRef, useMemo } from 'react';

import { useLibraryLeaveMutation } from '~/components/LibraryBeta/hooks/mutations/useLibraryLeaveMutation';
import { useCanLeaveLibrary } from '~/components/LibraryBeta/hooks/useCanLeaveLibrary';
import { LibraryItem, LibraryItemProps } from '~/components/LibraryBeta/LibraryBrowseModal/components/LibraryItem';
import { useLibraryToasts } from '~/hooks/useLibraryToasts';
import { useAccount } from '~/swr-hooks/account/useAccount';
import { convertUnknownToError } from '~/utils/ErrorUtils';

export type LibraryItemMemberContainerProps = Pick<LibraryItemProps, 'className'> & {
  library: LibraryWithPermissions;
  onLeave: () => void;
  stats: null | string[];
};

export const LibraryItemMemberContainer = forwardRef<HTMLDivElement, LibraryItemMemberContainerProps>(
  ({ className, library, onLeave, stats }, ref) => {
    const { showLibraryToast } = useLibraryToasts();
    const { libraryLeaveMutation } = useLibraryLeaveMutation({ libraryId: library.id });
    const { data: account } = useAccount();
    const { canLeaveLibrary } = useCanLeaveLibrary({ libraryId: library.id });

    const meta = useMemo(() => {
      if (isNull(stats)) {
        return null;
      }

      return [
        <div key="0" className="mr-1 flex items-center gap-1 text-12 font-semibold text-parrot-700">
          <Check className="h-4 w-4" />
          Joined
        </div>,
        ' • ',
        ...stats,
      ];
    }, [stats]);

    return (
      <LibraryItem
        actions={
          <Button
            appearance="ghost"
            color="grey"
            disabled={!account?.id || !canLeaveLibrary}
            isLoading={libraryLeaveMutation.isPending}
            data-testid="LIBRARY_ITEM_MEMBER_LEAVE_BUTTON"
            onClick={() => {
              if (!!account?.id) {
                libraryLeaveMutation.mutate(
                  { accountId: account.id },
                  {
                    onError: (error) =>
                      showLibraryToast({ message: convertUnknownToError(error).message, type: 'error' }),
                    onSuccess: () => {
                      showLibraryToast({
                        message: (
                          <>
                            Left <b className="text-jay-200">{library.title}</b> library.
                          </>
                        ),
                      });
                      onLeave();
                    },
                  },
                );
              }
            }}
            size="small"
          >
            Leave
          </Button>
        }
        className={classNames('border-b border-b-pigeon-100', className)}
        description={library.description}
        key={library.id}
        meta={meta || <Skeleton height={26} isText width={120} />}
        ref={ref}
        title={library.title}
      />
    );
  },
);

LibraryItemMemberContainer.displayName = 'LibraryItemContainer';
