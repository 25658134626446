import Router from 'next/router';
import { useCallback } from 'react';

import { Routes } from '~/constants/routes';
import { useIsCurrentUserEmailVerified } from '~/swr-hooks/account/useIsCurrentUserEmailVerified';
import { useListApprovedWorkspaces } from '~/swr-hooks/workspaces/useListApprovedWorkspaces';

export const useCreateWorkspace = () => {
  const { userEmailIsVerified } = useIsCurrentUserEmailVerified();

  const { data: approvedWorkspaces } = useListApprovedWorkspaces();

  const workspaces = approvedWorkspaces?.data || [];
  const hasApprovedDomainWorkspaces = workspaces.length > 0;

  const createWorkspace = useCallback(async () => {
    const shouldNavigateToApprovedDomains = userEmailIsVerified && hasApprovedDomainWorkspaces;

    const route = shouldNavigateToApprovedDomains ? Routes.joinApprovedWorkspace : Routes.createWorkspace;
    Router.push(route);
  }, [hasApprovedDomainWorkspaces, userEmailIsVerified]);

  return { createWorkspace };
};
