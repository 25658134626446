import { useTrackShownInactivityModal } from '@air/analytics';
import { Button } from '@air/primitive-button';
import { Modal, ModalTitle } from '@air/primitive-modal';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { PROMPT_BEFORE_IDLE_SECONDS } from '~/components/Layouts/HomeLayout/hooks/useHandleInactivity';
import { useInactivity } from '~/providers/InactivityProvider';
import { useLogout } from '~/swr-hooks/auth/useLogout';

export type InactivityAlertModalProps = AirModalProps;

export const InactivityAlertModal = ({ onClose }: InactivityAlertModalProps) => {
  const dismissButtonRef = useRef<HTMLButtonElement>(null);
  const { getRemainingTime, activate } = useInactivity();
  const calculateTotalRemainingSeconds = useCallback(() => Math.ceil(getRemainingTime() / 1000), [getRemainingTime]);
  const [totalRemainingSeconds, setTotalRemainingSeconds] = useState(calculateTotalRemainingSeconds());
  const { logout } = useLogout();
  const { trackShownInactivityModal } = useTrackShownInactivityModal();

  useEffect(() => {
    trackShownInactivityModal();
  }, [trackShownInactivityModal]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalRemainingSeconds(calculateTotalRemainingSeconds());
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [calculateTotalRemainingSeconds]);

  /**
   * There's a bug we've noticed where sometimes this modal is fired way before it should be.
   * If that happens, this code should detect that and then hide it.
   */
  useEffect(() => {
    if (totalRemainingSeconds > PROMPT_BEFORE_IDLE_SECONDS) {
      onClose();
    }
  }, [onClose, totalRemainingSeconds]);

  const minutes = Math.floor(totalRemainingSeconds / 60);
  const seconds = totalRemainingSeconds % 60;

  return (
    <Modal isOpen>
      <header className="mb-6 flex justify-between gap-4">
        <ModalTitle>Your session is about to expire</ModalTitle>
      </header>
      <div className="text-16 text-grey-11">
        <p className="mb-4 text-16">For your security, your session will expire shortly</p>
        <p>
          You will be logged out in{' '}
          <span className="inline font-bold">
            {minutes ? pluralize('minute', minutes, true) : ''}
            {seconds && minutes ? ' ' : ''}
            {seconds ? pluralize('second', seconds, true) : ''}
          </span>
          .
        </p>
      </div>
      <div className="mt-8 flex justify-end">
        <Button
          onClick={() => {
            logout();
            onClose();
          }}
          appearance="ghost"
          color="grey"
          className="mr-4"
          size="large"
        >
          Logout
        </Button>
        <Button
          ref={dismissButtonRef}
          onClick={() => {
            activate();
            onClose();
          }}
          appearance="filled"
          color="blue"
          size="large"
        >
          Stay logged in
        </Button>
      </div>
    </Modal>
  );
};
