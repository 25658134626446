import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentProps } from 'react';

import { NotificationsFiltersItem } from './NotificationsFiltersItem';

export type NotificationsFilter = {
  count?: number;
  id: string;
  isActive: boolean;
  name: string;
};

export type NotificationsFiltersProps = ComponentProps<'div'> & {
  filters: NotificationsFilter[];
  onFilterClick: (filter: NotificationsFilter) => void;
};

export const NotificationsFilters = ({
  className,
  filters,
  onFilterClick,
  ...restOfProps
}: NotificationsFiltersProps) => {
  return (
    <div
      className={tailwindMerge('flex items-center gap-2 px-2', className)}
      data-testid="NOTIFICATIONS_FILTERS"
      {...restOfProps}
    >
      {filters?.map((filter, index) => {
        return (
          <NotificationsFiltersItem
            count={filter.count}
            data-id={filter.id}
            key={filter.id || index}
            onClick={() => onFilterClick?.(filter)}
            state={filter.isActive ? 'active' : 'default'}
          >
            {filter.name}
          </NotificationsFiltersItem>
        );
      })}
    </div>
  );
};
