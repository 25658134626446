/**
 * This method moves item from fromIndex to toIndex in array
 *
 * @param array array to modify
 * @param fromIndex current index of item
 * @param toIndex desired index of item
 */
export function moveItemToIndex(array: any[], fromIndex: number, toIndex: number) {
  const itemToMove = array[fromIndex];

  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, itemToMove);

  return array;
}
