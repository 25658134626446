import { largeUploadWithS3InfoByIdSelector, Upload } from '@air/redux-uploader';
import { useUploadLargeFileChunkWithRetries } from '@air/upload-utils';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { privateUploadBoardIdSelector } from '~/store/privateUploadMetadata/selectors';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useAirStore } from '~/utils/ReduxUtils';
import { fetchPrivatePartUploadUrl } from '~/utils/uploadApi/privateUploadApi/privateLargeUploadApi';

export const useUploadLargePrivateFileWithRetries = () => {
  const store = useAirStore();
  const { uploadLargeFileChunkWithRetries } = useUploadLargeFileChunkWithRetries();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const uploadLargePrivateFileWithRetries = useCallback(
    ({ uploadId, chunk, partNumber }: { uploadId: Upload['id']; partNumber: number; chunk: Blob }) => {
      const upload = largeUploadWithS3InfoByIdSelector(store.getState(), uploadId);

      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      if (upload) {
        return uploadLargeFileChunkWithRetries({
          chunk,
          upload,
          partNumber,
          onPartError: ({ partNumber, error, upload }) => {
            reportErrorToBugsnag({
              error,
              context: 'Failed to upload large chunk',
              metadata: {
                upload,
                data: {
                  id: upload.id,
                  partNumber,
                },
              },
            });
          },
          getPartUrl: async (partNumber) => {
            const parentBoardId = privateUploadBoardIdSelector(store.getState(), upload.id);
            return fetchPrivatePartUploadUrl({
              partNumber,
              s3Info: upload.s3Info.uploadUrlInfo,
              parentBoardId,
              workspaceId,
            });
          },
        });
      }
    },
    [store, uploadLargeFileChunkWithRetries, workspaceId],
  );

  return {
    uploadLargePrivateFileWithRetries,
  };
};
