import { Auth, Boards } from '@air/api';
import { once } from 'lodash';
import Router from 'next/router';

import { saveSSOCallbackData } from '~/components/PublicSSOCallback/utils';
import { Routes } from '~/constants/routes';
import { useHandleUnauthenticatedUser } from '~/hooks/useHandleUnauthenticatedUser';
import { callOnFullAccountUserPool } from '~/utils/callOnFullAccountUserPool';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { getBoardIdFromPath } from '~/utils/PathUtils';

const handler = once(() => {
  Router.ready(async () =>
    callOnFullAccountUserPool(async () => {
      const redirect = `${window.location.pathname}${window.location.search}`;
      const boardId = getBoardIdFromPath(window.location.pathname);

      /**
       * When an unauthenticated user tries to access a board route,
       * we check if that board (via the boardId) is in a SAML-enforced workspace.
       * If it is, we get the workspaceId for that boardId and then redirect them to their SAML login.
       * If not, we redirect them to the normal login screen and store the redirect.
       */

      if (boardId) {
        try {
          const res = await Boards.getWorkspaceSAMLEnforcedByBoardId({ boardId });

          if (res.enforced) {
            saveSSOCallbackData({ url: redirect });

            const provider = Auth.convertWorkspaceIdToSAMLProvider(res.workspaceId);

            if (provider) {
              Auth.ssoSamlSignin(provider);
            } else {
              throw new Error(`Missing provider for SAML login!`);
            }
          } else {
            Router.push({ pathname: Routes.auth.login, query: { redirect } });
          }
        } catch (error) {
          reportErrorToBugsnag({
            error,
            context: 'Failed to call getWorkspaceSAMLEnforcedByBoardId',
            metadata: {
              data: {
                boardId,
                redirect,
              },
            },
          });

          Router.push({ pathname: Routes.auth.login, query: { redirect } });
        }
      } else {
        Router.push({ pathname: Routes.auth.login, query: { redirect } });
      }
    }),
  );
});

export const useHandleUnauthenticatedHomeLayoutUser = () => {
  useHandleUnauthenticatedUser({
    handleError: handler,
  });
};
