import { NotificationsFilters, NotificationsFiltersProps } from '@air/component-notifications';

export type MobileNotificationFooterProps = Required<Pick<NotificationsFiltersProps, 'filters' | 'onFilterClick'>>;

export const MobileNotificationFooter = ({ filters, onFilterClick }: MobileNotificationFooterProps) => {
  return (
    <footer className="shrink-0 border-t border-t-grey-5 px-1">
      <NotificationsFilters filters={filters} onFilterClick={onFilterClick} />
    </footer>
  );
};
