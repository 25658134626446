import { Modal } from '@air/primitive-modal';
import { useAirModal } from '@air/provider-modal';

import { LibraryDeleteAssetsModal } from '~/components/LibraryBeta/LibraryDeleteAssetsModal/LibraryDeleteAssetsModal';
import { LibraryDeleteConfirmationModalForm } from '~/components/LibraryBeta/LibraryDeleteConfirmationModal/components/LibraryDeleteConfirmationModalForm';
import { LibraryDeleteConfirmationModalHeader } from '~/components/LibraryBeta/LibraryDeleteConfirmationModal/components/LibraryDeleteConfirmationModalHeader';

export type LibraryDeleteConfirmationModalProps = {
  libraryId: string;
  name: string;
};

export const LibraryDeleteConfirmationModal = ({
  libraryId,
  name,
  onClose,
}: AirModalProps<LibraryDeleteConfirmationModalProps>) => {
  const [setShowLibraryDeleteModal] = useAirModal(LibraryDeleteAssetsModal);

  return (
    <Modal data-testid="LIBRARY_DELETE_CONFIRMATION_MODAL" isOpen onDismiss={onClose}>
      <LibraryDeleteConfirmationModalHeader onClose={onClose} />
      <LibraryDeleteConfirmationModalForm
        name={name}
        onClose={onClose}
        onSubmit={(values) => {
          if (values.name === name) {
            setShowLibraryDeleteModal({
              libraryId,
              name,
            });

            onClose();
          }
        }}
      />
    </Modal>
  );
};
