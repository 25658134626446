import image from '~/assets/people-holding-board.svg';
import { AccountAnnouncementModal } from '~/components/Modals/AccountAnnouncementModal';
import { useFixPlanAction } from '~/hooks/useFixPlanAction';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';

type MemberLimitModalProps = AirModalProps;

export const MemberLimitModal = ({ onClose }: MemberLimitModalProps) => {
  const { members } = useWorkspaceMembers();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const ctaProps = useFixPlanAction({ onShowPlansModal: onClose });

  if (!members || !currentWorkspaceMember) {
    return null;
  }

  return (
    <AccountAnnouncementModal
      withCloseButton
      onClose={onClose}
      ctaProps={ctaProps}
      title="Unlock more seats!"
      image={image}
      description="The Free plan allows up to 3 members in a workspace. Upgrade today and invite your whole team to Air!"
    />
  );
};
