import { WorkspaceMemberResponseWithRoles, WorkspaceMemberRoleDisplayName, WorkspaceMemberType } from '@air/api/types';
import { Badge } from '@air/primitive-badge';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import { isBoolean, isUndefined } from 'lodash';
import { memo, useRef } from 'react';

import { DropdownMenuWithSelectedLabelButton } from '~/components/Menus/DropdownMenuWithSelectedLabelButton';
import { usePlanFeature } from '~/hooks/usePlanFeature';
import { useAccountContext } from '~/providers/AccountProvider';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateWorkspaceMembers } from '~/swr-hooks/members/useUpdateWorkspaceMembers';
import { useWorkspaceMemberRoles } from '~/swr-hooks/members/useWorkspaceMemberRoles';
import { useReachedMemberLimit } from '~/swr-hooks/workspaces/useReachedMemberLimit';
import { canChangeWorkspaceMemberRole, canRemoveWorkspaceMembers } from '~/utils/permissions/workspacePermissions';
import { isPermissionsGTE } from '~/utils/PermissionsUtils';

import { DeactivateMemberModal } from '../Modals/DeactivateMemberModal';
import { MemberLimitModal } from '../Modals/MemberLimitModal';
import { PlansModal } from '../Modals/PlansModal/PlansModal';
import { ReactivateMemberModal } from '../Modals/ReactivateMemberModal';
import { RoleChangeConfirmationModal } from '../Modals/RoleChangeConfirmationModal';

export type MemberRoleMenuProps = {
  /** The member for which the menu will take action on */
  member: WorkspaceMemberResponseWithRoles;
};

/** This is used for changing member roles  */
export const MemberRoleMenu = memo(
  ({ member, member: { accountId, email, firstName, lastName, inactive, pending, roleId } }: MemberRoleMenuProps) => {
    const hasConfirmedChange = useRef(false);
    const { currentWorkspace } = useCurrentWorkspace();
    const { memberRoles } = useWorkspaceMemberRoles();
    const { data: account } = useAccountContext();

    const [showReactiveMemberModal] = useAirModal(ReactivateMemberModal);
    const [showDeactivateMemberModal] = useAirModal(DeactivateMemberModal);
    const [showRoleChangeConfirmationModal] = useAirModal(RoleChangeConfirmationModal);
    const [showMemberLimitModal] = useAirModal(MemberLimitModal);
    const [showPlansModal] = useAirModal(PlansModal);
    const { showToast } = useToasts();

    const { reachedMemberLimit } = useReachedMemberLimit();
    const { updateWorkspaceMembers } = useUpdateWorkspaceMembers();
    const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

    const canHaveCommenters = usePlanFeature('commentOnlyMembers');
    const currentRole = memberRoles?.find((r) => r.id === roleId);
    const isCurrentMember = account?.id === accountId;

    if (!memberRoles || !currentRole || !account || !workspacePermissions || !currentWorkspace) {
      return null;
    }

    const workspaceId = currentWorkspace.id;

    const options: DropdownMenuOption[] = [];

    const upgradeToProId = 'upgrade-to-pro';

    if (canChangeWorkspaceMemberRole(workspacePermissions)) {
      options.push(
        ...memberRoles.map(({ id, displayName, description, permissions }): DropdownMenuOption => {
          const changeRole = async () => {
            try {
              const type = WorkspaceMemberType.member;

              await updateWorkspaceMembers({
                workspaceId,
                members: [!!accountId ? { accountId, roleId: id, type } : { email, roleId: id, type }],
              });

              showToast(
                `${firstName && lastName ? `${firstName} ${lastName}’s role changed` : 'Member’s role changed'}`,
              );
            } catch (_error) {
              showToast(`Failed to update member. Please try again later.`);
            }
          };

          // if they are on the basic or plus plan, we want to upsell them to the pro plan to use a commenter feature
          return !canHaveCommenters && displayName === WorkspaceMemberRoleDisplayName.Commenter
            ? {
                id: upgradeToProId,
                onSelect: showPlansModal,
                label: (
                  <span className="flex gap-1 overflow-visible whitespace-normal">
                    {displayName}
                    <Badge className="shrink-0" color="teal">
                      Upgrade to Pro
                    </Badge>
                  </span>
                ),
                description: description || undefined,
                type: 'item',
              }
            : {
                id,
                onSelect: () => {
                  // if they selected the currently selected role, ignore change
                  if (id === currentRole.id) return;

                  // If they haven't seen the confirmation modal yet and they're about to change their own permissions,
                  // pop the modal to let them know what they're about to do.
                  if (!hasConfirmedChange.current && isCurrentMember) {
                    hasConfirmedChange.current = true;

                    return showRoleChangeConfirmationModal({
                      onConfirm: () => changeRole(),
                      currentRole: currentRole.displayName,
                      newRole: displayName,
                    });
                  }

                  changeRole();
                },
                label: displayName,
                description: description || undefined,
                disabled:
                  !isPermissionsGTE(workspacePermissions, permissions) ||
                  !isPermissionsGTE(workspacePermissions, currentRole.permissions),
                type: 'item',
              };
        }),
      );
    }

    if (!isCurrentMember) {
      if (
        canChangeWorkspaceMemberRole(workspacePermissions) &&
        inactive &&
        !pending &&
        !isUndefined(reachedMemberLimit) &&
        !reachedMemberLimit &&
        isPermissionsGTE(workspacePermissions, currentRole.permissions)
      ) {
        options.push(
          {
            type: 'separator',
            id: 'reactivate-separator',
          },
          {
            label: 'Reactivate',
            onSelect: reachedMemberLimit
              ? showMemberLimitModal
              : () => showReactiveMemberModal({ member, role: currentRole.displayName }),
            type: 'item',
            id: 'reactivate',
          },
        );
      } else if (
        canRemoveWorkspaceMembers(workspacePermissions) &&
        isPermissionsGTE(workspacePermissions, currentRole.permissions)
      ) {
        options.push(
          {
            type: 'separator',
            id: 'remove-separator',
          },
          {
            label: 'Remove from workspace',
            onSelect: () => showDeactivateMemberModal({ member }),
            type: 'item',
            className: 'text-red-10',
            id: 'remove',
          },
        );
      }
    }

    const enabled =
      options.length > 0 &&
      options.some(
        (option) =>
          option.type === 'item' && (!isBoolean(option.disabled) || !option.disabled) && option.id !== upgradeToProId,
      );

    return (
      <DropdownMenuWithSelectedLabelButton
        options={options}
        disabled={!enabled}
        selectedOption={{
          id: currentRole.id,
          label: currentRole.displayName,
        }}
      />
    );
  },
);

MemberRoleMenu.displayName = 'MemberRoleMenu';
