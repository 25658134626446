import { addErrorEventListener } from '@air/api';
import { NO_AUTH_HEADER } from '@air/errors';
import { isString } from 'lodash';
import Router from 'next/router';
import { useEffect } from 'react';

import { Routes } from '~/constants/routes';
import { NO_CURRENT_USER_ERROR } from '~/swr-hooks/account/useAccount';

interface UseHandleUnauthenticatedUserParams {
  handleError?: () => void;
}

const defaultParams: UseHandleUnauthenticatedUserParams = {
  handleError: () => {
    Router.ready(() => {
      Router.push(Routes.auth.login);
    });
  },
};

export const useHandleUnauthenticatedUser = ({ handleError }: UseHandleUnauthenticatedUserParams = defaultParams) => {
  /**
   * This is not inside of a mount useEffect because we want this to run immediately and not after
   * children have mounted so that way we can ensure our listener is setup as quickly as possible.
   */
  const removeListener = addErrorEventListener((error) => {
    if (isString(error)) {
      if (error.includes(NO_AUTH_HEADER.message || NO_CURRENT_USER_ERROR)) {
        handleError?.();
      }
    } else if (error.message.includes(NO_AUTH_HEADER.message || NO_CURRENT_USER_ERROR)) {
      handleError?.();
    }
  });

  useEffect(() => {
    return () => {
      removeListener();
    };
  }, [removeListener]);
};
