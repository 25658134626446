import { isUndefined } from 'lodash';
import Link from 'next/link';
import { memo } from 'react';

import { Routes } from '~/constants/routes';

interface GeneralLibraryMembersDescriptionProps {
  membersCount: number | undefined;
  onGoToSettingsClick: () => void;
}

export const GeneralLibraryMembersDescription = memo(
  ({ onGoToSettingsClick, membersCount }: GeneralLibraryMembersDescriptionProps) => {
    return (
      <div className="flex flex-col gap-2">
        <h6 className="text-12 font-semibold text-pigeon-500">
          Members {isUndefined(membersCount) ? '' : `(${membersCount})`}
        </h6>
        <p className="text-14 text-pigeon-600">
          All workspace members are automatically added to the default library. To manage workspace members and guests,
          go to{' '}
          <Link
            className="font-medium text-jay-600"
            href={Routes.workspaceSettings.members}
            onClick={onGoToSettingsClick}
          >
            Settings & Members
          </Link>
          .
        </p>
      </div>
    );
  },
);

GeneralLibraryMembersDescription.displayName = 'GeneralLibraryMembersDescription';
