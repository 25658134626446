import { largeUploadByIdSelector, Upload } from '@air/redux-uploader';
import { useCreateLargeUpload } from '@air/upload-utils';
import { useCallback } from 'react';

import { PrivateUploadTaskParams } from '~/components/Upload/hooks/types';
import { usePrepareForPrivateUploading } from '~/components/Upload/privateUpload/usePrepareForPrivateUploading';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { metadataForPrivateUploadSelector } from '~/store/privateUploadMetadata/selectors';
import { useAirStore } from '~/utils/ReduxUtils';
import { sendCreatePrivateMultipartUpload } from '~/utils/uploadApi/privateUploadApi/privateLargeUploadApi';

interface CreateLargePrivateUploadParams {
  uploadId: Upload['id'];
  onUploadStarted: PrivateUploadTaskParams['onUploadStarted'];
  onBoardCreated?: PrivateUploadTaskParams['onBoardCreated'];
  onError: PrivateUploadTaskParams['onError'];
}

export const useCreateLargePrivateUpload = () => {
  const store = useAirStore();
  const { createLargeUpload } = useCreateLargeUpload();
  const { prepareForUploading } = usePrepareForPrivateUploading();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const createLargePrivateUpload = useCallback(
    async ({ onBoardCreated, onUploadStarted, uploadId, onError }: CreateLargePrivateUploadParams) => {
      const upload = largeUploadByIdSelector(store.getState(), uploadId);
      if (upload) {
        await createLargeUpload({
          getLargeUploadUrlInfo: async () => {
            if (!workspaceId) {
              throw new Error('No workspace id');
            }

            const parentBoardId = await prepareForUploading({
              upload,
              onBoardCreated,
            });

            const uploadMetadata = metadataForPrivateUploadSelector(store.getState(), uploadId);

            return sendCreatePrivateMultipartUpload({
              assetId: uploadMetadata?.assetId,
              apiUploadData: upload.apiUploadData,
              parentBoardId,
              workspaceId,
            });
          },
          onError,
          upload,
          onUploadStarted,
        });
      }
    },
    [createLargeUpload, prepareForUploading, store, workspaceId],
  );

  return {
    createLargePrivateUpload,
  };
};
