import type { Board } from '@air/api/types';

import { createBoardRoute } from '~/constants/routes';
import { isUrlActiveRouteSelector } from '~/store/router/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

interface UseBoardsTreeItemIsActiveProps {
  board: Pick<Board, 'id' | 'title'>;
}

export const useBoardsTreeItemIsActive = ({ board: { id, title } }: UseBoardsTreeItemIsActiveProps) => {
  const url = createBoardRoute(id, title);
  const isActive = useAirSelector((state) => isUrlActiveRouteSelector(state, url));

  return { isActive };
};
