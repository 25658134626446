import { Board } from '@air/api/types';
import { Upload } from '@air/redux-uploader';
import { useCallback } from 'react';

import { useCreateParentBoardsForUpload } from '~/components/Upload/privateUpload/useCreateParentBoardsForUpload';
import { useGetPrivateUploadBoardId } from '~/components/Upload/privateUpload/useGetPrivateUploadBoardId';
import { metadataForPrivateUploadSelector } from '~/store/privateUploadMetadata/selectors';
import { useAirStore } from '~/utils/ReduxUtils';

export interface PrepareForUploadingParams {
  upload: Upload;
  onBoardCreated?: (board: Board) => void;
}

export const usePrepareForPrivateUploading = () => {
  const store = useAirStore();
  const { getUploadBoardId } = useGetPrivateUploadBoardId();
  const { createParentBoardsForUpload } = useCreateParentBoardsForUpload();

  const prepareForUploading = useCallback(
    async ({ upload, onBoardCreated }: PrepareForUploadingParams) => {
      let parentBoardId: string | undefined = getUploadBoardId(upload);

      const uploadMetadata = metadataForPrivateUploadSelector(store.getState(), upload.id);
      /**
       * if the upload has an origin board or filepath, we need to enforce a parentBoardId
       * (remember the use-case that they could drag a folder into library)
       */
      const requiresParentBoardId = uploadMetadata?.originBoard || uploadMetadata?.filepath;

      if (requiresParentBoardId && !parentBoardId) {
        parentBoardId = await createParentBoardsForUpload(upload, onBoardCreated);
      }
      return parentBoardId;
    },
    [createParentBoardsForUpload, getUploadBoardId, store],
  );

  return { prepareForUploading };
};
