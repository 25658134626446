import { Palette } from '@air/next-icons';
import { Avatar } from '@air/primitive-avatar';
import { Badge } from '@air/primitive-badge';
import { DropdownMenu, DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Router from 'next/router';
import { useTheme } from 'next-themes';
import { useMemo } from 'react';

import { Routes } from '~/constants/routes';
import { ACCOUNT_POPOVER_BUTTON, ACCOUNT_SETTINGS_LINK } from '~/constants/testIDs';
import { useAccountContext } from '~/providers/AccountProvider';
import { useLogout } from '~/swr-hooks/auth/useLogout';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';
import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';

import { AccountButtonSkeleton } from './AccountButtonSkeleton';

export const AccountButton = () => {
  const { data: account } = useAccountContext();
  const { logout } = useLogout();
  const { theme, setTheme } = useTheme();
  const { darkMode } = useFlags();

  const menuOptions: DropdownMenuOption[] = useMemo(() => {
    const optionsArray: DropdownMenuOption[] = [];

    optionsArray.push(
      {
        id: 'account-settings-separator',
        type: 'separator',
      },
      {
        ...getDropdownMenuOption('account-settings'),
        id: ACCOUNT_SETTINGS_LINK,
        onSelect: () => Router.push(Routes.account.myAccount),
        type: 'item',
      },
      {
        id: 'download-separator',
        type: 'separator',
      },
      // Disabled until the desktop app is stable
      // {
      //   ...getDropdownMenuOption('download-air-flow'),
      //   onSelect: () => window.open(Routes.marketing.desktop, '_blank'),
      //   type: 'item',
      // },
    );

    if (darkMode) {
      optionsArray.push({
        id: 'switch-theme',
        label: 'Switch theme',
        prefix: <Palette className="h-4 w-4" />,
        suffix: <Badge color="teal">Beta</Badge>,
        options: [
          {
            id: 'theme',
            onValueChange: (value) => setTheme(value),
            options: [
              {
                id: 'system',
                label: 'System',
                value: 'system',
                type: 'radio-item',
              },
              {
                id: 'light',
                label: 'Light',
                value: 'light',
                type: 'radio-item',
              },
              {
                id: 'dark',
                label: 'Dark',
                value: 'dark',
                type: 'radio-item',
              },
            ],
            type: 'radio-group',
            value: theme,
          },
        ],
        type: 'sub',
      });
    }

    optionsArray.push({
      ...getDropdownMenuOption('log-out'),
      onSelect: logout,
      type: 'item',
    });

    return optionsArray;
  }, [darkMode, logout, setTheme, theme]);

  if (!account) {
    return <AccountButtonSkeleton />;
  }

  const { avatar, firstName, lastName, email, id: userId } = account;

  return (
    <div className="relative px-1.5">
      <DropdownMenu
        className="w-[296px]"
        size="small"
        trigger={
          <button
            data-testid={ACCOUNT_POPOVER_BUTTON}
            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded border-none bg-transparent px-0"
          >
            <Avatar
              alt={`${firstName} ${lastName} avatar`}
              size={24}
              src={getAvatarImageSrc(avatar, 24)}
              style={{ cursor: 'pointer' }}
              text={!!firstName && !!lastName ? `${firstName[0]}${lastName[0]}` : ''}
              colorSeed={userId}
            />
          </button>
        }
      >
        <header className="mb-2 flex items-center py-1">
          <Avatar
            alt={`${firstName} ${lastName} avatar`}
            size={32}
            src={getAvatarImageSrc(avatar, 32)}
            text={!!firstName && !!lastName ? `${firstName[0]}${lastName[0]}` : ''}
            className="mr-3 shrink-0"
            colorSeed={userId}
          />
          <div>
            <div className="mb-0.5 truncate text-16 font-semibold text-grey-11">
              {firstName} {lastName}
            </div>
            <div className="truncate text-12 text-grey-9">{email}</div>
          </div>
        </header>
        {renderDropdownItems({ options: menuOptions })}
      </DropdownMenu>
    </div>
  );
};
