import classNames from 'classnames';
import { ComponentPropsWithoutRef, memo } from 'react';

export const SIDEBAR_NAVIGATION_TEST_ID = 'SIDEBAR_NAVIGATION';

export type SidebarNavigationProps = ComponentPropsWithoutRef<'div'>;

export const SidebarNavigation = memo(({ className, ...restOfProps }: SidebarNavigationProps) => {
  return (
    <div
      className={classNames('flex flex-col gap-1 px-2', className)}
      data-testid={SIDEBAR_NAVIGATION_TEST_ID}
      {...restOfProps}
    />
  );
});

SidebarNavigation.displayName = 'SidebarNavigation';
