import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps } from 'react';

export const WORKSPACE_NAV_IMAGE_AVATAR_SIZE = 32;

export type WorkspaceItemProps = ComponentProps<'img'> & {
  image: string;
};

export const WorkspaceNavImage = ({ className, image, ...restOfProps }: WorkspaceItemProps) => {
  return (
    <div className={tailwindMerge('h-8 w-8 shrink-0 overflow-hidden rounded-md', className)}>
      <img
        alt=""
        className="h-full w-full overflow-hidden object-cover"
        src={`${image}?h=${WORKSPACE_NAV_IMAGE_AVATAR_SIZE * 3}&w=${WORKSPACE_NAV_IMAGE_AVATAR_SIZE * 3}=&fit=crop`}
        {...restOfProps}
      />
    </div>
  );
};
