import { animated, useTransition } from '@react-spring/web';
import { memo } from 'react';

import { DuplicateItem } from '~/components/Modals/DuplicateItemModal';
import { DuplicateItemChoice, DuplicateItemOptions } from '~/components/Modals/DuplicateItemModal/DuplicateItemOptions';

export type DuplicateItemModalContentProps = {
  activeIndex: number;
  isApplyAll?: boolean;
  items: DuplicateItem[];
  onValueChange: (value: DuplicateItem) => void;
};

export const DuplicateItemModalContent = memo(
  ({ activeIndex, isApplyAll, items, onValueChange }: DuplicateItemModalContentProps) => {
    const transitions = useTransition(activeIndex, {
      key: activeIndex,
      from: { x: 48, opacity: 0 },
      enter: { x: 0, opacity: 1 },
      leave: { x: -48, opacity: 0 },
      config: {
        duration: 100,
      },
      exitBeforeEnter: true,
    });

    return (
      <div>
        {transitions((style, index) => {
          const item = items[index];

          return (
            <animated.div style={style}>
              <DuplicateItemOptions
                key={item.index}
                name={item.assetId}
                isApplyAll={isApplyAll}
                onValueChange={(value: DuplicateItemChoice) =>
                  onValueChange({
                    ...item,
                    choice: value,
                  })
                }
                value={item.choice}
              />
            </animated.div>
          );
        })}
      </div>
    );
  },
);

DuplicateItemModalContent.displayName = 'DuplicateItemModalContent';
