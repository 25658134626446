import { Button } from '@air/primitive-button';

export type LibraryDeleteAssetsModalFooterProps = {
  onDelete: ({ removeBoards, removeClips }: { removeBoards: boolean; removeClips: boolean }) => void;
  loading?: boolean;
};

export const LibraryDeleteAssetsModalFooter = ({ onDelete, loading }: LibraryDeleteAssetsModalFooterProps) => {
  return (
    <div className="mt-6 flex justify-end gap-3">
      <Button
        disabled={loading}
        appearance="ghost"
        color="grey"
        onClick={() => onDelete({ removeBoards: true, removeClips: false })}
        size="large"
      >
        Keep
      </Button>
      <Button
        disabled={loading}
        isLoading={loading}
        appearance="filled"
        color="red"
        onClick={() => onDelete({ removeBoards: true, removeClips: true })}
        size="large"
      >
        Delete
      </Button>
    </div>
  );
};
