import { Tasks } from '@air/api';
import { isEmpty } from 'lodash';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useReloadApplication } from '~/providers/ReloadApplicationProvider';
import { useSocketConnectionChange } from '~/providers/SocketContext/hooks/useSocketConnectionChange';
import { removeTasksAction } from '~/store/tasks/actions';
import { makeZippingTasksSelectorByWorkspaceId } from '~/store/tasks/selectors';
import { ReadyState } from '~/types/sockets';
import { useAirSelector } from '~/utils/ReduxUtils';
import { useCancelTask } from '~/utils/taskUtils/useCancelTask';
import { usePrivateSyncTasks } from '~/utils/taskUtils/usePrivateSyncTasks';

import { ZippingTasksPanel } from './ZippingTasksPanel';

export const PrivateZippingTasksPanel = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const tasksSelector = useMemo(
    () => makeZippingTasksSelectorByWorkspaceId(currentWorkspace?.id),
    [currentWorkspace?.id],
  );
  const dispatch = useDispatch();
  const zippingTasks = useAirSelector(tasksSelector);
  const { cancelTask } = useCancelTask();
  const { onAddPendingAction, onRemovePendingAction } = useReloadApplication();

  const getDownloadUrl = useCallback(
    (taskId: string | undefined) => {
      if (!taskId) {
        throw new Error('No task id');
      }

      const workspaceId = currentWorkspace?.id;

      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Tasks.ContentExporter.getDownloadUrl({ workspaceId, taskId });
    },
    [currentWorkspace?.id],
  );

  const { loadFromStorage, syncLocalTasks } = usePrivateSyncTasks({
    workspaceId: currentWorkspace?.id,
    tasksSelector,
    localType: 'ZippingForDownload',
    remoteType: 'ContentExporter',
    onComplete: async ({ task, enrichMetadata }) => {
      const { downloadUrl } = await getDownloadUrl(task.id);

      enrichMetadata({
        url: downloadUrl,
      });
    },
    onUpdate: async ({ task, enrichMetadata }) => {
      if (task.data?.secondsRemaining) {
        enrichMetadata({
          secondsRemaining: task.data.secondsRemaining,
        });
      }
    },
  });

  /**
   * On mount, take the tasks from local storage and add them to Redux
   */
  useEffect(() => {
    if (currentWorkspace?.id) {
      loadFromStorage();
    }
  }, [loadFromStorage, currentWorkspace?.id]);

  /**
   * Because sockets reconnect when the user's internet connection comes back online,
   * we don't need an explicit isOnline check but can piggyback off of the socket reconnecting
   */
  useSocketConnectionChange({
    onChange: (readyState) => {
      if (readyState === ReadyState.OPEN) {
        syncLocalTasks();
      }
    },
  });

  useEffect(() => {
    if (!isEmpty(zippingTasks)) {
      onAddPendingAction({ id: 'zipping', description: 'Zipping files' });
    } else {
      onRemovePendingAction('zipping');
    }
  }, [onAddPendingAction, onRemovePendingAction, zippingTasks]);

  /**
   * When this panel unmounts (user logs out most likely),
   * reset the state
   */
  useUnmount(() => {
    dispatch(
      removeTasksAction({
        taskIds: zippingTasks.map(({ localTaskId }) => localTaskId),
      }),
    );
  });

  if (zippingTasks.length < 1) {
    return null;
  }

  return (
    <ZippingTasksPanel
      onHandleExpiredLink={getDownloadUrl}
      zippingTasks={zippingTasks}
      onCancel={cancelTask}
      onClear={cancelTask}
    />
  );
});

PrivateZippingTasksPanel.displayName = 'PrivateZippingTasksPanel';
