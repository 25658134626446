import classNames from 'classnames';
import type { ComponentProps } from 'react';
import { memo } from 'react';

export type MemberListRowProps = ComponentProps<'li'>;

export const MemberListRow = memo(({ className, ...restOfProps }: MemberListRowProps) => (
  <li
    className={classNames('my-1 mr-1 flex min-h-[56px] items-center justify-between py-2 md:mr-0', className)}
    {...restOfProps}
  />
));

MemberListRow.displayName = 'MemberListRow';
