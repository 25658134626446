import { useCallback, useEffect, useRef } from 'react';

import { ContinuousScrolling, ContinuousScrollingParams } from '~/utils/ContinuousScrolling';

export interface UseDragScrollingParams {
  scrollElement?: ContinuousScrollingParams['scrollElement'];
}

/**
 * This hook is used to scroll a page while user is dragging and item close to top or bottom edge
 */
export function useDragScrolling({ scrollElement }: UseDragScrollingParams) {
  const mouseEvent = useRef<MouseEvent | null>();
  const scrollLogicRef = useRef<ContinuousScrolling>();

  useEffect(() => {
    scrollLogicRef.current = new ContinuousScrolling({ scrollElement });
  }, [scrollElement]);

  const onDrag = useCallback(
    (event: MouseEvent) => {
      if (
        !mouseEvent.current ||
        mouseEvent.current.clientX !== event.clientX ||
        mouseEvent.current.clientY !== event.clientY
      ) {
        mouseEvent.current = event;
        scrollLogicRef.current?.startScrolling(event);
      }
    },
    [scrollLogicRef],
  );

  const onDragEnd = useCallback(() => {
    if (mouseEvent.current) {
      mouseEvent.current = null;
      scrollLogicRef.current?.stopScrolling();

      window.removeEventListener('dragover', onDrag);
      window.removeEventListener('dragend', onDragEnd);
      window.removeEventListener('mousemove', onDragEnd);
      window.removeEventListener('mouseup', onDragEnd);
    }
  }, [scrollLogicRef, onDrag]);

  const onDragStart = useCallback(() => {
    window.addEventListener('dragover', onDrag);
    window.addEventListener('dragend', onDragEnd);
    window.addEventListener('mousemove', onDragEnd);
    window.addEventListener('mouseup', onDragEnd);
  }, [onDragEnd, onDrag]);

  useEffect(() => {
    window.addEventListener('dragstart', onDragStart);

    return () => {
      window.removeEventListener('dragstart', onDragStart);
    };
  }, [onDragStart]);
}
