import { type ComponentProps } from 'react';

export type MobileSearchMetaProps = Pick<ComponentProps<'div'>, 'children'>;

export const MobileSearchMeta = ({ children }: MobileSearchMetaProps) => {
  return (
    <div className="flex h-10 shrink-0 items-center gap-1 bg-grey-3 px-3 text-14 text-grey-9">
      <span>Searching in</span>
      <span className="font-medium">{children}</span>
    </div>
  );
};
