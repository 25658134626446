import { useMemo } from 'react';

import { useFetchAnyWorkspacePermissions } from '~/hooks/useFetchAnyWorkspacePermissions';
import { useCurrentWorkspaceAddOns } from '~/swr-hooks/workspaces/addOns/useCurrentWorkspaceAddOns';
import { canViewAddOns } from '~/utils/permissions/workspacePermissions';

export const useCanSeePrivateIPTCFields = () => {
  const { data: permissions } = useFetchAnyWorkspacePermissions();

  const { data: addOns } = useCurrentWorkspaceAddOns({ enabled: canViewAddOns(permissions) });

  const canSeePrivateIPTCFields = useMemo(
    () => !!addOns?.data.some((addOn) => addOn.key === 'metadata' && addOn.enabled && addOn.visible),
    [addOns],
  );

  return {
    canSeePrivateIPTCFields,
  };
};
