import { Boards } from '@air/api';
import { Board } from '@air/api/types';
import { InfiniteData } from '@tanstack/react-query';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import { RecentlyDeletedResponse } from '~/components/RecentlyDeleted/types';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSocketConnectionChange } from '~/providers/SocketContext/hooks/useSocketConnectionChange';
import { removeTasksAction } from '~/store/tasks/actions';
import { makeRestoreBoardTasksByWorkspaceId } from '~/store/tasks/selectors';
import { queryClient } from '~/swr-hooks/queryClient';
import { ReadyState } from '~/types/sockets';
import { useAddBoardsToAllLists } from '~/utils/mutateUtils/AllBoards';
import { useAirSelector } from '~/utils/ReduxUtils';
import { useCancelTask } from '~/utils/taskUtils/useCancelTask';
import { useHideTask } from '~/utils/taskUtils/useHideTask';
import { usePrivateSyncTasks } from '~/utils/taskUtils/usePrivateSyncTasks';

import { PaneContainer } from '../FileStatusTrackingPane/PaneContainer';
import { getRecentlyDeletedKey } from '../RecentlyDeleted/hooks/queries/useRecentlyDeleted';
import { RestoreBoardCompletedPanelItem } from './RestoreBoardTaskCompletedPanelItem';
import { RestoreBoardFailedPanelItem } from './RestoreBoardTaskFailedPanelItem';
import { RestoreBoardPanelInProgressPanelItem } from './RestoreBoardTaskInProgressPanelItem';

export const PrivateRestoreBoardTasksPanel = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const tasksSelector = useMemo(() => makeRestoreBoardTasksByWorkspaceId(currentWorkspace?.id), [currentWorkspace?.id]);
  const dispatch = useDispatch();
  const restoreBoardTasks = useAirSelector(tasksSelector);
  const { cancelTask } = useCancelTask();
  const { hideTask } = useHideTask();

  const { addBoardsToAllLists } = useAddBoardsToAllLists();

  const { loadFromStorage, syncLocalTasks } = usePrivateSyncTasks({
    workspaceId: currentWorkspace?.id,
    tasksSelector,
    localType: 'BoardRestoration',
    remoteType: 'ContentRestorer',
    onComplete: async ({ task }) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      const recentlyDeletedKey = getRecentlyDeletedKey(workspaceId);
      queryClient.setQueriesData<InfiniteData<RecentlyDeletedResponse> | undefined>(
        {
          queryKey: recentlyDeletedKey,
        },
        (oldData) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                data: page.data.filter(
                  (item) => item.itemType !== 'board' || !task.data?.restoredBoardIds?.includes(item.board.id),
                ),
              };
            }),
          };
        },
      );

      const allRestoredBoards = await Boards.listByIds({
        boardIds: task.data?.restoredBoardIds || [],
        workspaceId,
      });

      const boardsGroupedByParent = allRestoredBoards.reduce((acc, board) => {
        const parentId = board.parentId;
        if (!acc.has(parentId)) {
          acc.set(parentId, []);
        }
        acc.get(parentId)?.push(board);

        return acc;
      }, new Map<string | null, Board[]>());

      boardsGroupedByParent.forEach((boards, parentId) => {
        addBoardsToAllLists(boards, parentId);
      });
    },
  });

  /**
   * On mount, take the tasks from local storage and add them to Redux
   */
  useEffect(() => {
    if (currentWorkspace?.id) {
      loadFromStorage();
    }
  }, [loadFromStorage, currentWorkspace?.id]);

  /**
   * Because sockets reconnect when the user's internet connection comes back online,
   * we don't need an explicit isOnline check but can piggyback off of the socket reconnecting
   */
  useSocketConnectionChange({
    onChange: (readyState) => {
      if (readyState === ReadyState.OPEN) {
        syncLocalTasks();
      }
    },
  });

  /**
   * When this panel unmounts (user logs out most likely),
   * reset the state
   */
  useUnmount(() => {
    dispatch(
      removeTasksAction({
        taskIds: restoreBoardTasks.map(({ localTaskId }) => localTaskId),
      }),
    );
  });

  if (restoreBoardTasks.length < 1) {
    return null;
  }

  return (
    <div>
      {restoreBoardTasks.map((duplicateBoardTask) => {
        return (
          <PaneContainer key={duplicateBoardTask.localTaskId} className="mb-2 last:mb-0">
            {!duplicateBoardTask.hidden && duplicateBoardTask.status === 'in-progress' && (
              <RestoreBoardPanelInProgressPanelItem
                onCancel={() => hideTask(duplicateBoardTask.localTaskId)}
                {...duplicateBoardTask}
              />
            )}
            {duplicateBoardTask.status === 'completed' && (
              <RestoreBoardCompletedPanelItem
                onClear={() => cancelTask(duplicateBoardTask.localTaskId)}
                {...duplicateBoardTask}
              />
            )}
            {duplicateBoardTask.status === 'error' && (
              <RestoreBoardFailedPanelItem
                onClear={() => cancelTask(duplicateBoardTask.localTaskId)}
                {...duplicateBoardTask}
              />
            )}
          </PaneContainer>
        );
      })}
    </div>
  );
});

PrivateRestoreBoardTasksPanel.displayName = 'PrivateDuplicateBoardTasksPanel';
