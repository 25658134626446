import { Button } from '@air/primitive-button';
import { Modal, ModalTitle } from '@air/primitive-modal';
import { Spinner } from '@air/primitive-spinner';
import { useAirModal } from '@air/provider-modal';
import classNames from 'classnames';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { CUSTOM_FIELD_DELETE, CUSTOM_FIELD_MODAL_CANCEL, CUSTOM_FIELD_MODAL_SAVE } from '~/constants/testIDs';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canDeleteCustomFields } from '~/utils/permissions/workspacePermissions';

import { CustomFieldDeleteModal } from '../CustomFieldDeleteModal';
import { CustomFieldModalForm, CustomFieldModalFormProps } from './CustomFieldModalForm/CustomFieldModalForm';
import { CustomFieldModalFormData } from './CustomFieldModalForm/types';

export interface CustomFieldModalProps extends Pick<CustomFieldModalFormProps, 'modalType' | 'colors'> {
  onClose: () => void;
  initialFormValues: CustomFieldModalFormData;
  handleSubmit: (values: CustomFieldModalFormData) => void;
  handleDelete?: () => Promise<void>;
  isLoading: boolean;
  isDataLoading?: boolean;
  fieldTypes?: CustomFieldModalFormProps['fieldTypes'];
  modalType: 'edit' | 'create';
}
export const CUSTOM_FIELD_MODAL_FORM_ID = 'customFieldModalForm';
export const CUSTOM_FIELD_MODAL_FORM_OPTIONS = 'values';
export const CUSTOM_FIELD_MODAL_FORM_DESCRIPTION = 'description';
export const CUSTOM_FIELD_MODAL_FORM_TITLE = 'name';
export const CUSTOM_FIELD_MODAL_FORM_FIELD_TYPE = 'fieldTypeId';

const customFieldSchema = Yup.object()
  .shape({
    name: Yup.string().required('Title is required').max(40, 'Title must be no more than 40 characters').default(''),
    description: Yup.string().default('').max(170, 'Description must be no more than 170 characters'),
    values: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().max(255, 'Text must be no more than 35 characters'),
          color: Yup.object().shape({
            backgroundHex: Yup.string(),
            fontHex: Yup.string(),
            id: Yup.string(),
            name: Yup.string(),
          }),
        }),
      )
      .min(1, 'Field must contain at least 1 option'),
  })
  .required();

export const CustomFieldModal = ({
  modalType,
  onClose,
  fieldTypes,
  initialFormValues,
  colors,
  handleSubmit,
  handleDelete,
  isLoading,
  isDataLoading,
}: CustomFieldModalProps) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isSaveFocused, setIsSaveFocused] = useState(false);
  const [showDeleteModal] = useAirModal(CustomFieldDeleteModal);
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canDelete = handleDelete && canDeleteCustomFields(permissions);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isSaveFocused) saveButtonRef.current?.focus();
  }, [isSaveFocused]);

  return (
    <Modal dangerouslyBypassFocusLock isOpen size="medium">
      <ModalTitle>{modalType === 'edit' ? 'Edit' : 'Create'} a custom field</ModalTitle>
      {!fieldTypes || isDataLoading ? (
        <div className="mt-6 flex justify-center">
          <Spinner className="text-teal-9" />
        </div>
      ) : (
        <Formik initialValues={initialFormValues} validationSchema={customFieldSchema} onSubmit={handleSubmit}>
          <CustomFieldModalForm
            modalType={modalType}
            setIsSaveDisabled={setIsSaveDisabled}
            setIsSaveFocused={setIsSaveFocused}
            colors={colors}
            fieldTypes={fieldTypes}
          />
        </Formik>
      )}
      <div className={classNames('mt-4 flex items-center', canDelete ? 'justify-between' : 'justify-end')}>
        {canDelete && (
          <Button
            appearance="ghost"
            color="red"
            className={modalType === 'edit' ? 'visible' : 'invisible'}
            data-testid={CUSTOM_FIELD_DELETE}
            onClick={() => showDeleteModal({ handleConfirmDeleteClick: handleDelete })}
            size="large"
          >
            Delete
          </Button>
        )}
        <div className="flex items-center gap-2">
          <Button
            appearance="ghost"
            color="grey"
            data-testid={CUSTOM_FIELD_MODAL_CANCEL}
            onClick={onClose}
            size="large"
          >
            Cancel
          </Button>
          <Button
            appearance="filled"
            color="blue"
            data-testid={CUSTOM_FIELD_MODAL_SAVE}
            disabled={isSaveDisabled}
            form={CUSTOM_FIELD_MODAL_FORM_ID}
            isLoading={isLoading}
            ref={saveButtonRef}
            size="large"
            type="submit"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
