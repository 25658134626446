import { type NotificationClip as NotificationClipType } from '@air/api/types';
import { memo, useCallback } from 'react';

import { NotificationClip } from '~/components/AirNotifications/components/NotificationClip';
import {
  NotificationItemsThumbnails,
  NotificationItemsThumbnailsProps,
} from '~/components/AirNotifications/components/NotificationItemsThumbnails';

export type NotificationClipsProps = {
  clips: NotificationClipType[];
  totalClips: number;
};

export const NotificationClips = memo(({ clips, totalClips }: NotificationClipsProps) => {
  const renderClip = useCallback<NotificationItemsThumbnailsProps<NotificationClipType>['renderItem']>(
    (clip) => <NotificationClip clip={clip} key={clip.id} />,
    [],
  );

  return <NotificationItemsThumbnails renderItem={renderClip} totalItemsCount={totalClips} items={clips} />;
});

NotificationClips.displayName = 'NotificationClips';
