import { BrokenLink as BrokenLinkIcon, Link as LinkIcon } from '@air/next-icons';
import classNames from 'classnames';

export type NotificationShareLinkIconProps = {
  isBroken?: boolean;
};

export const NotificationShareLinkIcon = ({ isBroken }: NotificationShareLinkIconProps) => {
  return (
    <div
      className={classNames(
        'flex h-8 w-8 items-center justify-center rounded-full bg-grey-4',
        isBroken ? 'text-flamingo-700' : 'text-pigeon-400',
      )}
    >
      {isBroken ? <BrokenLinkIcon className="h-5 w-5" /> : <LinkIcon className="h-5 w-5" />}
    </div>
  );
};
