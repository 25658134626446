import { Board } from '@air/api/types';
import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentPropsWithoutRef } from 'react';
import { useDrop } from 'react-dnd';

import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';

import { boardDragTypes, DropData } from '../dragTypes';

export interface DNDBoardsRootInterface extends Pick<Board, 'id'> {
  ancestors: Board['id'][];
}

export type DNDBoardsRootProps = Pick<ComponentPropsWithoutRef<'div'>, 'children' | 'className'>;

export const DNDBoardsRoot = ({ className, children }: DNDBoardsRootProps) => {
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const canCreateRootBoards = canCreateBoard(permissions);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: boardDragTypes,
    drop: (): DropData => ({
      type: 'move',
      location: 'sub-nav-rearrange',
    }),
    collect: (monitor) => {
      const item = monitor.getItem();
      /**
       * Checks if the board that is being dropped is at the root level.
       */
      const isNotRootBoard = item?.firstItem?.library?.id || item?.firstItem?.ancestors?.length !== 0;

      return {
        isOver: monitor.isOver(),
        canDrop: canCreateRootBoards && isNotRootBoard,
      };
    },
  });

  return (
    <div
      className={tailwindMerge(
        'group/droppable-item relative h-full',
        isOver && canDrop ? 'hovering-drop' : undefined,
        className,
      )}
      ref={drop}
    >
      {children}
    </div>
  );
};
