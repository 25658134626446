import Router from 'next/router';
import { useEffect } from 'react';

import { Routes } from '~/constants/routes';
import { useWorkspaceRedirectState } from '~/hooks/useWorkspaceRedirectState';

export const useHandleUserNeedsRedirect = () => {
  const { workspaceRedirectState } = useWorkspaceRedirectState();

  useEffect(() => {
    if (workspaceRedirectState === 'needsRedirect') {
      Router.push(Routes.redirectWorkspace);
    }
  }, [workspaceRedirectState]);
};
