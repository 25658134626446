import { ReactNode, useMemo } from 'react';

export const NOTIFICATION_CLIPS_MAX_COUNT = 5;

export interface NotificationItemsThumbnailsProps<T extends { id: string }> {
  maxCount?: number;
  renderItem: (item: T) => ReactNode;
  totalItemsCount: number;
  items: T[];
}

export function NotificationItemsThumbnails<T extends { id: string }>({
  renderItem,
  totalItemsCount,
  maxCount = NOTIFICATION_CLIPS_MAX_COUNT,
  items,
}: NotificationItemsThumbnailsProps<T>) {
  const formattedItems = useMemo(() => {
    if (totalItemsCount > maxCount) {
      return items.slice(0, maxCount);
    } else {
      return items;
    }
  }, [items, maxCount, totalItemsCount]);

  return (
    <div className="flex gap-3">
      {formattedItems?.map((item, index) => {
        if (totalItemsCount > maxCount && index === maxCount - 1) {
          return (
            <div className="relative" key={item.id || index}>
              {renderItem(item)}
              <div className="absolute inset-0 flex items-center justify-center rounded bg-pigeon-900/60 text-14 font-medium text-white">
                +{totalItemsCount - maxCount}
              </div>
            </div>
          );
        }

        return renderItem(item);
      })}
    </div>
  );
}

NotificationItemsThumbnails.displayName = 'NotificationItemsThumbnails';
