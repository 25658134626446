import { Workspaces } from '@air/api';
import { isAdminDotAirDotInc } from '@air/sysadmin';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getWorkspacesListKey } from '~/swr-hooks/workspaces/useWorkspaces';

import { useHandleNoWorkspaces } from './useHandleNoWorkspaces';

export const useCheckIfUserHasWorkspaces = () => {
  const { data: workspaces, isFetched } = useQuery({
    queryKey: getWorkspacesListKey(),
    queryFn: () => Workspaces.list(),
    enabled: !isAdminDotAirDotInc(),
  });

  const dispatch = useDispatch();
  const { handleNoWorkspaces } = useHandleNoWorkspaces();

  useEffect(() => {
    if (!workspaces?.length && isFetched) {
      handleNoWorkspaces();
    }
  }, [dispatch, handleNoWorkspaces, isFetched, workspaces?.length]);
};
