import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import classNames from 'classnames';
import { type ComponentProps, type ReactNode } from 'react';

export type MobileSearchTokenProps = Omit<ComponentProps<'div'>, 'prefix'> & {
  onRemove?: () => void;
  prefix?: ReactNode;
};

export const MobileSearchToken = ({ children, onRemove, prefix }: MobileSearchTokenProps) => {
  return (
    <div
      className={classNames(
        'flex items-center gap-2 rounded bg-grey-5 py-1 text-14 font-medium text-grey-11',
        !!children ? 'px-2' : 'px-1',
      )}
    >
      {!!prefix && <div>{prefix}</div>}
      {!!children && <div>{children}</div>}
      {!!onRemove && (
        <IconButton appearance="ghost" color="grey" icon={Close} label="Remove" onClick={onRemove} size="extra-small" />
      )}
    </div>
  );
};
