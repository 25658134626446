import { useTrackSavedFilterDeleted } from '@air/analytics';
import { SavedFilter } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';
import Router from 'next/router';

import { Routes } from '~/constants/routes';
import { useSavedFilterIdSelector } from '~/hooks/useSavedFilterIdSelector';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';

import { useDeleteSavedFilter } from './shared/hooks/useDeleteSavedFilter';

export interface SavedFilterDeleteModalProps extends AirModalProps {
  savedFilter: SavedFilter;
  onDelete?: () => void;
}

export const DeleteSavedFilterModal = ({ onClose, savedFilter, onDelete }: SavedFilterDeleteModalProps) => {
  const { deleteSavedFilter } = useDeleteSavedFilter();
  const savedFilterBeingViewedId = useSavedFilterIdSelector();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const { trackSavedFilterDeleted } = useTrackSavedFilterDeleted();

  const onClick = async () => {
    deleteSavedFilter.mutate(savedFilter.id, {
      onSuccess: () => {
        trackSavedFilterDeleted({
          currentUser: currentWorkspaceMember?.accountId || '',
          role: currentWorkspaceMember?.role?.type || '',
          createdBy: savedFilter.createdBy,
        });

        onClose();
        onDelete?.();
        if (savedFilter.id === savedFilterBeingViewedId) {
          Router.push(Routes.media.all);
        }
      },
    });
  };

  const modalDescription = (
    <div className="flex flex-wrap gap-1 text-14">
      <span className="whitespace-nowrap">This will permanently delete</span>
      <span className="truncate font-semibold">{savedFilter.name}.</span>
      <span className="whitespace-nowrap">This will not delete any assets.</span>
    </div>
  );

  return (
    <ConfirmationModal
      id="delete-saved-filter-modal"
      isOpen
      onDismiss={onClose}
      title="Delete saved filter"
      description={modalDescription}
      ctaPrimaryText="Confirm Delete"
      onConfirm={onClick}
      isDangerous
      isLoading={deleteSavedFilter.isPending}
      isDisabled={deleteSavedFilter.isPending}
    />
  );
};
