import { AirActionTrackingLocation, TrackClickedConnectToSlackOptionParams } from '@air/analytics';
import { Board } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import { useMemo } from 'react';

import { EditBoardModal } from '~/components/EditPrivateBoardModal/EditBoardModal/EditBoardModal';
import { useConnectToSlack } from '~/components/EditPrivateBoardModal/EditBoardModal/SlackIntegration/hooks/useConnectToSlack';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useBoardSlackIntegrations } from '~/swr-hooks/slack/useBoardSlackIntegrations';
import { canChangeBoardIntegrationDetails } from '~/utils/permissions/boardPermissions';

export const useSlackMenuOptions = (board?: Board) => {
  const [showEditBoardModal] = useAirModal(EditBoardModal);
  const { data } = useBoardSlackIntegrations(board?.id);
  const { connectToSlack } = useConnectToSlack();
  const { boardPermissions } = useBoardPermissions({ boardId: board?.id });
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  const { showToast } = useToasts();

  const canManageSlackIntegration = canChangeBoardIntegrationDetails({
    workspaceContext: workspacePermissions,
    boardContext: boardPermissions,
  });

  const onConnectToSlack = useMemo(
    () =>
      board &&
      data?.length === 0 &&
      canManageSlackIntegration /* show "connect to slack" only when we get empty data from API*/
        ? async (trackLocation: TrackClickedConnectToSlackOptionParams['trackLocation']) => {
            try {
              await connectToSlack({ board, trackLocation });
            } catch {
              showToast('There was an error with slack authentication. Please try again later', {
                timeBeforeAutoDismiss: undefined,
                type: 'assertive',
                withCloseButton: true,
              });
            }
          }
        : undefined,
    [data, canManageSlackIntegration, board, connectToSlack, showToast],
  );

  const onConnectedToSlack = useMemo(
    () =>
      board && !!data?.[0]
        ? (trackLocation: AirActionTrackingLocation) => {
            showEditBoardModal({ board, trackLocation });
          }
        : undefined,
    [data, board, showEditBoardModal],
  );
  return {
    onConnectToSlack,
    onConnectedToSlack,
    slackIntegrationData: board && !!data ? data[0] : undefined,
  };
};
