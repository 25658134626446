import { ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';

export type LibraryDeleteConfirmationModalHeaderProps = {
  onClose: () => void;
};

export const LibraryDeleteConfirmationModalHeader = ({ onClose }: LibraryDeleteConfirmationModalHeaderProps) => {
  return (
    <header className="flex flex-col gap-1">
      <div className="flex gap-1">
        <ModalTitle>Are you sure you&apos;d like to delete this library?</ModalTitle>
        <ModalCloseButton className="shrink-0" onClick={onClose} />
      </div>
      <ModalDescription>
        The library and all the boards inside of it will be permanently deleted. Comments made in those boards will also
        be deleted.
      </ModalDescription>
    </header>
  );
};
