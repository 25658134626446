import { DropdownMenuOption } from '@air/primitive-dropdown-menu';

import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';

interface GetHelpMenuOptionsParams {
  onChatWithSales: () => void;
  onChatWithSupport: () => void;
  onHelpCenter: () => void;
  onLeaveFeedback?: () => void;
}

export const getHelpMenuOptions = ({
  onChatWithSales,
  onChatWithSupport,
  onHelpCenter,
  onLeaveFeedback,
}: GetHelpMenuOptionsParams): DropdownMenuOption[] => {
  const optionsArray: DropdownMenuOption[] = [
    {
      ...getDropdownMenuOption('help-center'),
      onSelect: onHelpCenter,
      type: 'item',
    },
    {
      ...getDropdownMenuOption('chat-with-support'),
      onSelect: onChatWithSupport,
      type: 'item',
    },
    {
      ...getDropdownMenuOption('meet-with-sales'),
      onSelect: onChatWithSales,
      type: 'item',
    },
  ];

  if (!!onLeaveFeedback) {
    optionsArray.push({
      ...getDropdownMenuOption('leave-feedback'),
      onSelect: onLeaveFeedback,
      type: 'item',
    });
  }

  return optionsArray;
};
