import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentPropsWithoutRef, memo } from 'react';

export type SidebarFooterProps = ComponentPropsWithoutRef<'footer'>;

export const SidebarFooter = memo(({ className, ...restOfProps }: SidebarFooterProps) => {
  return (
    <footer
      className={tailwindMerge(
        'mx-2 flex items-center justify-between gap-2 border-t border-t-grey-5 px-2 py-3',
        className,
      )}
      data-testid="SIDEBAR_FOOTER"
      {...restOfProps}
    />
  );
});

SidebarFooter.displayName = 'SidebarFooter';
