import { CustomFields } from '@air/api';
import { CustomFieldTypeName } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { getCustomFieldTypesKey } from '~/constants/react-query-keys';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { canSeeCustomFields } from '~/utils/permissions/workspacePermissions';

const CUSTOM_FIELD_TYPE_WHITELIST = [
  CustomFieldTypeName.singleSelect,
  CustomFieldTypeName.multiSelect,
  CustomFieldTypeName.plainText,
  CustomFieldTypeName.date,
];

export const useGetCustomFieldTypes = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  return useQuery({
    queryKey: getCustomFieldTypesKey(workspaceId),

    queryFn: () => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return CustomFields.listCustomFieldTypes({ workspaceId }).then(({ data, ...rest }) => ({
        data: data.filter(({ title }) => CUSTOM_FIELD_TYPE_WHITELIST.includes(title)),
        ...rest,
      }));
    },
    enabled: !!workspaceId && canSeeCustomFields(workspacePermissions),
  });
};
