import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentPropsWithoutRef, memo } from 'react';

export type SidebarHeaderProps = ComponentPropsWithoutRef<'header'>;

export const SidebarHeader = memo(({ className, ...restOfProps }: SidebarHeaderProps) => {
  return (
    <header
      className={tailwindMerge(
        'mx-2 flex h-16 items-center justify-between gap-2 border-b border-b-grey-5 p-2',
        className,
      )}
      data-testid="SIDEBAR_HEADER"
      {...restOfProps}
    />
  );
});

SidebarHeader.displayName = 'SidebarHeader';
