import classNames from 'classnames';
import { Form } from 'formik';
import { ReactNode } from 'react';

import { CUSTOM_FIELD_MODAL_FORM_ID } from '../CustomFieldModal';

export interface CustomFieldModalFormContainer {
  innerScroll?: boolean;
  children: ReactNode;
}

export const CustomFieldModalFormContainer = ({ children, innerScroll }: CustomFieldModalFormContainer) => {
  return (
    <Form
      id={CUSTOM_FIELD_MODAL_FORM_ID}
      data-testid="CUSTOM_FIELD_MODAL"
      className={classNames('relative max-h-[500px] px-px py-1', innerScroll ? 'overflow-y-auto' : '')}
    >
      {children}
    </Form>
  );
};
