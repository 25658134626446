import { Trash } from '@air/next-icons';
import { Button } from '@air/primitive-button';

export type LibraryAdvancedSettingsDeleteLibraryProps = {
  onDelete?: () => void;
};

export const LibraryAdvancedSettingsDeleteLibrary = ({ onDelete }: LibraryAdvancedSettingsDeleteLibraryProps) => {
  return (
    <div className="flex flex-col items-start gap-4" data-testid="LIBRARY_SETTINGS_DELETE_LIBRARY_BUTTON">
      <div className="flex flex-col gap-1">
        <h3 className="text-10 font-bold uppercase tracking-wide text-grey-12">Delete library</h3>
        <p className="text-14 text-grey-10">Permanently delete all boards and remove members from the library</p>
      </div>

      <Button appearance="outline" color="red" onClick={onDelete} prefix={<Trash className="h-4 w-4" />}>
        Delete library
      </Button>
    </div>
  );
};
