import { tailwindMerge } from '@air/tailwind-variants';
import type { ComponentProps } from 'react';
import { memo } from 'react';

export interface CountBadgeProps extends Pick<ComponentProps<'div'>, 'children' | 'className' | 'style'> {
  count: number;
}

export const CountBadge = memo(({ className, count, children, style }: CountBadgeProps) => {
  return (
    <div className={tailwindMerge('relative z-[9] mx-auto w-fit', className)} style={style}>
      {count > 0 && (
        <div className="absolute left-4 z-[2] flex h-4 min-w-[16px] items-center justify-center rounded-full border border-grey-1 bg-red-9 px-1 text-10 font-bold uppercase tracking-wide text-white">
          {count > 99 ? '+99' : count}
        </div>
      )}

      {children}
    </div>
  );
});

CountBadge.displayName = 'CountBadge';
