import { Notifications } from '@air/api';
import { type ListNotificationsResponse } from '@air/api/types';
import { type InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { getNotificationsKey } from '~/components/AirNotifications/hooks/useNotifications';
import { markNotificationAsReadInCache } from '~/components/AirNotifications/utils/markNotificationAsReadInCache';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const key = getNotificationsKey(currentWorkspace?.id);

  const markNotificationAsRead = useCallback(
    async (notificationId: string) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      queryClient.setQueryData<InfiniteData<ListNotificationsResponse> | undefined>(key, (data) =>
        markNotificationAsReadInCache(data, notificationId),
      );
      await Notifications.readNotification({ workspaceId, notificationId });
      queryClient.invalidateQueries({ queryKey: key });
    },
    [key, queryClient, currentWorkspace?.id],
  );

  return { markNotificationAsRead };
};
