import type { Board } from '@air/api/types';
import { EllipsisVertical as EllipsisVerticalIcon, Plus as PlusIcon } from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import { memo } from 'react';

import { DropdownMenuWithActionSheet } from '~/components/Menus/DropdownMenuWithActionSheet';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useCreateSubBoard } from '~/hooks/useCreateSubBoard';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';

interface BoardsTreeItemProps {
  board: Board;
  menuOptions: DropdownMenuOption[];
  onMenuChange: (isOpen: boolean) => void;
}

export const BoardsTreeItemOptions = memo(({ board, menuOptions, onMenuChange }: BoardsTreeItemProps) => {
  const { createSubBoard } = useCreateSubBoard();
  const { boardPermissions } = useBoardPermissions({
    boardId: board.id,
  });
  const canAddSubBoard = canCreateBoard(boardPermissions);
  const plusButtonTooltip = 'Create New Sub-Board';

  if (!canAddSubBoard && menuOptions.length === 0) {
    return null;
  }

  return (
    <div className="flex min-w-min content-end">
      {menuOptions.length > 0 && (
        <DropdownMenuWithActionSheet
          data-testid={`board-title-submenu-${board.id}`}
          isTitleHidden
          onChange={onMenuChange}
          options={menuOptions}
          title="More board options"
          trigger={
            <IconButton
              data-testid="SIDE_NAV_BOARD_MENU_BUTTON"
              icon={EllipsisVerticalIcon}
              size="small"
              className="shrink-0"
              color="grey"
              appearance="ghost"
              label="More options"
            />
          }
        />
      )}

      {canAddSubBoard && (
        <Tooltip label={plusButtonTooltip} side="right">
          <IconButton
            data-testid="BOARD_TREE_ITEM_ADD_SUB_BOARD_PLUS_BUTTON"
            icon={PlusIcon}
            label={plusButtonTooltip}
            size="small"
            color="grey"
            appearance="ghost"
            onClick={() =>
              createSubBoard({ selectedParentBoard: board, trackLocation: 'side-nav-extension-item-button' })
            }
          />
        </Tooltip>
      )}
    </div>
  );
});

BoardsTreeItemOptions.displayName = 'BoardsTreeItemOptions';
