import { ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';

export type LibraryDeleteAssetsModalHeaderProps = {
  name: string;
  onClose: () => void;
};

export const LibraryDeleteAssetsModalHeader = ({ name, onClose }: LibraryDeleteAssetsModalHeaderProps) => {
  return (
    <header className="flex flex-col gap-2">
      <div className="flex gap-1">
        <ModalTitle>Would you like to delete assets that are only in this library?</ModalTitle>
        <ModalCloseButton className="shrink-0" onClick={onClose} />
      </div>
      <ModalDescription>
        There are assets that only existed in <b className="font-bold">{name}</b>. Would you like to permanently delete
        these assets from the entire workspace? If you keep them, they will be accessible by all members in{' '}
        <b className="font-bold">All assets & files</b>.
      </ModalDescription>
    </header>
  );
};
