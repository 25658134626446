import { useAirModal } from '@air/provider-modal';
import { activeUploadsSelector, isFinishedUploadingSelector, isLarge } from '@air/redux-uploader';
import { useDeleteAllUploads, usePauseAllUploads, useResumeAllUploads } from '@air/upload-utils';
import { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UploadsTrackingPane, UploadsTrackingPaneProps } from 'src/components/Upload/UploadsTrackingPane';

import { CancelAllUploadsModal } from '~/components/Modals/CancelAllUploads';
import { usePrivateUploadManager } from '~/components/Upload/hooks/usePrivateUploadManager';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useReloadApplication } from '~/providers/ReloadApplicationProvider';
import { uploadedDestinationSummarySelector } from '~/store/privateUploadMetadata/selectors';
import { useAirStore } from '~/utils/ReduxUtils';
import { sendAbortPrivateMultipartUpload } from '~/utils/uploadApi/privateUploadApi/privateLargeUploadApi';

export const PrivateUploadManager = memo(() => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const activeUploads = useSelector(activeUploadsSelector);
  const { onAddPendingAction, onRemovePendingAction } = useReloadApplication();

  const { deleteAllUploads } = useDeleteAllUploads();
  const { pauseAllUploads } = usePauseAllUploads();
  const { resumeAllUploads } = useResumeAllUploads();
  const [showCancelAllUploadsModal, closeCancelAllUploadsModal] = useAirModal(CancelAllUploadsModal);
  const summaryBoard = useSelector(uploadedDestinationSummarySelector);

  const store = useAirStore();

  useEffect(() => {
    if (activeUploads.length > 0) {
      onAddPendingAction({ id: 'upload', description: 'Uploading files' });
    } else {
      onRemovePendingAction('upload');
    }
  }, [activeUploads, onAddPendingAction, onRemovePendingAction]);

  const onDelete = useCallback<Required<UploadsTrackingPaneProps>['onDeleteUpload']>(
    async (upload) => {
      if (!workspaceId) {
        return;
      }

      if (isLarge(upload) && upload.s3Info.uploadUrlInfo) {
        sendAbortPrivateMultipartUpload({ uploadUrlInfo: upload.s3Info.uploadUrlInfo, workspaceId });
      }
    },
    [workspaceId],
  );

  usePrivateUploadManager();

  return (
    <UploadsTrackingPane
      summaryLocation={summaryBoard?.title || currentWorkspace?.name}
      onCancelAllUploads={() => {
        const isFinished = isFinishedUploadingSelector(store.getState());

        pauseAllUploads();

        if (isFinished) {
          deleteAllUploads();
        } else {
          showCancelAllUploadsModal({
            onContinueUpload: () => {
              resumeAllUploads();
            },
            onCancelUpload: () => {
              deleteAllUploads();
              closeCancelAllUploadsModal();
            },
          });
        }
      }}
      onDeleteUpload={onDelete}
    />
  );
});

PrivateUploadManager.displayName = 'PrivateUploadManager';
