import type { ReactNode } from 'react';

export type LibraryDetailsAccessibilityProps = {
  description: string;
  label: string;
  icon: ReactNode;
};

export const LibraryDetailsAccessibility = ({ description, icon, label }: LibraryDetailsAccessibilityProps) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-1 text-14 font-medium text-pigeon-600">
        {icon}
        {label}
      </div>

      <p className="text-12 text-pigeon-500">{description}</p>
    </div>
  );
};
