import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { hideTaskAction } from '~/store/tasks/actions';

export const useHideTask = () => {
  const dispatch = useDispatch();

  const hideTask = useCallback(
    (localTaskId: string) => {
      dispatch(hideTaskAction({ localTaskId }));
    },
    [dispatch],
  );

  return {
    hideTask,
  };
};
