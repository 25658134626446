import { Auth } from '@air/api';
import { useIsLoggedIn } from '@air/utils-auth';
import { useRouter } from 'next/router';
import { memo, ReactNode } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { SocketContextProvider } from '~/providers/SocketContext/SocketContext';

export interface PrivateSocketContextProviderProps {
  children: ReactNode;
}

export const PrivateSocketContextProvider = memo(({ children }: PrivateSocketContextProviderProps) => {
  const { isReady } = useRouter();
  const { isLoggedIn } = useIsLoggedIn();
  const { currentWorkspace } = useCurrentWorkspace();

  return (
    <SocketContextProvider
      connect={isReady && isLoggedIn}
      getAuthToken={Auth.getJWTToken}
      currentWorkspaceId={currentWorkspace?.id}
    >
      {children}
    </SocketContextProvider>
  );
});

PrivateSocketContextProvider.displayName = '_PrivateSocketContextProvider';
