import { Images as ImagesIcon } from '@air/next-icons';
import { useToasts } from '@air/provider-toast';
import type { ReactNode } from 'react';
import { useCallback } from 'react';

export const useLibraryToasts = () => {
  const { showToast } = useToasts();

  const showLibraryToast = useCallback(
    ({ message, type = 'info' }: { message: ReactNode; type?: 'error' | 'info' }) => {
      showToast(message, {
        color: type === 'error' ? 'red' : 'black',
        prefix: <ImagesIcon className="h-4 w-4" />,
        withCloseButton: true,
      });
    },
    [showToast],
  );

  return { showLibraryToast };
};
