import { useTrackMarkedNotificationAsRead, useTrackNavigatedToItemFromNotification } from '@air/analytics';
import { NotificationFilter } from '@air/api/types';
import { NotificationsList } from '@air/component-notifications';
import { Spinner } from '@air/primitive-spinner';
import { useCallback } from 'react';

import { NotificationEmpty } from '~/components/AirNotifications/components/NotificationEmpty';
import { NotificationAssetCustomFieldUpdatedType } from '~/components/AirNotifications/components/types/NotificationAssetCustomFieldUpdatedType';
import { NotificationBoardCreatedType } from '~/components/AirNotifications/components/types/NotificationBoardCreatedType';
import { NotificationBoardCustomFieldUpdatedType } from '~/components/AirNotifications/components/types/NotificationBoardCustomFieldUpdatedType';
import { NotificationClipCreatedType } from '~/components/AirNotifications/components/types/NotificationClipCreatedType';
import { NotificationClipDownloadedType } from '~/components/AirNotifications/components/types/NotificationClipDownloadedType';
import { NotificationCommentMentionedType } from '~/components/AirNotifications/components/types/NotificationCommentMentionedType';
import { NotificationCommentType } from '~/components/AirNotifications/components/types/NotificationCommentType';
import { NotificationItemsDownloadedType } from '~/components/AirNotifications/components/types/NotificationItemsDownloadedType';
import { NotificationLibraryMemberInvitedType } from '~/components/AirNotifications/components/types/NotificationLibraryMemberInvitedType';
import { NotificationLibraryMemberRequestedType } from '~/components/AirNotifications/components/types/NotificationLibraryMemberRequestedType';
import { NotificationLibraryMemberUpdatedType } from '~/components/AirNotifications/components/types/NotificationLibraryMemberUpdatedType';
import { NotificationMemberInvitedType } from '~/components/AirNotifications/components/types/NotificationMemberInvitedType';
import { NotificationShareLinkExpiredType } from '~/components/AirNotifications/components/types/NotificationShareLinkExpiredType';
import { NotificationShareLinkExpiringType } from '~/components/AirNotifications/components/types/NotificationShareLinkExpiringType';
import { NotificationShareLinkNotDownloadedType } from '~/components/AirNotifications/components/types/NotificationShareLinkNotDownloadedType';
import { NotificationShareLinkNotViewedType } from '~/components/AirNotifications/components/types/NotificationShareLinkNotViewedType';
import { NotificationShareLinkViewedType } from '~/components/AirNotifications/components/types/NotificationShareLinkViewed';
import { useMarkNotificationAsRead } from '~/components/AirNotifications/hooks/useMarkNotificationAsRead';
import { useNotifications } from '~/components/AirNotifications/hooks/useNotifications';
import { AccountNotificationsType } from '~/components/AirNotifications/utils/types';
import { useHandleNotification } from '~/hooks/useHandleNotification';

export type AirNotificationListProps = {
  filter?: NotificationFilter;
  onNotificationClick?: (notification: AccountNotificationsType) => void;
};

export const AirNotificationList = ({ filter, onNotificationClick }: AirNotificationListProps) => {
  const {
    data: notifications,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isInitialLoading,
  } = useNotifications({ filter });
  const { markNotificationAsRead } = useMarkNotificationAsRead();
  const { trackMarkedNotificationAsRead } = useTrackMarkedNotificationAsRead();
  const { trackNavigatedToItemFromNotification } = useTrackNavigatedToItemFromNotification();
  const { handleNotification } = useHandleNotification();

  const handleClick = useCallback(
    (notification: AccountNotificationsType) => {
      handleNotification(notification);
      markNotificationAsRead(notification.id);
      !notification.readAt && trackMarkedNotificationAsRead();
      trackNavigatedToItemFromNotification({ type: notification.type });
      onNotificationClick?.(notification);
    },
    [
      handleNotification,
      markNotificationAsRead,
      onNotificationClick,
      trackMarkedNotificationAsRead,
      trackNavigatedToItemFromNotification,
    ],
  );

  if (isInitialLoading) {
    return (
      <div className="flex h-full items-center justify-center py-10 text-peacock-400">
        <Spinner />
      </div>
    );
  }

  return (
    <NotificationsList<AccountNotificationsType>
      onFetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      notifications={notifications}
      renderLoader={() => (
        <div className="flex items-center justify-center py-4 text-teal-9">
          <Spinner />
        </div>
      )}
      renderNotification={({ notification }) => {
        switch (notification.type) {
          case 'commentCreated_userIsMentioned':
            return (
              <NotificationCommentMentionedType notification={notification} onClick={() => handleClick(notification)} />
            );
          case 'commentCreated_userIsBoardFollower':
          case 'commentCreated_userIsShareLinkCreator':
          case 'commentCreated_userIsDiscussionParticipant':
            return <NotificationCommentType notification={notification} onClick={() => handleClick(notification)} />;
          case 'boardAppended_userIsBoardFollower':
            return (
              <NotificationBoardCreatedType notification={notification} onClick={() => handleClick(notification)} />
            );
          case 'clipAppended_userIsBoardFollower':
          case 'clipAppended_userIsShareLinkCreator':
            return (
              <NotificationClipCreatedType notification={notification} onClick={() => handleClick(notification)} />
            );
          case 'clipDownloaded_userIsShareLinkCreator':
            return (
              <NotificationClipDownloadedType notification={notification} onClick={() => handleClick(notification)} />
            );
          case 'itemsDownloaded_userIsShareLinkCreator':
            return (
              <NotificationItemsDownloadedType notification={notification} onClick={() => handleClick(notification)} />
            );
          case 'memberInvited_userIsMember':
            return (
              <NotificationMemberInvitedType notification={notification} onClick={() => handleClick(notification)} />
            );
          case 'assetCustomFieldUpdated_userIsBoardFollower':
          case 'assetCustomFieldUpdated_userIsShareLinkCreator':
            return (
              <NotificationAssetCustomFieldUpdatedType
                notification={notification}
                onClick={() => handleClick(notification)}
              />
            );
          case 'boardCustomFieldUpdated_userIsBoardFollower':
          case 'boardCustomFieldUpdated_userIsShareLinkCreator':
            return (
              <NotificationBoardCustomFieldUpdatedType
                notification={notification}
                onClick={() => handleClick(notification)}
              />
            );
          case 'libraryMemberInvited_userIsMember':
            return (
              <NotificationLibraryMemberInvitedType
                notification={notification}
                onClick={() => handleClick(notification)}
              />
            );
          case 'libraryMemberInvited_userIsAdmin':
            return (
              <NotificationLibraryMemberRequestedType
                notification={notification}
                onApprove={() => markNotificationAsRead(notification.id)}
                onClick={() => handleClick(notification)}
                onDeny={() => markNotificationAsRead(notification.id)}
              />
            );
          case 'libraryMemberUpdated_userIsMember':
            return (
              <NotificationLibraryMemberUpdatedType
                notification={notification}
                onClick={notification.data.update === 'approved' ? () => handleClick(notification) : undefined}
              />
            );
          case 'shareLinkViewed_userIsShareLinkCreator':
            return (
              <NotificationShareLinkViewedType notification={notification} onClick={() => handleClick(notification)} />
            );
          case 'shareLinkExpiring_userIsShareLinkCreator':
            return (
              <NotificationShareLinkExpiringType
                notification={notification}
                onClick={() => handleClick(notification)}
              />
            );
          case 'shareLinkExpired_userIsShareLinkCreator':
            return (
              <NotificationShareLinkExpiredType notification={notification} onClick={() => handleClick(notification)} />
            );
          case 'shareLinkNotViewed_userIsShareLinkCreator':
            return (
              <NotificationShareLinkNotViewedType
                notification={notification}
                onClick={() => handleClick(notification)}
              />
            );
          case 'shareLinkNotDownloaded_userIsShareLinkCreator':
            return (
              <NotificationShareLinkNotDownloadedType
                notification={notification}
                onClick={() => handleClick(notification)}
              />
            );

          default:
            return null;
        }
      }}
      renderNullState={() => <NotificationEmpty />}
    />
  );
};
