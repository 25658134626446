import { WorkspaceMemberResponseWithRoles } from '@air/api/types';
import { useMemo } from 'react';

import { useFetchMembers } from './useFetchMembers';

export const useUserByAccountIdOrEmail = ({
  userId,
}: {
  userId: WorkspaceMemberResponseWithRoles['accountId'] | WorkspaceMemberResponseWithRoles['email'];
}): { user: WorkspaceMemberResponseWithRoles | undefined } => {
  const { data: members } = useFetchMembers();

  const user = useMemo(
    () => members?.find(({ email, accountId }) => email === userId || accountId === userId),
    [members, userId],
  );

  return {
    user,
  };
};
