import { Notifications } from '@air/api';
import { type ListNotificationsResponse } from '@air/api/types';
import { type InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { getNotificationSummaryKey } from '~/components/AirNotifications/hooks/useGetNotificationSummary';
import { getNotificationsKey } from '~/components/AirNotifications/hooks/useNotifications';
import { markAllNotificationsInCacheAsRead } from '~/components/AirNotifications/utils/markAllNotificationsInCacheAsRead';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const useMarkAllNotificationsAsRead = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const notificationKey = getNotificationsKey(currentWorkspace?.id);
  const notificationSummaryKey = getNotificationSummaryKey(currentWorkspace?.id);

  const markAllNotificationsAsRead = useCallback(async () => {
    const workspaceId = currentWorkspace?.id;
    if (!workspaceId) {
      throw new Error('No workspace id');
    }
    queryClient.setQueryData<InfiniteData<ListNotificationsResponse> | undefined>(
      notificationKey,
      markAllNotificationsInCacheAsRead,
    );

    await Notifications.readAllNotifications({ workspaceId });

    queryClient.invalidateQueries({ queryKey: notificationKey });
    queryClient.invalidateQueries({ queryKey: notificationSummaryKey });
  }, [queryClient, notificationKey, notificationSummaryKey, currentWorkspace?.id]);

  return { markAllNotificationsAsRead };
};
