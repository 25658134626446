import { WorkspaceMemberRoleDisplayName, WorkspaceUserRole } from '@air/api/types';
import { FormikField } from '@air/formik-field';
import { Badge } from '@air/primitive-badge';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { useAirModal } from '@air/provider-modal';
import { Field, Formik } from 'formik';
import { isUndefined } from 'lodash';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';

import Form from '~/components/Form';
import { DropdownMenuWithSelectedLabelButton } from '~/components/Menus/DropdownMenuWithSelectedLabelButton';
import { usePlanFeature } from '~/hooks/usePlanFeature';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { useWorkspaceMemberRoles } from '~/swr-hooks/members/useWorkspaceMemberRoles';
import { useReachedMemberLimit } from '~/swr-hooks/workspaces/useReachedMemberLimit';
import { getLeastPermissiveRole, isPermissionsGTE } from '~/utils/PermissionsUtils';

import { PlansModal } from '../../PlansModal/PlansModal';

const InviteSchema = Yup.object()
  .shape({
    email: Yup.string().email().required('Please enter a valid email address'),
    selectedRole: Yup.object<WorkspaceUserRole>().required('Please choose a role'),
  })
  .required();

export interface InviteByEmailInputProps {
  submitMember: ({ email, selectedRole }: { email: string; selectedRole: WorkspaceUserRole }) => void;
  onError: (error: unknown) => void;
}

interface EmailInviteFormValues {
  email: string;
  selectedRole: WorkspaceUserRole;
}

export const InviteByEmailInput = ({ submitMember, onError }: InviteByEmailInputProps) => {
  const [loading, setLoading] = useState(false);
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const { reachedMemberLimit } = useReachedMemberLimit();
  const { memberRoles } = useWorkspaceMemberRoles();
  const [showPlansModal] = useAirModal(PlansModal);
  const canHaveCommenters = usePlanFeature('commentOnlyMembers');
  const { data: subscription } = useSubscriptionContext();
  const hasSubscriptionIssue = subscription?.expirationReason ? true : false;
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  const leastPermissiveRole = useMemo(() => {
    const roles = canHaveCommenters
      ? memberRoles
      : memberRoles?.filter((role) => role.displayName !== WorkspaceMemberRoleDisplayName.Commenter);
    return roles ? getLeastPermissiveRole(roles) : undefined;
  }, [canHaveCommenters, memberRoles]);

  if (
    !currentWorkspaceMember ||
    isUndefined(reachedMemberLimit) ||
    !memberRoles ||
    !workspacePermissions ||
    !leastPermissiveRole
  ) {
    return null;
  }

  const initialFormValues: EmailInviteFormValues = {
    email: '',
    selectedRole: leastPermissiveRole,
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={InviteSchema}
      initialValues={initialFormValues}
      onSubmit={async ({ email, selectedRole }, { setErrors, resetForm }) => {
        setLoading(true);

        try {
          await submitMember({ email, selectedRole });
          resetForm({
            values: {
              ...initialFormValues,
              selectedRole,
            },
          });
        } catch (error) {
          setErrors({ email: 'Failed to add members. Please try again later.' });
          onError(error);
        } finally {
          setLoading(false);
        }
      }}
    >
      {({ values: { selectedRole, email }, setFieldValue }) => {
        return (
          <Form>
            <div className="flex items-end">
              <div className="relative mb-6 mt-4 flex grow items-center">
                <FormikField
                  className="w-full"
                  component={
                    <Input
                      className="w-full"
                      style={{
                        // This prevents text from going under the [ Editor v ]
                        // list menu that renders next to the Input. The prop
                        // `adornment` was attempted first, however it has a
                        // fixed width so this was easier. Please replace with a
                        // Zephyr-esque implementation in the future.
                        paddingRight: 125,
                      }}
                      data-1p-ignore
                      data-lpignore
                      type="email"
                      required
                      disabled={reachedMemberLimit || hasSubscriptionIssue}
                      placeholder="Email"
                      size="extra-large"
                    />
                  }
                  id="email"
                  isLabelHidden
                  label="Email"
                  name="email"
                />

                <div className="absolute right-2 top-1/2 -translate-y-1/2">
                  <Field name="selectedRole" id="selectedRole">
                    {() => (
                      <DropdownMenuWithSelectedLabelButton
                        selectedOption={{
                          id: selectedRole.id,
                          label: selectedRole.displayName,
                        }}
                        options={memberRoles.map((role) => {
                          const { displayName, description, id } = role;

                          return !canHaveCommenters && displayName === WorkspaceMemberRoleDisplayName.Commenter
                            ? {
                                id,
                                onSelect: showPlansModal,
                                label: (
                                  <span className="flex gap-1 overflow-visible whitespace-normal">
                                    {displayName}
                                    <Badge className="shrink-0" color="teal">
                                      Upgrade to Pro
                                    </Badge>
                                  </span>
                                ),
                                description: description || undefined,
                                type: 'item',
                              }
                            : {
                                id,
                                onSelect: () => setFieldValue('selectedRole', role),
                                label: displayName,
                                description: description || undefined,
                                disabled: !isPermissionsGTE(workspacePermissions, role.permissions),
                                type: 'item',
                              };
                        })}
                        disabled={reachedMemberLimit || hasSubscriptionIssue}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <Button
                appearance="filled"
                className="mb-6 ml-3 shrink-0"
                color="blue"
                disabled={!email || !selectedRole || reachedMemberLimit || hasSubscriptionIssue}
                isLoading={loading}
                size="extra-large"
                type="submit"
              >
                Invite
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
