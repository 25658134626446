export type MobileSearchListTitleProps = {
  title: string;
};

export const MobileSearchListTitle = ({ title }: MobileSearchListTitleProps) => {
  return (
    <div className="flex h-9 items-center gap-3 px-3 text-12 font-bold uppercase tracking-wider text-grey-9">
      {title}
    </div>
  );
};
