import { WorkspaceMemberResponse, WorkspaceUserRole } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';
import { useToasts } from '@air/provider-toast';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useState } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useReactivateWorkspaceMembers } from '~/swr-hooks/members/useReactivateWorkspaceMembers';
import { sanitizeEmail } from '~/utils/EmailUtils';

interface ReactivateMemberModalProps {
  member: Pick<WorkspaceMemberResponse, 'firstName' | 'lastName' | 'accountId' | 'email'>;
  role: WorkspaceUserRole['displayName'];
}

export const ReactivateMemberModal = ({
  member: { firstName, lastName, accountId, email },
  role,
  onClose,
}: AirModalProps<ReactivateMemberModalProps>) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { showToast } = useToasts();
  const [reactivating, setReactivating] = useState(false);
  const { reactivateWorkspaceMembers } = useReactivateWorkspaceMembers();

  const onReactivate = async () => {
    setReactivating(true);
    try {
      await reactivateWorkspaceMembers({
        members: [accountId ? { accountId, inactive: false } : { email: sanitizeEmail(email), inactive: false }],
      });

      onClose();
      showToast(`${firstName} ${lastName} was reactivated`);
    } catch (error) {
      reportErrorToBugsnag({
        error,
        context: `Reactivating of members failed`,
        metadata: {
          accountId,
          email: sanitizeEmail(email),
        },
      });
      showToast('There was an error trying to reactivate this member. Please try again later.');
      setReactivating(false);
    }
  };

  return (
    <ConfirmationModal
      isOpen
      id="reactivate-member-modal"
      title={`Reactivate ${firstName && lastName ? `${firstName} ${lastName}` : 'this member'}?`}
      description={`They will be reactivated in the "${currentWorkspace?.name}" workspace with the "${role}" role.`}
      ctaPrimaryText="Reactivate"
      isLoading={reactivating}
      isDisabled={reactivating}
      onConfirm={onReactivate}
      onDismiss={onClose}
    />
  );
};
