import { grey, greyDark, red, redDark } from '@air/colors';
import { useTheme } from 'next-themes';
import { useEffect, useMemo, useState } from 'react';
import { CardElement } from 'react-stripe-elements';

import { CreditCardsIcons } from '~/components/Workspace/Checkout/PaymentFields/CreditCardsIcons';

interface Props {
  disabled?: boolean;
}

const PaymentFields = ({ disabled }: Props) => {
  const theme = useTheme();

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const isDark = theme?.theme === 'dark';

  const fieldStyles = useMemo(
    () => ({
      base: {
        color: isDark ? greyDark.grey12 : grey.grey12,
        '::placeholder': { color: isDark ? greyDark.grey8 : grey.grey8 },
      },
      invalid: {
        color: isDark ? redDark.red10 : red.red10,
      },
    }),
    [isDark],
  );

  if (!isMounted) return null;

  return (
    <div className="flex w-full flex-col text-14 font-medium text-grey-10">
      <div className="mb-2 flex justify-between">
        <div className="text-12 text-grey-11">Credit card or debit card</div>
        <CreditCardsIcons alt="visa, mastercard and american express" />
      </div>
      <CardElement style={fieldStyles} {...{ disabled }} />
      <div className="mt-2.5 hidden whitespace-nowrap text-14 font-normal text-red-9">This value is invalid.</div>
    </div>
  );
};

export default PaymentFields;
