import { ItemToRearrange, RearrangableItem } from '~/components/Shared/Drag/dragTypes';

export const calculateRearrangeData = (
  items: { id: string }[],
  draggedItem: ItemToRearrange,
  adjacentItem: RearrangableItem,
) => {
  const hoverIndex = adjacentItem.direction === 'after' ? adjacentItem.index + 1 : adjacentItem.index;
  const draggingRight = hoverIndex > draggedItem.index;

  const newIndex = draggingRight ? hoverIndex - 1 : hoverIndex;

  const nextItemId = items[hoverIndex - 1]?.id;
  const previousItemId = items[hoverIndex]?.id;
  return { newIndex, nextItemId, previousItemId };
};
