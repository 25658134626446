import { tailwindMerge } from '@air/tailwind-variants';
import { HTMLAttributes, memo } from 'react';

export const LibraryMembersListLabel = memo(({ className, ...props }: HTMLAttributes<HTMLHeadElement>) => {
  return (
    <h4 className={tailwindMerge('text-10 font-bold uppercase tracking-wide text-grey-12', className)} {...props} />
  );
});

LibraryMembersListLabel.displayName = 'LibraryMembersListLabel';
